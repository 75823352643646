export type SET_TOTAL_AMOUNT = "checkout.SET_TOTAL_AMOUNT";
export const SET_TOTAL_AMOUNT: SET_TOTAL_AMOUNT = "checkout.SET_TOTAL_AMOUNT";
export type SET_ORIGINAL_AMOUNT = "checkout.SET_ORIGINAL_AMOUNT";
export const SET_ORIGINAL_AMOUNT: SET_ORIGINAL_AMOUNT =
  "checkout.SET_ORIGINAL_AMOUNT";
export type SET_CREDIT_BALANCE = "checkout.SET_CREDIT_BALANCE";
export const SET_CREDIT_BALANCE: SET_CREDIT_BALANCE =
  "checkout.SET_CREDIT_BALANCE";
export type SET_CREDIT_SUBBALANCE = "checkout.SET_CREDIT_SUBBALANCE";
export const SET_CREDIT_SUBBALANCE: SET_CREDIT_SUBBALANCE =
  "checkout.SET_CREDIT_SUBBALANCE";

export type PROMOCODE_VALIDATION_SERVICE_INIT =
  "checkout.PROMOCODE_VALIDATION_SERVICE_INIT";
export const PROMOCODE_VALIDATION_SERVICE_INIT: PROMOCODE_VALIDATION_SERVICE_INIT =
  "checkout.PROMOCODE_VALIDATION_SERVICE_INIT";
export type PROMOCODE_VALIDATION_SUCCESS =
  "checkout.PROMOCODE_VALIDATION_SUCCESS";
export const PROMOCODE_VALIDATION_SUCCESS: PROMOCODE_VALIDATION_SUCCESS =
  "checkout.PROMOCODE_VALIDATION_SUCCESS";
export type PROMOCODE_VALIDATION_FAILURE =
  "checkout.PROMOCODE_VALIDATION_FAILURE";
export const PROMOCODE_VALIDATION_FAILURE: PROMOCODE_VALIDATION_FAILURE =
  "checkout.PROMOCODE_VALIDATION_FAILURE";

export type PROMOCODE_CLEAR = "checkout.PROMOCODE_CLEAR";
export const PROMOCODE_CLEAR: PROMOCODE_CLEAR = "checkout.PROMOCODE_CLEAR";

export type CLEAR_CHECKOUT = "checkout.CLEAR_CHECKOUT";
export const CLEAR_CHECKOUT: CLEAR_CHECKOUT = "checkout.CLEAR_CHECKOUT";

export type BOOKING_FEE_ATTEMPT = "checkout.BOOKING_FEE_ATTEMPT";
export const BOOKING_FEE_ATTEMPT: BOOKING_FEE_ATTEMPT = "checkout.BOOKING_FEE_ATTEMPT";
export type BOOKING_FEE_SUCCESS = "checkout.BOOKING_FEE_SUCCESS";
export const BOOKING_FEE_SUCCESS: BOOKING_FEE_SUCCESS = "checkout.BOOKING_FEE_SUCCESS";
