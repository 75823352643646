import * as React from "react";
import styled, { keyframes } from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  color?: string;
}

const bouncedelay = keyframes`
  {
    0%, 80%, 100% {
      transform: scale(0);
  } 40% {
      transform: scale(1.0);
    }
  }
`;

const SimpleLoaderWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  > div {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    background-color: ${(props: IProps) =>
      props.color ? props.color : theme.color.ruubyBlack};
    border-radius: 100%;
    animation: ${bouncedelay} 1.8s infinite ease-in-out both;
  }
`;

const SimpleLoaderBounce1 = styled.div`
  &&& {
    animation-delay: -0.32s;
  }
`;
const SimpleLoaderBounce2 = styled.div`
  &&& {
    animation-delay: -0.16s;
  }
`;
const SimpleLoaderBounce3 = styled.div``;

const SimpleLoader: React.FC<IProps> = (props) => {
  return (
    <SimpleLoaderWrapper color={props.color} data-testid="simple-loader">
      <SimpleLoaderBounce1 />
      <SimpleLoaderBounce2 />
      <SimpleLoaderBounce3 />
    </SimpleLoaderWrapper>
  );
};

export { SimpleLoader };
