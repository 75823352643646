import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import * as dropin from "braintree-web-drop-in";

import { getBraintreeToken } from "../../services/api/payment";
import { theme } from "../../theme/new";

type UseBraintreeDropin = (params: {
  total: number;
  onApplePayConfirmed?: () => Promise<void>;
}) => {
  isLoading: boolean;
  isPaymentRequestable: boolean;
  isCancelled: boolean;
  dropinContainerRef: RefObject<HTMLDivElement>;
  requestPaymentNonce: <T extends {amount: string;}>(threeDSecure: T) => Promise<string | undefined>;
  clearPaymentMethod: () => void;
  error?: string;
}

export const useBraintreeDropin: UseBraintreeDropin = ({
  total,
  onApplePayConfirmed,
}) => {
  const [ braintreeInstance, setBraintreeInstance ] = useState<dropin.Dropin | null>(null);
  const [ braintreeClientToken, setBraintreeClientToken ] = useState<string>();
  const dropinContainerRef = useRef<HTMLDivElement>(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPaymentRequestable, setIsPaymentRequestable ] = useState(false);
  const [ isCancelled, setIsCancelled ] = useState(false); 
  const [ error, setError ] = useState<string>();
  
  useEffect(() => {
    getBraintreeToken()
      .then(token => setBraintreeClientToken(token));
  }, []);

  useEffect(() => {
    if (braintreeInstance) {
      setIsPaymentRequestable(braintreeInstance.isPaymentMethodRequestable());

      const paymentMethodRequestable = async ({ type }) => {
        setIsPaymentRequestable(true);

        if (type === "ApplePayCard") {
          await onApplePayConfirmed?.();
        }
      };

      const noPaymentMethodRequestable = () => 
        setIsPaymentRequestable(false);

      const cancelled3dSecure = () => {
        braintreeInstance.clearSelectedPaymentMethod();
        setIsCancelled(true);
        setIsPaymentRequestable(false);
      };

      braintreeInstance.on("paymentMethodRequestable", paymentMethodRequestable);
      braintreeInstance.on("noPaymentMethodRequestable", noPaymentMethodRequestable);
      braintreeInstance.on("3ds:customer-canceled", cancelled3dSecure);

      return () => {
        braintreeInstance.off("paymentMethodRequestable", paymentMethodRequestable);
        braintreeInstance.off("noPaymentMethodRequestable", noPaymentMethodRequestable);
        braintreeInstance.off("3ds:customer-canceled", cancelled3dSecure);
      }
    }
  }, [braintreeInstance]);  

  useEffect(() => {
    if (dropinContainerRef?.current && braintreeClientToken && !braintreeInstance) {
      setIsLoading(true);
      setIsPaymentRequestable(false);

      dropin.create({
        applePay: {
          displayName: "Ruuby",
          paymentRequest: {
            requiredBillingContactFields: ["postalAddress"],
            total: {
              amount: total.toString(),
              label: "Ruuby",
            },
            countryCode: "GB",
            currencyCode: "GBP",
            supportedNetworks: ["visa", "masterCard", "amex"],
            merchantCapabilities: ["supports3DS", "supportsCredit"],
          },
        },
        authorization: braintreeClientToken,
        card: {
          cardholderName: { required: true },
          overrides: {
            fields: {
              cvv: {
                placeholder: "Security Code",
                type: "password",
              },
            },
            styles: {
              input: {
                color: `${theme.colours.ruubyBlack}`,
                "font-size": "14px",
                "font-weight": 400,
                height: "48px",
              },
            },
          },
        },
        container: dropinContainerRef.current,
        threeDSecure: true,
        vaultManager: true,
      })
      .then(dropinInstance => setBraintreeInstance(dropinInstance))
      .catch(error => setError(error.message))
      .finally(() =>  setIsLoading(false));  
    }

    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown(() => {});
        setBraintreeInstance(null);
      }
    };        
  }, [dropinContainerRef, braintreeClientToken, total, braintreeInstance]);  

  const requestPaymentNonce = useCallback(
    async <T extends {amount: string;}>(threeDSecure: T) => {
    setIsCancelled(false);

    if (braintreeInstance) {
      const { nonce } = await braintreeInstance.requestPaymentMethod({
        threeDSecure,
      });

      return nonce;
    }
  }, [braintreeInstance, setIsCancelled]);
  
  const clearPaymentMethod = useCallback(() => {
    setBraintreeInstance(null);
    setIsPaymentRequestable(false);
    setError(undefined);
  }, [setBraintreeInstance, setIsPaymentRequestable, setError]); 

  return {
    isLoading,
    isPaymentRequestable,
    dropinContainerRef,
    isCancelled,
    error,
    requestPaymentNonce,
    clearPaymentMethod,
  };
};