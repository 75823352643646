import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";

import { device } from "../../../../../../../utils";
import { IconChevron } from "../../../../../../atoms";
import { AvailabilitySlot } from "../../../../therapist-detail-page/types";

const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colours.ruubyLightGrey};
  padding: 15px 0;
  cursor: default;
  position: relative;
`;

const Mask = styled.div`
  mask-image: linear-gradient(
    to right,
    transparent 0,
    black 15px calc(100% - 15px),
    transparent 100%
  );
  
  @media ${device.laptop} {
    & .swiper {
      margin: 0 40px 0 41px;
    }
  } 
`;

const TimeSlot = styled.div`
  width: 82px;
  height: 35px;
  background-color: ${({ theme }) => theme.colours.background.greyFaint};
  border: 1px solid ${({ theme }) => theme.colours.ruubyLightGrey};
  border-radius: ${({ theme }) => theme.type.sizes.size8};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: bold;
  color: ${({ theme }) => theme.colours.textMain};
  text-align: center;
  line-height: 34px;
  cursor: pointer;
`;

const ButtonCss = css`
  display: none;
  
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    height: 37px;
    width: 28px;
    z-index: 10;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colours.ruubyLightGrey};
    border-radius: ${({ theme }) => theme.type.sizes.size8};
    background-color: ${({ theme }) => theme.colours.background.white};

    &.disabled,
    &.swiper-button-lock {
      opacity: 0;
      cursor: default;
      pointer-events: none;
    }
  }
`;

const SwiperButtonNext = styled.div`
  ${ButtonCss}
  right: 5px;
`;

const SwiperButtonPrevious = styled.div`
  ${ButtonCss}
  left: 5px;

  svg {
    transform: rotate(180deg);
  }
`;

const SlideElement = styled(SwiperSlide)`
  &&& {
    width: fit-content;
  }

  :first-child {
    margin-left: 15px;
  }

  :last-child {
    margin-right: 15px;
  }
  
  @media ${device.laptop} {
    :first-child,
    :last-child {
      margin: 0;
    }   
  }
`;

interface Props {
  therapistUrn: string;
  availability: AvailabilitySlot[];
  onClick(therapistUrnOrSlug: string, slotTime?: string): void;
}

export const TherapistTimeWidget = ({
  availability,
  therapistUrn,
  onClick,
}: Props): JSX.Element => {
  const id = useMemo(uuid, []);
  
  const swiperProps = useMemo(() => ({
    modules: [Navigation],
    navigation: {
      nextEl: `#next-${id}`,
      prevEl: `#previous-${id}`,
      disabledClass: "disabled",
    },
  }), [id]);

  const timeSlots = useMemo(() => 
    availability.map(({ start }) => {
      const slotTime = format(start, "HH:mm");

      return (
        <SlideElement>
          <TimeSlot 
            onClick={() => onClick(therapistUrn, slotTime)}
          >
            {slotTime}
          </TimeSlot>
        </SlideElement>
      );
    })
  , [availability]);

  return (
    <Container>
      <SwiperButtonPrevious id={`previous-${id}`}>
        <IconChevron />
      </SwiperButtonPrevious>
      <SwiperButtonNext id={`next-${id}`}>
        <IconChevron />
      </SwiperButtonNext>

      <Mask>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={9}
          {...swiperProps}
        >
          {timeSlots}
        </Swiper>
      </Mask>
    </Container>
  );
};