import React, { useCallback, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentForm } from "../../../../organisms/stripe-payment-form";
import { PaymentButton } from "../../../../molecules/payment-button";
import { PaymentSuccess } from "../.././components/payment-success";
import { ErrorText } from "../../../../atoms/errors/error-text";
import styled from "styled-components";
import { BookingData } from "../../use-payment-requested";
import { formatPriceByCurrency } from "../../../../../../utils/format-price-by-currency";

const Error = styled(ErrorText)`
  margin-bottom: ${({ theme }) => theme.type.sizes.size24};
`;

interface StripeCheckoutProps {
  bookingData: BookingData;
  isPaymentCompleted: boolean;
  onPaymentComplete: () => void;
}

export const StripeCheckout = ({
  bookingData,
  isPaymentCompleted,
  onPaymentComplete,
}: StripeCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentAllowed, setIsPaymentAllowed] = useState(false);

  const handleError = useCallback((message?: string) => {
    setError(message ?? "Something went wrong. Please try again.");
    setIsLoading(false);
  }, []);

  const handleCheckout = useCallback(async () => {
    setError(null);
    setIsLoading(true);

    if (!stripe || !elements) {
      handleError();
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError.message);
      return;
    }

    if (!bookingData || !bookingData.paymentIntent || !bookingData.address) {
      handleError("Something went wrong.");
      setIsLoading(false);
      return;
    }

    const { host, protocol } = window.location;
    const { error: stripeError } = await stripe.confirmPayment({
      elements,
      clientSecret: bookingData.paymentIntent,
      confirmParams: {
        return_url: `${protocol}//${host}`,
      },
      redirect: "if_required",
    });

    if (stripeError) {
      handleError(stripeError.message);
      return;
    }

    setIsLoading(false);
    onPaymentComplete();
  }, [stripe, elements, bookingData, onPaymentComplete]);

  if (isPaymentCompleted) {
    return (
      <PaymentSuccess
        size="big"
        text="Payment successful! Your booking is secured."
      />
    );
  }

  return (
    <>
      {Boolean(error) && <Error>{error}</Error>}
      <StripePaymentForm
        showSavedPaymentMethods={false}
        showSavePaymentMethod={false}
        onPaymentAllowedChange={setIsPaymentAllowed}
      />
      <PaymentButton
        price={formatPriceByCurrency(
          bookingData.outstandingAmount,
          bookingData.address.postcodeDistrict.region.country.currency,
          bookingData.address.postcodeDistrict.region.country.country,
          bookingData.address.postcodeDistrict.region.country.languageCode,
        )}
        onClick={handleCheckout}
        isLoading={isLoading}
        isDisabled={!isPaymentAllowed}
      />
    </>
  );
};
