import {
  fetchUserCreditBalance,
  IPromotion,
} from "../../services/api/promotion";
import { ActionDispatch } from "../index";

import * as types from "./action-types";

export const actionCreators = {
  clearCheckout: () => ({
    type: types.CLEAR_CHECKOUT,
  }),
  promocodeClear: () => ({
    type: types.PROMOCODE_CLEAR,
  }),
  promocodeValidationFailure: (error: Error) => ({
    payload: { error },
    type: types.PROMOCODE_VALIDATION_FAILURE,
  }),
  promocodeValidationServiceInit: () => ({
    type: types.PROMOCODE_VALIDATION_SERVICE_INIT,
  }),
  promocodeValidationSuccess: (data: IPromotion) => ({
    payload: { data },
    type: types.PROMOCODE_VALIDATION_SUCCESS,
  }),
  setCreditBalance: (data: number) => ({
    payload: { data },
    type: types.SET_CREDIT_BALANCE,
  }),
  setCreditSubBalance: (data: number) => ({
    payload: { data },
    type: types.SET_CREDIT_SUBBALANCE,
  }),
  setOriginalAmount: (data: number) => ({
    payload: { data },
    type: types.SET_ORIGINAL_AMOUNT,
  }),
  setTotalAmount: (data: number) => ({
    payload: { data },
    type: types.SET_TOTAL_AMOUNT,
  }),
  attemptBookingFee: () => ({
    type: types.BOOKING_FEE_ATTEMPT,
  }),
  setBookingFee: (data: number) => ({
    payload: { data },
    type: types.BOOKING_FEE_SUCCESS,
  }),
};

/**
 * Get customer's credit balance
 */
export function getCreditBalance(): ActionDispatch<void> {
  return async (dispatch) => {
    const balance = await fetchUserCreditBalance();
    dispatch(actionCreators.setCreditBalance(balance));
    dispatch(actionCreators.setCreditSubBalance(balance));

    dispatch(setSummaryAmount());
  };
}

/**
 * Get subtotal and set total
 */
function setSummaryAmount(): ActionDispatch<void> {
  return async (dispatch, getState) => {
    if (!getState) {
      throw new Error("Could not get state");
    }

    const originalAmount = getState().checkoutState.originalAmount;
    const promo = getState().checkoutState.promocode;
    const credit = getState().userState.isLogedIn
      ? getState().checkoutState.creditBalance
      : 0;
    const configuration = getState().configurationState.configuration;

    if (!configuration) {
      throw new Error();
    }

    const bookingFee = getState().checkoutState.bookingFee;

    if (credit) {
      dispatch(actionCreators.setCreditSubBalance(credit));
    }

    let total = originalAmount;

    let isFullyPayedByCredits = false;
    // check if we need to subtract credits
    if (total && credit && credit > 0) {
      const totalWithFee = total + bookingFee;
      let creditApplied: number;

      if (credit >= totalWithFee) {
        creditApplied = totalWithFee;
        isFullyPayedByCredits = true;
      } else {
        creditApplied = credit;
      }

      total -= creditApplied;
      dispatch(actionCreators.setCreditSubBalance(creditApplied));
      total = total < 0 ? 0 : total;
    }

    // check if we need subtract promo value
    if (total && promo && !isFullyPayedByCredits) {
      const mulitplier = 0.01;
      let promoValue = 0;

      promoValue =
        promo.deductionType === "absolute"
          ? promo.deduction
          : total * promo.deduction * mulitplier;

      total -= promoValue;
      total = total < 0 ? 0 : total;
    }

    if (typeof total !== "undefined") {
      total = isFullyPayedByCredits ? total : total + bookingFee;

      // set total
      dispatch(actionCreators.setTotalAmount(total));
    }
  };
}
