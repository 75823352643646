import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { IconStar } from "../../atoms";
import { WithClassName } from "../../types";
import { device } from "../../../../utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.type.sizes.size8};
`;

const IconStarStyled = styled(IconStar)`
  width: ${rem("28px")};
  height: ${rem("28px")};
  fill: ${({ theme }) => theme.colours.iconOrange};
`;

const RatingText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-style: normal;
  font-weight: 300;
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: ${({ theme }) => theme.type.sizes.size12};

  @media ${device.mobileL} {
    margin-left: ${({ theme }) => theme.type.sizes.size12};
    margin-top: 0;
  }
`;

export const RatingAppStore = ({ className }: WithClassName) => (
  <Container className={className}>
    <IconsContainer>
      <IconStarStyled />
      <IconStarStyled />
      <IconStarStyled />
      <IconStarStyled />
      <IconStarStyled />
    </IconsContainer>
    <RatingText>Rated 4.8 on the app store</RatingText>
  </Container>
);
