export type CATEGORIES_FETCH_ATTEMPT = "categories.CATEGORIES_FETCH_ATTEMPT";
export const CATEGORIES_FETCH_ATTEMPT: CATEGORIES_FETCH_ATTEMPT =
  "categories.CATEGORIES_FETCH_ATTEMPT";
export type CATEGORIES_FETCH_SUCCESS = "categories.CATEGORIES_FETCH_SUCCESS";
export const CATEGORIES_FETCH_SUCCESS: CATEGORIES_FETCH_SUCCESS =
  "categories.CATEGORIES_FETCH_SUCCESS";
export type CATEGORIES_FETCH_FAILURE = "categories.CATEGORIES_FETCH_FAILURE";
export const CATEGORIES_FETCH_FAILURE: CATEGORIES_FETCH_FAILURE =
  "categories.CATEGORIES_FETCH_FAILURE";

export type SET_CATEGORY = "categories.SET_CATEGORY";
export const SET_CATEGORY: SET_CATEGORY = "categories.SET_CATEGORY";
export type CLEAR_CATEGORY = "categories.CLEAR_CATEGORY";
export const CLEAR_CATEGORY: CLEAR_CATEGORY = "categories.CLEAR_CATEGORY";

export type SET_CATEGORY_FEATURED_TREATMENT =
  "categories.SET_CATEGORY_FEATURED_TREATMENT";
export const SET_CATEGORY_FEATURED_TREATMENT: SET_CATEGORY_FEATURED_TREATMENT =
  "categories.SET_CATEGORY_FEATURED_TREATMENT";
export type CLEAR_CATEGORY_FEATURED_TREATMENT =
  "categories.CLEAR_CATEGORY_FEATURED_TREATMENT";
export const CLEAR_CATEGORY_FEATURED_TREATMENT: CLEAR_CATEGORY_FEATURED_TREATMENT =
  "categories.CLEAR_CATEGORY_FEATURED_TREATMENT";
