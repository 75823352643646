import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { rem } from "polished";
import styled from "styled-components";

import { IconCaret } from "../../../../atoms";
import { device } from "../../../../../utils";
import { DropdownItem } from "./types";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DropdownButton = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.size18}`};

  &:hover {
    cursor: pointer;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const DropdownButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem("18px")};
  height: ${rem("18px")};
  margin-right: ${({ theme }) => theme.spacing.size12};

  @media ${device.tablet} {
    margin-right: ${({ theme }) => theme.spacing.size22};
  }
`;

const IconRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownText = styled.span<{ isInactive?: boolean }>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  line-height: ${({ theme }) => theme.type.sizes.size14};
  color: ${({ theme: { colours }, isInactive }) => isInactive ? colours.textLightGrey : colours.textMain };
  margin: 0;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
    line-height: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const DropdownButtonText = styled(DropdownText)`
  padding-bottom: ${rem("2px")};
`;

const PlaceholderText = styled(DropdownButtonText)`
  color: ${({ theme }) => theme.colours.textPlaceholder};
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  max-height: ${rem("250px")};
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom-left-radius: ${rem("20px")};
  border-bottom-right-radius: ${rem("20px")};
  box-shadow: ${({ theme }) => theme.shadows.therapistFilter};
  -webkit-clip-path: inset(-5px 0px -5px -5px);
  clip-path: inset(0 -50px -50px -50px);
  z-index: 200;
`;

const DropdownItemContainer = styled.li<{ isSelected: boolean; hasIcon: boolean }>`
  display: flex;
  align-items: center;
  height: ${rem("40px")};
  padding-left: ${({ theme, hasIcon }) =>
    hasIcon ? `${rem("48px")}` : theme.spacing.size8};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colours.background.greyLighter
      : theme.colours.background.white};

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `${rem("1px")} solid ${theme.colours.ruubyLightGrey}`};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, isSelected }) =>
      !isSelected && theme.colours.background.greyFaint};
  }

  @media ${device.tablet} {
    padding-left: ${({ theme, hasIcon }) =>
      hasIcon ? `${rem("58px")}` : theme.spacing.size18};
  }
`;

interface Props {
  selectedValue?: string;
  placeholder?: string;
  items: DropdownItem[];
  icon?: React.ReactNode;
  isDisabled?: boolean;
  onSelect(value: string): void;
  onChangeOpen?(state: boolean): void;
}

export const FilterFieldDropdown = ({
  selectedValue,
  placeholder,
  items,
  icon,
  isDisabled,
  onSelect,
  onChangeOpen,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const selectedName = useMemo(
    (): string | undefined =>
      items.find((item) => item[0] === selectedValue)?.[1],
    [items, selectedValue],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (isOpen && !containerRef.current?.contains(event.target as Node)) {
        if (onChangeOpen) {
          onChangeOpen(false);
        }
        setIsOpen(false);
      }
    },
    [setIsOpen, isOpen, containerRef],
  );

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const handleClick = useCallback(
    () => setIsOpen((v) => {
      if (onChangeOpen) {
        onChangeOpen(!v);
      }

      return !v;
    }),
    [setIsOpen],
  );

  return (
    <DropdownContainer ref={containerRef}>
      <DropdownButton
        onClick={handleClick}
        isDisabled={Boolean(isDisabled)}
      >
        <DropdownButtonInnerContainer>
          {icon && <IconContainer>{icon}</IconContainer>}
          <DropdownButtonText>
            {selectedName ?? <PlaceholderText>{placeholder}</PlaceholderText>}
          </DropdownButtonText>
        </DropdownButtonInnerContainer>
        <IconRightContainer>
          <IconCaret width="9" height="5" />
        </IconRightContainer>
      </DropdownButton>
      {isOpen && items && items.length > 0 && (
        <DropdownList>
          {items.map(([value, name, isInactive = false]) => (
            <DropdownItemContainer
              key={value}
              onClick={() => {
                if (!isInactive) {
                  onSelect(value);
                  setIsOpen(false);
                  if (onChangeOpen) {
                    onChangeOpen(false);
                  }
                }
              }}
              isSelected={selectedValue === value}
              hasIcon={Boolean(icon)}
            >
              <DropdownText isInactive={isInactive}>{name}</DropdownText>
            </DropdownItemContainer>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};
