import styled from "styled-components";

import { device } from "../../../../../utils";

export const ErrorText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size12};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.error};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size14};
  }
`;
