import React, { useRef, useEffect, useCallback, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const Keys = {
  BACKSPACE: "Backspace",
  LEFT_ARROW: "ArrowLeft",
  RIGHT_ARROW: "ArrowRight",
  SPACE: " ",
} as const;

const shake = keyframes`
  8%, 41% {
    transform: translateX(-20px);
  }
  25%, 58% {
    transform: translateX(20px);
  }
  75% {
    transform: translateX(-10px);
  }
  92% {
    transform: translateX(10px);
  }
  0%, 100% {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${(props: IProps) => (props.invalid ? css`animation: ${shake} .5s linear;` : "")}
`;

const Entry = styled.input<IEntryProps>`
  width: 37px;
  height: auto;
  margin: 0 8px;
  border: 0;
  padding: 0;
  padding-bottom: 5px;
  border-bottom: solid 3px ${({ theme }) => theme.colours.inputBorderColor};
  background-color: #ffffff;
  margin-bottom: 0.82353rem;
  font-size: ${({ theme }) => theme.type.sizes.size24};
  font-weight: 700;
  color: ${({ theme }) => theme.colours.textMain};
  text-align: center;
  &:focus {
    outline: none;
  }
  :disabled {
    color: ${({ theme }) => theme.colours.buttonDisabledText};
  }
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.colours.ruubyDarkGrey : theme.colours.inputBorderColor};
  caret-color: ${({ hideCursor }) => (hideCursor ? "transparent" : "auto")};
`;

interface IEntryProps {
  isActive: boolean;
  hideCursor: boolean;
}

interface IProps {
  size: number;
  invalid?: boolean;
  onComplete(code: string): void;
}

export const OtpEntry: React.FC<IProps> = (props) => {
  const [isOTPDisabled, setIsOTPDisabled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [totalEntries, setTotalEntries] = useState(0);
  const { invalid, size, onComplete } = props;

  const entriesRef = useRef<HTMLInputElement[]>([]);

  const isValueEntered = useCallback(
    (index: number): boolean | undefined =>
      entriesRef.current[index]?.value !== "",
    [entriesRef],
  );

  useEffect(() => {
    if (invalid) {
      setIsOTPDisabled(true);
    } else {
      entriesRef.current.forEach((e) => (e.value = ""));
      setIsOTPDisabled(false);
      setActiveIndex(-1);
      setTotalEntries(0);
      entriesRef.current[0].focus();
    }
  }, [invalid]);

  useEffect(() => {
    if (totalEntries === size) {
      setIsOTPDisabled(true);
      setActiveIndex(-1);
      setTotalEntries(0);
      onComplete(entriesRef.current.map((e) => e.value).join(""));
    }
  }, [totalEntries, size, onComplete]);

  const onPaste = useCallback(
    (
      event: React.ClipboardEvent<HTMLInputElement>,
      inputIndex: number,
    ): void => {
      event.preventDefault();
      const pasted = event.clipboardData.getData("text/plain").trim();
      const digits = pasted.replace(/[^0-9]/g, "");

      const pastedArray = digits.split("").slice(0, size - inputIndex);
      pastedArray.forEach((char, index) => {
        entriesRef.current[index + inputIndex].value = char;
      });

      setTotalEntries(() =>
        entriesRef.current.reduce((acc, curr) => {
          if (curr.value !== "") {
            return acc + 1;
          }
          return acc;
        }, 0),
      );

      const nextIndex = Math.min(pastedArray.length + inputIndex, size - 1);
      entriesRef.current[nextIndex].focus();
    },
    [onComplete, entriesRef, size, totalEntries],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
      const entry = entriesRef.current[index];
      const leftEntry = entriesRef.current[index - 1];
      const rightEntry = entriesRef.current[index + 1];
      if (e.ctrlKey || e.metaKey) {
        return;
      }
      switch (e.key) {
        case Keys.BACKSPACE:
          if (entry.value) {
            entry.value = "";
            setTotalEntries((current) => Math.max(current - 1, 0));
            return;
          }
          if (leftEntry) {
            if (leftEntry.value) {
              setTotalEntries((current) => Math.max(current - 1, 0));
            }
            leftEntry.value = "";
            leftEntry.focus();
            return;
          }
          break;

        case Keys.LEFT_ARROW:
          if (leftEntry) {
            leftEntry.focus();
            return;
          }
          break;

        case Keys.RIGHT_ARROW:
          if (rightEntry) {
            rightEntry.focus();
            return;
          }
          break;

        default:
          break;
      }

      if (!/^[0-9]$/.test(e.key) || e.key === Keys.SPACE) {
        e.preventDefault();
        return;
      }

      if (!entry.value) {
        setTotalEntries((current) => current + 1);
      }

      if (rightEntry) {
        entry.value = e.key;
        e.preventDefault();
        rightEntry.focus();
      } else {
        entry.value = e.key;
        e.preventDefault();
      }
    },
    [entriesRef, onComplete, size, totalEntries],
  );

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      target.value = value.replace(/[^0-9]/g, "");
    },
    [],
  );

  const handleBlur = useCallback((): void => {
    setActiveIndex(-1);
  }, []);

  const handleFocus = useCallback((index: number): void => {
    setActiveIndex(index);
  }, []);

  return (
    <Container {...props}>
      {Array(size)
        .fill(null)
        .map((_, i) => (
          <Entry
            key={`otp-entry-${i}`}
            ref={(el) => {
              if (el) {
                entriesRef.current[i] = el;
              }
            }}
            onPaste={(e) => onPaste(e, i)}
            autoFocus={i === 0}
            onFocus={() => handleFocus(i)}
            type="tel"
            disabled={isOTPDisabled}
            maxLength={1}
            onInput={handleInput}
            onKeyDown={(e) => handleKeyDown(e, i)}
            onBlur={handleBlur}
            isActive={i === activeIndex}
            hideCursor={isValueEntered(i) ?? false}
          />
        ))}
    </Container>
  );
};
