import React from "react";
import styled from "styled-components";

interface IProps {
  size?: "normal" | "big";
  text: string;
}

const SuccessMessage = styled.p<Pick<IProps, "size">>`
  margin-top: ${({ theme }) => theme.spacing.size18};
  font-size: ${({ size, theme }) => size === "big" ? theme.type.sizes.size20 : theme.type.sizes.size16 };
`;

export const PaymentSuccess = ({ size = "normal", text }: IProps): JSX.Element => (
  <SuccessMessage size={size}>
    {text}
  </SuccessMessage>
);