import * as React from "react";
import styled from "styled-components";

import { IconSocial, NavItem, Text } from "../../../../components/atoms";
import { AppStore, EmailSubscribe } from "../../../../components/molecules";
import { Column, Container, Row } from "../../../grid";

import { theme } from "../../../../theme";
import { config } from "../../../../config";

const FullFooterContainer = styled(Container)`
  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

const FullFooterHeadLine = styled(Text)`
  padding-bottom: 16px;
`;

const FullFooterAppStore = styled(AppStore)`
  display: inline-block;

  > div {
    padding: 0;
    text-align: left;
  }

  a {
    display: block;
    margin: 0;
  }
`;

const FullFooterNavItem = styled(NavItem)`
  display: block;
  font-size: ${theme.fontSize.main};
  font-weight: normal;
  color: ${theme.color.ruubyNewGreyLight};
  line-height: 2;
`;

const FullFooterRowSocial = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    width: 14px;
    height: 14px;

    svg {
      width: 14px;
      height: 14px;
      transition: transform ${theme.transition.easeInOut03};
    }

    &:hover {
      svg {
        transform: scale(1.5, 1.5);
      }
    }
  }

  @media screen and (max-width: 992px) {
    padding-top: 10px;
    justify-content: flex-start;
  }
`;

const FullFooterWrapper = styled.div`
  padding: 36px 0;
  border-top: solid 1px ${theme.color.borderColorGrey};
  background: ${theme.color.ruubyNewBlue};

  @media screen and (max-width: 992px) {
    padding-top: 10px;
  }
`;

const FullFooterSubscribeRow = styled(Row)`
  margin-bottom: 10px;
`;

const FullFooterCopyright = styled(Text)`
  margin-top: 24px;
`;

const FullFooter: React.FC = () => {
  const [clickCount, setClickCount] = React.useState(0);

  const handleCopyrightClick = () => {
    if (clickCount === 9) {
      throw new Error("Forced test crash");
    }

    setClickCount(clickCount + 1);
  };

  return (
    <FullFooterWrapper>
      <FullFooterContainer>
        <Row>
          <Column md="3">
            <FullFooterHeadLine color="newGreyLight" weight="semibold">
              Ruuby App
            </FullFooterHeadLine>
            <FullFooterAppStore />
          </Column>
          <Column sm="4" md="2">
            <FullFooterHeadLine color="newGreyLight" weight="semibold">
              Company
            </FullFooterHeadLine>
            <FullFooterNavItem to="/about">About</FullFooterNavItem>
            <FullFooterNavItem to="/faqs">FAQs</FullFooterNavItem>
            {Boolean(config.affiliates?.joinUrl) && (
            <FullFooterNavItem to="/affiliates">
              Affiliates
            </FullFooterNavItem>
            )}
            <FullFooterNavItem to="/corporate">
              Corporate
            </FullFooterNavItem>
            <FullFooterNavItem to="/events">
              Events
            </FullFooterNavItem>
            <FullFooterNavItem to="/privacy-policy">
              Privacy Policy
            </FullFooterNavItem>
            <FullFooterNavItem to="/terms_and_conditions">
              Terms and Conditions
            </FullFooterNavItem>
          </Column>
          <Column sm="4" md="2">
            <FullFooterHeadLine color="newGreyLight" weight="semibold">
              Treatments
            </FullFooterHeadLine>
            <FullFooterNavItem to="/category">Categories</FullFooterNavItem>
            <FullFooterNavItem href="https://shop.ruuby.com/" target="_blank">
              Gift Cards
            </FullFooterNavItem>
          </Column>
          <Column sm="4" md="2">
            <FullFooterHeadLine color="white" weight="semibold">
              For Beauticians
            </FullFooterHeadLine>
            <FullFooterNavItem to="/jobs">Jobs</FullFooterNavItem>
            <FullFooterNavItem
              href="https://shop.ruuby.com/collections/ruuby-merchandise"
              target="_blank"
            >
              Therapist's store
            </FullFooterNavItem>
          </Column>
          <Column md="3">
            <FullFooterHeadLine color="newGreyLight" weight="semibold">
              Follow Ruuby
            </FullFooterHeadLine>
            <FullFooterSubscribeRow>
              <Column>
                <EmailSubscribe />
              </Column>
            </FullFooterSubscribeRow>
            <FullFooterRowSocial>
              <NavItem
                href="https://www.facebook.com/ruubyapp/"
                target="_blank"
              >
                <IconSocial type="facebook" />
              </NavItem>
              <NavItem href="https://twitter.com/ruubyapp" target="_blank">
                <IconSocial type="twitter" />
              </NavItem>
              <NavItem
                href="https://www.pinterest.com/ruubyapp/"
                target="_blank"
              >
                <IconSocial type="pinterest" />
              </NavItem>
              <NavItem
                href="https://www.instagram.com/ruubyapp/"
                target="_blank"
              >
                <IconSocial type="instagram" />
              </NavItem>
              <NavItem
                href="https://www.linkedin.com/company/5262281/"
                target="_blank"
              >
                <IconSocial type="linkedin" />
              </NavItem>
            </FullFooterRowSocial>
          </Column>
        </Row>
        <Row>
          <Column textAlign="center">
            <FullFooterCopyright
              size="12"
              color="newGreyLight"
              onClick={handleCopyrightClick}
            >
              ©2020 Ruuby. All Rights Reserved.
            </FullFooterCopyright>
          </Column>
        </Row>
      </FullFooterContainer>
    </FullFooterWrapper>
  );
};

export { FullFooter };
