import React, { useState, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface RouterHistoryContextType {
  currPath: string;
  prevPath: string;
}

export const RouterHistoryContext = createContext<RouterHistoryContextType>({
  currPath: "",
  prevPath: "",
});

interface Props {
  children: React.ReactNode;
}

export const RouterHistoryProvider = ({ children }: Props) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    currPath: "",
    prevPath: "",
  });

  useEffect(() => {
    setRoute((prev) => ({
      currPath: location.pathname,
      prevPath: prev.currPath,
    }));
  }, [location]);

  return (
    <RouterHistoryContext.Provider value={route}>
      {children}
    </RouterHistoryContext.Provider>
  );
};
