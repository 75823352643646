import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const IS_COPIED_TIMEOUT = 3000;

export type UseShareButton = (params: { name: string }) => {
  isCopied: boolean;
  onShare(): Promise<void>;
};

export const useShareButton: UseShareButton = ({ name }) => {
  const [isCopied, setIsCopied] = useState(false);

  const { pathname } = useLocation();

  const currentUrl = `${window.location.origin}${pathname}`;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, IS_COPIED_TIMEOUT);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied]);

  const onShare = useCallback(async () => {
    if (typeof navigator.share !== "undefined") {
      try {
        await navigator.share({
          text: `Hey! I thought I would share this link with you to book with ${name} on Ruuby.`,
          url: currentUrl,
        });
      } catch (err) {}
    } else if (typeof navigator.clipboard?.writeText !== "undefined") {
      try {
        await navigator.clipboard.writeText(currentUrl);
        setIsCopied(true);
      } catch (err) {}
    }
  }, [currentUrl]);

  return {
    isCopied,
    onShare,
  };
};
