import styled from "styled-components";

import { Text } from "../../text";
import { newTheme } from "../../../../../theme";
import { device } from "../../../../../utils";

export const EventText = styled(Text)`
  color: ${(props) => props.color ? newTheme.colours.text[props.color] : newTheme.colours.text.main};
  text-align: center;
  font-family: ${newTheme.type.fonts.main};
  font-size: ${newTheme.type.sizes.size16};
  margin: 0;
  @media ${device.mobileL} {
    font-size: ${newTheme.type.sizes.size20};
  }
  font-style: normal;
  line-height: 140%;
  letter-spacing: -0.5px;
`;
