import * as queryString from "query-string";

import { ApiError, IApiErrorData } from "../error-handler";
import { getHandler, postHandler } from "../request-handler";

import { ICategory } from "./category-list";

export interface IService {
  category: string;
  description?: string;
  duration: number;
  name: string;
  price: number;
  urn: string;
  bookingsPopularity?: number;
}

export interface IServiceTreatment extends IService {
  count: number;
}

interface IAddress {
  address1: string;
  address2: string;
  city: string;
  postcode: string;
}

export interface ITherapistRating {
  raw: number;
  display: string;
  numberOfReviews: number;
}

export interface ITherapist {
  address?: IAddress;
  availability: string[];
  categories: string[];
  images: string[];
  insight: string;
  isElite: boolean; // TODO: deprecated
  isFavourite: boolean;
  isMobile: boolean;
  isRuubyOnDemand: boolean;
  logoImage: string;
  name: string;
  products: string | null;
  rating: ITherapistRating;
  recommended: boolean;
  review: { body: string; author: string };
  services: IService[];
  slug: string;
  therapistName: string;
  urn: string;
  tags?: string[];
  isPartner?: boolean;
  tier: TherapistTier;
}

export interface ITherapistsReduced {
  [urn: string]: ITherapist;
}

interface IApiTherapistRating {
  raw: number;
  display: string;
  numberOfReviews: number;
}

/**
 * Therapist tiers
 * @enum {string}
 */
enum TherapistTier {
  CLASSIC = "CLASSIC",
  ELITE = "ELITE",
  ON_DEMAND = "ON_DEMAND",
  BLACK_LABEL = "BLACK_LABEL",
}

interface IApiTherapistServices {
  "@id": string;
  name: string;
  slug: string;
  isElite: boolean; // TODO: deprecated
  rating: IApiTherapistRating;
  services: {
    "@id": string;
    name: string;
    price: number;
    duration: number;
    description: string;
    category: string;
    bookingsPopularity: number;
  }[];
  tags: string[];
  tier: TherapistTier;
}

interface IApiTherapistSalon {
  "@id": string;
  address: IAddress;
  categories: { name: string; subCategories: string[] }[];
  images: string[];
  insight: string;
  isActive: boolean;
  isMobile: boolean;
  logoImages: string[];
  name: string;
  products: string;
  profileImage?: string;
  rating: string;
  recommended: boolean;
  review: { body: string; author: string };
}

// Therapist details
interface IApiTherapist {
  availability: string[];
  isElite: boolean; // TODO: deprecated
  isFavourite: boolean;
  isRuubyOnDemand: boolean;
  salon: IApiTherapistSalon;
  therapist: IApiTherapistServices;
}

export interface ISearchTherapistsParamsOptions {
  availableAt?: string;
  availableFrom?: string;
  availableTo?: string;
  ignoreTherapists?: string;
  slot?: string;
}

interface ISearchTherapistsParams
  extends ISearchTherapistsParamsOptions {
  treatmentUrn?: string;
  category?: ICategory;
  date?: string;
  outcode: string;
}

/**
 * Fetch therapists with filter params
 */
export async function searchTherapists(
  params: ISearchTherapistsParams,
): Promise<ITherapist[]> {
  const searchOptions = {
    availableAt: params.availableAt,
    availableFrom: params.availableFrom,
    availableTo: params.availableTo,
    category: params.category && params.category.id,
    date: params.date,
    ignoreTherapists: params.ignoreTherapists,
    outcode: params.outcode,
    slot: params.slot,
    treatmentUrn: params.treatmentUrn,
  };

  const endPoint = `/therapists?${queryString.stringify(searchOptions)}`;

  const response = await getHandler<IApiTherapist[]>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result.map((therapist) =>
    mapToTherapist(therapist, params.category && params.category.name),
  );
}

// Therapists Availability
interface ISearchTherapistsAvailabilityParams {
  category?: string;
  day: string;
  outcode: string;
}

/**
 * Fetch therapists availability for day
 */
export async function fetchTherapistsAvailabilityForDay(
  params: ISearchTherapistsAvailabilityParams,
): Promise<string[]> {
  const endPoint = `/therapists-availability?${queryString.stringify(params)}`;

  const response = await getHandler<string[]>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

export interface ITherapistAvailability {
  [date: string]: string[];
}

interface ITherapistAvailabilityResponse {
  error: IApiErrorData;
  result: ITherapistAvailability;
}

/**
 * Fetch therapist availability
 * by therapist id (urn) and service ids (urns)
 */
export async function fetchTherapistAvailability(
  therapistUrn: string,
  serviceUrns: string[],
): Promise<ITherapistAvailability> {
  const endPoint = `/therapists/${therapistUrn}/availability`;

  const body = JSON.stringify({ serviceUrns });

  const response: ITherapistAvailabilityResponse = await postHandler(
    endPoint,
    body,
    false,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

interface ITherapistServePostcode {
  isServed: boolean;
}

/**
 * Check therapist's serviced postcode
 */
export async function checkTherapistServicedPostcode(
  therapistUrn: string,
  postcode: string,
): Promise<boolean> {
  const endPoint = `/therapists/${therapistUrn}/postcode/${postcode}`;

  const response = await getHandler<ITherapistServePostcode>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result.isServed;
}

export interface IApiTherapistReview {
  urn: string;
  reviewIconUrl: string;
  rating: number;
  customerUrn: string;
  reviewer: string;
  createdDate: string;
  comment: string;
}

/**
 * Get therapist reviews
 */
export async function fetchTherapistReviews(
  therapistUrn: string,
): Promise<IApiTherapistReview[]> {
  const endPoint = `/reviews/${therapistUrn}`;

  const response = await getHandler<IApiTherapistReview[]>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

/**
 * Map therapist result for store
 */
function mapToTherapist(
  apiTherapist: IApiTherapist,
  filteredCategory?: string,
): ITherapist {
  let categories: string[] = [];

  if (filteredCategory) {
    const resultCategories = apiTherapist.salon.categories.filter(
      (category) =>
        category.name.toLowerCase() === filteredCategory.toLowerCase(),
    );

    if (resultCategories.length) {
      categories = resultCategories[0].subCategories;
    }
  }

  const services = apiTherapist.therapist.services.map((service) => ({
    bookingsPopularity: service.bookingsPopularity,
    category: service.category,
    description: service.description,
    duration: service.duration,
    name: service.name,
    price: service.price,
    urn: service["@id"],
  }));

  const therapist: ITherapist = {
    address: apiTherapist.salon.address,
    availability: apiTherapist.availability,
    categories,
    images: apiTherapist.salon.images,
    insight: apiTherapist.salon.insight,
    isElite: apiTherapist.isElite
      ? apiTherapist.isElite
      : apiTherapist.therapist.isElite, // TODO: deprecated
    isFavourite: apiTherapist.isFavourite,
    isMobile: apiTherapist.salon.isMobile,
    isRuubyOnDemand: apiTherapist.isRuubyOnDemand,
    logoImage: apiTherapist.salon.profileImage
      ? apiTherapist.salon.profileImage
      : apiTherapist.salon.logoImages[0],
    name: apiTherapist.salon.isMobile
      ? apiTherapist.therapist.name
      : apiTherapist.salon.name,
    products: apiTherapist.salon.products,
    rating: apiTherapist.therapist.rating,
    recommended: apiTherapist.salon.recommended,
    review: apiTherapist.salon.review,
    services,
    slug: apiTherapist.therapist.slug,
    tags: apiTherapist.therapist.tags,
    therapistName: apiTherapist.therapist.name,
    tier: apiTherapist.therapist.tier,
    urn: apiTherapist.therapist["@id"],
  };

  return therapist;
}
