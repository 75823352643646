import * as React from "react";
import styled from "styled-components";

import popupImage from "../../../../assets/images/ruuby-subscribe-popup.jpg";
import { theme } from "../../../../theme";
import { Button } from "../../../atoms";
import { Input } from "../../../molecules";
import { Modal } from "../../../themed-v2/molecules/modal";

interface IProps {
  innerRef: React.RefObject<HTMLInputElement>;
  isShow: boolean;
  onClose(): void;
  onSubscribe(e: React.FormEvent<HTMLFormElement>): void;
}

const PopupImage = styled.img`
  height: 95%;
`;

const ModalStyled = styled(Modal)`
  padding: 0px;
  height: 90%;
  @media screen and (max-width: 765px) {
    height: 80%;
  }
  @media screen and (max-width: 360px) {
    height: 70%;
  }
  .closeIcon {
    svg {
      padding-right: 10px;
      padding-top: 10px;
      width: 38px;
      height: 38px;
    }
  }
  @media screen and (max-width: 992px) {
    height: 90%;
    width: auto;
    overflow: auto;
  }
`;

const HomeSubscribeModalEmailForm = styled.div`
  position: absolute;
  top: 85%;
  width: 95%;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    font-size: ${theme.fontSize.bigger};
    padding: 24px;
  }

  button {
    padding: 18.5px;
  }

  @media screen and (max-width: 765px) {
    top: 80%;
    form {
      flex-direction: column;
      align-items: center;
    }
    button {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 360px) {
    top: 75%;
    width: 80%;
    form {
      flex-direction: column;
      align-items: center;
    }
    button {
      padding: 15px;
      margin-top: 10px;
    }
  }
`;

const HomeSubscribeModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HomeSubscribeModal: React.FC<IProps> = props => {
  return (
    <ModalStyled isShow={props.isShow} onClose={props.onClose}>
      <PopupImage src={popupImage} />
      <HomeSubscribeModalWrapper>
        <HomeSubscribeModalEmailForm>
          <form
            method="get"
            onSubmit={props.onSubscribe}
            action="//share.hsforms.com/1F--nBZnYSBCHejAE1KhwNQ3scj5"
            target="_blank"
          >
            <Input
              type="email"
              name="email"
              inputStyle="box"
              placeholder="Enter your email"
              autoComplete="false"
              innerRef={props.innerRef}
            />
            <Button
              type="submit"
              name="subscribe"
              background="grey"
              size="small"
            >
              Subscribe
            </Button>
          </form>
        </HomeSubscribeModalEmailForm>
      </HomeSubscribeModalWrapper>
    </ModalStyled>
  );
};

export { HomeSubscribeModal };
