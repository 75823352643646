import pricelist from "./bridal-price-list.json";

interface IBridalTreatment {
  name: string;
  price: string;
}

interface IBridalPriceList {
  name: string;
  treatments: IBridalTreatment[];
}

const bridalPriceListData: IBridalPriceList[] = pricelist as IBridalPriceList[];

export { bridalPriceListData };
