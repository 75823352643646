import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as CalendarImage } from "./calendar.svg";

export const IconCalendar = styled(CalendarImage)`
  width: ${rem("22px")};
  height: ${rem("25px")};
  fill: ${({ theme }) => theme.colours.icon};
  stroke: ${({ theme }) => theme.colours.icon};
`;