import { getHandler } from "../request-handler";

interface StaticPage {
  id: string;
  text: string;
}

/**
 * Get static page content
 */
export async function fetch(id: string): Promise<StaticPage> {
  const response = await getHandler<StaticPage>(`/page/${id}`, false);

  return response.result;
}
