import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as ClockImage } from "./clock.svg";

export const IconClock = styled(ClockImage)`
  width: ${rem("20px")};
  height: ${rem("20px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
