import React from "react";

import { FilterFieldDatePicker } from "../../../../molecules/filter/filter-field-date-picker";
import { FieldWrapper } from "../field-wrapper";

interface Props {
  value: string;
  onChange: (text: string) => void;
}

export const DateField = ({ onChange, value }: Props): JSX.Element => (
  <FieldWrapper>
    <FilterFieldDatePicker
      maxDaysRange={90}
      selectedDate={value}
      onSelectDate={onChange}
      isWideInput={true}
    />
  </FieldWrapper>
);
