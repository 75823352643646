import { useCallback, useMemo, useState } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

import * as analytics from "../../../../../../analytics";
import { ApiError, useOtpValidateMutation } from "../../../../../../services/api/types/graphql";
import { useSaveAccessToken } from "../use-save-access-token";
import { ContactMethod, LoginFlowType } from "../..";

const trackValidatedOtp = async (
  type: ContactMethod,
  userType: LoginFlowType,
) => {
  analytics.track({
    name: analytics.AnalyticsEvents.OTP_SCREEN_VALIDATED,
    payload: {
      type,
      userType,
    },
  });
};

interface OtpEmailTokenContents extends JwtPayload {
  method: ContactMethod.EMAIL;
  email: string;
  expiryTime: string;
}

interface OtpPhoneTokenContents extends JwtPayload {
  method: ContactMethod.PHONE;
  phone: string;
  expiryTime: string;
  requestId: string;
}

type OtpTokenContents = OtpEmailTokenContents | OtpPhoneTokenContents;

type OtpValidate = (params: {
  token: string;
  onSuccess(): void;
  onValidateNewContact(token: string): void;
}) => {
  error?: ApiError;
  isLoading: boolean;
  tokenDetails: OtpTokenContents;
  validateOtp(code: string): Promise<void>;
};

export const useOtpValidate: OtpValidate = ({
  token,
  onSuccess,
  onValidateNewContact,
}) => {
  const [error, setError] = useState<ApiError>();
  const saveAccessToken = useSaveAccessToken();

  const tokenDetails = useMemo(
    () => jwtDecode<OtpTokenContents>(token),
    [token],
  );

  const [otpValidate, { loading: isLoading }] = useOtpValidateMutation({
    onCompleted: async ({ otpValidate: data }) => {
      switch (data.__typename) {
        case "AuthTokens":
          const userType = LoginFlowType.EXISTING;

          analytics.track({
            name: analytics.AnalyticsEvents.LOGIN_FLOW_COMPLETED,
            payload: { userType },
          });
          await trackValidatedOtp(tokenDetails.method, userType);
          await saveAccessToken(data.accessToken, userType);

          onSuccess();
          break;

        case "ContactVerificationToken":
          analytics.track({
            name: analytics.AnalyticsEvents.OTP_SCREEN_VALIDATED,
            payload: {
              type: tokenDetails.method,
              userType: LoginFlowType.NEW,
            },
          });
          onValidateNewContact(data.verifiedContactToken!);
          break;

        case "RuubyGraphError":
          if (error) {
            analytics.track({
              name: analytics.AnalyticsEvents.OTP_SCREEN_VALIDATION_ERROR,
              payload: {
                type: tokenDetails.method,
                error,
              },
            });  
          }
          setError(data.error);
      }
    },
    onError: () => setError(ApiError.GENERAL_ERROR),
    errorPolicy: "all",
  });

  const validateOtp = useCallback(
    async (otpCode: string): Promise<void> => {
      setError(undefined);

      analytics.track({ name: analytics.AnalyticsEvents.OTP_SCREEN_CODE_ENTERED });

      await otpValidate({
        variables: {
          token,
          code: otpCode,
        },
      });
    },
    [otpValidate, token],
  );

  return {
    tokenDetails,
    error,
    isLoading,
    validateOtp,
  };
};
