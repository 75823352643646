import React from "react";
import styled from "styled-components";

const ProductsContainer = styled.p`
  margin: ${({ theme }) => `${theme.spacing.size18} ${theme.spacing.size42}`};
  color: ${({ theme }) => theme.colours.textGrey};
`;

interface Props {
  products: string;
}

export const TherapistProducts = ({ products }: Props): JSX.Element => 
  <ProductsContainer>{ products }</ProductsContainer>;