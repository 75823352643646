import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import { ICategory } from "../../services/api/category-list";
import { IFeaturedTreatment } from "../../services/api/treatments";

import { actionCreators } from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  allCategories: ICategory[];
  categories: ICategory[];
  categoriesFetchError?: Error;
  isCategoriesLoading: boolean;
  selectedCategory?: ICategory;
  selectedCategoryFeaturedTreatment?: IFeaturedTreatment;
}

const INITIAL_STATE: IState = {
  allCategories: [],
  categories: [],
  categoriesFetchError: undefined,
  isCategoriesLoading: false,
  selectedCategory: undefined,
  selectedCategoryFeaturedTreatment: undefined,
};

/**
 * Reducer for categories
 */
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.CATEGORIES_FETCH_ATTEMPT: {
      return iassign(state, (s) => {
        s.allCategories = [];
        s.categories = [];
        s.categoriesFetchError = undefined;
        s.isCategoriesLoading = true;

        return s;
      });
    }

    case types.CATEGORIES_FETCH_SUCCESS: {
      const data = action.payload.data;

      // Manually add halloween category
      // const todayDate = DateTime.utc();
      // const endOfHalloweenDate = DateTime.fromISO("2019-11-5T00:00:00.000Z");
      // if (todayDate < endOfHalloweenDate) {
      //   data.unshift({
      //     code: "HALLOWEEN",
      //     id: "urn:ruuby:treatment-category:halloween",
      //     image: "https://d13h2ltdv23vr0.cloudfront.net/category_images/HALLOWEEN.png",
      //     name: "Halloween",
      //   });
      // }

      return iassign(state, (s) => {
        s.allCategories = data;
        s.categories = data.filter((c) => c.showOnApp);
        s.categoriesFetchError = undefined;
        s.isCategoriesLoading = false;

        return s;
      });
    }

    case types.CATEGORIES_FETCH_FAILURE: {
      return iassign(state, (s) => {
        s.allCategories = [];
        s.categories = [];
        s.categoriesFetchError = action.payload.error;
        s.isCategoriesLoading = false;

        return s;
      });
    }

    case types.CLEAR_CATEGORY: {
      return iassign(state, (s) => {
        s.selectedCategory = undefined;
        s.selectedCategoryFeaturedTreatment = undefined;

        return s;
      });
    }

    case types.SET_CATEGORY: {
      return iassign(state, (s) => {
        s.selectedCategory = action.payload.category;

        return s;
      });
    }

    case types.SET_CATEGORY_FEATURED_TREATMENT: {
      return iassign(state, (s) => {
        s.selectedCategoryFeaturedTreatment = action.payload.data;

        return s;
      });
    }

    case types.CLEAR_CATEGORY_FEATURED_TREATMENT: {
      return iassign(state, (s) => {
        s.selectedCategoryFeaturedTreatment = undefined;

        return s;
      });
    }

    default:
      return state;
  }
}
