import { rem } from "polished";
import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem("400px")};
`;

const ErrorSymbol = styled.div`
  display: flex;
  justify-content: center;
  width: ${rem("48px")};
  height: ${rem("48px")};
  border-radius: 50%;
  border: ${rem("2px")} solid ${({ theme }) => theme.colours.error};
  margin-top: ${({ theme }) => theme.spacing.size64};
  margin-bottom: ${({ theme }) => theme.spacing.size24};
`;

const ErrorSymbolText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size26};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.error};
  text-align: center;
  padding-top: ${rem("2px")};
`;

const ErrorTitle = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size20};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.error};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.size12};
`;

const ErrorBody = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.error};
  max-width: ${rem("400px")};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.size24};
`;

interface IProps {
  title: string;
  text: string;
}

export const ApiErrorMessage = ({ title, text }: IProps): JSX.Element => (
  <ErrorContainer>
    <ErrorSymbol>
      <ErrorSymbolText>!</ErrorSymbolText>
    </ErrorSymbol>
    <ErrorTitle>{title}</ErrorTitle>
    <ErrorBody>{text}</ErrorBody>
  </ErrorContainer>
);
