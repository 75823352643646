import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ApiError } from '../../../../../../../services/api/types/graphql';
import { ContactMethod, ContactMethodType } from '../../..';
import { useCountdown } from '../../../hooks/use-countdown';
import { BlueLink } from '../../atoms/blue-link';
import { BlueText } from '../../atoms/blue-text';
import { Error } from '../../atoms/error';
import { Instructions } from '../../atoms/instructions';
import { RequestOtpButton } from '../../molecules/request-otp-button';
import { AuthContainer } from '../../organisms/auth-container';
import { OtpEntry } from '../../molecules/otp-entry';

const MessagesSection = styled('div')`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ResendSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 10px;
`;

interface ValidateOtpProps {
  contact: string,
  contactMethod: ContactMethodType,
  validateOtp(code: string): Promise<void>,
  otpValidateError: ApiError | undefined,
  expiryTime: string,
  isValidating: boolean,
}

export const ValidateOtp = ({ contact, contactMethod, validateOtp, otpValidateError, expiryTime }: ValidateOtpProps): JSX.Element => {
  const { durationLeft, isCountdownFinished } = useCountdown(expiryTime);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (typeof otpValidateError !== "undefined") {
      setInvalid(true);
      console.error('OTP validation error: ', otpValidateError);
      setTimeout(() => {
        setInvalid(false);
      }, 500);
    }
  }, [otpValidateError]);

  return (
    <AuthContainer title="Verification">
      <Instructions>Enter verification code sent to your {contactMethod === ContactMethod.PHONE ? 'phone' : 'email'}</Instructions>

      <div>
        <OtpEntry
          size={6}
          onComplete={validateOtp}
          invalid={invalid || isCountdownFinished}
        />
      </div>

      <MessagesSection>

        {(typeof otpValidateError !== "undefined") && (
          otpValidateError === 'OTP_INVALID_CODE' ? (
            <Error>Invalid code. Please, try again.</Error>
          ) : (
            <Error capitalize>{otpValidateError.replaceAll('_', ' ')}</Error>
          )
        )}

        {isCountdownFinished ? (
          <ResendSection>
            Didn't receive a code?
            &nbsp;
            <RequestOtpButton contact={contact} contactMethod={contactMethod}>
              Resend
            </RequestOtpButton>
          </ResendSection>
        ) : (
          <BlueText>Time remaining to enter your code: {durationLeft}</BlueText>
        )}

        {contactMethod === ContactMethod.PHONE ? (
          <BlueText><BlueLink to="/signup/phone">Enter</BlueLink> an alternative mobile number.</BlueText>
        ) : (
          <BlueText><BlueLink to="/signup/email">Enter</BlueLink> an alternative email address.</BlueText>
        )}
      </MessagesSection>
    </AuthContainer>
  );
}

