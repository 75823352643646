import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

type RouteProps = RouteComponentProps;

class ScrollTop extends React.Component<RouteProps> {
  public componentDidUpdate(prevProps: RouteProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Scroll to the top of the page
   * on the change of page
   */
  public render() {
    return this.props.children;
  }
}

const router = withRouter(ScrollTop);

export { router as ScrollTop };
