import styled from "styled-components";

interface IProps {
  isDefaultPadding?: boolean;
}

const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 1px 17px rgba(0, 0, 0, 0.1);

  ${(props: IProps) => props.isDefaultPadding && "padding: 22px"}
`;

export { Card };
