import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { History } from "history";

import {
  CollapsibleSectionPanel,
  HeroPanelWithButton,
  JobPanel,
  SectionPanel,
} from "../../../../components/themed-v2/molecules";
import { fetchJobs, IApiJobList } from "../../../../services/api/jobs";

import image from "./jobs-hero.jpg";
import { config } from "../../../../config";

interface JobsData {
  handleClickButton(): void;
  hero: Omit<
    React.ComponentProps<typeof HeroPanelWithButton>,
    "onClickButton"
  > & {
    image: string;
  };
  sections: {
    why: {
      heading: string;
      cards: React.ComponentProps<typeof SectionPanel>[];
      button: {
        label: string;
      };
    };
    positions: {
      heading: string;
      cards?: (React.ComponentProps<typeof JobPanel> & { id: string })[];
      isLoading: boolean;
    };
    faqs: {
      heading: string;
      cards: React.ComponentProps<typeof CollapsibleSectionPanel>[];
      button: {
        label: string;
      };
    };
  };
}

// TODO: Upgrade react router to use hooks i.e. 'useHistory'
export type UseJobData = (history: History) => JobsData;

const faqsCards: Pick<
  JobsData["sections"]["faqs"]["cards"][0],
  "heading" | "paragraph"
>[] = [
  {
    heading: "What does Ruuby stand for?",
    paragraph:
      "At Ruuby we pride ourselves on championing entrepreneurs and the freelance beauty professionals, providing innovative treatments that are at the forefront of the industry and providing our customers with the very best, luxurious experiences.",
  },
  {
    heading: "Is it free to become part of Ruuby?",
    paragraph: "Yes, it is totally free to be featured on the Ruuby app.",
  },
  {
    heading: "What documents do I need in order to apply?",
    paragraph:
      "You will need to provide DBS documents, qualifications, personal insurance and proof of ID during the recruitment process.",
  },
  {
    heading: "How much can I look to earn per hour?",
    paragraph: "A minimum of £40 per hour",
  },
  {
    heading: "Do I need my own kit? ",
    paragraph:
      "Yes, you will be expected to provide your own for the treatments you want to provide however, we provide all our Ruuby professionals access to our professional shop with trade discounts. Many of our branded treatments also provide you with the initial brand kit for free when you pass their training.",
  },
];

export const useJobData: UseJobData = (history) => {
  const [currentFaq, setCurrentFaq] = useState(0);

  const { data, status } = useQuery("jobs", async () => await fetchJobs());

  const handleClickJob = useCallback(
    (id: IApiJobList["id"]) => (): void => {
      history.push(`/jobs/${id}`);
    },
    [],
  );

  const handleClickFaq = useCallback(
    (index: number) => (): void => setCurrentFaq(index),
    [],
  );

  const handleClickButton = useCallback((): void => {
    window.open(config.hubSpot.forms.therapistJoin);
  }, []);

  return {
    handleClickButton,
    hero: {
      image,
      heading:
        "Join the UK's leading mobile beauty company and be your own boss",
      paragraphs: [
        "Take charge of your career and boost your earnings as part of a widely respected and recognised beauty network.",
      ],
      buttonLabel: "Join Ruuby",
    },
    sections: {
      why: {
        heading: "Why become a Ruuby Professional?",
        cards: [
          {
            heading: "Ruuby Squad Benefits",
            paragraph:
              "Working with only the very best professionals in the industry, we are committed to delivering luxury experiences to clients in homes, offices and five star hotels as well as fulfilling the needs of our high profile partners, luxury brands, fashion houses and VIPs, including Burberry, Giorgio Armani and Business of Fashion.",
          },
          {
            heading: "Dedicated Support",
            paragraph:
              "All our professionals have complimentary access to our bespoke diary 24/7 management software for mobile practitioners. Our concierge team is available around the clock to help resolve any issues and answer any  questions and our community team offers 1 to 1 progression meetings to help you progress your career. ",
          },
          {
            heading: "Earn More",
            paragraph:
              "Ruuby offers the best therapist commissions in the industry. It's simple, we charge a small management fee in recognition of the support and marketing that we provide and the rest is yours.",
          },
          {
            heading: "Access VIP and Luxury Brand Partners ",
            paragraph:
              "Build your portfolio through our luxury brand partnerships which include hotels, event companies, fashion brands and concierge services.",
          },
          {
            heading: "Flexibility",
            paragraph:
              "Ruuby allows you to set your hours and the areas where you'd like to work, so your work times fit around your lifestyle.",
          },
          {
            heading: "The Ruuby Academy",
            paragraph:
              "Exclusive access to free trainings and workshops with globally recognised brands such as Dr. Barbara Sturm and Bamford.",
          },
        ],
        button: {
          label: "Join the team",
        },
      },
      positions: {
        heading: "Open Positions",
        isLoading: !data && status === "loading",
        ...(data && {
          cards: data.map(({ id, title, joblocation }) => ({
            id,
            jobTitle: title,
            location: joblocation,
            onClick: handleClickJob(id),
          })),
        }),
      },
      faqs: {
        heading: "Commonly Asked Questions",
        button: {
          label: "Apply now",
        },
        cards: faqsCards.map((card, index) => ({
          ...card,
          onClickHeader: handleClickFaq(index),
          isOpen: currentFaq === index,
        })),
      },
    },
  };
};
