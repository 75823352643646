import { rem } from "polished";
import styled from "styled-components";

export const Heading3 = styled.h3`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: ${rem("8px")};
  margin-bottom: ${rem("8px")};

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`;
