import React, { useEffect, useState } from "react";
import { rem } from "polished";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";

import { device } from "../../../utils/media-queries";
import {
  IconCaret,
  Paragraph,
  Panel,
  Heading2,
} from "../../../components/themed-v2/atoms";

const StyledHeading = styled(Heading2)`
  margin-bottom: 0;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  color: ${({ theme }) => theme.colours.sectionPanel.text};
  line-height: 143%;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;

interface IProps {
  heading: string;
  paragraph: string;
  isOpen: boolean;
  onClickHeader(): void;
  className?: string;
}

export const CollapsibleSectionPanel: React.FC<IProps> = ({
  heading,
  paragraph,
  className,
  isOpen,
  onClickHeader,
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [ref, { height }] = useMeasure<HTMLParagraphElement>();

  useEffect(() => {
    setContentHeight(height);

    window.addEventListener("resize", () => setContentHeight(height));

    return window.removeEventListener("resize", () => setContentHeight(height));
  }, [height]);

  const expand = useSpring({
    height: isOpen ? rem(`${contentHeight}px`) : "0rem",
    opacity: isOpen ? 1 : 0,
    overflow: "hidden",
    marginTop: isOpen ? rem("12px") : "0rem",
  });

  const invert = useSpring({
    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
    height: "24px",
  });

  return (
    <Panel className={className}>
      <Header onClick={onClickHeader}>
        <StyledHeading>{heading}</StyledHeading>
        <animated.div style={invert}>
          <IconCaret />
        </animated.div>
      </Header>
      <animated.div style={expand}>
        <StyledParagraph ref={ref}>{paragraph}</StyledParagraph>
      </animated.div>
    </Panel>
  );
};
