import * as React from "react";

// tslint:disable:max-line-length
const IconStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M8 12.0553L12.944 15L11.632 9.45L16 5.71579L10.248 5.22632L8 0L5.752 5.22632L0 5.71579L4.36 9.45L3.056 15L8 12.0553Z"
        fill="#C3C6CF"
      />
    </svg>
  );
};

export { IconStar };
