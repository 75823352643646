import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { Button } from "../../../components/themed-v2/atoms";
import { HeroPanel } from "./hero-panel";

const StyledButton = styled(Button)`
  margin-top: ${rem("20px")};
`;

interface Props {
  heading: string;
  paragraphs: string[];
  buttonLabel: string;
  onClickButton(): void;
}

export const HeroPanelWithButton = ({
  heading,
  paragraphs,
  buttonLabel,
  onClickButton,
}: Props): JSX.Element => (
  <HeroPanel heading={heading} paragraphs={paragraphs}>
    <StyledButton size="small" onClick={onClickButton}>
      {buttonLabel}
    </StyledButton>
  </HeroPanel>
);
