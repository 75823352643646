import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";
import { History } from "history";

import {
  HeroPanel,
  TreatmentPanel,
  DownloadAppPanel,
  RatingAppStore,
} from "../../../../components/themed-v2/molecules";
import {
  Heading1,
  HeroContainer,
  Section,
  Paragraph,
  Button,
  Panel,
  CategoryTile,
} from "../../../../components/themed-v2/atoms";
import { device } from "../../../../utils/media-queries";

import {
  UseCategoryLandingData,
  useCategoryLandingData,
} from "./use-category-landing-data";
import {
  CustomerReviews,
  ProcessWidget,
} from "../../../../components/themed-v2/organisms";
import { SearchWidget } from "../../organisms/search-widget";

const RatingAppStoreStyled = styled(RatingAppStore)`
  margin-top: ${rem("24px")};
`;

const ButtonRow = styled(Row)`
  margin-top: ${rem("24px")};

  > div {
    display: flex;
    justify-content: center;
  }
`;

const ParagraphStyled = styled(Paragraph)`
  margin-bottom: ${rem("23px")};

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: ${rem("31px")};
  }
`;

const TreatmentsContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const TreatmentsRow = styled(Row)`
  margin-bottom: ${rem("-20px")};

  > div > div {
    min-height: calc(
      100% - ${rem("20px")}
    ); // to make the cards same height as siblings
    box-sizing: border-box;
    margin-bottom: ${rem("20px")};
  }
`;

const CategoriesRow = styled(Row)`
  margin-top: ${rem("24px")};
  margin-bottom: ${rem("-20px")};
  text-align: center;

  > div > a {
    margin: ${rem("15px")};

    @media ${device.laptop} {
      margin: ${rem("25px")};
    }
  }
`;

interface IProps {
  useData?: UseCategoryLandingData;
  history: History;
}

export const CategoryLandingPage = ({
  history,
  useData = useCategoryLandingData,
}: IProps): JSX.Element => {
  const {
    hero,
    mostPopular,
    reviews,
    categories,
    categoryName,
    handleClickButton,
  } = useData(history);

  if (
    hero &&
    mostPopular &&
    reviews &&
    categories &&
    categoryName &&
    handleClickButton
  ) {
    const { image, ...heroProps } = hero;
    return (
      <>
        <HeroContainer image={image}>
          <Container>
            <Row justify="center">
              <Col lg={10}>
                <HeroPanel {...heroProps}>
                  <RatingAppStoreStyled />
                  <SearchWidget category={categoryName} />
                </HeroPanel>
              </Col>
            </Row>
          </Container>
        </HeroContainer>

        <Section colour="white">
          <ProcessWidget handleClickButton={handleClickButton} />
        </Section>

        <Section colour="blueGrey">
          <Container>
            <Row>
              <Col>
                <Panel>
                  <Heading1>{mostPopular.title}</Heading1>
                  <ParagraphStyled>{mostPopular.description}</ParagraphStyled>
                  <TreatmentsContainer>
                    <TreatmentsRow>
                      {mostPopular.treatments.map((treatment) => (
                        <Col md={6} key={treatment.name}>
                          <TreatmentPanel {...treatment} />
                        </Col>
                      ))}
                    </TreatmentsRow>
                  </TreatmentsContainer>
                </Panel>
              </Col>
            </Row>
            <ButtonRow>
              <Col>
                <Button onClick={handleClickButton} size="small">
                  Book now
                </Button>
              </Col>
            </ButtonRow>
          </Container>
        </Section>

        <Section colour="darkGrey">
          <CustomerReviews
            heading="What our Ruuby customers say about us"
            reviews={reviews}
          />
        </Section>

        <Section colour="white">
          <Container>
            <Row justify="center">
              <Col>
                <Heading1>Your One Stop Shop for Beauty and Wellness</Heading1>
              </Col>
            </Row>
            <CategoriesRow justify="center">
              <Col>
                {categories.map((category) => (
                  <CategoryTile category={category} />
                ))}
              </Col>
            </CategoriesRow>
          </Container>
        </Section>

        <Section colour="white">
          <Container>
            <Row justify="center">
              <DownloadAppPanel />
            </Row>
          </Container>
        </Section>
      </>
    );
  }

  return <></>;
};
