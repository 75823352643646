import React from "react";
import { Col } from "react-grid-system";
import styled from "styled-components";

import { TherapistListingCard } from "./therapist-listing-card";
import { ListingTherapist } from "../../../types";
import { device } from "../../../../../../../utils";

const ColStyled = styled(Col)`
  margin-bottom: ${({ theme }) => theme.spacing.size18};

  @media ${device.tablet} {
    margin-bottom: ${({ theme }) => theme.spacing.size30};
  }
`;

interface Props {
  therapists: ListingTherapist[];
  onClick(therapistUrnOrSlug: string, slotTime?: string): void;
}

export const TherapistListingCardsList = ({
  therapists,
  onClick,
}: Props): JSX.Element => (
  <>
    {therapists.map((therapist, index) => (
      <ColStyled key={`${therapist.urn}-${index}`} md={6}>
        <TherapistListingCard therapist={therapist} onClick={onClick} />
      </ColStyled>
    ))}
  </>
);
