import { ApiError } from "../error-handler";
import { postHandler } from "../request-handler";

export interface ICreateBookingParams {
  address?: {
    address1: string;
    address2: string;
    postcode: string;
  };
  addressUrn?: string;
  bookingDate: string;
  notes?: string;
  origin: string;
  paymentMethodNonce?: string;
  paymentMethodToken?: string;
  promoCode?: string;
  serviceUrns: string[];
  therapistUrn: string;
  rakutenSiteid?: string;
  rakutenTimeEntered?: string;
}

export interface MessageTemplate {
  type: string;
  title?: string;
  message: string[];
}

interface IApiBookingResponse {
  booking: {
    urn: string;
  };
  message: MessageTemplate;
}

export async function checkoutBooking(
  params: ICreateBookingParams,
): Promise<IApiBookingResponse> {
  const body = JSON.stringify(params);

  const response = await postHandler<IApiBookingResponse>(
    "/customer/booking",
    body,
    true,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}
