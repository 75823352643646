import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as analytics from "./analytics";
import { createRootReducer, IRootState } from "./store/index";
import { LOGOUT_USER } from "./store/user/action-types";
import { config } from "./config";

const persistConfig = {
  key: `redux-store-${config.version}`,
  storage,
  blacklist: ["configurationState"],
};

// webpack global constant
// declare const __DEV__: boolean;

export const history = createBrowserHistory();

const trackMiddleware: any = () => (next) => (actions) => {
  const { type, payload } = actions;

  if (type === "@@router/LOCATION_CHANGE") {
    analytics.page(payload.location.pathname);
  }

  next(actions);
};

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

/**
 * Creating redux store with hot reload for dev env.
 */
export const configureStore = (initialState: IRootState) => {
  // let store;
  const middlewares: Middleware[] = [
    thunkMiddleware,
    routerMiddleware(history),
    trackMiddleware,
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  // tslint:disable-next-line: strict-boolean-expressions
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const appReducer = createRootReducer(history);
  const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
      storage.removeItem("persist:root");
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers),
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
