import * as React from "react";
import styled from "styled-components";

import { IconStore, NavItem } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const AppStoreGroup = styled.div`
  text-align: center;

  a {
    margin: 0 10px;
  }

  svg {
    display: inline-block;
  }

  @media screen and (max-width: 992px) {
    svg {
      width: 10rem;
    }
  }
`;

const AppStoreNavItem = styled(NavItem)`
  svg {
    transition: all ${theme.transition.linear02};
  }

  &:hover {
    svg {
      opacity: 0.9;
    }
  }
`;

const AppStore: React.FC<IProps> = (props) => {
  return (
    <AppStoreGroup className={props.className}>
      <AppStoreNavItem
        href="https://itunes.apple.com/gb/app/ruuby/id951092698?mt=8"
        target="_blank"
      >
        <IconStore type="ios" />
      </AppStoreNavItem>
      <AppStoreNavItem
        href="https://play.google.com/store/apps/details?id=com.ruubycustomerapp"
        target="_blank"
      >
        <IconStore type="android" />
      </AppStoreNavItem>
    </AppStoreGroup>
  );
};

export { AppStore };
