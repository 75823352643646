export enum Category {
  MASSAGE = "massage",
  HAIR = "hair",
  NAILS = "nails",
  TANNING = "tanning",
  MAKEUP = "makeup",
  FACIAL = "facial",
  WAXING = "waxing",
  EYES = "eyes",
  WELLNESS = "wellness",
  OSTEOPATHY = "osteopathy",
  HEALTH = "health",
  AESTHETICS = "cosmetic",
}

export const categories = [
  Category.MASSAGE,
  Category.HAIR,
  Category.NAILS,
  Category.TANNING,
  Category.MAKEUP,
  Category.FACIAL,
  Category.WAXING,
  Category.EYES,
  Category.WELLNESS,
  Category.OSTEOPATHY,
  Category.HEALTH,
  Category.AESTHETICS,
];
