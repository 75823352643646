import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import { device } from "../../../../utils";

const TherapistAvatarContainer = styled.div`
  width: ${rem("86px")};
  height: ${rem("86px")};

  @media ${device.tablet} {
    width: ${rem("100px")};
    height: ${rem("100px")};
  }
`;

const TherapistAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const TherapistAvatarDefault = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme }) => theme.type.sizes.size42};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textMain};
  background-color: ${({ theme }) => theme.colours.ruubyBlueGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size48};
  }
`;

interface Props {
  name?: string;
  avatarImageUrl?: string;
}

export const TherapistAvatar = ({ name, avatarImageUrl }: Props) => (
  <TherapistAvatarContainer>
    {avatarImageUrl ? (
      <TherapistAvatarImage src={avatarImageUrl} />
    ) : name ? (
      <TherapistAvatarDefault>
        {name.charAt(0).toUpperCase()}
      </TherapistAvatarDefault>
    ) : (
      <Skeleton circle height="100%" />
    )}
  </TherapistAvatarContainer>
);
