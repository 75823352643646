import * as React from "react";

// tslint:disable:max-line-length
export const IconReferral = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6367_23)">
      <path
        d="M9.48444 2.77191C9.13629 1.18707 7.70541 0 5.99279 0C4.01995 0 2.42065 1.57585 2.42065 3.51939C2.42065 5.46293 4.01995 7.03878 5.99279 7.03878C6.26123 7.03878 6.52303 7.00948 6.77466 6.95438C6.9251 4.05275 8.91084 2.9747 9.48405 2.77152L9.48444 2.77191Z"
        fill="#BBBEBC"
      />
      <path
        d="M12.2766 11.6175C14.7417 11.6175 16.7401 9.61914 16.7401 7.15406C16.7401 4.68898 14.7417 2.69064 12.2766 2.69064C9.81157 2.69064 7.81323 4.68898 7.81323 7.15406C7.81323 9.61914 9.81157 11.6175 12.2766 11.6175Z"
        fill="#BBBEBC"
      />
      <path
        d="M3.01963 6.55466C3.01963 6.55466 4.49624 8.25437 6.9915 7.75384L7.34121 9.17027C7.34121 9.17027 6.12367 9.9537 5.5571 10.659L3.80932 8.97138L1.60946 11.1232H0C0 11.1232 0.168799 8.23015 3.01963 6.55466Z"
        fill="#BBBEBC"
      />
      <path
        d="M8.46887 10.9653C8.46887 10.9653 12.1516 14.6324 16.0664 10.9653C16.0664 10.9653 20.313 12.9573 19.9812 18.0311H8.55288C8.55288 18.0311 5.94039 16.8733 5.73682 14.0451L8.68455 14.0557L6.81526 12.188C6.81526 12.188 7.12668 11.8765 8.46887 10.9657V10.9653Z"
        fill="#BBBEBC"
      />
      <path
        d="M3.47172 10.4859L6.50346 13.3379H4.82563C4.82563 13.3379 4.40637 16.0101 6.94656 17.9756C6.94656 17.9756 2.94383 17.6282 2.06935 13.3379H0.427856L3.47172 10.4859Z"
        fill="#BBBEBC"
      />
    </g>
    <defs>
      <clipPath id="clip0_6367_23">
        <rect width="20" height="18.0311" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
