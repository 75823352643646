import * as React from "react";
import Helmet from "react-helmet";
import {
  Route,
  Switch,
} from "react-router";
import styled from "styled-components";

import { Header } from "../../../components/atoms";
import { AddressCreate } from "../../../components/organisms/address-management/address-create";
import { AddressSelect } from "../../../components/organisms/address-management/address-select";
import { AddressUpdate } from "../../../components/organisms/address-management/address-update";
import { Column, Container, Row } from "../../grid";
import { metaData } from "../../../static/meta";
import { MainTemplate } from "../../templates/main";

const AddressPageHeaderRow = styled(Row)`
  margin-bottom: 30px;

  @media screen and (max-width: 992px) {
    margin-bottom: 0;
  }
`;

const AddressPageSummaryColumn = styled(Column)`
  padding-left: 100px;

  @media screen and (max-width: 992px) {
    order: -1;
  }
`;

const AddressPageBookingRow = styled(Row)`
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    margin-bottom: 50px;

    ${AddressPageSummaryColumn} {
      padding: 0 5%;
    }
  }
`;

export const AddressPage = (): JSX.Element => (
  <MainTemplate>
    <Container offsetPaddings>
      <Helmet>
        <title>{metaData.checkout.title}</title>
        <meta name="description" content={metaData.checkout.description} />
      </Helmet>
      <AddressPageHeaderRow>
        <Column textAlign="center">
          <Header>Checkout</Header>
        </Column>
      </AddressPageHeaderRow>
      <AddressPageBookingRow>
        <Column md="12">
          <Switch>
            <Route
              exact={true}
              path={"/address/:type/select"}
              render={() => <AddressSelect />}
            />
            <Route
              exact={true}
              path={"/address/new"}
              render={() => <AddressCreate />}
            />
            <Route
              exact={true}
              path={"/address/:urn/edit"}
              render={() => <AddressUpdate />}
            />
          </Switch>
        </Column>
      </AddressPageBookingRow>
    </Container>
  </MainTemplate>
);
