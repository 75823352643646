import * as React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import {
  FeaturedTreatmentsDetailsList,
  SearchFormTreatmentsModal,
} from "../../components/organisms";
import { LoaderContainer } from "../../containers/common/loader";
import { ICategory } from "../../services/api/category-list";
import { IService } from "../../services/api/therapists";
import { IFeaturedTreatment } from "../../services/api/treatments";
import { actionCreators as categoriesActions } from "../../store/categories/actions";
import { IRootState } from "../../store/index";
import {
  actionCreators,
  getFeaturedTreatmentsData,
  getFeaturedTreatmentsListData,
  IFeaturedTreatmentsList,
  IFeaturedTreatmentsService,
} from "../../store/treatments/actions";
import { deNormalizeArray } from "../../store/utils";

interface IState {
  isShowBookModal: boolean;
  selectedTreatment?: IService;
}

interface IReduxProps {
  categories: ICategory[];
  featuredTreatments: IFeaturedTreatmentsService;
  isFeaturedTreatmentsError: boolean;
  featuredTreatmentsList?: IFeaturedTreatmentsList;
  isFeaturedTreatmentsListLoading: boolean;
  isFeaturedTreatmentsLoading: boolean;
}

type RouteProps = RouteComponentProps<{
  featuredListUrn: string;
}>;
type Props = IReduxProps & RouteProps & { dispatch: Dispatch<any> };

class FeaturedTreatmentsDetailsContainer extends React.Component<
  Props,
  IState
> {
  public state: IState = {
    isShowBookModal: false,
    selectedTreatment: undefined,
  };

  public async componentWillMount() {
    const urn = this.props.match.params.featuredListUrn;

    await this.props.dispatch(getFeaturedTreatmentsListData());

    if (!{}.hasOwnProperty.call(this.props.featuredTreatments, urn)) {
      await this.props.dispatch(getFeaturedTreatmentsData(urn));
    }
  }

  public async componentWillUnmount() {
    await this.props.dispatch(actionCreators.clearErrors());
  }

  public shouldComponentUpdate() {
    if (this.props.location.pathname !== this.props.history.location.pathname) {
      return false;
    }

    return true;
  }

  public showModalForm = (treatment?: IService) => {
    const selectedTreatment = treatment;

    this.setState({ selectedTreatment }, () => {
      this.setState({ isShowBookModal: true });
    });
  };

  public hideModalForm = () => {
    this.setState({ isShowBookModal: false });
  };

  public onBook = () => {
    this.props.dispatch(
      categoriesActions.setCategoryFeaturedTreatment(
        this.state.selectedTreatment as IFeaturedTreatment,
      ),
    );
  };

  /**
   * Render featured treatments details component
   */
  public render() {
    // workaround to prevent rerender on page leave
    if (this.props.location.pathname !== this.props.history.location.pathname) {
      // tslint:disable-next-line: no-null-keyword
      return null;
    }

    const listUrn = this.props.match.params.featuredListUrn;
    const featuredTreatments = this.props.featuredTreatments[listUrn] || [];

    if (this.props.isFeaturedTreatmentsError) {
      return <Redirect to="/404" />;
    }

    if (
      !featuredTreatments.length ||
      this.props.isFeaturedTreatmentsListLoading ||
      this.props.isFeaturedTreatmentsLoading
    ) {
      return <LoaderContainer type="block" fullHeight />;
    }

    const featuredTreatmentsList = this.props.featuredTreatmentsList[listUrn];

    return (
      <React.Fragment>
        <Helmet>
          <title>Ruuby - Book {featuredTreatmentsList.name}</title>
        </Helmet>

        <FeaturedTreatmentsDetailsList
          categories={denormalizeCategoryByName(this.props.categories)}
          featuredTreatments={featuredTreatments}
          featuredTreatmentsList={featuredTreatmentsList}
          onClick={this.showModalForm}
        />
        <SearchFormTreatmentsModal
          treatmentName={
            this.state.selectedTreatment && this.state.selectedTreatment.name
          }
          featuredTreatmentUrn={this.state.selectedTreatment?.urn}
          isShow={this.state.isShowBookModal}
          onCancel={this.hideModalForm}
          onBook={this.onBook}
        />
      </React.Fragment>
    );
  }
}

// store
const mapStateToProps = (state: IRootState): IReduxProps => {
  return {
    categories: state.categoriesState.categories,
    featuredTreatments: state.treatmentsState.featuredTreatments,
    featuredTreatmentsList: state.treatmentsState.featuredTreatmentsList,
    isFeaturedTreatmentsError:
      state.treatmentsState.isFeaturedTreatmentsError ||
      state.treatmentsState.isFeaturedTreatmentsListError,
    isFeaturedTreatmentsListLoading:
      state.treatmentsState.isFeaturedTreatmentsListLoading,
    isFeaturedTreatmentsLoading:
      state.treatmentsState.isFeaturedTreatmentsLoading,
  };
};

const store = withRouter(
  connect<IReduxProps, {}, {}, IRootState>(mapStateToProps)(
    FeaturedTreatmentsDetailsContainer,
  ),
);

export { store as FeaturedTreatmentsDetailsContainer };

/**
 * Denormalize categories to object of objects with name key
 */
function denormalizeCategoryByName(categories: ICategory[]) {
  return deNormalizeArray(categories, "name");
}
