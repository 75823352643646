import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  background?: "blue" | "grey" | "light-grey" | "pink" | "rose";
  boxed?: boolean;
  className?: string;
  disabled?: boolean;
  href?: string;
  to?: string;
  innerRef?: React.RefObject<HTMLElement>;
  name?: string;
  onClick?(e?: React.MouseEvent<HTMLElement>): void;
  size?: "big" | "medium" | "small" | "tiny";
  target?: string;
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
}

const style = `
  display: inline-block;
  border: 0;
  border-radius: 20px;
  font-size: ${theme.fontSize.main};
  font-weight: 700;
  color: ${theme.color.ruubyBlack};
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: all ${theme.transition.linear02};
`;

const StyledButton = styled.button`
  ${style}

  ${(props: IProps) => setButtonsPadding(props.size)};
  ${(props: IProps) => setBackground(props.background)};
  ${(props: IProps) => (props.boxed ? setBoxedButton() : undefined)};
  ${(props: IProps) => (props.disabled ? setDisabled() : undefined)};

  &:hover:not([disabled]) {
    ${(props: IProps) => setBackgroundHover(props.background)};
  }
`;

const StyledButtonLink = styled(StyledButton)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledButtonNavLink = styled(NavLink)`
  ${style}

  ${(props: IProps) => setButtonsPadding(props.size)};
  ${(props: IProps) => setBackground(props.background)};
  ${(props: IProps) => (props.boxed ? setBoxedButton() : undefined)};
  ${(props: IProps) => (props.disabled ? setDisabled() : undefined)};

  &:hover:not([disabled]) {
    ${(props: IProps) => setBackgroundHover(props.background)};
  }

  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Button: React.FC<IProps> = (props) => {
  if (props.href) {
    return <StyledButtonLink as="a" {...props} target={props.target} />;
  } else if (props.to) {
    const { innerRef, ...withoutInnerRef } = props;

    return <StyledButtonNavLink {...withoutInnerRef} to={props.to} />;
  } else {
    return <StyledButton {...props} />;
  }
};

/**
 * Set button background color
 */
function setBackground(bg): string {
  switch (bg) {
    case "light-grey":
      return `background: ${theme.color.ruubyGreyLight}`;

    case "grey":
      return `
        background: ${theme.color.ruubyNewGrey}
        border: 1px solid ${theme.color.borderColorNewGrey}
      `;

    case "pink":
      return `background: ${theme.color.ruubyPink}`;

    case "rose":
      return `background: ${theme.color.ruubyRose}`;

    case "blue":
      return `background: ${theme.color.ruubyNewBlue}; color: white`;

    default:
      return `background: white`;
  }
}

/**
 * Set button hover background color
 */
function setBackgroundHover(bg): string {
  switch (bg) {
    case "grey":
    case "light-grey":
      return `background: ${theme.color.borderColorGrey}`;

    case "pink":
      return `background: ${theme.color.ruubyPinkDark}`;

    case "rose":
      return `background: ${theme.color.ruubyPinkDark}`;

    case "blue":
      return `background: ${theme.color.ruubyBlue}`;

    default:
      return `background: ${theme.color.borderColorGrey}`;
  }
}

/**
 * Set button size (paddings)
 */
function setButtonsPadding(size): string {
  switch (size) {
    case "tiny":
      return `padding: 8px 12px;`;

    case "small":
      return `padding: 12px`;

    case "medium":
      return `padding: 12px 50px`;

    case "big":
    default:
      return `padding: 30px 80px`;
  }
}

// tslint:disable-next-line: completed-docs
function setBoxedButton() {
  return `
    &&& {
      box-shadow: inset 0 0 0 1px ${theme.color.borderColor};
      background: transparent;

      &:hover {
        box-shadow: inset 0 0 0 1px ${theme.color.ruubyBlack};
        background: transparent;
      }
    }
  `;
}

// tslint:disable-next-line: completed-docs
function setDisabled() {
  return `
    &&& {
      opacity: 0.6;
      cursor: not-allowed;
    }
  `;
}

export { Button };
