import React, { useState } from "react";
import { rem } from "polished";
import styled from "styled-components";

import { Tier } from "../../../../../../services/api/types/graphql";
import { TierLabel } from "../../../../atoms/tier-label";
import { IconCaret } from "../../../../../atoms";
import { theme } from "../../../../../../theme";
import { device } from "../../../../../../utils";

const TherapistTierInfoContainer = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

const TierTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TierTextContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.size8};
`;

const TherapistTierText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  line-height: ${({ theme }) => theme.type.sizes.size22};
  color: ${({ theme }) => theme.colours.textGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

const IconContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  transition: transform ${theme.transition.easeInOut02};

  ${({ isOpen }) => isOpen && "transform: rotate(180deg);"}
`;

const DescriptionContainer = styled.div<{ isOpen: boolean }>`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: ${({ theme }) => theme.spacing.size4};
  margin-left: ${rem("50px")};
  transition: all ${theme.transition.easeInOut05};

  ${({ isOpen }) =>
    isOpen &&
    `
    max-height: ${rem("250px")};
    opacity: 1;
    `}

  @media ${device.tablet} {
    ${({ isOpen }) => isOpen && `max-height: ${rem("120px")};`}
  }
`;

interface Props {
  tierType: Tier;
  name: string;
  description: string;
}

export const TherapistTierInfo = ({
  tierType,
  name,
  description,
}: Props): JSX.Element => {
  const [isTierInfoShown, setIsTierInfoShown] = useState(false);

  const handleClickShowInfoButton = (): void =>
    setIsTierInfoShown((prevState) => !prevState);

  return (
    <TherapistTierInfoContainer>
      <InnerContainer
        onClick={handleClickShowInfoButton}
        data-testid="therapist-tier-info-inner-container"
      >
        <TierTypeContainer>
          <TierLabel type={tierType} width={43} height={43} fontSize={24} />
          <TierTextContainer>
            <TherapistTierText>
              <Bold>{name}</Bold> Therapist
            </TherapistTierText>
          </TierTextContainer>
        </TierTypeContainer>
        <IconContainer isOpen={isTierInfoShown} data-testid="icon">
          <IconCaret width="9" height="6" />
        </IconContainer>
      </InnerContainer>
      <DescriptionContainer
        isOpen={isTierInfoShown}
        data-testid="description-container"
      >
        <TherapistTierText>{description}</TherapistTierText>
      </DescriptionContainer>
    </TherapistTierInfoContainer>
  );
};
