import { Fields } from "../../utils/class-types";

import { AreaPresenter } from "./area-presenter";
import { CheckoutAddressPresenter } from "./checkout-address-presenter";
/**
 *  Represents an address that has not been resolved to a udprn, but is enough to checkout with.
 *  This is for when the user begins their search with a postcode area, then later adds address lines 1 and 2
 */
export class UnresolvedAddressPresenter extends CheckoutAddressPresenter {
  constructor(props: Fields<AreaPresenter>) {
    super();
    Object.assign(this, props);
  }
  public getLabel() {
    return this.postcode;
  }
  public getIdentifierKey() {
    return "postcode";
  }
  public getIdentifierValue() {
    return this.postcode;
  }
  public getSummaryAddress() {
    return `${this.line_1} ${this.postcode}`;
  }
}
