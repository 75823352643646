import styled from "styled-components";

import { theme } from "../../../../theme";

const Paragraph = styled.p`
  font-size: ${theme.fontSize.paragraph};
  color: ${theme.color.ruubyGrey};
  margin: 1rem 0;
`;

export { Paragraph };
