import React from "react";
import { isFuture, differenceInDays, differenceInMonths } from "date-fns";
import styled from "styled-components";

import { device } from "../../../../../../utils";
// TODO Use the function from @ruuby/common and update its name there to be `getPastTime`:
// import { getLastBookedLabel } from "@ruuby/common/lib/utils/therapists";

// TODO Use the function from @ruuby/common
const getPastTime = (date: Date, locale: string = "en-GB") => {
  if (isFuture(date)) {
    return;
  }

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
  const now = new Date();

  const daysDifference = differenceInDays(date, now);
  const monthsDifference = differenceInMonths(date, now);

  if (daysDifference > -60) {
    return formatter.format(daysDifference, "days");
  } else if (daysDifference > -180) {
    return formatter.format(monthsDifference, "months");
  }
};

const PastTimeText = styled.time`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 700;
  font-style: italic;
  color: ${({ theme }) => theme.colours.textGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  date: Date;
}

export const PastTimeLabel = ({ date }: Props): JSX.Element => (
  <PastTimeText>{getPastTime(date)}</PastTimeText>
);
