import React from "react";
import styled from "styled-components";

import { device } from "../../../../../utils";
import { newTheme } from "../../../../../theme";
import { Column, Container, Row } from "../../../../grid";
import { EventTitle, EventText } from "../../../atoms";
import { GetInTouchButton } from "../../../atoms/events/get-in-touch-button";

const ContentHeader = styled.div<{ color: string }>`
  background-color: ${({ color }) => newTheme.colours.sectionBackground[color]};
  padding: 52px 0;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  max-width: 780px;
  padding: 0;
`;

const Banner = styled.div`
  color: ${(props) => props.color ? newTheme.colours.text[props.color] : newTheme.colours.text.main};
  font-weight: bold;
  padding: 0 16px;
  margin: 20px 10px;
  max-width: 652px;
  @media ${device.mobileL} {
    padding: 0;
    margin: 20px 0;
  }
  @media ${device.desktop} {
    max-width: 782px;
  }
`;

export interface EventHeaderProps {
  variant: 'light' | 'dark';
  title: string;
  content: string;
  href?: string;
  to?: string;
}

export const EventHeader = (props: EventHeaderProps) => {
  const backgroundColor = props.variant === 'light'? 'blueGrey' : 'darkGrey';
  const textColor = props.variant === 'light'? 'main' : 'white';

  return (
    <ContentHeader color={backgroundColor}>
      <StyledContainer limitedWidth>
        <Row justifyContent="center">
          <Column textAlign="center">
            <EventTitle color={textColor}>{props.title}</EventTitle>
          </Column>
        </Row>
        <Row justifyContent="center">
          <EventText>
            <Banner color={textColor}>{props.content}</Banner>
          </EventText>
        </Row>
        <Row justifyContent="center">
          <GetInTouchButton href={props.href} to={props.to} variant={props.variant === 'light'? 'dark' : 'light'} />
        </Row>
      </StyledContainer>
    </ContentHeader>
  );
}
