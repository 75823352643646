import React from "react";
import { Col, Hidden, Row, Visible } from "react-grid-system";

import { CheckoutSection } from "../checkout-section";
import { SummarySection } from "../summary-section";
import { PromotionSection } from "../promotion-section";
import { PaymentDetailsSection } from "../payment-details-section";
import { usePaymentInfo } from "../../hooks/use-payment-info";

interface CheckoutContainerProps {
  onClickFeeInfo: () => void;
}

export const CheckoutContainer = ({
  onClickFeeInfo,
}: CheckoutContainerProps): JSX.Element => {

  const { promoError, handlePromo, isLoadingPromo } =
    usePaymentInfo();

  const visibilityProps = { xs: true, sm: true, md: true };

  const summarySection = (
    <SummarySection
      onClickFeeInfo={onClickFeeInfo}
    />
  );

  const promoSection = (
    <PromotionSection
      isLoading={isLoadingPromo}
      error={promoError}
      onHandlePromo={handlePromo}
    />
  );

  return (
    <Row>
      <Visible {...visibilityProps}>
        <Col>
          <CheckoutSection />
          {summarySection}
          {promoSection}
          <PaymentDetailsSection />
        </Col>
      </Visible>
      <Hidden {...visibilityProps}>
        <Col lg={7} xl={8}>
          <CheckoutSection />
          <PaymentDetailsSection />
        </Col>
        <Col lg={5} xl={4}>
          {summarySection}
          {promoSection}
        </Col>
      </Hidden>
    </Row>
  );
};
