import * as React from "react";
import Helmet from "react-helmet";

import { FaqComponent } from "../../../components/organisms";
import { MainTemplate } from "../../../components/templates/main";

const FaqsPage: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>
      <FaqComponent />
    </MainTemplate>
  );
};

export { FaqsPage };
