import styled from "styled-components";

export const PaymentTile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 104px;
  padding: ${({ theme }) => theme.spacing.size22};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.type.fonts.main};
  color: ${({ theme }) => theme.colours.darkGrey};
  background-color: ${({ theme }) => theme.colours.blueGrey};
  border-radius: ${({ theme }) => theme.spacing.size8};

  transition-property: background-color, color; 
  transition-duration: 0.25s; 
  transition-timing-function: ease-out; 

  &[data-is-selected="true"] {
    background-color: ${({ theme }) => theme.colours.mediumGrey2};
    color: ${({ theme }) => theme.colours.white};
  }
`;