import { IBookingLocation, locationType } from "../../store/postcodes/reducer";

import { AreaPresenter } from "./area-presenter";
import { ResolvedAddressPresenter } from "./resolved-address-presenter";
import { UnresolvedAddressPresenter } from "./unresolved-address-presenter";

/**
 * Create a BookingLocationPresenter object from the plain data fields representing that location in state.
 * @param object The postcodesState.bookingLocation state. Must contain type, and the fields corresponding to that type.
 */
export function inflateBookingLocationPresenter(object: IBookingLocation) {
  if (typeof object === "undefined") {
    return undefined;
  }

  switch (object.type) {
    case locationType.address:
      return new ResolvedAddressPresenter(object.value as AreaPresenter);

    case locationType.area:
      return new AreaPresenter(object.value as AreaPresenter);

    case locationType.unresolvedAddress:
      return new UnresolvedAddressPresenter(object.value as AreaPresenter);

    default:
      throw new Error(`Unhandled address type: ${object.type}`);
  }
}
