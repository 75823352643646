import styled from "styled-components";

import { Container } from "../../../components/grid";
import { theme } from "../../../theme";

interface IProps {
  top?: string;
}

export const TopBar = styled.div`
  position: fixed;
  top: ${(props: IProps) => (props.top ? `${props.top}px` : "0")};
  display: flex;
  width: 100%;
  height: 50px;
  line-height: 1;
  border-bottom: 1px solid ${theme.color.ruubyNewGreyLight};
  z-index: 999;
  background: white;
  /* fix flashing for sticky element*/
  transform: translateZ(0);

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
