import { useContext, useLayoutEffect, useState } from "react";
import { History } from "history";

import { RouterHistoryContext } from "../../providers/router-history-provider";

export type UseBackButton = (params: { history: History }) => {
  isBack: boolean;
  handleClickBack(): void;
};

export const useBackButton: UseBackButton = ({ history }) => {
  const [isBack, setIsBack] = useState(false);
  const { currPath, prevPath } = useContext(RouterHistoryContext);

  useLayoutEffect(() => {
    setIsBack(Boolean(prevPath) && currPath !== prevPath);
  }, [currPath, prevPath]);

  const handleClickBack = (): void =>
    isBack ? history.goBack() : history.replace("/");

  return {
    isBack,
    handleClickBack,
  };
};
