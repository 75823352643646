import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Container } from "react-grid-system";
import styled from "styled-components";

import { metaData } from "../../../../static/meta";
import { MainTemplate } from "../../../templates/main";
import { device } from "../../../../utils";
import { PageBackButton } from "../../molecules/back-button";

import { SwitzerlandPanel } from "./components/switzerland-panel";

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media ${device.desktop} {
    max-width: 920px !important;
  }
  @media screen and (max-width: 360px) {
    padding: 0 !important;
  }
`;

const BackButtonContainer = styled.div`
  display: none;
  width: 100%;
  @media ${device.desktop} {
    padding-top: 32px;
    display: block;
  }
`;

export const SwitzerlandPage: React.FC = () => {
  const history = useHistory();
  const handleClickBack = React.useCallback(() => {
    history.push("/");
  }, []);

  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.switzerland.description}</title>
        <meta name="description" content={metaData.switzerland.description} />
      </Helmet>
      <MainContainer>
        <BackButtonContainer>
          <PageBackButton isBack={true} onClickBack={handleClickBack} />
        </BackButtonContainer>
        <SwitzerlandPanel />
      </MainContainer>
    </MainTemplate>
  );
};
