import styled from "styled-components";

import { Header } from "../../../../atoms";
import { newTheme } from "../../../../../theme";
import { device } from "../../../../../utils";

export const EventTitle = styled(Header)`
  color: ${(props) => props.color ? newTheme.colours.text[props.color] : newTheme.colours.text.main};
  font-family: ${newTheme.type.fonts.heading};
  margin-top: 20px;
  font-size: 30px;
  @media ${device.mobileL} {
    margin: inherit;
    font-size: 36px;
  }
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;
