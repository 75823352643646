import * as React from "react";

import * as analytics from "../../analytics";
import { HomeSubscribeModal } from "../../components/organisms/home-group/home-subscribe-modal";
import { config } from "../../config";

interface IState {
  isShown: boolean;
}

class HomeSubscribeModalContainer extends React.Component<{}, IState> {
  public timer!: number;
  public readonly delay = config.signupPopupTimer;
  private readonly inputRef = React.createRef<HTMLInputElement>();

  public state: IState = {
    isShown: false,
  };

  public componentDidMount() {
    if (!window.localStorage.getItem("ruuby_signup_subscription")) {
      this.timer = window.setTimeout(() => {
        this.setState({ isShown: true });
      }, this.delay);
    }
  }

  public componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  public closeSubscriptionModal = () => {
    this.setState({ isShown: false });
    window.localStorage.setItem("ruuby_signup_subscription", "1");

    analytics.track({
      name: analytics.AnalyticsEvents.NEWSLETTER_MODAL_CANCEL,
    });
  };

  public subscribe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity()) {
      if (this.inputRef.current) {
        analytics.track({
          name: analytics.AnalyticsEvents.NEWSLETTER_MODAL_SUBSCRIBE,
          payload: {
            email: this.inputRef.current.value,
          },
        });
      }

      window.localStorage.setItem("ruuby_signup_subscription", "1");
      e.currentTarget.submit();
    }
  };

  /**
   * Render home subscribe modal window
   */
  public render() {
    return (
      <HomeSubscribeModal
        isShow={this.state.isShown}
        onClose={this.closeSubscriptionModal}
        onSubscribe={this.subscribe}
        innerRef={this.inputRef}
      />
    );
  }
}

export { HomeSubscribeModalContainer };
