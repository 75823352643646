import { useCallback, useEffect, useState } from "react";
import { filterOutSlotsByDuration, filterOutSlotsByLastBookingTime } from "@ruuby/common/lib/utils/availability";
import { utcToZonedTime } from "date-fns-tz";

import { AvailabilitySlot } from "../../types";
import { format } from "date-fns";
import { config } from "../../../../../../config";

export interface DateGroupedAvailability {
  [key: string]: string[];
};

type UseAvailability = (params: {
  pageUrl: URL,
  therapistAvailability?: AvailabilitySlot[];
  treatmentsDuration: string;
  lastBookingTime?: string;
}) => {
  selectedDate?: string;
  selectedTime?: string;
  availability: DateGroupedAvailability;
  handleSelectDate: (date: string) => void;
  handleRemoveDate: () => void;
  handleSelectTime: (time: string) => void;
  handleRemoveTime: () => void;
};

const DATE_PARAM = "date";
const TIME_PARAM = "time";

export const useAvailability: UseAvailability = ({
  pageUrl,
  therapistAvailability,
  treatmentsDuration,
  lastBookingTime,
}) => {
  const [availability, setAvailability] = useState<DateGroupedAvailability>({});
  const [ selectedDate, setSelectedDate] = useState(
    pageUrl.searchParams.get(DATE_PARAM) ?? undefined
  );
  const [selectedTime, setSelectedTime] = useState(
    pageUrl.searchParams.get(TIME_PARAM) ?? undefined
  );

  const setUrl = useCallback(
    (): void => window.history.replaceState(null, "", pageUrl.href), 
    [pageUrl.href],
  );

  const handleSelectDate = useCallback((date: string) => {
    setSelectedDate(date);
    pageUrl.searchParams.set(DATE_PARAM, date);

    setUrl();
  }, [setSelectedDate]);

  const handleSelectTime = useCallback((time: string) => {
    setSelectedTime(time);
    pageUrl.searchParams.set(TIME_PARAM, time);

    setUrl();
  }, [setSelectedTime]);    

  const handleRemoveDate = useCallback((): void => {
    setSelectedDate(undefined);
    pageUrl.searchParams.delete(DATE_PARAM);

    setUrl();
  }, []);

  const handleRemoveTime = useCallback((): void => {
    setSelectedTime(undefined);
    pageUrl.searchParams.delete(TIME_PARAM);

    setUrl();
  }, []);

  const checkAvailability = useCallback(
    (groupedAvailability: DateGroupedAvailability): void => {
      const timeSlots = selectedDate && groupedAvailability[selectedDate];
  
      if (!timeSlots) {
        handleRemoveDate();
        handleRemoveTime();
      }
      else if (selectedTime && !timeSlots?.includes(selectedTime)) {
        handleRemoveTime();
      }
    },
    [selectedDate, selectedTime],
  );

  useEffect(() => {
    if (!therapistAvailability || !lastBookingTime) {
      return;
    }

    const filteredAvailability = filterOutSlotsByLastBookingTime(
      filterOutSlotsByDuration(therapistAvailability, treatmentsDuration),
      lastBookingTime,
      config.dateTime.timezone,
    ).map(({ start, end }) => ({
      start: utcToZonedTime(start, config.dateTime.timezone),
      end: utcToZonedTime(end, config.dateTime.timezone),
    }));

    const groupedAvailability = {};

    for (const {start} of filteredAvailability) {
      const date = format(start, "yyyy-MM-dd");
      const time = format(start, "HH:mm");

      if (!groupedAvailability[date]) {
        groupedAvailability[date] = [time];
      }
      else {
        groupedAvailability[date].push(time);
      }
    }

    checkAvailability(groupedAvailability);
    setAvailability(groupedAvailability);
  }, [therapistAvailability, treatmentsDuration]);

  return {
    selectedDate,
    selectedTime,
    availability,
    handleSelectDate,
    handleRemoveDate,
    handleSelectTime,
    handleRemoveTime,
  };
};