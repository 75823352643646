import { getHandler } from "../../services/request-handler";

export interface IApiMarketingMessage {
  message: string;
  enabled: boolean;
}

/**
 * Fetch marketing message
 */
export async function fetchMarketingMessage(): Promise<IApiMarketingMessage> {
  const response = await getHandler<IApiMarketingMessage>(
    "/notifications/marketing-message",
    false,
  );

  return response.result;
}
