import * as React from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import { IconX, Text } from "../../../components/atoms";
import { theme } from "../../../theme";

interface ITooltipsPosition {
  position?: "bottom" | "top";
}

interface IProps extends ITooltipsPosition {
  isShow: boolean;
  text?: string;
  onClose?(): void;
  className?: string;
}

const duration = {
  enter: 300,
  exit: 300,
};

const tooltipStyle = {
  transition: `all ${duration.enter}ms`,
};

const tooltipStyles = {
  entered: { opacity: 1 },
  entering: { opacity: 0 },
  exited: { opacity: 0 },
  exiting: { opacity: 1 },
};

const TooltipIconXButton = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;

  svg {
    width: 14px;
    height: 14px;
    fill: ${theme.color.borderColor};
    cursor: pointer;
    transition: fill ${theme.transition.linear03};

    &:hover {
      fill: ${theme.color.ruubyGrey};
    }
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px;
  border-radius: 3px;
  background: ${theme.color.tooltip};
  z-index: 100;

  &:after {
    content: " ";
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    border-width: 6px;
    margin-left: -6px;
    ${(props: ITooltipsPosition) => tooltipPosition(props)};
  }
`;

const Tooltip: React.FC<IProps> = (props) => {
  return (
    <Transition in={props.isShow} timeout={duration}>
      {(state) => (
        <React.Fragment>
          {props.isShow && (
            <TooltipWrapper
              className={props.className}
              style={{
                ...tooltipStyle,
                ...tooltipStyles[state],
              }}
              position={props.position}
            >
              {props.onClose && (
                <TooltipIconXButton onClick={props.onClose}>
                  <IconX />
                </TooltipIconXButton>
              )}
              <Text weight="semibold" color="white">
                {props.text}
              </Text>
            </TooltipWrapper>
          )}
        </React.Fragment>
      )}
    </Transition>
  );
};

/**
 * Set the position of tooltip
 */
function tooltipPosition(props: ITooltipsPosition) {
  if (props.position === "top") {
    return `
      top: 100%;
      border-top-color: ${theme.color.tooltip};
    `;
  } else {
    return `
      bottom: 100%;
      border-bottom-color: ${theme.color.tooltip};
    `;
  }
}

export { Tooltip };
