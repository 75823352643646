import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useOtpValidate } from "../../hooks/use-validate-otp";
import { ValidateOtp } from "../../components/templates/validate-otp";

interface ValidateOtpPageProps {
  onCompleteLogin: () => void,
}

export const ValidateOtpPage = ({ onCompleteLogin }: ValidateOtpPageProps): JSX.Element => {
  const history = useHistory();
  const { contact, method, token } = useParams();
  const [expiryTime, setExpiryTime] = useState<string>('');

  // New contact
  const onValidateNewContact = useCallback((contactToken: string) => {
    history.replace(`/signup/${method}/${contact}/register/${contactToken}`);
  }, [method, contact]);

  // Existing contact
  const onSuccess = useCallback(() => {
    onCompleteLogin();
  }, []);

  const {
    error: otpValidateError,
    validateOtp,
    tokenDetails,
    isLoading: isValidating,
  } = useOtpValidate({
    token,
    onValidateNewContact,
    onSuccess
  });

  useEffect(() => {
    setExpiryTime(tokenDetails.expiryTime);
  }, [tokenDetails]);

  return (
    <ValidateOtp
      contactMethod={method.toUpperCase()}
      contact={contact}
      expiryTime={expiryTime}
      otpValidateError={otpValidateError}
      validateOtp={validateOtp}
      isValidating={isValidating}
    />
  );
}
