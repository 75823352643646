import styled from "styled-components";

export const Section = styled.section<{ small?: boolean }>`
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-direction: column;
  fill: #FFF;
  border-radius: 8px;
  box-shadow: 0px 1px 17px 0px rgba(0,0,0,0.10);
  padding: 30px 20px;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
`;