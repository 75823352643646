import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { IconStore } from "../../../../components/atoms";
import { device } from "../../../../utils/media-queries";

import { useDownloadAppLink } from "../../../../hooks/use-download-app-link";
import { Heading1, Panel, Paragraph } from "../../atoms";

import phone from "./phone.webp";
import LazyLoad from "react-lazy-load";

const PanelStyled = styled(Panel)<{ colour?: string }>`
  background-color: ${({ colour = undefined, theme }) => colour ? theme.colours.sectionBackground[colour] : theme.colours.ruubyPink};
  display: inline-flex;
  padding: 26px 10px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 296px;
  @media ${device.mobileL} {
    width: auto;
  }
  @media ${device.laptop} {
    width: auto;
    flex-direction: column;
    padding: 0px 98px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px; 
  flex-direction: column-reverse;
  @media ${device.mobileL} {
    flex-direction: unset;
    padding-right: 8px;
  }
  @media ${device.laptop} {
    gap: 30px; 
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 265px;
  align-items: flex-end;
  align-content: flex-end;
  gap: 20px 15px;
  flex-wrap: wrap;
  @media ${device.mobileL} {
    width: 394px;
  }
`;

const Heading = styled(Heading1)`
  margin: 0;
  padding: 0;
  text-align: center;
  @media ${device.mobileL} {
    line-height: 25px;
  }
`;

const Intro = styled(Paragraph)`
  text-align: center;
  margin: 0 0;
  font-size: ${({ theme }) => theme.type.sizes.size16};
  width: 340px;
  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size20};
    text-align: left;
  }
`;

const IconsContainer = styled.div`
  width: 100%;
`;

const IconStoreStyled = styled(IconStore)`
  height: ${rem("39px")};

  :not(:first-child) {
    margin-left: ${rem("7px")};
  }

  :not(:last-child) {
    margin-right: ${rem("7px")};
  }

  @media ${device.tablet} {
    height: ${rem("48px")};
  }
`;

const PhoneImage = styled.div`
  flex-shrink: 0;
  width: ${rem("234px")};
  height: ${rem("235px")};
  background: url(${phone}) -31.322px -42.566px / 113.437% 200.776% no-repeat;
  @media ${device.mobileS} {
    flex-shrink: unset;
    width: ${rem("279px")};
    height: ${rem("280px")};
    background: url(${phone}) -37.345px -50.717px / 113.437% 200.776% no-repeat;
  }
  @media ${device.laptop} {
    width: ${rem("300px")};
    height: ${rem("282px")};
  }
  background: url(${phone}) -40.156px -54.581px / 113.437% 214.539% no-repeat;
`;

interface DownloadAppPanelProps {
  colour?: string;
}

export const DownloadAppPanel = ( props: DownloadAppPanelProps ): JSX.Element => {
  const { QrModal, handleClickDownloadApp } = useDownloadAppLink();

  return (
    <>
      <PanelStyled colour={props.colour} onClick={handleClickDownloadApp}>
        <InnerContainer>
          <LazyLoad offset={200}>
            <PhoneImage />
          </LazyLoad>
          <ContentContainer>
            <Heading>Download our Ruuby App</Heading>
            <Intro>
              Browse, book and manage your treatments straight from your phone.
            </Intro>
            <IconsContainer>
              <IconStoreStyled type="ios" />
              <IconStoreStyled type="android" />
            </IconsContainer>
          </ContentContainer>
        </InnerContainer>
      </PanelStyled>
      <QrModal />
    </>
  );
};
