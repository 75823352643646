import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as ShareImage } from "./share.svg";

export const IconShare = styled(ShareImage)`
  width: ${rem("15px")};
  height: ${rem("16px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
