import * as React from "react";
import styled from "styled-components";

interface IProps {
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 100px;

  @media screen and (max-width: 992px) {
    margin-top: 50px;
  }
`;

const LandingTemplate: React.FC<IProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export { LandingTemplate };
