import React from "react";
import styled from "styled-components";

import { device } from "../../../../../../../utils";
import { Heading1 } from "../../../../../atoms";

import FacialTreatmentImage from "../../../../../../../assets/images/login/facial_treatment.webp";

const Container = styled('div')`
  display: flex;
  width: 920px;
  max-width: 100%;
  height: 589px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 0;
  border-radius: 7px;
  overflow: hidden;
  @media ${device.tablet} {
    width: 745px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.13);
  }
  @media ${device.laptop} {
    width: 920px;
    margin-bottom: 52px;
  }
`;

const FacialTreatmentSection = styled('div')`
  display: none;
  max-width: 445px;
  min-width: 50%;
  flex-shrink: 0;
  align-self: stretch;
  background: url(${FacialTreatmentImage}) lightgray 50% / cover no-repeat;
  @media ${device.tablet} {
    display: flex;
  }
`;

const Content = styled('div')<{ smallPadding?: boolean, error?: boolean }>`
  display: flex;
  padding: 33px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch; 
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background.white};
  @media ${device.tablet} {
    ${({ error, smallPadding }) => error? `padding: 50px 48px;` : (smallPadding ? `padding: 90px 48px;` : `padding: 97px 48px;`)}
    max-width: 475px;
  }
`;

const SignupSection = styled('div')<{ smallPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ smallPadding }) => smallPadding? `gap: 16px;` : `gap: 33px;`}
  flex: 1 0 0;
  align-self: stretch; 
`;

const SignupContent = styled('div')<{ smallPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ smallPadding }) => smallPadding? `gap: 20px;` : `gap: 24px;`}
  width: 100%;
  max-width: 345px;
`;

const Header = styled(Heading1)`
  color: ${({ theme }) => theme.colours.textMain};
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  line-height: 100%;
  margin: 0;
`;

interface AuthContainerProps {
  title: string,
  smallPadding?: boolean,
  error?: boolean,
  children: React.ReactNode,
}

export const AuthContainer = (props: AuthContainerProps) => (
  <Container>
    <FacialTreatmentSection />
    <Content smallPadding={props.smallPadding} error={props.error}>
      <SignupSection smallPadding={props.smallPadding}>
        <Header>{props.title}</Header>
        <SignupContent smallPadding={props.smallPadding}>
          {props.children}
        </SignupContent>
      </SignupSection>
    </Content>
  </Container>
);