import { rem } from "polished";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { Heading1 } from "../../../../components/themed-v2/atoms";
import { ReviewPanel } from "../../../../components/themed-v2/molecules";
import { device } from "../../../../utils/media-queries";

const ReviewsHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colours.background.white};
`;

const ReviewsRow = styled(Row)`
  margin-top: ${rem("24px")};

  margin-bottom: ${rem("-20px")};
  > div > div {
    min-height: calc(
      100% - ${rem("20px")}
    ); // to make the cards same height as siblings
    box-sizing: border-box;
    margin-bottom: ${rem("20px")};
  }

  @media ${device.tablet} {
    margin-top: ${rem("34px")};
  }
`;

interface IProps {
  heading: string;
  reviews: React.ComponentProps<typeof ReviewPanel>[];
}

export const CustomerReviews = ({ heading, reviews }: IProps): JSX.Element => (
  <Container>
    <Row justify="center">
      <Col md={9}>
        <ReviewsHeading>{heading}</ReviewsHeading>
      </Col>
    </Row>
    <ReviewsRow>
      {reviews.map((review) => (
        <Col lg={4} key={review.review}>
          <ReviewPanel {...review} />
        </Col>
      ))}
    </ReviewsRow>
  </Container>
);
