import * as React from "react";
import styled from "styled-components";

import { IconChevron } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  center?: boolean;
  centerIfMobile?: boolean;
  clickNext(): void;
  clickPrev(): void;
  scrollerBeltRef: React.RefObject<HTMLDivElement>;
  scrollerWrapperRef: React.RefObject<HTMLDivElement>;
  leftArrow: boolean;
  rightArrow: boolean;
  children?: React.ReactNode;
}

const centerIfMobileArrowRight = `
  left: 93%;

  &&& {
    &:hover {
      transform: none;
    }
  }
`;

const centerIfMobileArrowLeft = `
  left: auto;
  right: 93%;
`;

const ScrollerBeltWrapper = styled.div`
  overflow: hidden;
`;

const ScrollerBelt = styled.div`
  &.animate {
    transition: transform ${theme.transition.easeInOut05};
  }
`;

const ScrollerRightArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 95%;
  cursor: pointer;
  z-index: 101;
  transition: transform ${theme.transition.easeInOut02};

  svg {
    width: 28px;
    height: 28px;
  }

  &:hover {
    transform: translate(8px, 0);
  }

  @media screen and (max-width: 992px) {
    marging: 0 5%
      ${(props: Partial<IProps>) =>
        props.centerIfMobile ? centerIfMobileArrowRight : `display: flex`};
  }
`;

const ScrollerLeftArrow = styled(ScrollerRightArrow)`
  justify-content: flex-end;
  width: 100%;
  left: auto;
  right: 95%;

  svg {
    transform: rotate(180deg);
  }

  &:hover {
    transform: translate(-8px, 0);
  }

  @media screen and (max-width: 992px) {
    ${(props: Partial<IProps>) =>
      props.centerIfMobile ? centerIfMobileArrowLeft : `display: flex`};
  }
`;

const ScrollerWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 5%;
  align-items: center;
  overflow: hidden;

  ${(props: Partial<IProps>) =>
    props.center ? `justify-content: center` : undefined};

  @media screen and (max-width: 992px) {
    ${(props: Partial<IProps>) =>
      !props.center && props.centerIfMobile
        ? `justify-content: center`
        : undefined};
  }
`;

const Scroller: React.FC<IProps> = (props) => {
  return (
    <ScrollerWrapper
      center={props.center}
      centerIfMobile={true}
      ref={props.scrollerWrapperRef}
    >
      {props.leftArrow ? (
        <React.Fragment>
          <ScrollerLeftArrow onClick={props.clickPrev} centerIfMobile={true}>
            <IconChevron />
          </ScrollerLeftArrow>
        </React.Fragment>
      ) : (
        ""
      )}
      <ScrollerBeltWrapper ref={props.scrollerBeltRef}>
        <ScrollerBelt>{props.children}</ScrollerBelt>
      </ScrollerBeltWrapper>
      {props.rightArrow ? (
        <React.Fragment>
          <ScrollerRightArrow onClick={props.clickNext} centerIfMobile={true}>
            <IconChevron />
          </ScrollerRightArrow>
        </React.Fragment>
      ) : (
        ""
      )}
    </ScrollerWrapper>
  );
};

export { Scroller };