import * as api from "../../services/api/notifications";
import { createStandardAction } from "typesafe-actions";

import { ActionDispatch } from "../index";

interface IFetchMarketingMessageSuccess {
  message: api.IApiMarketingMessage;
}

interface IFetchMarketingMessageError {
  error: Error;
}

export const actionCreators = {
  fetchMarketingMessageInit: createStandardAction(
    "notifications/FETCH_MARKETING_MESSAGE_INIT",
  )<void>(),
  fetchMarketingMessageSuccess: createStandardAction(
    "notifications/FETCH_MARKETING_MESSAGE_SUCCESS",
  )<IFetchMarketingMessageSuccess>(),
  // tslint:disable-next-line:object-literal-sort-keys
  fetchMarketingMessageError: createStandardAction(
    "notifications/FETCH_MARKETING_MESSAGE_ERROR",
  )<IFetchMarketingMessageError>(),
};

/**
 * Fetch marketing message
 */
export function fetchMarketingMessage(): ActionDispatch<void> {
  return async (dispatch) => {
    dispatch(actionCreators.fetchMarketingMessageInit());

    try {
      const message = await api.fetchMarketingMessage();

      dispatch(actionCreators.fetchMarketingMessageSuccess({ message }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(actionCreators.fetchMarketingMessageError({ error }));
      }
    }
  };
}
