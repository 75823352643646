import React, { useCallback } from "react";

import { AffiliatesHero } from "./components/affiliates-hero";
import { AffiliatesWho } from "./components/affiliates-who";
import { AffiliatesInfo } from "./components/affiliates-info";
import { AffiliatesCta } from "./components/affiliates-cta";
import { config } from "../../../../config";

export const AffiliatesPage = (): JSX.Element => {
  const handleClickButton = useCallback(() => window.open(config.affiliates.joinUrl), []);

  return (
    <>
      <AffiliatesHero onClickButton={handleClickButton} />
      <AffiliatesWho />
      <AffiliatesInfo />
      <AffiliatesCta onClickButton={handleClickButton} />
    </>
  );
};
