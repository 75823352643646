import * as React from "react";
import styled from "styled-components";

import { Paragraph, Text } from "../../../../components/atoms";
import { theme } from "../../../../theme";

interface IProps {
  title: string;
  text: string;
  className?: string;
}

const Wrapper = styled.div`
  line-height: ${theme.fontSize.big};
`;

const TextSection: React.FC<IProps> = (props) => {
  return (
    <Wrapper {...props}>
      <Text className="title" size="24">
        {props.title}
      </Text>
      <Paragraph className="text">{props.text}</Paragraph>
    </Wrapper>
  );
};

export { TextSection };
