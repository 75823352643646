import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import {
  HeroPanel,
  TreatmentPanel,
  ReviewPanel,
} from "../../../../../components/themed-v2/molecules";
import { device } from "../../../../../utils/media-queries";

import { Category } from "../../../../../types";

import massageHero from "./massage.webp";
import hairHero from "./hair.webp";
import nailsHero from "./nails.webp";
import tanningHero from "./tanning.webp";
import makeupHero from "./makeup.webp";
import facialHero from "./facials.webp";
import waxingHero from "./waxing.webp";
import osteopathyHero from "./osteopathy.webp";
import healthHero from "./health.webp";
import aestheticsHero from "./aesthetics.webp";
import { ReactComponent as TatlerLogoImage } from "./tatler.svg";

export const TatlerLogo = styled(TatlerLogoImage)`
  width: ${rem("119px")};
  height: ${rem("38px")};
  fill: ${({ theme }) => theme.colours.icon};

  @media ${device.tablet} {
    width: ${rem("143px")};
    height: ${rem("46px")};
  }
`;

interface CategoryData {
  hero: React.ComponentProps<typeof HeroPanel> & {
    image: string;
  };
  mostPopular: {
    title: string;
    description: string;
    treatments: React.ComponentProps<typeof TreatmentPanel>[];
  };
  reviews: React.ComponentProps<typeof ReviewPanel>[];
}

const reviews = [
  {
    customer: "Emma",
    review:
      "Ruuby is fantastic! Being able to have treatments at home is so useful. The therapists I have booked have always been extremely professional and the final result brilliant. I couldn’t recommend Ruuby enough!",
  },
  {
    logo: <TatlerLogo />,
    review:
      "Ruuby, the fashion set's go-to digital beauty concierge founded four years ago, has gathered an impeccable reputation for providing salon and medi spa level treatments in the indulgent comfort of one’s home.",
  },
];

export const categoryData: Record<Category, CategoryData> = {
  [Category.MASSAGE]: {
    hero: {
      image: massageHero,
      heading:
        "Book high quality massages with our curated list of mobile therapists",
      paragraphs: [
        "On demand massage appointments from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Massage Treatments",
      description:
        "Enjoy a spa-quality massage from our extensive treatment menu, without having to leave the home, office or hotel with our expert mobile professionals. The ultimate luxury. All masseuses come with beds.",
      treatments: [
        {
          name: "Bespoke Massage",
          description:
            "Designed to up-lift and re-energise or relax and de-stress, our masseuses will create a personalised massage targeted to your individual needs, pressure preferences and focus areas.",
          price: 73,
        },
        {
          name: "Deep Tissue",
          description:
            "A firm pressure, full body massage which releases chronic muscle tension, relieves pain and restores normal movement.",
          price: 76,
        },
        {
          name: "Lymphatic Drainage",
          description:
            "Using light pressure, this massage stimulates the natural drainage of the lymph. Benefits include reducing fluid retention, boosting the immune system, metabolism and circulation. ",
          price: 76,
        },
        {
          name: "Bamford B-Silent",
          description:
            "Bamford’s ninety minute full body experience is devoted to relaxing the body and preparing it for a restful sleep. Includes a therapeutic back and scalp massage and Thai stretching.",
          price: 130,
        },
        {
          name: "Aromatherapy Associates Unwind & Destress",
          description:
            "Using essential oil blends, this massage focuses on the back, neck and shoulders, and uses a variety of massage techniques to relax the mind and release muscle tension.",
          price: 95,
        },
        {
          name: "Sports Massage",
          description:
            "Manual and manipulative therapy to treat pain and injury, aid recovery, and enhance performance. Perfect for those who undertake strenuous physical activity.",
          price: 80,
        },
        {
          name: "Pregnancy Massage",
          description:
            "A prenatal massage that focuses on the special needs of the mother-to-be. This massage will improve circulation, relieve muscle tension, reduce swelling and promote a restful sleep.",
          price: 73,
        },
        {
          name: "Thai Massage",
          description:
            "A traditional Thai massage is conducted on a mat on the floor. This treatment manipulates the body in certain ways to stimulate organs and improve flexibility.",
          price: 80,
        },
        {
          name: "Anti-Cellulite Massage",
          description:
            "A specially modified massage designs to specifically target cellulite by breaking down the underlying fat in the areas affected, using a pinching and rolling technique.",
          price: 73,
        },
        {
          name: "Reflexology Massage",
          description:
            "A therapeutic treatment to relieve pain by stimulating predefined pressure points on the feet and hands. Relieves symptoms such as stress, injury and illness.",
          price: 73,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I booked a massage with a 5 star rated therapist , and she was amazing , she lived up to her rating she was intuitive and tailored the hour around my needs. The app is easy to use and there is an immediate response to any questions you have. Effortless. Professional and value for money in your own home.",
        customer: "Suzy",
      },
    ],
  },
  [Category.HAIR]: {
    hero: {
      image: hairHero,
      heading:
        "Book salon quality hair-dressing with our curated list of mobile hairdressers",
      paragraphs: [
        "On demand hairdressing appointments from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Hair Treatments",
      description:
        "Our mobile hair stylists have everything you need to give a salon finish at-home. For blow dries, please have wet hair upon arrival.",
      treatments: [
        {
          name: "Blow Dry",
          description:
            "The best blow dries in the business. Choose from bouncy to sleek, our stylists will deliver a bespoke blow dry to your personal preference.",
          price: 47,
        },
        {
          name: "Updo",
          description:
            "Choose an updo of your choice, from a ballerina bun to a sleek pony.",
          price: 65,
        },
        {
          name: "Braids",
          description:
            "Choose a braid style of your choice, from fishtail to halo braids.",
          price: 50,
        },
        {
          name: "Cut and Blow Dry",
          description:
            "At-home hair stylists who offer everything from trims to a fresh new do.",
          price: 85,
        },
        {
          name: "Hair Colour",
          description:
            "At-home hair stylists offer everything from highlights to balayage, toners and gloss.",
          price: 30,
        },
        {
          name: "Bridal",
          description:
            "Bridal trials and styling for the day of your wedding. Bespoke bridal packages are available on request.",
          price: 100,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I’ve used Ruby now a few times for blow dries and up do’s. Honestly the hairdressers have been phenomenal, they are experienced and listen to every aspect of what you ask for. I would not hesitate to recommend them. Fantastic service.",
        customer: "Zahra",
      },
    ],
  },
  [Category.NAILS]: {
    hero: {
      image: nailsHero,
      heading:
        "Book high quality nail appointments with our curated list of mobile therapists",
      paragraphs: [
        "On demand nail technicians from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Nail Treatments",
      description: "",
      treatments: [
        {
          name: "Classic Manicure",
          description:
            "The perfect pick-me-up for tired hands. Nails are filed and buffed, cuticles tidied and hands moisturised during a relaxing hand massage. Choose from a large selection of classic paints.",
          price: 40,
        },
        {
          name: "Classic Pedicure",
          description:
            "The perfect pick-me-up for tired feet. Includes cut and file, cuticle removal, foot soak and exfoliation, a nourishing foot massage and application of a classic nail polish of your choice.",
          price: 45,
        },
        {
          name: "Gel Manicure",
          description:
            "A gel manicure is chip free and lasts up to two weeks. Nails are filed and buffed, cuticles tidied and hands moisturised during a relaxing hand massage. Choose from a large selection of gel nail polish colours.",
          price: 45,
        },
        {
          name: "Gel Pedicure",
          description:
            "A gel pedicure is chip free and lasts up to two weeks.  Includes cut and file, cuticle removal, foot soak and exfoliation, a nourishing foot massage and application of a gel nail polish of your choice.",
          price: 50,
        },
        {
          name: "Acrylic/Extensions Manicure",
          description:
            "Gel extensions are a great way to add strength and length to your natural nails. Acrylic extensions is the oldest form of extension which covers the entire nail and uses a polymer powder for a more long lasting finish overall.",
          price: 80,
        },
        {
          name: "Gel Removal",
          description:
            "Removal of gel polish on both hands and feet without damaging the nails.",
          price: 10,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "A really fantastic service. The beauticians are always punctual, polite and exceptionally good at their craft. Longest lasting gel mani/pedi I've ever had!",
        customer: "Tatiana",
      },
    ],
  },
  [Category.TANNING]: {
    hero: {
      image: tanningHero,
      heading:
        "Book fuss-free tanning appointments with our curated list of mobile therapists",
      paragraphs: [
        "On demand tanning experts from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Tanning Services",
      description: "",
      treatments: [
        {
          name: "Spray Tan",
          description:
            "A long lasting spray tan that gives you a sun kissed tan that enhances all your best bits in one go.",
          price: 45,
        },
        {
          name: "Express Spray Tan",
          description:
            "A long lasting spray tan that starts to develop in one hour. This option is ideal for those who do not like to sleep in tan or have no time to waste.",
          price: 50,
        },
        {
          name: "Body Scrub",
          description:
            "Available as an add-on treatment, this detoxifying and smoothing scrub will leave your skin feeling refreshed and glowing before your tan.",
          price: 20,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "Love using Ruuby! Super convenient to have treatments at home, saves so much time! Especially with spray tans so you don’t have to worry about the awkward journey home, you’re already there! Therapists are friendly and professional and I’d recommend to anyone!",
        customer: "Sophie",
      },
    ],
  },
  [Category.MAKEUP]: {
    hero: {
      image: makeupHero,
      heading:
        "Book industry renowned make-up appointments with our curated list of mobile make-up artists",
      paragraphs: [
        "On demand make-up artists from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Make-Up Services",
      description: "",
      treatments: [
        {
          name: "Bespoke Make-Up",
          description:
            "Our internationally acclaimed make-up artists will create a bespoke flawless look, most suitable to your skin tone and facial features.",
          price: 79,
        },
        {
          name: "Celebrity Make-Up",
          description:
            "Book with one of our celebrity make-up artists for a show-stopping look for a big event. These make-up artists are handpicked based on their experience on international fashion weeks, editorials campaigns and high profile events.",
          price: 175,
        },
        {
          name: "Bridal Make-Up",
          description:
            "Trials and wedding day make-up for the big day. Our make-up artists work with brides to achieve their dream look for guaranteed perfection.",
          price: 125,
        },
        {
          name: "Make-Up Lesson",
          description:
            "Our bespoke makeup lessons are tailored entirely to you, ensuring you get the most from your personalised tutorial. Whether you want to nail a smokey eye, fresh-faced glow or highlighted cheekbones, your makeup artist will arrive equipped with a full makeup kit, allowing you to practise using professional tools and products.",
          price: 150,
        },
        {
          name: "Hair and Make-up Packages",
          description:
            "Combining two of our most popular treatments, Ruuby’s skilled hair and make-up artist will provide a bespoke look of your choice.",
          price: 110,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "Hands down best at-home beauty.  From manicures to make-up, I’ve never had a bad service.  5 stars all the way.",
        customer: "Whitney",
      },
    ],
  },
  [Category.FACIAL]: {
    hero: {
      image: facialHero,
      heading:
        "Book high quality facials with our curated list of mobile therapists",
      paragraphs: ["On demand facials from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular Facial Treatments",
      description:
        "Enjoy a spa-quality facial from our extensive treatment menu, without having to leave the home, office or hotel with our expert mobile professionals. The ultimate luxury. All therapists come with beds.",
      treatments: [
        {
          name: "Bespoke Facial",
          description:
            "Our signature bespoke facial delivers results exactly where they're needed; anti-ageing, cleansing or brightening. See immediate results including restored hydration, decreased appearance of enlarged pores, improved skin tone and texture, reduced pigmentation, improved appearance of fine lines and wrinkles.",
          price: 79,
        },
        {
          name: "Dr. Barbara Sturm Signature Sturm Glow",
          description:
            "The signature cult Dr. Barbara Sturm facial. Cleanse, nourish and awaken the skin and help restore the complexion’s natural glow.",
          price: 225,
        },
        {
          name: "Dr. Barbara Sturm Super Anti-Ageing",
          description:
            "This facial replenishes moisture reservoirs resulting in a smoother and more youthful complexion, promoting the skin’s natural barrier function and reducing the appearance of fine lines and wrinkles. Choose the micro-needling option to see added benefits and boost your skins collagen production.",
          price: 300,
        },
        {
          name: "The Light Salon Face & Décolletage Treatment",
          description:
            "This collagen-boosting treatment combines The Light Salon’s clinically proven red 633nm and near-infrared 830nm wavelengths with a nourishing Hydrogel mask to plump, smooth and correct the skin on your face and décolletage. An extended massage of the face, shoulders, arms and hands erases tension and lifts the complexion. Expect an instant glow that will last for days.",
          price: 120,
        },
        {
          name: "Gents Refinery Facial",
          description:
            "Perfect for men looking for a deep cleanse and brightening, this facial hydrates and plumps. Skin is left luminous, mind is left calmed and stilled. ",
          price: 130,
        },
        {
          name: "Bamford Wellness Facial ",
          description:
            "Combining Gua Sha and lymphatic massage, this facial cleanses, refines, treats and nourishes the skin and includes a head, neck and stomach massage to target areas of the body that contribute to skin health from the inside.",
          price: 150,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I had a Dr Barbara Sturm facial which was divine. Complete with a large array of her products, hot towels, spa music and a clean and friendly therapist. Have since booked for my husband, thoroughly recommend.",
        customer: "Simone",
      },
    ],
  },
  [Category.WAXING]: {
    hero: {
      image: waxingHero,
      heading:
        "Book five-star waxing appointments with our curated list of mobile therapists",
      paragraphs: ["On demand waxing from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular Waxing Services",
      description: "",
      treatments: [
        {
          name: "Hollywood",
          description:
            "Removal of all bikini hair. Choose between hot or strip wax.",
          price: 45,
        },
        {
          name: "Brazilian",
          description:
            "Removal of all bikini hair, leaving a small landing strip. Choose between hot or strip wax.",
          price: 40,
        },
        {
          name: "Bikini",
          description:
            "Removal of all bikini hair outside the knicker line. Choose between hot or strip wax.",
          price: 25,
        },
        {
          name: "Underarm",
          description:
            "Removal of underarm hair. Choose between hot or strip wax.",
          price: 15,
        },
        {
          name: "Upper lip",
          description:
            "Removal of upper lip hair. Choose between hot or strip wax.",
          price: 10,
        },
        {
          name: "Eyebrows",
          description: "Shape and tidy the brows using wax.",
          price: 12,
        },
        {
          name: "Leg Waxing",
          description:
            "The removal of leg hair. Choose between hot or strip wax.",
          price: 30,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "Ruuby is the best invention ever! Incredible service straight to your door. I have my go to therapists who deliver time and time again. Thank you for making my life so convenient!",
        customer: "Katie",
      },
    ],
  },
  [Category.EYES]: {
    hero: {
      image: tanningHero,
      heading:
        "Book brow and lash appointments with our curated list of mobile therapists",
      paragraphs: ["On demand beauty from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular Lash and Brow Services",
      description: "",
      treatments: [
        {
          name: "Eyebrow Wax",
          description: "Shape and tidy the brows using wax.",
          price: 12,
        },
        {
          name: "Brow Threading",
          description: "Shape and tidy the brows using threading.",
          price: 20,
        },
        {
          name: "Brow Tint",
          description:
            "Tints give the appearance of thicker, darker and more defined brows.",
          price: 15,
        },
        {
          name: "Lash Tint",
          description:
            "Tints give the appearance of thicker, darker and more voluminous lashes.",
          price: 15,
        },
        {
          name: "Lash Lift",
          description:
            "A natural alternative to lash extensions, this treatment gently curls your natural lashes from the lash line, enhancing their thickness and length to give a more defined appearance.",
          price: 65,
        },
        {
          name: "Lash Extensions",
          description:
            "The perfect way to add instant glamour, without the need for makeup. Extensions involve the addition of an extension to every natural lash for a dramatic increase in volume and length. Available as full set, half set and infills.",
          price: 75,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "Ruuby is the best invention ever! Incredible service straight to your door. I have my go to therapists who deliver time and time again. Thank you for making my life so convenient!",
        customer: "Katie",
      },
    ],
  },
  [Category.WELLNESS]: {
    hero: {
      image: tanningHero,
      heading:
        "Book hassle-free wellness appointments with our curated list of mobile professionals",
      paragraphs: ["On demand wellness from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular Wellness Treatments",
      description:
        "Enjoy a wellness treatment without having to leave the home, office or hotel with our expert mobile professionals. The ultimate luxury. All therapists come with beds unless otherwise stated.",
      treatments: [
        {
          name: "Reiki",
          description:
            "A form of alternative medicine known as energy healing that promotes relaxation, reducing stress and anxiety by working with the energy fields around the body.",
          price: 80,
        },
        {
          name: "Reflexology",
          description:
            "A therapeutic method of relieving pain and stress by stimulating predefined pressure points on the feet and hands.",
          price: 73,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "Had the best reflexology with Diane. Just booked her for a full massage as she was excellent.",
        customer: "Monica",
      },
    ],
  },
  [Category.OSTEOPATHY]: {
    hero: {
      image: osteopathyHero,
      heading:
        "Book qualified osteopaths and physiotherapists with our mobile professionals",
      paragraphs: [
        "On demand health services from early to late, seven days a week.",
      ],
    },
    mostPopular: {
      title: "Our Most Popular Osteopathy & Physiotherapy Treatments",
      description: "",
      treatments: [
        {
          name: "Osteopathy",
          description:
            "A physical non,invasive, hands-on treatment that detects, diagnoses and treats damaged parts of the body’s tissues and joints. This treatment includes a pain specialist consultation, assessment, examination and treatment by one of our registered professionals.",
          price: 80,
        },
        {
          name: "Add on Acupuncture",
          description:
            "Using fine needles at certain sites in the body to increase blood flow and circulation to problematic areas and promotes relaxation.",
          price: 45,
        },
        {
          name: "Physiotherapy",
          description:
            "This treatment helps to restore, maintain and make the most of your mobility, function and well-being. A hands on treatment for injury, rehabilitation, sports preparation and post operative care with one of our registered professionals.",
          price: 80,
        },
        {
          name: "Add on Kinesiology Taping",
          description:
            "Involves placing strips of tape on your body in specific directions to help support your joints.",
          price: 30,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I have used Ruuby many times over the years and have always had a positive experience. The staff are so friendly and professional. Would highly recommend!",
        customer: "Debbie",
      },
    ],
  },
  [Category.HEALTH]: {
    hero: {
      image: healthHero,
      heading:
        "Book medical grade IV drips with our mobile medical professionals ",
      paragraphs: ["On demand IV from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular IV Drips",
      description:
        "Our medical partners are one of London’s leading medical operators, offering a unique range of services, all of which are designed by doctors and administered by highly trained nurses.",
      treatments: [
        {
          name: "Bespoke IV Drip",
          description:
            "Our practitioners will bring a selection of products and you can customise your drip on the spot. We have a wide range of amino acids, vitamins, electrolytes, pain relieving and anti-sickness medications to protect, promote and restore your health",
          price: 350,
        },
        {
          name: "Deluxe Recovery IV Drip",
          description:
            "Our Deluxe Recovery IV drip is the ultimate hangover cure. Curated by Anaesthetic Doctors you can do not better. Built around litre of isotonic rehydration solution it contains prescription-only IV paracetamol, anti-sickness, and anti-inflammatory medications and it also contains Vitamin C, Vitamins B1, B2, B3, B6, and Magnesium.",
          price: 325,
        },
        {
          name: "Curcumin IV Drip",
          description:
            "This premium infusion contains IV curcumin 100mg which is a natural and powerful anti-inflammatory supplement that can help treat discomfort and disease resulting from inflammation in the body including: auto-immune disease flare-ups, leaky gut and IBS, Crohn’s Disease and Rheumatoid Arthritis. This drip also includes a B-vitamin complex and the anti-oxidant vitamin C.",
          price: 300,
        },
        {
          name: "NAD+IV Therapy",
          description:
            "Nicotinamide adenine dinucleotide (NAD) is a powerful molecule that powers multiple metabolic processes and impacts a wide variety of systems, including digestion, cognition and mental clarity, ageing, and overall energy levels. Our NAD+ IV Therapy is specifically formulated to boost your body’s levels of NAD+ to increase your energy levels, boost your metabolism, and improve focus and concentration.",
          price: 325,
        },
        {
          name: "Mood Boosting & Anti Anxiety Drip ",
          description:
            "Help to boost levels of serotonin and dopamine, your happy-hormones, with this unique blend of high-level supplements and medications which will help to reduce anxiety and improve both mood and sleep. By increasing your levels of dopamine and serotonin you will be less irritable have better mood, reduced anxiety, reduced aggression, reduced addictive behaviour and improved memory and improved sleep.",
          price: 325,
        },
        {
          name: "Sleep Aid Drip",
          description:
            "Help your ZZZs with some ZMAs. Our specially formulated amino acid and mineral infusion works with your body’s natural sleep cycle to aid you in achieving restful sleep and relaxation. The Sleep Aid Drip contains the following: Zinc, Magnesium, Vitamin B6, Tryptophan.",
          price: 300,
        },
        {
          name: "The Beauty Tonic",
          description:
            "The Ultimate Glow-Up!  This drip increases collagen levels and contains a high dose of Vitamin C, essential for producing collagen and elastin, zinc, selenium, and a blend of vitamins, minerals and micronutrients required for radiant skin, shiny hair and strong healthy nails.",
          price: 325,
        },
        {
          name: "Under the Weather IV Drip",
          description:
            "Are you suffering from a post-viral lull?This drip contains prescription only medications which will speed recovery from illness. It will relieve nausea, pain and inflammation and contains immune boosting Vitamin C.",
          price: 325,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I totally recommend the Ruuby app — I have used their services several times by now and I am very satisfied with the quality of therapists and services they offer. Always on time, very professional and enjoyable to work with! They also do their best to accommodate any bespoke requirements you may have which is very helpful!",
        customer: "Niki",
      },
    ],
  },
  [Category.AESTHETICS]: {
    hero: {
      image: aestheticsHero,
      heading:
        "Book professional aesthetic treatments with our curated list of medical professionals.",
      paragraphs: ["On demand appointments, from early to late, seven days a week."],
    },
    mostPopular: {
      title: "Our Most Popular Aesthetics Treatments",
      description:
        "Our anti-wrinkle injections and fillers offer targeted solutions to smooth fine lines and restore volume, helping you achieve a radiant, youthful appearance. With expert care and medical professionals, we diminish signs of ageing, enhancing your natural beauty and boosting your confidence.",
      treatments: [
        {
          name: "Anti wrinkle injection",
          description:
            "Anti-wrinkle injections are a local muscle relaxant that smoothes lines and wrinkles by relaxing the muscles responsible for producing them. A quick and relatively pain-free solution for addressing fine-lines and wrinkles around the face.",
          price: 280,
        },
        {
          name: "Dermal lip filler",
          description: "0.5ml.Lip Fillers are a popular treatment which create fuller and more desirable lips.",
          price: 175,
        },
        {
          name: "Underarm anti-wrinkle injections",
          description: "Anti-wrinkle Injections can be used in the underarm area to address 'hyperhidrosis' or excessive sweating.",
          price: 450,
        },
        {
          name: "Cheek Lift (Filler)",
          description: "Dermal fillers are quick, injectable treatments that soften wrinkles and fill hollows to give the appearance of more youthful skin. Fillers are ideal for those looking for a quick, non-surgical solution for ageing skin.",
          price: 400,
        },
      ],
    },
    reviews: [
      ...reviews,
      {
        review:
          "I totally recommend the Ruuby app — I have used their services several times by now and I am very satisfied with the quality of therapists and services they offer. Always on time, very professional and enjoyable to work with! They also do their best to accommodate any bespoke requirements you may have which is very helpful!",
        customer: "Niki",
      },
    ],
  },
};
