import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../../../utils/media-queries";

export const Heading1 = styled.h1<{ textAlign?: string }>`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-style: normal;
  font-weight: 550;
  font-size: ${({ theme }) => theme.type.sizes.size30};
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: ${rem("12px")};
  margin-bottom: ${rem("12px")};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    text-align: ${({ textAlign = "center" }) => textAlign};
    font-size: ${({ theme }) => theme.type.sizes.size36};
    margin-top: ${rem("16px")};
    margin-bottom: ${rem("16px")};
  }
`;
