import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as TatlerLogoImage } from "./tatler.svg";
import { ReactComponent as VogueLogoImage } from "./vogue.svg";
import { ReactComponent as InStyleLogoImage } from "./in-style.svg";
import { ReactComponent as SheerluxeLogoImage } from "./sheerluxe.svg";
import { ReactComponent as WiredLogoImage } from "./wired.svg";

const TatlerLogo = styled(TatlerLogoImage)`
  width: ${rem("138px")};
  height: ${rem("51px")};
  fill: white;
`;

const VogueLogo = styled(VogueLogoImage)`
  width: ${rem("153px")};
  height: ${rem("40px")};
  fill: white;
`;

const InStyleLogo = styled(InStyleLogoImage)`
  width: ${rem("170px")};
  height: ${rem("40px")};
  margin-top: ${rem("8px")};
  fill: white;
`;

const SheerluxeLogo = styled(SheerluxeLogoImage)`
  width: ${rem("204px")};
  height: ${rem("24px")};
  fill: white;
`;

const WiredLogo = styled(WiredLogoImage)`
  width: ${rem("170px")};
  height: ${rem("34px")};
  fill: white;
`;

const ContainerStyled = styled(Container)`
  padding-top: ${rem("25px")};
`;

const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > span {
    margin: ${rem("10px")} ${rem("25px")};
  }
`;

export const FeaturedPanel = (): JSX.Element => (
  <ContainerStyled>
    <Row>
      <ColStyled>
        <span>
          <TatlerLogo />
        </span>
        <span>
          <VogueLogo />
        </span>
        <span>
          <InStyleLogo />
        </span>
        <span>
          <SheerluxeLogo />
        </span>
        <span>
          <WiredLogo />
        </span>
      </ColStyled>
    </Row>
  </ContainerStyled>
);
