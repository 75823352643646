import styled from "styled-components";

import { theme } from "../../../../theme";

const InputLabel = styled.label`
  position: absolute;
  left: 16px;
  top: 8px;
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.smaller};
  font-weight: 600;
`;

export { InputLabel };
