import styled from "styled-components";

import { theme } from "../../../../theme";

interface IProps {
  align?: "left" | "center" | "right";
  /**
   * Default: 14px size
   */
  size?: "8" | "10" | "12" | "14" | "16" | "18" | "20" | "22" | "24";
  /**
   * If window max-width 992px
   */
  mobileSize?: "8" | "10" | "12" | "14" | "16" | "18" | "20" | "22" | "24";
  /**
   * Default: normal weight
   */
  weight?: "bold" | "semibold";
  /**
   * Transform text
   */
  transform?: "capitalize" | "lowercase" | "uppercase";
  /**
   * Default: black
   */
  color?:
    | "newGreyLight"
    | "ruubyNewBlueGrey"
    | "blue"
    | "grey"
    | "greyer"
    | "white";
  lineHeight?: string;
  textDecoration?: "line-through" | "overline" | "underline";
}

const Text = styled.div`
  font-family: ${theme.fontName.main};

  ${(props: IProps) => setColor(props.color)};
  ${(props: IProps) => setWeight(props.weight)};
  ${(props: IProps) => setSize(props.size)};
  ${(props: IProps) =>
    props.align ? `text-align: ${props.align}` : undefined};
  ${(props: IProps) =>
    props.transform ? `text-transform: ${props.transform}` : undefined};
  ${(props: IProps) =>
    props.lineHeight ? `line-height: ${props.lineHeight}` : undefined};
  ${(props: IProps) =>
    props.textDecoration
      ? `text-decoration: ${props.textDecoration}`
      : undefined};

  @media screen and (max-width: 992px) {
    ${(props: IProps) => props.mobileSize && setSize(props.mobileSize)};
  }
`;

/**
 * Set font-weight for text
 */
function setWeight(w): string {
  switch (w) {
    case "bold":
      return "font-weight: 700";

    case "semibold":
      return "font-weight: 600";

    default:
      return "font-weight: 400";
  }
}

/**
 * Set font-size for text
 */
function setSize(s): string {
  switch (s) {
    case "8":
      return `font-size: ${theme.fontSize.tiny}`;

    case "10":
      return `font-size: ${theme.fontSize.small}`;

    case "12":
      return `font-size: ${theme.fontSize.smaller}`;

    case "14":
      return `font-size: ${theme.fontSize.main}`;

    case "16":
      return `font-size: ${theme.fontSize.bigger}`;

    case "18":
      return `font-size: ${theme.fontSize.paragraph}`;

    case "20":
      return `font-size: ${theme.fontSize.big}`;

    case "22":
      return `font-size: ${theme.fontSize.headingNew}`;

    case "24":
      return `font-size: ${theme.fontSize.heading}`;

    default:
      return `font-size: ${theme.fontSize.main};`;
  }
}

/**
 * Set color for text
 */
function setColor(c): string {
  switch (c) {
    case "blue":
      return `color: ${theme.color.ruubyBlue};`;

    case "grey":
      return `color: ${theme.color.ruubyGrey};`;

    case "greyer":
      return `color: ${theme.color.borderColor};`;

    case "white":
      return `color: white;`;

    case "newGreyLight":
      return `color: ${theme.color.ruubyNewGreyLight};`;

    case "ruubyNewBlueGrey":
      return `color: ${theme.color.ruubyNewBlueGrey};`;

    default:
      return `color: ${theme.color.ruubyBlack};`;
  }
}

export { Text };
