import React from "react";
import Lottie, { Options } from "react-lottie";
import { ScreenClass, useScreenClass } from "react-grid-system";

import animationData from "./animation.json";

const mapScreenClassToDimensions = (
  screenClass: ScreenClass,
): Pick<React.ComponentProps<typeof Lottie>, "width" | "height"> => {
  switch (screenClass) {
    case "md":
    case "lg":
    case "xl":
    case "xxl":
    case "xxxl":
      return { width: 46, height: 53 };
    default:
      return { width: 39, height: 44 };
  }
};

interface IProps {
  className?: string;
}

export const Loader: React.FC<IProps> = ({ className }) => {
  const screenClass = useScreenClass();

  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={className}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        {...mapScreenClassToDimensions(screenClass)}
      />
    </div>
  );
};
