import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import { IPromotion } from "../../services/api/promotion";
import { MessageTemplate } from "../../services/api/checkout";

import { actionCreators } from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  creditBalance?: number;
  creditSubBalance?: number;
  isPromocodeLoaded: boolean;
  isPromocodeLoading: boolean;
  originalAmount?: number;
  promocode?: IPromotion;
  promocodeError?: string;
  bookingFee: number;
  isBookingFeeLoading: boolean;
  totalAmount?: number;
  bookingMessage?: MessageTemplate;
}

const INITIAL_STATE: IState = {
  creditBalance: undefined,
  creditSubBalance: undefined,
  isPromocodeLoaded: false,
  isPromocodeLoading: false,
  originalAmount: undefined,
  promocode: undefined,
  promocodeError: undefined,
  bookingFee: 0,
  isBookingFeeLoading: false,
  totalAmount: undefined,
  bookingMessage: undefined,
};

// tslint:disable-next-line: completed-docs
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.PROMOCODE_VALIDATION_SERVICE_INIT: {
      return iassign(state, (s) => {
        s.isPromocodeLoaded = false;
        s.isPromocodeLoading = true;
        s.promocodeError = undefined;

        return s;
      });
    }

    case types.PROMOCODE_VALIDATION_SUCCESS: {
      return iassign(state, (s) => {
        s.isPromocodeLoaded = true;
        s.isPromocodeLoading = false;
        s.promocode = action.payload.data;

        return s;
      });
    }

    case types.PROMOCODE_CLEAR: {
      return iassign(state, (s) => {
        s.promocode = undefined;

        return s;
      });
    }

    case types.PROMOCODE_VALIDATION_FAILURE: {
      return iassign(state, (s) => {
        s.isPromocodeLoaded = true;
        s.isPromocodeLoading = false;
        s.promocodeError = action.payload.error.message;

        return s;
      });
    }

    case types.SET_TOTAL_AMOUNT: {
      return iassign(state, (s) => {
        s.totalAmount = action.payload.data;

        return s;
      });
    }

    case types.SET_ORIGINAL_AMOUNT: {
      return iassign(state, (s) => {
        s.originalAmount = action.payload.data;

        return s;
      });
    }

    case types.SET_CREDIT_BALANCE: {
      return iassign(state, (s) => {
        s.creditBalance = action.payload.data;

        return s;
      });
    }

    case types.SET_CREDIT_SUBBALANCE: {
      return iassign(state, (s) => {
        s.creditSubBalance = action.payload.data;

        return s;
      });
    }

    case types.BOOKING_FEE_ATTEMPT: {
      return iassign(state, (s) => {
        s.isBookingFeeLoading = true;

        return s;
      });
    }

    case types.BOOKING_FEE_SUCCESS: {
      return iassign(state, (s) => {
        s.bookingFee = action.payload.data;
        s.isBookingFeeLoading = false;

        return s;
      });
    } 

    case types.CLEAR_CHECKOUT: {
      return iassign(state, (s) => {
        s.creditBalance = undefined;
        s.creditSubBalance = undefined;
        s.originalAmount = undefined;
        s.bookingFee = 0;
        s.isBookingFeeLoading = false;
        s.totalAmount = undefined;
        s.bookingMessage = undefined;

        return s;
      });
    }

    default:
      return state;
  }
}
