import * as React from "react";
import { pure } from "recompose";
import styled from "styled-components";

import { Text } from "../../../../components/atoms";
import { TextDoubleHeader } from "../../../../components/molecules/text-group/text-double-header"; // prevent circular imports
import { IPromotion } from "../../../../services/api/promotion";
import { getPromoValue } from "../../../../utils/promo-code";
import { formatPrice } from "../../../../utils/format-price-for-display";

export interface IOrderSummaryTotalProps {
  creditBalance: number;
  creditSubBalance: number;
  originalAmount: number;
  promocode?: IPromotion;
  removePromo(): void;
  totalAmount: number;
  bookingFee: number;
  isBookingFeeLoading: boolean;
}

const OrderSummaryTotalBlock = styled(TextDoubleHeader)`
  padding-bottom: 0;
  padding-right: 10px;
`;

const OrderSummarySubTotal = styled.div`
  padding-bottom: 16px;
`;

const PromotionRemoveButtonText = styled(Text)`
  display: inline;
  margin-left: 5px;
  cursor: pointer;
`;

const OrderSummaryTotalWrapper = styled.div`
  padding: 12px 0;
`;

interface PromoCodeProps {
  promotion: IPromotion;
  total: number;
  onClick: () => void;
}

const PromoBlock = ({
  promotion,
  total,
  onClick,
}: PromoCodeProps): JSX.Element => {
  const { deduction, percentage } = getPromoValue(promotion, total);

  return (
    <OrderSummaryTotalBlock>
      <Text mobileSize="12" weight="semibold">
        Promotion{typeof percentage !== "undefined" ? ` (${percentage}%)` : ""}
        <PromotionRemoveButtonText size="12" onClick={onClick}>
          (remove)
        </PromotionRemoveButtonText>
      </Text>
      <Text mobileSize="12" weight="semibold">
        {deduction}
      </Text>
    </OrderSummaryTotalBlock>
  );
};

const OrderSummaryTotal: React.FC<IOrderSummaryTotalProps> = (props) => {
  const { promocode, originalAmount, creditSubBalance, removePromo } = props;

  return (
    <OrderSummaryTotalWrapper>
      {props.originalAmount !== props.totalAmount && (
        <OrderSummarySubTotal>
          {props.originalAmount !== props.totalAmount && (
            <OrderSummaryTotalBlock>
              <Text size="16" weight="bold">
                Subtotal
              </Text>
              <Text size="16" weight="bold">
                {formatPrice(props.originalAmount)}
              </Text>
            </OrderSummaryTotalBlock>
          )}
          {props.creditSubBalance > 0 && (
            <OrderSummaryTotalBlock>
              <Text mobileSize="12" weight="semibold">
                Account credit
              </Text>
              <Text mobileSize="12" weight="semibold">
                {formatPrice(props.creditSubBalance)}
              </Text>
            </OrderSummaryTotalBlock>
          )}
          {promocode && (
            <PromoBlock
              promotion={promocode}
              total={originalAmount - creditSubBalance}
              onClick={removePromo}
            />
          )}
          {props.bookingFee > 0 && (
            <OrderSummaryTotalBlock>
              <Text mobileSize="12" weight="semibold">
                Booking Fee
              </Text>
              <Text size="16">
                {props.isBookingFeeLoading
                  ? "Loading..."
                  : formatPrice(props.bookingFee)
                }
              </Text>
            </OrderSummaryTotalBlock>
          )}
        </OrderSummarySubTotal>
      )}
      {
        props.totalAmount && (
          <OrderSummaryTotalBlock>
            <Text size="16" weight="bold">
              Total
            </Text>
            <Text size="16" weight="bold">
              {props.isBookingFeeLoading
                ? "Loading..."
                : formatPrice(props.totalAmount)
              }
            </Text>
          </OrderSummaryTotalBlock>
        )
      }
    </OrderSummaryTotalWrapper>
  );
};

const pureComponent = pure(OrderSummaryTotal);

export { pureComponent as OrderSummaryTotal };
