import { DateTime } from "luxon";
import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { categories } from "../../../utils/search";

export const LandingProxyPage = (): JSX.Element => {
  const history = useHistory();
  const { categoryName, districtCode } = useParams();

  const redirect = useCallback(() => {
    const currentDate = DateTime.local().toFormat("yyyy-MM-dd");

    const categoryExists = categories.some(
      (category) => category.id === categoryName,
    );

    if (!categoryExists) {
      history.replace("/");
      return;
    }

    history.replace(
      `/book?category=${categoryName}&postcode=${districtCode}&date=${currentDate}&time=all`,
    );
  }, []);

  useEffect(() => {
    redirect();
  }, [redirect]);

  return <></>;
};
