import * as React from "react";
import styled from "styled-components";

import { IconCaret, NavItem } from "../../../../components/atoms";
import { theme } from "../../../../theme";

interface IProps {
  to?: string;
  title?: string;
  top?: string;
  children: React.ReactNode;
}

const NavSubArrow = styled.div`
  display: flex;
  width: 9px;
  margin-left: 8px;
`;

const NavSubElements = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  background: white;
  border-top: 1px solid ${theme.color.borderColorGrey};
  border-bottom: 1px solid ${theme.color.borderColorGrey};
  top: ${(props: IProps) => props.top}px;
  visibility: hidden;

  > * {
    line-height: 2;
    font-weight: 600;
    opacity: 0;
  }
`;

const NavSubWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;

  &:hover,
  &:active {
    ${NavSubElements} {
      height: auto;
      visibility: visible;

      > * {
        opacity: 1;

        > a {
          color: ${theme.color.ruubyBlack};
        }
      }
    }
  }
`;

const NavSub: React.FC<IProps> = (props) => {
  return (
    <NavSubWrapper>
      <NavItem to={props.to}>
        {props.title}
        <NavSubArrow>
          <IconCaret />
        </NavSubArrow>
      </NavItem>
      <NavSubElements top={props.top}>{props.children}</NavSubElements>
    </NavSubWrapper>
  );
};

export { NavSub };
