import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Container, ScreenClass, useScreenClass } from "react-grid-system";
import styled from "styled-components";

import * as analytics from "../../../../analytics";
import { metaData } from "../../../../static/meta";
import { IRootState } from "../../../../store";
import { Modal } from "../../molecules/modal";
import { device } from "../../../../utils";
import { useLoginRedirect } from "../login/hooks/use-login-redirect";
import { PageBackButton } from "../../molecules/back-button";

import HeaderImage from "./assets/header.webp";
import { CheckoutContainer } from "./components/checkout-container";
import { Heading1 } from "../../atoms";
import { BookingCheckoutProvider } from "./provider";

const Header = styled.div`
  width: 100%;
  height: 190px;
  flex-shrink: 0;
  border-radius: 8px;
  background-image: url(${HeaderImage});
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position-y: bottom;
  @media ${device.mobileL} {
    height: 202px;
    background: url(${HeaderImage}) lightgray 0px -348.588px / 100% 368.528% no-repeat;
  }
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  width: 100%;
  max-width: 920px;
  @media ${device.desktop} {
    max-width: 1110px;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BackButtonContainer = styled.div`
  display: none;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.size64};

  @media ${device.tablet} {
    display: block;
  }
`;

const Page = (): JSX.Element => {
  const isLoggedIn = useSelector<IRootState>(
    (state) => state.userState.isLogedIn,
  );
  const loginRedirect = useLoginRedirect();
  const history = useHistory();
  const [isShowModal, setShowModal] = useState(false);
  const screenClass = useScreenClass();
  const isDesktop = (["lg", "xl", "xxl", "xxxl"] as ScreenClass[]).includes(
    screenClass,
  );

  if (!isLoggedIn) {
    loginRedirect("/booking");
  }

  useEffect(() => {
    analytics.track({
      name: analytics.AnalyticsEvents.BOOKING_CHECKOUT,
    });
  }, []);

  const handleClickBack = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <Container fluid={!isDesktop}>
      <Helmet>
        <title>{metaData.checkout.title}</title>
        <meta name="description" content={metaData.checkout.description} />
      </Helmet>

      <Content>
        <BackButtonContainer>
          <PageBackButton isBack={true} onClickBack={handleClickBack} />
        </BackButtonContainer>
        <Header />
        <Heading1 textAlign="left">Checkout</Heading1>
        <Modal
          title="Booking Fee"
          isShow={isShowModal}
          onClose={() => setShowModal(false)}
        >
          <p>
            The Ruuby service fee is 6% of your basket, before promotions or
            discounts are applied. The booking fee plays a crucial role in
            sustaining the Ruuby platform, guaranteeing we are able to offer
            real-time customer service throughout the booking and appointment
            process. Additionally, it aids in sustaining operational functions
            such as the thorough vetting and background checks of Ruuby
            professionals and ensuring we are continuously able to offer new and
            innovative services to our customers whilst delivering a high
            standard of on-demand at-home services. Capped at £9.99
          </p>
        </Modal>
        <CheckoutContainer onClickFeeInfo={() => setShowModal(true)} />
      </Content>
    </Container>
  );
};

export const CheckoutPage = (): JSX.Element => (
  <BookingCheckoutProvider>
    <Page />
  </BookingCheckoutProvider>
);
