import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import { IconShare } from "../../../../atoms";
import { device } from "../../../../../../utils";
import { UseShareButton, useShareButton } from "./use-share-button";

const ButtonContainer = styled.div`
  width: 160px;
`;

const ShareButtonStyled = styled.button<{ isCopied: boolean }>`
  height: 40px;
  background-color: ${({ theme, isCopied }) =>
    isCopied
      ? theme.colours.button.greyButton.backgroundActive
      : theme.colours.button.greyButton.background};
  border: none;
  border-radius: 18px;

  @media ${device.tablet} {
    &:hover {
      ${({ theme, isCopied }) =>
        !isCopied &&
        `
              background-color: ${theme.colours.button.greyButton.backgroundHover};
              cursor: pointer;
            `}
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.size16};
`;

const IconContainer = styled.div<{ isCopied: boolean }>`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.size12};

  svg {
    fill: ${({ theme, isCopied }) =>
      isCopied
        ? theme.colours.button.greyButton.textActive
        : theme.colours.button.greyButton.text};
  }
`;

const ShareText = styled.span<{ isCopied: boolean }>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme, isCopied }) =>
    isCopied
      ? theme.colours.button.greyButton.textActive
      : theme.colours.button.greyButton.text};
  margin-right: ${({ theme }) => theme.spacing.size8};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  therapistName?: string;
  useData?: UseShareButton;
}

const Button = ({
  therapistName,
  useData,
}: Required<Props>): JSX.Element => {
  const { isCopied, onShare } = useData({ name: therapistName });

  return (
    <ShareButtonStyled
      isCopied={isCopied}
      onClick={onShare}
      data-testid="share-button"
    >
      <InnerContainer>
        <IconContainer isCopied={isCopied}>
          <IconShare data-testid="icon-share" />
        </IconContainer>
        <ShareText isCopied={isCopied}>
          {isCopied ? "Link Copied" : "Share Profile"}
        </ShareText>
      </InnerContainer>
    </ShareButtonStyled>
  );
};

export const ShareButton = ({ therapistName, useData = useShareButton }: Props): JSX.Element => (
  <ButtonContainer>
    {therapistName && useData ? (
      <Button therapistName={therapistName} useData={useData} />
    ) : (
      <Skeleton height={40}/>
    )}
  </ButtonContainer>
);
