import { capitalizeText } from "../../utils/capitalizeText";
import { Fields } from "../../utils/class-types";

import { AreaPresenter } from "./area-presenter";
import { CheckoutAddressPresenter } from "./checkout-address-presenter";
/** Represents a full address that we've resolved to a udprn using the lookup service */
export class ResolvedAddressPresenter extends CheckoutAddressPresenter {
  public post_town!: string;
  public udprn!: string;
  constructor(props: Fields<AreaPresenter>) {
    super();
    Object.assign(this, props);
  }
  public getLabel(isMobile?: boolean) {
    return isMobile
      ? this.postcode
      : `${capitalizeText(this.post_town)} ${this.postcode}`;
  }
  public getIdentifierKey() {
    return "udprn";
  }
  public getIdentifierValue() {
    return this.udprn;
  }
  public getSummaryAddress() {
    return `${this.line_1} ${capitalizeText(this.post_town)} ${this.postcode}`;
  }
}
