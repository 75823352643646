import * as React from "react";
import styled from "styled-components";

import { IconAdd, IconRemove, Text } from "../../../../components/atoms";
import { theme } from "../../../../theme";

interface IProps {
  count: number;
  onAdd(): void;
  onRemove(): void;
}

const TherapistTreatmentIcon = styled.div`
  > svg {
    cursor: pointer;
    transition: ${theme.transition.linear02};

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const TherapistTreatmentCount = styled(Text)`
  padding: 0 12px;
`;

const TherapistTreatmentActionColumn = styled.div`
  display: inline-flex;
`;

export const TreatmentControl: React.FC<IProps> = (props) => (
  <TherapistTreatmentActionColumn>
    <TherapistTreatmentIcon onClick={props.onAdd}>
      <IconAdd />
    </TherapistTreatmentIcon>
    {props.count > 0 && (
      <>
        <TherapistTreatmentCount>{props.count}</TherapistTreatmentCount>
        <TherapistTreatmentIcon onClick={props.onRemove}>
          <IconRemove />
        </TherapistTreatmentIcon>
      </>
    )}
  </TherapistTreatmentActionColumn>
);
