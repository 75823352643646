import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { get3DSecure } from "../../../../../../services/api/payment";
import {
  checkoutBooking,
  ICreateBookingParams,
} from "../../../../../../services/api/checkout";

import { Section } from "../section";
import { PaymentButton } from "../../../../molecules/payment-button";
import { ErrorText } from "../../../../atoms/errors/error-text";
import { formatPrice } from "../../../../../../utils/format-price-for-display";
import { ThreeDSecure } from "@ruuby/common/lib/interfaces/three-d-secure";
import { useBraintreeDropin } from "../../../../../../hooks/use-braintree-dropin";
import { useBookingCheckoutContext } from "../../provider";
import { parseRakutenCookie } from "../../../../../../utils/cookies";
import { useConfirmationParams } from "../../../../../../hooks/use-confirmation-params";
import {
  AnalyticsEvents,
  trackBookingSuccess,
  trackBookingFailure,
} from "../../../../../../analytics";

const ErrorMessage = styled(ErrorText)`
  margin-bottom: ${({ theme }) => theme.type.sizes.size24};
`;

const BraintreeDropIn = styled.div``;

interface BraintreeCheckoutProps {
  total: number;
  bookingFee: number;
  creditsDeduction: number | undefined;
  promotionDeduction: number | undefined;
}

export const BraintreeCheckout = ({
  total,
  bookingFee,
  creditsDeduction,
  promotionDeduction,
}: BraintreeCheckoutProps): JSX.Element => {
  const { bookingParams, setIsBookingBeingPlaced } =
    useBookingCheckoutContext();
  const {
    isLoading: isBraintreeLoading,
    isPaymentRequestable,
    isCancelled,
    error: braintreeError,
    dropinContainerRef,
    requestPaymentNonce,
    clearPaymentMethod,
  } = useBraintreeDropin({
    total,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [threeDSecureParams, setThreeDSecureParams] =
    useState<ThreeDSecure | null>(null);
  const { saveConfirmationParamsToSession } = useConfirmationParams();
  const history = useHistory();

  useEffect(() => {
    if (bookingParams.addressUrn) {
      get3DSecure({
        amount: total,
        billingAddressUrn: bookingParams.addressUrn,
        bookingAddressUrn: bookingParams.addressUrn,
        therapistUrn: bookingParams.therapistUrn,
        treatmentUrns: bookingParams.addedTreatments.map(({ urn }) => urn),
        bookingTime: bookingParams.bookingDate,
      }).then((params) => setThreeDSecureParams(params));
    }
  }, [JSON.stringify(bookingParams)]);

  const handleCheckout = useCallback(async () => {
    setIsLoading(true);
    setIsBookingBeingPlaced(true);
    setError(undefined);

    try {
      const paymentMethodNonce = await requestPaymentNonce({
        ...threeDSecureParams,
        amount: total.toFixed(2),
      });

      if (!paymentMethodNonce || isCancelled) {
        return;
      }

      const rakutenCookie = parseRakutenCookie(document.cookie);
      const createBookingParams: ICreateBookingParams = {
        origin: "WEBSITE",
        paymentMethodNonce,
        bookingDate: bookingParams.bookingDate,
        notes: bookingParams.notes,
        therapistUrn: `${bookingParams.therapistUrn}-${bookingParams.therapistWorkstation}`,
        serviceUrns: bookingParams.addedTreatments.flatMap(
          ({ urn, quantity }) =>
            Array.from<string>({ length: quantity }).fill(urn, 0, quantity),
        ),
        ...(bookingParams.addressUrn && {
          addressUrn: bookingParams.addressUrn,
        }),
        ...(rakutenCookie && {
          rakutenSiteid: rakutenCookie.atrv,
          rakutenTimeEntered: new Date(rakutenCookie.auld).toISOString(),
        }),
        ...(bookingParams.promo && {
          promoCode: bookingParams.promo.code,
        }),
      };

      const {
        booking: { urn },
      } = await checkoutBooking(createBookingParams);

      // TODO: Move this to the createBooking hook
      saveConfirmationParamsToSession({
        therapistName: bookingParams.therapistName,
        addedTreatments: bookingParams.addedTreatments.slice(),
        bookingDate: bookingParams.bookingDate,
        total,
        ...(bookingParams.address && {
          addressString: `${bookingParams.address.address1} ${
            bookingParams.address.address2
              ? `, ${bookingParams.address.address2}`
              : ""
          } ${bookingParams.address.postcode.toUpperCase()}`,
        }),
        category: bookingParams.category,
      });

      trackBookingSuccess({
        analyticsEventName: AnalyticsEvents.BRAINTREE_BOOKING_SUCCESS,
        bookingUrn: urn,
        ...(bookingParams.promo && {
          promoCode: bookingParams.promo.code,
        }),
        ...(bookingParams.category && {
          category: bookingParams.category,
        }),
        ...(creditsDeduction && {
          creditsDeduction,
        }),
        ...(promotionDeduction && {
          promotionDeduction,
        }),
        bookingFee,
        treatments: bookingParams.addedTreatments,
      });

      history.replace("/booking/confirmation");
    } catch (error: unknown) {
      if (error instanceof Error) {
        trackBookingFailure({
          analyticsEventName: AnalyticsEvents.BRAINTREE_BOOKING_FAIL,
          message: error.message,
        });

        setError(error.message);
      }
    } finally {
      setIsLoading(false);
      setIsBookingBeingPlaced(false);
    }
  }, [
    isCancelled,
    requestPaymentNonce,
    clearPaymentMethod,
    threeDSecureParams,
    JSON.stringify(bookingParams),
    setIsBookingBeingPlaced,
    total,
    saveConfirmationParamsToSession,
  ]);

  const errorMessage = useMemo(() => {
    if (!isCancelled && (error || braintreeError)) {
      return <ErrorMessage>{error || braintreeError}</ErrorMessage>;
    }
  }, [isCancelled, error, braintreeError]);

  return (
    <>
      <Section>
        {errorMessage}
        <BraintreeDropIn ref={dropinContainerRef} />
      </Section>
      <PaymentButton
        price={formatPrice(total)}
        onClick={() => handleCheckout()}
        isLoading={isLoading}
        isDisabled={!isPaymentRequestable || isBraintreeLoading}
      />
    </>
  );
};
