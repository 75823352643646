import * as React from "react";

import { LandingTemplate } from "../../../components/templates/landing";
import { FeaturedTreatmentsDetailsContainer } from "../../../containers/featured-treatments";

const FeaturedTreatmentsPage: React.FC = () => {
  return (
    <LandingTemplate>
      <FeaturedTreatmentsDetailsContainer />
    </LandingTemplate>
  );
};

export { FeaturedTreatmentsPage };
