import * as React from "react";
import { Image } from "../../../../components/atoms";
import styled from "styled-components";
import background from "../../../../assets/images/join.jpg";
import { RateModalContainer } from "../../../../containers/booking/rate-modal";
import {
  IApiBooking,
  fetchBooking,
  saveRating,
} from "../../../../services/api/booking";
import { Modal } from '../../../themed-v2/molecules/modal';
import * as H from "history";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background: url(${background}) no-repeat center;
  background-size: cover;

  ${Image} {
    max-width: 200px;
    object-fit: contain;
  }
`;

interface IProps {
  bookingId: string;
  history: H.History;
}
type Props = IProps;

interface IState {
  bookingId: string;
  rate: string;
  comment: string;
  booking?: IApiBooking;
  isRatingModalVisible: boolean;
  isErrorModalVisible: boolean;
  isRatingCompleteVisible: boolean;
}

const INITIAL_STATE = {
  bookingId: "",
  rate: "",
  comment: "",
  booking: undefined,
  isRatingModalVisible: false,
  isErrorModalVisible: false,
  isRatingCompleteVisible: false,
};

export class RateBooking extends React.PureComponent<Props> {
  public state: IState = {
    ...INITIAL_STATE,
  };

  public async componentDidMount() {
    await this.getBooking();
  }

  private async getBooking() {
    try {
      const booking = await fetchBooking(this.props.bookingId);

      this.setState({
        booking: booking,
        bookingUrn: booking.urn,
        isRatingModalVisible: true,
      });
    } catch (err) {
      this.props.history.replace(`/login/phone`);
    }
  }

  // Submit rating and toggle thank you message
  private submit = async (
    bookingUrn: string,
    rate: number,
    comment: string,
  ) => {
    try {
      await saveRating(bookingUrn, rate, comment);
    } catch (e) {
      this.toggleErrorModal();
    } finally {
      this.setState({
        isRatingModalVisible: false,
        isRatingCompleteVisible: true,
      });
    }
  };

  // Close modal and direct user to homepage
  private onClose = () => {
    location.href = "/";
  };

  private toggleErrorModal = () => {
    this.setState({ isErrorModalVisible: !this.state.isErrorModalVisible });
  };

  public render(): JSX.Element {
    return (
      <>
        <Header />
        <Modal
          isShow={this.state.isErrorModalVisible}
          onClose={this.toggleErrorModal}
          isOverlayClose
          modalText="Sorry something has gone wrong"
        />
        <Modal
          isShow={this.state.isRatingCompleteVisible}
          onClose={this.onClose}
          isOverlayClose
          modalText="Thank you for leaving a review"
        />
        {this.state.booking && (
          <RateModalContainer
            booking={this.state.booking}
            isShow={this.state.isRatingModalVisible}
            onSubmit={this.submit}
            onCancel={this.onClose}
          />
        )}
      </>
    );
  }
}
