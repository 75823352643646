import {
  Country,
  Currency,
  LanguageCode,
} from "../../services/api/types/graphql";

export const formatPriceByCurrency = (
  amount: number,
  currency: Currency,
  country: Country,
  languageCode = LanguageCode.EN,
  options?: Intl.NumberFormatOptions,
): string =>
  new Intl.NumberFormat(`${languageCode.toLowerCase()}-${country}`, {
    style: "currency",
    currency,
    ...options,
  }).format(amount);
