export type CLEAR_ADDRESS = "ideal-postcodes.CLEAR_ADDRESS";
export const CLEAR_ADDRESS: CLEAR_ADDRESS = "ideal-postcodes.CLEAR_ADDRESS";

export type CLEAR_BILLING_ADDRESS = "ideal-postcodes.CLEAR_BILLING_ADDRESS";
export const CLEAR_BILLING_ADDRESS: CLEAR_BILLING_ADDRESS =
  "ideal-postcodes.CLEAR_BILLING_ADDRESS";

export type GET_ADDRESS_SUGGESTIONS_ATTEMPT =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_ATTEMPT";
export const GET_ADDRESS_SUGGESTIONS_ATTEMPT: GET_ADDRESS_SUGGESTIONS_ATTEMPT =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_ATTEMPT";
export type GET_ADDRESS_SUGGESTIONS_SUCCESS =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_SUCCESS";
export const GET_ADDRESS_SUGGESTIONS_SUCCESS: GET_ADDRESS_SUGGESTIONS_SUCCESS =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_SUCCESS";
export type GET_ADDRESS_SUGGESTIONS_FAILURE =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_FAILURE";
export const GET_ADDRESS_SUGGESTIONS_FAILURE: GET_ADDRESS_SUGGESTIONS_FAILURE =
  "ideal-postcodes.GET_ADDRESS_SUGGESTIONS_FAILURE";

export type SET_POSTCODE_SUGGESTIONS = "postcode.SET_POSTCODE_SUGGESTIONS";
export const SET_POSTCODE_SUGGESTIONS: SET_POSTCODE_SUGGESTIONS =
  "postcode.SET_POSTCODE_SUGGESTIONS";

export type CLEAR_POSTCODE_SUGGESTIONS = "postcode.CLEAR_POSTCODE_SUGGESTIONS";
export const CLEAR_POSTCODE_SUGGESTIONS: CLEAR_POSTCODE_SUGGESTIONS =
  "postcode.CLEAR_POSTCODE_SUGGESTIONS";

export type SET_SELECTED_POSTCODE = "postcode.SET_SELECTED_POSTCODE";
export const SET_SELECTED_POSTCODE: SET_SELECTED_POSTCODE =
  "postcode.SET_SELECTED_POSTCODE";

export type FETCH_ADDRESS_UDPRN_ATTEMPT =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_ATTEMPT";
export const FETCH_ADDRESS_UDPRN_ATTEMPT: FETCH_ADDRESS_UDPRN_ATTEMPT =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_ATTEMPT";
export type FETCH_ADDRESS_UDPRN_SUCCESS =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_SUCCESS";
export const FETCH_ADDRESS_UDPRN_SUCCESS: FETCH_ADDRESS_UDPRN_SUCCESS =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_SUCCESS";
export type FETCH_ADDRESS_UDPRN_FAILURE =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_FAILURE";
export const FETCH_ADDRESS_UDPRN_FAILURE: FETCH_ADDRESS_UDPRN_FAILURE =
  "ideal-postcodes.FETCH_ADDRESS_UDPRN_FAILURE";

export type RESET_SUGGESTED_STATE = "ideal-postcodes.RESET_SUGGESTED_STATE";
export const RESET_SUGGESTED_STATE: RESET_SUGGESTED_STATE =
  "ideal-postcodes.RESET_SUGGESTED_STATE";

export type SET_SUGGESTED_ADDRESS = "ideal-postcodes.SET_SUGGESTED_ADDRESS";
export const SET_SUGGESTED_ADDRESS: SET_SUGGESTED_ADDRESS =
  "ideal-postcodes.SET_SUGGESTED_ADDRESS";

export type SET_SUGGESTED_ENTRY = "ideal-postcodes.SET_SUGGESTED_ENTRY";
export const SET_SUGGESTED_ENTRY: SET_SUGGESTED_ENTRY =
  "ideal-postcodes.SET_SUGGESTED_ENTRY";

export type SET_CORRECTED_ADDRESS = "ideal-postcodes.SET_CORRECTED_ADDRESS";
export const SET_CORRECTED_ADDRESS: SET_CORRECTED_ADDRESS =
  "ideal-postcodes.SET_CORRECTED_ADDRESS";

export type SET_ADDRESS_MANUALLY = "ideal-postcodes.SET_ADDRESS_MANUALLY";
export const SET_ADDRESS_MANUALLY: SET_ADDRESS_MANUALLY =
  "ideal-postcodes.SET_ADDRESS_MANUALLY";

export type SET_BILLING_ADDRESS_MANUALLY =
  "ideal-postcodes.SET_BILLING_ADDRESS_MANUALLY";
export const SET_BILLING_ADDRESS_MANUALLY: SET_BILLING_ADDRESS_MANUALLY =
  "ideal-postcodes.SET_BILLING_ADDRESS_MANUALLY";

export type CLEAR_ADDRESS_SUGGESTIONS =
  "ideal-postcodes.CLEAR_ADDRESS_SUGGESTIONS";
export const CLEAR_ADDRESS_SUGGESTIONS: CLEAR_ADDRESS_SUGGESTIONS =
  "ideal-postcodes.CLEAR_ADDRESS_SUGGESTIONS";
