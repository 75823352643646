import * as React from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  backgroundColor?: "grey" | "pink" | "white";
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
  margin-top: 100px;

  ${(props: IProps) =>
    props.backgroundColor && setBackgroundColor(props.backgroundColor)};

  @media screen and (max-width: 992px) {
    margin-top: 50px;
  }
`;

const MainTemplate: React.FC<IProps> = (props) => {
  return (
    <Wrapper backgroundColor={props.backgroundColor}>{props.children}</Wrapper>
  );
};

// tslint:disable-next-line: completed-docs
function setBackgroundColor(color: string) {
  switch (color) {
    case "pink":
      return `background-color: ${theme.color.ruubyPink};`;

    case "white":
      return `background-color: white`;

    default:
      return `background-color: ${theme.color.ruubyGreyBg};`;
  }
}

export { MainTemplate };
