import { ILoginUser } from "../../services/api/customer";

import * as types from "./action-types";

export const actionCreators = {
  loginUser: (data: ILoginUser) => ({
    payload: { data },
    type: types.LOGIN_USER,
  }),
  logoutUser: () => ({
    type: types.LOGOUT_USER,
  }),
  setUserFields: (data: ILoginUser) => ({
    payload: { data },
    type: types.SET_USER_FIELDS,
  }),
  registerSuccess: (urn: string, token: string) => ({
    type: types.REGISTRATION_SUCCESS,
    payload: { urn, token },
  }),
  registerFailure: (error: Error) => ({
    type: types.REGISTRATION_FAILURE,
    payload: { error },
  }),
};
