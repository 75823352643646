import styled from "styled-components";
import React from "react";

import { newTheme } from "../../../../theme";
import { device } from "../../../../utils";

interface IProps {
  align?: React.CSSProperties["textAlign"];
  /**
   * Default: size16 size
   */
  size?: keyof typeof newTheme["type"]["sizes"]
  /**
   * If window max-width 768px
   */
  mobileSize?: keyof typeof newTheme["type"]["sizes"]
  /**
   * Default: normal weight
   */
  weight?: "normal" | "bold" | "semibold";
  /**
   * Transform text
   */
  transform?: React.CSSProperties["textTransform"];
  /**
   * Default: black
   */
  color?: keyof typeof newTheme["colours"]["text"];
  decoration?: React.CSSProperties["textDecoration"];
  lineHeight?: React.CSSProperties["lineHeight"];
  flexGrow?: React.CSSProperties["flexGrow"];
}

const Text = styled.div<IProps>`
  font-family: ${newTheme.type.fonts.main};
  font-weight: ${(props: IProps) => setWeight(props.weight)};
  color: ${({ color = 'main'}: IProps) => newTheme.colours.text[color]};
  font-size: ${({ mobileSize = 'size14' }: IProps) => newTheme.type.sizes[mobileSize]};
  ${({ align }: IProps) => align ? `text-align: ${align}` : undefined};
  ${({ transform }: IProps) => transform ? `text-transform: ${transform}` : undefined};
  ${({ lineHeight }: IProps) => lineHeight ? `line-height: ${lineHeight}` : undefined};
  ${({ decoration }: IProps) => decoration ? `text-decoration: ${decoration}` : undefined};
  ${({ flexGrow }: IProps) => flexGrow ? `flex-grow: ${flexGrow}` : undefined};
  @media ${device.tablet} {
    font-size: ${({ size = 'size16' }: IProps) => newTheme.type.sizes[size]};
  }
`;

/**
 * Set font-weight for text
 */
function setWeight(w): string {
  switch (w) {
    case "bold":
      return "700";
    case "semibold":
      return "600";
    default:
      return "400";
  }
}

export { Text };
