import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as CaretImage } from "./caret.svg";

export const IconCaret = styled(CaretImage)`
  width: ${rem("24px")};
  height: ${rem("24px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
