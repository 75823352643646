import * as React from "react";
import styled from "styled-components";

import { Button, Text } from "../../../../components/atoms";
import { Modal } from '../../../themed-v2/molecules/modal';
import { IApiBooking } from "../../../../services/api/booking";
import { dateFromIso } from "../../../../utils/index";
import { getRefundRule } from "@ruuby/common/lib/utils/refund-utils";
import { IAppConfiguration } from "../../../../services/api/configuration";

interface IProps {
  booking: IApiBooking;
  isShow: boolean;
  configuration: IAppConfiguration;
  onApprove(): void;
  onCancel(): void;
}

const BookingCancelationModalText = styled(Text)`
  margin-bottom: 20px;
`;

const BookingCancelationModalButtons = styled.div`
  display: flex;
  direction: row;
  justify-content: flex-end;

  > * {
    margin-left: 10px;
  }
`;

const BookingCancelationModalSection = styled.div``;

const BookingCancelationModal: React.FC<IProps> = (props) => {
  const refundRule =
    props.booking &&
    getRefundRule(
      new Date(props.booking.timeCreated),
      new Date(props.booking.timeStarts),
      props.configuration.CANCELLATION_POLICIES,
    );
  return (
    <Modal isShow={props.isShow} onClose={props.onCancel}>
      {props.isShow && (
        <BookingCancelationModalSection>
          {/* Hide cancellation functionallity */}
          <BookingCancelationModalText size="16" weight="semibold">
            Are you sure you want to cancel booking on{" "}
            {dateFromIso(props.booking.timeStarts).toFormat("cccc LLLL dd")}{" "}
            with {props.booking.therapist.name}?
          </BookingCancelationModalText>

          <BookingCancelationModalText>
            {`${refundRule.message}`}
          </BookingCancelationModalText>

          <BookingCancelationModalButtons>
            <Button
              size="small"
              background="light-grey"
              onClick={props.onApprove}
            >
              Yes! Cancel this booking
            </Button>
            <Button size="small" background="rose" onClick={props.onCancel}>
              No!
            </Button>
          </BookingCancelationModalButtons>
        </BookingCancelationModalSection>
      )}
    </Modal>
  );
};

export { BookingCancelationModal };
