import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { rem } from "polished";
import "react-loading-skeleton/dist/skeleton.css";

import { IconAdd, IconCaret, IconRemove } from "../../../../../atoms";
import { TreatmentText } from "../../../therapist-detail-page/components/treatment-text";
import { device } from "../../../../../../utils";
import { theme } from "../../../../../../theme";
import { SwitzerlandTreatment } from "../../types";
import { formatPrice } from "../../../../../../utils/format-price-for-display";

const TreatmentContainer = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing.size18} ${theme.spacing.size20} ${theme.spacing.size16}`};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colours.ruubyBlueGrey : theme.colours.white};

  border-bottom: ${({ theme }) =>
    `${rem("1px")} solid ${theme.colours.ruubyLightGrey}`};

  @media ${device.tablet} {
    padding: ${({ theme }) =>
      `${theme.spacing.size12} ${theme.spacing.size38} ${theme.spacing.size12} ${theme.spacing.size28}`};
  }
`;

const TreatmentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const TreatmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;
`;

const TreatmentDetails = styled.div<{ hasDescription: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.size16};

  &:hover {
    ${({ hasDescription }) => hasDescription && "cursor: pointer;"};
  }

  @media ${device.tablet} {
    justify-content: space-between;
    margin-right: ${rem("76px")};
    margin-bottom: 0;
  }
`;

const TreatmentName = styled(TreatmentText)`
  max-width: 80%;
  min-width: 45%;
  font-weight: 600;

  @media ${device.tablet} {
    min-width: none;
    max-width: none;
  }
`;

const IconContainer = styled.div<{ hasDescription: boolean; isOpen: boolean }>`
  display: flex;
  align-items: center;
  ${({ hasDescription }) => !hasDescription && "visibility: hidden;"};
  background: none;
  border: none;
  margin-left: ${({ theme }) => theme.spacing.size20};
  transition: transform ${theme.transition.easeInOut02};
  ${({ isOpen }) => isOpen && "transform: rotate(180deg);"}

  .svg {
    align-self: center;
    vertical-align: middle;
  }
`;

const TreatmentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const TreatmentPrice = styled(TreatmentText)`
  font-weight: 600;
`;

const AddTreatmentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.size16};

  @media ${device.tablet} {
    gap: ${({ theme }) => theme.spacing.size12};
  }
`;

const AdjustQuantityButton = styled.div`
  display: flex;
  align-items: center;
  width: ${rem("21px")};
  height: ${rem("21px")};
  border-radius: 50%;

  @media ${device.laptop} {
    width: ${rem("19px")};
    height: ${rem("19px")};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colours.ruubyBlueGrey};
    }
  }

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const TreatmentsCount = styled(TreatmentText)`
  min-width: ${rem("10px")};
`;

const TreatmentDescriptionContainer = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  opacity: 0;
  transition: all ${theme.transition.easeInOut05};

  ${({ isOpen, theme }) =>
    isOpen &&
    `
      margin-bottom: ${theme.spacing.size16};
      opacity: 1;
  `}

  @media ${device.tablet} {
    margin-top: ${({ theme }) => theme.spacing.size12};
  }
`;

const DescriptionMobile = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

const DescriptionDesktop = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const TreatmentDescription = styled(TreatmentText)`
  font-size: ${({ theme }) => theme.type.sizes.size14};
`;

const TreatmentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  treatment: SwitzerlandTreatment;
  addedQuantity: number;
  isDescriptionShown?: boolean;
  onClickDetails?: () => void;
  onAdd?: (treatmentUrn: string) => void;
  onRemove?: (treatmentUrn: string) => void;
}

export const SwitzerlandTreatmentRow = (props: Props): JSX.Element => {
  const {
    isDescriptionShown = false,
    onClickDetails: onClickTreatmentDetails,
    onAdd,
    onRemove,
  } = props;

  const { name, id: urn, description, price } = props.treatment;
  const hasDescription = useMemo(() => Boolean(description), [description]);

  const descriptionContent = useMemo((): JSX.Element | null => {
    if (!isDescriptionShown || !description) {
      return null;
    }

    return (
      <TreatmentDescriptionContainer
        isOpen={isDescriptionShown}
        data-testid="description-container"
      >
        <TreatmentDescription>{description}</TreatmentDescription>
      </TreatmentDescriptionContainer>
    );
  }, [isDescriptionShown, description]);

  const handleClickTreatmentDetails = useCallback((): void => {
    if (hasDescription) {
      onClickTreatmentDetails?.();
    }
  }, [hasDescription, onClickTreatmentDetails]);

  const handleAdd = useCallback((): void => {
    if (urn) {
      onAdd?.(urn);
    }
  }, [urn]);

  const handleRemove = useCallback((): void => {
    if (urn) {
      onRemove?.(urn);
    }
  }, [urn]);

  return (
    <TreatmentContainer isSelected={props.addedQuantity > 0}>
      <TreatmentInnerContainer>
        <TreatmentDetailsContainer>
          <TreatmentDetails
            hasDescription={hasDescription}
            onClick={handleClickTreatmentDetails}
          >
            <TreatmentName>{name}</TreatmentName>

            <IconContainer
              hasDescription={hasDescription}
              isOpen={isDescriptionShown}
              data-testid="icon"
            >
              <IconCaret width="9" height="6" />
            </IconContainer>
          </TreatmentDetails>
          <DescriptionMobile>{descriptionContent}</DescriptionMobile>
        </TreatmentDetailsContainer>

        <TreatmentInfo>
          <TreatmentTypeContainer>
            {typeof price.classic === "number" && (
              <TreatmentText>Classic</TreatmentText>
            )}
            {typeof price.elite === "number" && (
              <TreatmentText>Elite</TreatmentText>
            )}
          </TreatmentTypeContainer>

          <TreatmentTypeContainer>
            {typeof price.classic === "number" && (
              <TreatmentPrice>
                {formatPrice(price.classic, {
                  currency: "CHF",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TreatmentPrice>
            )}

            {typeof price.elite === "number" && (
              <TreatmentPrice>
                {formatPrice(price.elite, {
                  currency: "CHF",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TreatmentPrice>
            )}
          </TreatmentTypeContainer>

          <AddTreatmentContainer>
            <AdjustQuantityButton onClick={handleRemove}>
              <IconRemove />
            </AdjustQuantityButton>
            <TreatmentsCount>{props.addedQuantity}</TreatmentsCount>
            <AdjustQuantityButton onClick={handleAdd}>
              <IconAdd />
            </AdjustQuantityButton>
          </AddTreatmentContainer>
        </TreatmentInfo>
      </TreatmentInnerContainer>
      <DescriptionDesktop>{descriptionContent}</DescriptionDesktop>
    </TreatmentContainer>
  );
};
