import { useCallback, useEffect, useMemo, useState } from "react";
import { addHours, format, isAfter, isPast, parseISO, startOfDay } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import { getDistrictCode } from "@ruuby/common/lib/utils/postcodes";

import { DropdownItem } from "../../../../molecules/filter/filter-field-dropdown/types";
import { config } from "../../../../../../config";
import { defaultTimeOptions, categories, TherapistListingQueryKey } from "../../../../../../utils/search";

// From: https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L299C1-L326C2
const useUpdateQueryStringValueWithoutNavigation = (
  queryKey: string,
  queryValue: string,
): void => {
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const oldQuery = currentSearchParams.get(queryKey) ?? "";
    if (queryValue === oldQuery) return;

    if (queryValue) {
      currentSearchParams.set(queryKey, queryValue);
    } else {
      currentSearchParams.delete(queryKey);
    }
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    // alright, let's talk about this...
    // Normally with remix, you'd update the params via useSearchParams from react-router-dom
    // and updating the search params will trigger the search to update for you.
    // However, it also triggers a navigation to the new url, which will trigger
    // the loader to run which we do not want because all our data is already
    // on the client and we're just doing client-side filtering of data we
    // already have. So we manually call `window.history.pushState` to avoid
    // the router from triggering the loader.
    window.history.replaceState(null, "", newUrl);
  }, [queryKey, queryValue]);
};

export interface FilterFields {
  category: string;
  treatmentUrn?: string;
  postcode?: string;
  date: string;
  time: string;
}

export type UseSearchFilter = () => {
  filter: FilterFields;
  categoryOptions: [string, string][];
  timeOptions: DropdownItem[];
  handleChangeCategory: (category: string) => void;
  handleChangePostcode: (postcode: string) => void;
  handleChangeDate: (date: string) => void;
  handleChangeTime: (time: string) => void;
  isValidPostcode: (selectedPostcode: string) => boolean;
};

export const useSearchFilter: UseSearchFilter = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const treatmentUrn = useMemo(() => searchParams.get(TherapistListingQueryKey.TREATMENT_URN)?.toLowerCase() ?? undefined, [searchParams]);

  const [category, setCategory] = useState<string>(
    () =>
      searchParams.get(TherapistListingQueryKey.CATEGORY)?.toLowerCase() ?? "",
  );
  useUpdateQueryStringValueWithoutNavigation(
    TherapistListingQueryKey.CATEGORY,
    category ?? "",
  );

  const [postcode, setPostcode] = useState<string>(
    () => searchParams.get(TherapistListingQueryKey.POSTCODE) ?? "",
  );
  useUpdateQueryStringValueWithoutNavigation(
    TherapistListingQueryKey.POSTCODE,
    postcode.toUpperCase() ?? "",
  );

  const [date, _setDate] = useState<string>(
    () => searchParams.get(TherapistListingQueryKey.DATE) ?? "",
  );
  useUpdateQueryStringValueWithoutNavigation(
    TherapistListingQueryKey.DATE,
    date ?? "",
  );

  const setDate = useCallback((date: string) => {
    setTime("all");
    _setDate(date);
  }, []);

  const [time, setTime] = useState<string>(
    () => searchParams.get(TherapistListingQueryKey.TIME) ?? "",
  );
  useUpdateQueryStringValueWithoutNavigation(
    TherapistListingQueryKey.TIME,
    time ?? "",
  );

  const categoryOptions = useMemo(
    () => categories.map<[string, string]>(({ id, name }) => [id, name]),
    [],
  );

  useEffect(() => {
    if (!treatmentUrn && !categoryOptions.some(([id]) => id === category)) {
      history.replace("/");
    }
  }, [category]);

  useEffect(() => {
    if (date && !isPast(parseISO(date))) {
      if (!isFinite(parseISO(date).getTime())) {
        history.replace("/");
      }
    } else {
      setDate(format(startOfDay(new Date()), "yyyy-MM-dd"));
    }
  }, [date]);

  useEffect(() => {
    if (time) {
      if (!defaultTimeOptions.some(([id]) => id === time)) {
        history.replace("/");
      }
    } else {
      setTime(defaultTimeOptions[0][0]);
    }
  }, [time]);

  const isValidPostcode = useCallback(
    (selectedPostcode: string): boolean =>
      Boolean(getDistrictCode(selectedPostcode)),
    [],
  );

  const handleChangePostcode = useCallback((selectedPostcode: string) => {
    if (isValidPostcode(selectedPostcode)) {
      setPostcode(selectedPostcode);
    }
  }, []);

  const timeOptions = useMemo(
    () =>
      defaultTimeOptions.map((t) => {
        const optionEndHour = config.dateTime.intervals[t[0]].end;
        const optionEndTime = addHours(startOfDay(parseISO(date)), optionEndHour);

        return [
          ...t,
          isAfter(new Date(), optionEndTime),
        ] as DropdownItem;
      }),
    [date],
  );

  return {
    filter: {
      category,
      treatmentUrn,
      postcode,
      date,
      time,
    },
    categoryOptions,
    timeOptions,
    handleChangeCategory: setCategory,
    handleChangePostcode,
    handleChangeDate: setDate,
    handleChangeTime: setTime,
    isValidPostcode,
  };
};
