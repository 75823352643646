import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { device } from "../../../../../../utils";
import { config } from "../../../../../../config";
import { formatPrice } from "../../../../../../utils/format-price-for-display";

const StyledLabelContainer = styled.div`
  border: 0;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.text.white};
  background-color: ${({ theme }) => theme.colours.textPlaceholder};
  width: ${rem("290px")};
  height: ${rem("40px")};
  border-radius: ${rem("20px")};
  padding-bottom: ${rem("4px")};
  width: 100%;

  @media ${device.tablet} {
    max-width: 380px;
  }
`;

const TextLabel = styled.label`
  font-weight: 400;
  text-align: center;
  line-height: ${({ theme }) => theme.type.sizes.size16};
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const MinimumSumLabel = (): JSX.Element => (
  <StyledLabelContainer>
    <InnerContainer>
      <TextLabel>
        Please note our minimum call-out fee is{" "}
        {formatPrice(config.minimumCallOutFee, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </TextLabel>
    </InnerContainer>
  </StyledLabelContainer>
);
