import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../../../utils/media-queries";

export const Heading2 = styled.h2`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: ${rem("12px")};
  margin-bottom: ${rem("12px")};

  :first-child {
    margin-top: 0;
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size20};
  }
`;
