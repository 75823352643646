import { useCallback, useState } from "react";
import { getDistrictCode } from "@ruuby/common/lib/utils/postcodes";

type UsePostcode = (pageUrl: URL) => {
  postcode?: string;
  handleSelectPostcode: (selectedPostcode: string) => void;
  handleRemovePostcode: () => void;
  isValidPostcode: (selectedPostcode: string) => boolean;
  isCoveredPostcode: (selectedPostcode: string, districts: string[]) => boolean; 
}

const POSTCODE_PARAM = "postcode";

export const usePostcode: UsePostcode = (pageUrl) => {
  const [postcode, setPostcode] = useState(pageUrl.searchParams.get(POSTCODE_PARAM) ?? undefined);

  const handleSelectPostcode = useCallback((selectedPostcode: string): void => {
    setPostcode(selectedPostcode);
    pageUrl.searchParams.set(POSTCODE_PARAM, selectedPostcode);

    window.history.replaceState(null, "", pageUrl.href);
  }, []);

  const handleRemovePostcode = useCallback((): void => {
    setPostcode(undefined);
    pageUrl.searchParams.delete(POSTCODE_PARAM);

    window.history.replaceState(null, "", pageUrl.href);
  }, []);

  const isValidPostcode = useCallback((selectedPostcode: string): boolean => 
    Boolean(getDistrictCode(selectedPostcode))
  , []);

  const isCoveredPostcode = useCallback((selectedPostcode: string, districts: string[]): boolean => {
    const districtCode = getDistrictCode(selectedPostcode);
    
    if (!districtCode) {
      return false;
    }

    return districts.includes(districtCode);
  }, []);

  return {
    postcode,
    handleRemovePostcode,
    handleSelectPostcode,
    isValidPostcode,
    isCoveredPostcode,
  };
};