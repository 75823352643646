import { useCallback } from "react";
import { useDispatch } from "react-redux";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { actionCreators } from "../../../../../../store/user/actions";
import { setAuthToken } from "../../../../../../services/token-storage";
import * as analytics from "../../../../../../analytics";
import { LoginFlowType } from "../..";

interface Customer {
  urn: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isActive: boolean;
}

interface RuubyJwtPayload extends JwtPayload {
  customer: Customer;
  sendbirdToken: string;
  deviceId: string;
}

type UseSaveAccessToken = () => (
  token: string,
  userType: LoginFlowType,
) => Promise<void>;

export const useSaveAccessToken: UseSaveAccessToken = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (token, userType) => {
      const { customer } = jwtDecode<RuubyJwtPayload>(token);

      const { urn, firstName, lastName, email } = customer;

      if (userType === LoginFlowType.NEW) {
        dispatch(actionCreators.registerSuccess(urn, token));
      }

      dispatch(actionCreators.loginUser(customer));
      setAuthToken(token);
      
      analytics.track({
        name: analytics.AnalyticsEvents.LOGIN_FLOW_COMPLETED,
        payload: {
           userType,
        },
      });
      analytics.identify(urn, { firstName, lastName, email });
    },
    [dispatch],
  );
};
