interface RakutenCookie {
  amid: string;
  atrv: string;
  ald: string;
  auld: number;
}

/**
 * The `parseRakutenCookie` function takes a cookie string and extracts specific values from it to
 * create a `RakutenCookie` object.
 * @param {string} cookieStr - The `cookieStr` parameter is a string that represents the cookie value.
 * It is expected to contain a specific cookie named "rmStoreGateway" that contains a payload with
 * various properties and values separated by "|". The function will parse this cookie string and
 * return an object of type `RakutenCookie
 * @returns returns an object of type `RakutenCookie` or `undefined`.
 */
export const parseRakutenCookie = (cookieStr: string): RakutenCookie | undefined => {
  const rakutenCookie = cookieStr.match(/rmStoreGateway=([^;]+)/);

  if (!rakutenCookie) {
    return undefined;
  }

  const payload: {[key: string]: string;} = decodeURIComponent(
    rakutenCookie[1]
  )
  .split("|")
  .reduce((acc, item) => {
    const [property, value] = item.split(":");

    acc[property] = value;

    return acc;
  }, {});

  return {
    amid: payload.amid,
    atrv: payload.atrv,
    ald: payload.ald,
    auld: Number(payload.auld) * 1000,
  };
};