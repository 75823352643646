import { GroupedSwitzerlandTreatments } from "./types";

export const zurichTreatments: GroupedSwitzerlandTreatments[] = [
  {
    name: "Nails",
    treatments: [
      {
        id: "_classic_manicure",
        name: "Classic Manicure",
        description:
          "Ruuby's signature manicure is the perfect pick-me-up for tired hands. Your nails will be filed and buffed",
        price: {
          classic: 95,
        },
      },
      {
        id: "_classic_pedicure",
        name: "Classic Pedicure",
        description:
          "Ruuby's signature pedicure is the perfect pick-me-up for tired feet in need of some TLC. Your nails will be filed and buffed",
        price: {
          classic: 120,
        },
      },
      {
        id: "_shellac_manicure",
        name: "Shellac Manicure",
        description: "",
        price: {
          classic: 115,
        },
      },
      {
        id: "_shellac_pedicure",
        name: "Shellac Pedicure",
        description: "",
        price: {
          classic: 140,
        },
      },
      {
        id: "_gel_manicure",
        name: "Gel Manicure",
        description:
          "Ruuby's Gel Manicure is the perfect pick-me-up for tired hands in need of some TLC. Your nails will be filed and buffed",
        price: {
          classic: 140,
        },
      },
      {
        id: "_gel_pedicure",
        name: "Gel Pedicure",
        description:
          "Ruuby's Gel Pedicure is the perfect pick-me-up for tired feet in need of some TLC. Your nails will be filed and buffed",
        price: {
          classic: 165,
        },
      },
      {
        id: "_shellacgel_removal_hands",
        name: "Gel Removal (hands)",
        description:
          '"Removal of gel polish on your hands without damaging the nails. *Please note this is for the removal of shellac polish',
        price: {
          classic: 25,
        },
      },
      {
        id: "_shellacgel_removal_feet",
        name: "Gel Removal (feet)",
        description:
          '"Removal of gel polish on your feet without damaging the nails. *Please note this is for the removal of shellac polish',
        price: {
          classic: 25,
        },
      },
      {
        id: "_classic_mani_and_pedi",
        name: "Classic Mani & Pedi",
        description: '"Includes cut/file',
        price: {
          classic: 210,
        },
      },
      {
        id: "_shellac_mani_and_pedi",
        name: "Shellac Mani and Pedi",
        description: "",
        price: {
          classic: 245,
        },
      },
      {
        id: "_gel_mani_and_pedi",
        name: "Gel Mani & Gel Pedi",
        description: '"Includes cut/file',
        price: {
          classic: 290,
        },
      },
    ],
  },
  {
    name: "Massage",
    treatments: [
      {
        id: "_bespoke_massage_60_mins",
        name: "Ruuby Bespoke Massage - 60 minutes",
        description:
          '"Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage which is mixed of Swedish and Aromatherapy targeted to your individual needs. Begin with a detailed consultation to find out what areas you would like to concentrate on',
        price: {
          classic: 175,
          elite: 250,
        },
      },
      {
        id: "_deep_tissue_massage_60_mins",
        name: "Deep Tissue Massage - 60 Minutes ",
        description:
          '"This classic massage technique applies sustained pressure using slow',
        price: {
          classic: 165,
          elite: 260,
        },
      },
      {
        id: "_sports_massage_60_mins",
        name: "Sports Massage - 60 Minutes ",
        description:
          '"Sports Massage is a specialised treatment that involves the manipulation of soft tissue to benefit an individual engaged in regular physical activity. It is designed to assist in correcting problems and imbalances in soft tissue that are caused from repetitive and strenuous physical activity and trauma. All massage therapists come equipped with their own treatment bed.',
        price: {
          classic: 190,
          elite: 265,
        },
      },
      {
        id: "_aromatherapy_massage_60_mins",
        name: "Aromatherapy Massage - 60 mins",
        description:
          '"A Therapeutic massage using essential oils chosen according to individual specific needs to promote relaxation',
        price: {
          classic: 175,
          elite: 250,
        },
      },
      {
        id: "_pregnancy_massage_60_mins",
        name: "Pregnancy Massage - 60 Minutes ",
        description:
          '"* This treatment is not suitable for women in their first trimester of pregnancy. Reduced back, leg and joint pain reduced water tension and swelling',
        price: {
          classic: 175,
          elite: 250,
        },
      },
      {
        id: "_thai_massage_60_mins",
        name: "Thai Massage - 60 Minutes ",
        description:
          '"Traditional Thai Yoga Massage (TTYM) is an ancient healing art. It’s practised on a mat on the floor and it’s performed while you remain clothed. The therapist uses their hands',
        price: {
          classic: 175,
          elite: 250,
        },
      },
      {
        id: "_brazilian_lymphatic_drainage_massage_60_mins",
        name: "Brazilian Lymphatic Drainage Massage",
        description: "",
        price: {
          classic: 235,
        },
      },
      {
        id: "_manual_facial_lifting_massage_45_mins",
        name: "Manual Facial Lifting Massage",
        description: "",
        price: {
          classic: 150,
        },
      },
      {
        id: "_bespoke_massage_90_mins",
        name: "Ruuby Bespoke Massage - 90 minutes",
        description:
          '"Relax and enjoy a Bespoke massage – the ultimate in massage therapy. Our expert Therapists will create your very own personalised massage mix of Swedish and Aromatherapy ',
        price: {
          classic: 240,
          elite: 300,
        },
      },
      {
        id: "_deep_tissue_massage_90_mins",
        name: "Deep Tissue Massage - 90 mins",
        description:
          '"This classic massage technique applies sustained pressure using slow',
        price: {
          classic: 250,
          elite: 310,
        },
      },
      {
        id: "_sports_massage_90_mins",
        name: "Sports Massage - 90 Minutes ",
        description:
          '"Sports Massage is a specialised treatment that involves the manipulation of soft tissue to benefit an individual engaged in regular physical activity. It is designed to assist in correcting problems and imbalances in soft tissue that are caused from repetitive and strenuous physical activity and trauma. All massage therapists come equipped with their own treatment bed.',
        price: {
          classic: 255,
          elite: 315,
        },
      },
      {
        id: "_aromatherapy_massage_90_mins",
        name: "Aromatherapy Massage - 90 Minutes ",
        description:
          '"A Therapeutic massage using essential oils chosen according to individual specific needs to promote relaxation',
        price: {
          classic: 240,
          elite: 300,
        },
      },
      {
        id: "_pregnancy_massage_90_mins",
        name: "Pregnancy Massage - 90 Minutes ",
        description:
          '"* This treatment is not suitable for women in their first trimester of pregnancy.  * reduced water tension and swelling',
        price: {
          classic: 240,
          elite: 300,
        },
      },
      {
        id: "_thai_massage_90_mins",
        name: "Thai Massage - 90 Minutes ",
        description:
          '"Traditional Thai Yoga Massage (TTYM) is an ancient healing art. It’s practised on a mat on the floor and it’s performed while you remain clothed. The therapist uses their hands',
        price: {
          classic: 240,
          elite: 300,
        },
      },
      {
        id: "_acupuncture_90_mins_first_appointment",
        name: "Acupuncture (First Appointment)",
        description: "",
        price: {
          elite: 400,
        },
      },
      {
        id: "_acupuncture_60_mins",
        name: "Acupuncture -  60 Minute ",
        description:
          '"Acupuncture is a form of Chinese and Japanese medicine that involves using tiny needles to stimulate the sensory nerves under the skin to relieve pain. It can treat a range of musculoskeletal problems (back pain and general muscle tension)',
        price: {
          elite: 270,
        },
      },
    ],
  },
  {
    name: "Hair",
    treatments: [
      {
        id: "_bespoke_blowdry",
        name: "Bespoke Blowdry",
        description: '"At Ruuby',
        price: {
          classic: 125,
          elite: 150,
        },
      },
      {
        id: "_dry_styling",
        name: "Dry Styling ",
        description:
          '"Dry hairstyling using heated tools to create anything from beach waves to bouncy curls. ',
        price: {
          classic: 100,
          elite: 125,
        },
      },
      {
        id: "_updo",
        name: "Updo",
        description: '"Elevate your look with a stunning updo',
        price: {
          classic: 140,
          elite: 165,
        },
      },
      {
        id: "_cut_and_blowdry",
        name: "Cut & Blowdry ",
        description:
          "We've carefully hand-picked our Team of hair stylists to ensure the best in the business. We guarantee outstanding results alongside five-star service. Whether you're looking for a bob or fringe",
        price: {
          classic: 180,
          elite: 230,
        },
      },
      {
        id: "_mens_cut",
        name: "Men's Cut ",
        description:
          "We've carefully hand-picked our Team of hair stylists and barbers to ensure the best in the business. Your stylist will work with you to achieve your desired look. We guarantee outstanding results alongside five-star service. Please make sure that your hair is washed and wet ready for your stylist's arrival.",
        price: {
          classic: 120,
          elite: 140,
        },
      },
      {
        id: "_kids_cut",
        name: "Child's Cut",
        description:
          "Book a fresh cut for your little one! Our hairdressers will cut your child's hair ( under 11 yo) in the comfort of your own home. **Please have your child's hair washed and wet prior to booking**",
        price: {
          classic: 80,
          elite: 100,
        },
      },
    ],
  },
  {
    name: "Makeup",
    treatments: [
      {
        id: "_bespoke_makeup",
        name: "Bespoke Makeup (Not Halloween)",
        description:
          "This is the perfect option for anyone wanting to achieve that Wow Factor for a special event! We've handpicked the best talented MUA's to create a team of make-up artists who deliver to the highest possible standard. With numerous high-profile clients",
        price: {
          classic: 175,
          elite: 310,
        },
      },
    ],
  },
  {
    name: "Waxing",
    treatments: [
      {
        id: "_full_leg",
        name: "Full Leg",
        description: " ",
        price: {
          classic: 110,
        },
      },
      {
        id: "_half_leg",
        name: "Half Leg",
        description: " ",
        price: {
          classic: 70,
        },
      },
      {
        id: "_underarms",
        name: "Underarm",
        description: " ",
        price: {
          classic: 45,
        },
      },
      {
        id: "_bikini",
        name: "Bikini",
        description:
          '"Choose between strip or hot wax. While strip wax is best for larger areas',
        price: {
          classic: 60,
        },
      },
      {
        id: "_hollywoodbrazilian",
        name: "Hollywood / Brazilian",
        description:
          '"The Hollywood wax involves complete removal of all of the pubic hair',
        price: {
          classic: 90,
        },
      },
      {
        id: "_arms",
        name: "Full Arm",
        description: " ",
        price: {
          classic: 60,
        },
      },
      {
        id: "_back",
        name: "Back Wax",
        description: "",
        price: {
          classic: 70,
        },
      },
      {
        id: "_stomach",
        name: "Stomach",
        description: " ",
        price: {
          classic: 65,
        },
      },
      {
        id: "_full_face",
        name: "Full Face Wax",
        description: " ",
        price: {
          classic: 70,
        },
      },
      {
        id: "_upper_lip",
        name: "Upper Lip",
        description: " ",
        price: {
          classic: 35,
        },
      },
      {
        id: "_brows",
        name: "Eyebrow Shape & Tidy",
        description:
          "A 15 minute grooming session for your brows that will leave them in perfect shape. Work with your therapist to achieve your desired look.",
        price: {
          classic: 35,
        },
      },
      {
        id: "_chin",
        name: "Chin",
        description: " ",
        price: {
          classic: 35,
        },
      },
    ],
  },
];
