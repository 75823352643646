import React, { useCallback } from "react";
import styled from "styled-components";

import { IconCard } from "../../atoms/icons/card";
import { PaymentTile } from "../payment-tile";

interface Props {
  id: string;
  brand: string;
  lastFour: string;
  expirationYear: number;
  expirationMonth: number;
  isSelected: boolean;
  onClick: (id: string) => void;
}

const Container = styled(PaymentTile)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.size12};

  & svg {
    fill: ${({ theme }) => theme.colours.darkGrey};
  }

  &[data-is-selected="true"] {
    & svg {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

const BrandContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  gap: ${({ theme }) => theme.spacing.size12};
  font-size: ${({ theme }) => theme.type.sizes.size18};
  line-height: ${({ theme }) => theme.type.sizes.size18};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.size12};

  & > span:first-child {
    font-size: ${({ theme }) => theme.type.sizes.size18};
    line-height: ${({ theme }) => theme.type.sizes.size18};
  }

  & > span:last-child {
    font-size: ${({ theme }) => theme.type.sizes.size14};
    line-height: ${({ theme }) => theme.type.sizes.size14};
  }
`;

export const PaymentMethodTile = ({
  id,
  brand,
  lastFour,
  expirationYear,
  expirationMonth,
  isSelected,
  onClick,
}: Props) => {
  const handleClick = useCallback(() => {
    if (!isSelected) {
      onClick(id);
    }
  }, [isSelected, onClick]);

  return (
  <Container onClick={handleClick} data-is-selected={isSelected}>
    <BrandContainer>
      <IconCard />
      <span>{brand}</span>
    </BrandContainer>
    <DetailsContainer>
      <span>...{lastFour} </span>
      <span>
        {expirationMonth.toString().padStart(2, "0")}/
        {(expirationYear - 2000).toString().padStart(2, "0")}
      </span>
    </DetailsContainer>
  </Container>
);
};