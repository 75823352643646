import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

type RouteProps = RouteComponentProps<{
  categoryName: string;
  therapistId: string;
}>;

class RedirectHelper extends React.Component<RouteProps> {
  /**
   * Redirect to 404 in case of non existing route
   * Redirect old search therapists route to category landing
   */
  public componentDidMount() {
    const categoryNameArray = 2;

    if (!this.props.location.key) {
      const parsedPath = this.props.location.pathname.split("/");

      if (this.props.location.pathname === "/terms") {
        this.props.history.replace("/faqs");
      } else if (this.props.location.pathname === "/price-list") {
        this.props.history.replace("/");
      } else if (
        parsedPath.length > categoryNameArray &&
        parsedPath[1] === "search"
      ) {
        this.props.history.replace(
          `/mobile-beauty/${parsedPath[categoryNameArray].toLowerCase()}`,
        );
        // for redirecting old 'provider' therapist URLs
      } else if (parsedPath.length > 1 && parsedPath[1] === "provider") {
        this.props.history.replace(`/book/${parsedPath[2].toLowerCase()}`);
        // for redirecting old 'landing' therapist URLs
      } else if (parsedPath.length > 1 && parsedPath[1] === "landing") {
        this.props.history.replace(
          `/mobile-beauty/${parsedPath[2].toLowerCase()}${
            parsedPath[3] ? `/${parsedPath[3]}` : ""
          }`,
        );
      } else {
        this.props.history.replace("/404");
      }
    }
  }

  /**
   * Renderless component
   */
  public render() {
    // tslint:disable-next-line:no-null-keyword
    return null;
  }
}

const router = withRouter(RedirectHelper);

export { router as RedirectHelper };
