import { ActionDispatch } from "../";

import {
  fetchConfiguration,
  IAppConfiguration,
} from "../../services/api/configuration";

import * as types from "./action-types";

export const actionCreators = {
  fetchConfigurationFailure: (error: Error) => ({
    payload: { error },
    type: types.FETCH_CONFIGURATION_FAILURE,
  }),
  fetchConfigurationSuccess: (config: IAppConfiguration) => ({
    payload: { config },
    type: types.FETCH_CONFIGURATION_SUCCESS,
  }),
};

export function fetchAppConfiguration(): ActionDispatch<void> {
  return async (dispatch) => {
    try {
      const config = await fetchConfiguration();
      dispatch(actionCreators.fetchConfigurationSuccess(config));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(actionCreators.fetchConfigurationFailure(error));
      }
    }
  };
}
