import * as types from "./action-types";
import { IUnresolvedAddress, locationType } from "./reducer";

export const actionCreators = {
  clearAddress: () => ({
    type: types.CLEAR_ADDRESS,
  }),
  setAddressManually: (data: IUnresolvedAddress, type?: locationType) => ({
    payload: { data, type },
    type: types.SET_ADDRESS_MANUALLY,
  }),
  setBillingAddressManually: (
    data: IUnresolvedAddress,
    type?: locationType,
  ) => ({
    payload: { data, type },
    type: types.SET_BILLING_ADDRESS_MANUALLY,
  }),
  setSelectedPostcode: (data: string) => ({
    payload: { data },
    type: types.SET_SELECTED_POSTCODE,
  }),
};
