import * as React from "react";
import styled from "styled-components";

import { Card, NavItem, Text } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  className?: string;
  externalImage?: boolean;
  imageUrl: string;
  link: string;
  text?: string;
  title: string;
  children?: React.ReactNode;
}

interface IHomeImageTextCardImage {
  imageUrl: string;
}

const HomeImageTextCardImage = styled.div`
  height: 170px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  background: url(${(props: IHomeImageTextCardImage) => props.imageUrl})
    no-repeat center;
  background-size: 100%;
  transition: background-size ${theme.transition.easeInOut03};
`;

const HomeTrendingTextBlock = styled.div`
  padding: 25px 15px 15px;
`;

const HomeTrendingTitle = styled(Text)`
  padding-bottom: 5px;
  transition: color ${theme.transition.easeInOut03};
`;

const HomeImageTextCardLink = styled(NavItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &:hover {
    ${HomeImageTextCardImage} {
      background-size: 140%;
    }

    ${HomeTrendingTitle} {
      color: ${theme.color.borderColorNew};
    }
  }
`;

const HomeImageTextCard: React.FC<IProps> = (props) => {
  return (
    <HomeImageTextCardLink
      type="link"
      to={props.link}
      className={props.className}
    >
      <Card>
        <HomeImageTextCardImage
          imageUrl={
            props.externalImage
              ? props.imageUrl
              : (require(`../../../assets/${props.imageUrl}`) as string)
          }
        />
        <HomeTrendingTextBlock>
          <HomeTrendingTitle size="16" weight="bold">
            {props.title}
          </HomeTrendingTitle>
          {props.text && (
            <Text size="14" color="grey">
              {props.text}
            </Text>
          )}
        </HomeTrendingTextBlock>
        {props.children}
      </Card>
    </HomeImageTextCardLink>
  );
};

export { HomeImageTextCard };
