import { ApiError } from "../error-handler";
import { getHandler } from "../request-handler";

import { IService } from "./therapists";
import { ICategory } from "./category-list";

export interface IApiFeaturedTreatmentsList {
  description: string;
  largeImage: string;
  headerImages: string[];
  name: string;
  imageUrl: string;
  orderId: number;
  urn: string;
  isHidden?: boolean;
}

/**
 * Get list of featured treatments
 */
export async function getFeaturedTreatmentsLists(): Promise<
  IApiFeaturedTreatmentsList[]
> {
  const response = await getHandler<IApiFeaturedTreatmentsList[]>(
    "/treatment-list",
    false,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

interface IApiTreatment {
  urn: string;
  name: string;
  categoryUrns: string[];
  duration: number;
  description: string;
  priceClassic: number;
  priceElite: number;
  priceBlackLabel: number;
  isBlackLabel: boolean;
  isCorporate: boolean;
  isDeleted: boolean;
  timeCreated: string;
  timeUpdated: string;
}

/**
 * Get featured treatments details
 */
export async function getFeaturedTreatments(
  listUrn: string,
  categories: ICategory[],
): Promise<IFeaturedTreatment[]> {
  const response = await getHandler<IApiTreatment[]>(
    `/treatment-list/${listUrn}`,
    false,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return mapFeaturedTreatmentsToService(response.result, categories);
}

export interface IFeaturedTreatment extends IService {
  categoryId: string;
}

/**
 * Map featured treatmetns from API to therapists service
 */
function mapFeaturedTreatmentsToService(
  featured: IApiTreatment[],
  categories: ICategory[],
): IFeaturedTreatment[] {
  return featured.map((treatment) => {
    // get parent category
    const parentCategory = categories.find((c) =>
      treatment.categoryUrns.includes(c.id),
    );

    if (!parentCategory) {
      throw new Error("Parent category not found");
    }

    const categoryId = parentCategory.id;
    let category = categoryId.replace("urn:ruuby:treatment-category:", "");

    category = category.charAt(0).toUpperCase() + category.slice(1);

    const service: IFeaturedTreatment = {
      category,
      categoryId,
      description: treatment.description ? treatment.description : undefined,
      duration: treatment.duration,
      name: treatment.name,
      price: treatment.priceClassic,
      urn: treatment.urn,
    };

    return service;
  });
}
