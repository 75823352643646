export interface ICheckoutAddress {
  address1: string;
  address2: string;
  postcode: string;
}
/** Class representing the location where a booking is being created. Maybe an incomplete location. */
export abstract class BookingLocationPresenter {
  /**
   * Get the title for the location. This is what appears at the top of the breadcrumb element. For full addresses, it's the first line.
   */
  public abstract getTitle(): string;
  /**
   * Get the label for the location. This is what appears on the second line of the breadcrumb element. For full addresses, it's POST TOWN, POSTCODE.
   * @param isMobile optional, in mobile view shows only postcode.
   */
  public abstract getLabel(isMobile?: boolean): string;
  /**
   * Get the key used to identify this location in a url. For full, resolved addresses, it's "udprn".
   */
  public abstract getIdentifierKey(): string;
  /**
   * Get the value used to identify this location in a url. For full, resolved addresses, it's the udprn.
   */
  public abstract getIdentifierValue(): string;
  /**
   * Get the outward code of the postcode. This is available on all location types defined so far.
   */
  public abstract getPostCodeOutwardCode(): string;
  /**
   * Can we checkout with this address? True or false? Do we have enough information? False for "area" type locations.
   */
  public abstract canCheckoutWithThisAddress(): boolean;
  /**
   * Short form address, used in the therapists list. For full addresses, it's LINE_1, POSTCODE.
   */
  public abstract getShortAddress(): string;
  /**
   * Address line_1, line_2, postcode for use in the checkout (payment process).
   */
  public abstract getCheckoutAddress(): ICheckoutAddress;
  /**
   * Short address for display in the checkout. For full addresses, it's LINE_1, POST TOWN, POSTCODE.
   */
  public abstract getSummaryAddress(): string;
  /**
   * Returns a string of key=value, which can be added to a URL to identify this location. Used in therapist list.
   */
  public getIdentifierUrlFragment() {
    return `${this.getIdentifierKey()}=${this.getIdentifierValue()}`;
  }
}
