import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Container } from "react-grid-system";
import styled from "styled-components";

import { metaData } from "../../../../static/meta";
import { MainTemplate } from "../../../templates/main";
import { device } from "../../../../utils";
import { PageBackButton } from "../../molecules/back-button";

import { SouthOfFrancePanel } from "./components/south-of-france-panel";

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const BackButtonContainer = styled.div`
  display: none;
  width: 100%;
  @media ${device.desktop} {
    padding-top: 32px;
    display: block;
  }
`;

export const SouthOfFrancePage: React.FC = () => {
  const history = useHistory();
  const handleClickBack = React.useCallback(() => {
    history.push("/");
  }, []);

  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.southOfFrance.title}</title>
        <meta name="description" content={metaData.southOfFrance.description} />
      </Helmet>
      <MainContainer>
        <BackButtonContainer>
          <PageBackButton
            isBack={true}
            onClickBack={handleClickBack}
          />
        </BackButtonContainer>
        <SouthOfFrancePanel />
      </MainContainer>
    </MainTemplate>
  );
};
