import styled from "styled-components";

export const FieldWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  ${({ isOpen = false }) => isOpen && "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
  width: 100%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.inputField};
`;