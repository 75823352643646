import * as React from "react";
import Helmet from "react-helmet";

import { PrivacyPolicy } from "../../../components/organisms";
import { MainTemplate } from "../../../components/templates/main";
import { metaData } from "../../../static/meta";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.privacyPolicy.title}</title>
        <meta name="description" content={metaData.privacyPolicy.description} />
      </Helmet>
      <PrivacyPolicy />
    </MainTemplate>
  );
};

export { PrivacyPolicyPage };
