import * as React from "react";

import { FeaturedTreatmentsListsContainer } from "../../../../containers/featured-treatments";
import { CategorySelect } from "../../../../components/organisms";

const CategorySelectionPage: React.FC = () => {
  return (
    <React.Fragment>
      <CategorySelect />
      <FeaturedTreatmentsListsContainer />
    </React.Fragment>
  );
};

export { CategorySelectionPage };
