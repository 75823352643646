import * as React from "react";
import styled from "styled-components";

import { Column, Container, Row } from "../../grid";
import { Header } from "../../../components/atoms";
import { theme } from "../../../theme";

const Paragraph = styled.div`
  margin-top: 20px;
`;

const StrongSpan = styled.span`
  font-weight: bold;
`;

const TermsAndConditionsContainerStyled = styled(Container)`
  ${Row} {
    margin-bottom: 10px;
  }

  .title {
    font-size: ${theme.fontSize.big};
    font-weight: 600;
    color: ${theme.color.ruubyBlack};
  }

  .text {
    color: ${theme.color.ruubyBlack};
  }

  a {
    font-size: ${theme.fontSize.paragraph};
    text-transform: lowercase;
  }
`;

const TermsAndConditions: React.FC = () => {
  return (
    <TermsAndConditionsContainerStyled limitedWidth offsetPaddings>
      <Row>
        <Column textAlign="center">
          <Header>TERMS AND CONDITIONS</Header>
        </Column>
      </Row>

      <Row justifyContent="space-around">
        <Column md="7">
          <Paragraph>
            <StrongSpan>1. User terms</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.1</StrongSpan> These terms and conditions (“Terms”)
            apply to all services (“Services”) provided or arranged by us, Ruuby
            Limited ("us", "we" or “Ruuby”) to or for you the customer or user
            and recipient of the Services (“you”).
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.2</StrongSpan> These Terms govern your use of the
            website www.ruuby.com and our associated applications (together
            referred to in these Terms as the “Website”) or such alternative
            website or application or other means of accessing our Services as
            we may specify from time to time, your relationship with Ruuby and
            all services whatsoever supplied by us to you whether through the
            Website or otherwise. By continuing to use the Website or the
            Services you accept these Terms and they will apply to the agreement
            between you and us (the “Agreement”).
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.3</StrongSpan> Please note that these Terms apply only
            to the provision of Services directly by us to you namely the
            service we offer allowing you to book appointments and other events
            and services (“Appointments”) with our registered mobile beauty
            therapists (“Mobile Therapists”) (the “Providers”). These Terms do
            not apply to your attendance at an Appointment which is listed on
            the Website.
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.4</StrongSpan> The terms which apply between you and a
            Provider in relation to an Appointment are set out in our Mobile
            Therapist Terms (a copy of which is available on request) (the
            “Provider Terms”) and those additional conditions set out by the
            Provider in their Appointment listing or which are agreed between
            you and the Provider (“Appointment Conditions”). In summary, Ruuby
            facilitates the booking of beauty therapy appointments between
            mobile beauty therapists (the "Therapist") and customers (the
            "User"). The Therapist provides beauty treatments to the user (the
            “Therapist Services”). The User and the Therapist acknowledge and
            agree that Ruuby is a technology service provider and that it does
            not provide beauty therapy appointments, function as a Therapist or
            agent for Therapists. The Therapist is responsible for the accuracy
            of all information in its listing and any information the Therapist
            provides to Ruuby in relation to an Appointment. The Therapist will
            be liable to the User if the information provided in the listing or
            any other written material relating to the Appointment is
            inaccurate, incomplete or misleading in any way. The Therapist’s
            liability may include any reasonable losses incurred by the User as
            a result of such inaccurate, incomplete or misleading information
            provided by the Therapist and the Therapist may be ordered to pay
            reasonable damages on the basis of misrepresentation, breach of
            contract or otherwise. The Therapist acknowledges and agrees that
            the Therapist’s provision of the Therapist Services creates a legal
            and direct business relationship between the Therapist and the User,
            to which Ruuby is not party. Ruuby is not responsible or liable for
            the actions or inactions of a User in relation to the activities of
            a Therapist or the provision of Therapist Services.
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.5</StrongSpan> This Agreement is with you, the person
            using the Services and you will be responsible for ensuring that any
            person who attends an Appointment with you or who you have made a
            booking for (an “Authorised Person”) complies with the terms of this
            Agreement and the Appointment Conditions. You agree that you are
            responsible for the conduct of any such Authorised Person.
          </Paragraph>

          <Paragraph>
            <StrongSpan>1.6</StrongSpan> We may amend these Terms from time to
            time. Any amendments to these Terms or new Provider Terms will be
            posted to the Website. You may terminate this Agreement if you do
            not wish to be bound by any such amendments but by continuing to use
            the Website or the Services you will be deemed to have accepted the
            new or amended terms and conditions.
          </Paragraph>

          <Paragraph>
            <StrongSpan>2. Registration</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>2.1</StrongSpan> Registration is not necessary to be
            able to access the Website but you will not be to book an
            Appointment through us if you have not registered and do not have an
            account with us (“Account”).
          </Paragraph>

          <Paragraph>
            <StrongSpan>2.2</StrongSpan> To register with us or make a booking
            we will require that you provide us with your name, postal address,
            valid email address and credit or debit card details. We may also
            require additional information from time to time. Please note that
            any personal information that you provide to us will be subject to
            our data protection obligations which are set out in our privacy
            policy (“Privacy Policy”) which can be seen at www.ruuby.com. The
            Privacy Policy forms part of this Agreement.
          </Paragraph>

          <Paragraph>
            <StrongSpan>2.3</StrongSpan> You will be asked to create a password
            when registering. We recommend that you keep your password
            confidential and do not disclose it to any third party. If you do so
            reveal your password to a third party and they then access the
            Website or use our Services they will be deemed to be acting as
            agent for you. We will not be held responsible for any action taken
            by any third party to whom you have disclosed your password. If you
            believe a third party has become aware of your password and is using
            your Account without authorisation from you, please notify us
            immediately and we will suspend or close the Account at our
            discretion.
          </Paragraph>

          <Paragraph>
            <StrongSpan>2.4</StrongSpan> You must supply a valid email address
            when registering so that we can email booking confirmations and
            other information relating to your use of our Services. We will not
            be held responsible if you fail to provide a valid email address and
            you do not receive a booking confirmation or other information from
            us that you might be expecting. If you become aware that you have
            supplied an invalid email address please contact us immediately to
            correct the information we hold about you.
          </Paragraph>

          <Paragraph>
            <StrongSpan>2.5</StrongSpan> We may suspend or close your account at
            any time if you are in breach of any term of this Agreement. If we
            suspend or close your Account you will not be able to use our
            Services any longer and may not be able to access all areas of the
            Website.
          </Paragraph>

          <Paragraph>
            <StrongSpan>3. Bookings and Payment</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>3.1</StrongSpan> If you wish to book an Appointment then
            you must review the details of the Appointment, including any
            specific Appointment Conditions listed by the Provider before making
            the booking. You will then be required to pay the applicable fee
            charged by the Provider for the Appointment (“Appointment Fee”).
          </Paragraph>

          <Paragraph>
            <StrongSpan>3.2</StrongSpan> Please note that the agreement relating
            to the provision of services at an Appointment is between you and
            the Provider. We act as a booking platform or agent for the purposes
            of arranging bookings between you and the Provider but have no
            liability to you in relation to the Appointment other than as set
            out in this Agreement.
          </Paragraph>

          <Paragraph>
            <StrongSpan>3.3</StrongSpan> Once we have received payment and you
            have accepted the Appointment Conditions your booking will be
            confirmed and you will have entered a binding agreement with the
            Provider to attend the Appointment. We will send you a booking
            confirmation email (“Confirmation Email”).
          </Paragraph>

          <Paragraph>
            <StrongSpan>3.4</StrongSpan> If you have any questions in relation
            to the Appointment after receipt of the Confirmation Email you will
            need to either raise these with the Provider directly through the
            chat functionality in the Ruuby app, or by contacting the Ruuby
            Concierge Team:{" "}
            <a
              style={{ color: "#000000", textDecoration: "underline" }}
              href="mailto:concierge@ruuby.com"
            >
              concierge@ruuby.com
            </a>{" "}
            / 02074608319.
          </Paragraph>

          <Paragraph>
            <StrongSpan>4. Appointments</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>4.1</StrongSpan> You agree that the Website is a
            platform for advertising Appointments with our Providers and we have
            no responsibility for the Appointment other than to provide the
            Services under this Agreement which includes administering and
            confirming bookings and collecting payment on behalf of the
            Provider.
          </Paragraph>

          <Paragraph>
            <StrongSpan>4.2</StrongSpan> Whilst we endeavour to ensure the
            Appointments advertised on the Website are of a satisfactory quality
            we offer no warranty as to an Appointment’s suitability for your
            requirements. Similarly, we will have relied on the Provider for
            details about an Appointment provided on the Website and we offer no
            warranty in relation to these details.
          </Paragraph>

          <Paragraph>
            <StrongSpan>4.3</StrongSpan> Unless stated otherwise in these Terms,
            once we have confirmed your booking we have no further obligation to
            you in relation to the Appointment, and all responsibility lies with
            the Provider.
          </Paragraph>

          <Paragraph>
            <StrongSpan>5. Complaints and Disputes</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>5.1</StrongSpan> You agree that if you have any dispute
            with a Provider concerning them or the Appointment you will attempt
            to resolve it in the first instance by directly communicating with
            the Provider during the Appointment. If you reach a settlement with
            the Provider which involves a full or partial refund then you will
            be required to inform us of this settlement within 24 hours and we
            will confirm this with the Provider.
          </Paragraph>

          <Paragraph>
            <StrongSpan>5.2</StrongSpan> If you have a genuine complaint you
            must inform us within 24 hours of the Appointment by contacting{" "}
            <a
              style={{ color: "#000000", textDecoration: "underline" }}
              href="mailto:concierge@ruuby.com"
            >
              concierge@ruuby.com
            </a>
            , and providing any visual evidence of the complaint where possible.
          </Paragraph>

          <Paragraph>
            <StrongSpan>5.3</StrongSpan> In the event that we determine that a
            Provider should make a refund or other payment to you and if we are
            holding funds on behalf of the Provider we may make the refund on
            the Provider’s behalf (but we are not under an obligation to do so).
            We have authority from the Provider to utilise withheld funds to
            satisfy genuine complaints but we will not ourselves pay any
            compensation or refund in relation to an Appointment.
          </Paragraph>

          <Paragraph>
            <StrongSpan>5.4</StrongSpan> Please note that we release Appointment
            Fees to our Providers within 30 days of the date of your booking. If
            you make a complaint after we have paid the Appointment Fees to the
            Provider then we will not be able to offer any financial
            compensation or refund.
          </Paragraph>

          <Paragraph>
            <StrongSpan>5.5</StrongSpan> If you are unable to substantiate your
            complaint or if you fail to cooperate with us then we will release
            the Appointment Fee to the Provider.
          </Paragraph>

          <Paragraph>
            <StrongSpan>6. Cancellation and Termination.</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.1</StrongSpan> In the event of the Provider cancelling
            the Appointment the Appointment Fee will be refundable. Furthermore,
            you agree that if a Provider wishes to cancel an Appointment they
            may do so through us and our Website.
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.2</StrongSpan> The Appointment Fee will be refunded to
            you via your original method of payment as follows
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.2.1</StrongSpan> 100% of the Appointment fee will be
            refunded if you cancel an Appointment twenty-four or more hours
            prior to the start time of the appointment; or
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.2.2</StrongSpan> 50% of the Appointment fee will be
            refunded if you cancel an Appointment twenty-four to twelve hours
            prior to the start time of the Appointment.
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.2.3</StrongSpan> If you cancel with less than twelve
            hours’ notice or fail to attend the appointment you will be charged
            in full, as if you had attended.
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.3</StrongSpan> If you make a last minute bookings
            (i.e. with less than 12 hours between the the time of booking and
            appointment start time) you will have 10 minutes to amend the
            booking free of charge and after that usual cancellation terms
            apply.
          </Paragraph>

          <Paragraph>
            <StrongSpan>6.4.</StrongSpan> For the avoidance of doubt, it is the
            Provider’s responsibility to make any refund you are entitled to
            under clause 5 if these Terms (Complaints and Disputes).
          </Paragraph>

          <Paragraph>
            <StrongSpan>7. Your obligations and restrictions</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>7.1</StrongSpan> You must:
          </Paragraph>

          <ul>
            <li>
              <Paragraph>
                <StrongSpan>7.1.1</StrongSpan> Agree to observe and act in
                accordance with the Appointment Conditions;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.2</StrongSpan> Act with suitable consideration
                for the Provider and the Provider’s property;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.3</StrongSpan> Not, while attending the
                Appointment, act in any way which is offensive, inappropriate,
                rude, illegal or which might cause distress to others;
                including, but not limited to, engaging in inappropriate
                behaviour, such as sexually suggestive remarks or advances,
                excessive drinking, drug use, and/or other inappropriate
                behaviour
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.4</StrongSpan> Provide us with accurate
                information and ensure that all details that we hold about you
                (including your email address) are up to date and valid;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.5</StrongSpan> Agree to adhere as closely as
                possible to health and safety guidelines distributed in
                accordance with your appointments to ensure the safest possible
                treatment environment for you and your therapist.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.6</StrongSpan> Not deal with the Provider in any
                way which could be deemed to be harmful to the business or
                reputation of Ruuby or do anything which might adversely affect
                our relationship with a Provider;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.7</StrongSpan> Not attempt to contact a Provider
                directly until a booking has been confirmed in a Confirmation
                Email;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>7.1.8</StrongSpan> Refrain from soliciting a
                Provider directly for engagement in further beauty services
              </Paragraph>
            </li>
          </ul>

          <Paragraph>
            <StrongSpan>7.1.9</StrongSpan> Use the Website in accordance with
            these Terms and not in any way which may affect the reputation of
            Ruuby or the use and enjoyment of the Website or our Services by any
            other users or third parties;
          </Paragraph>

          <Paragraph>
            <StrongSpan>7.1.10</StrongSpan> Where applicable, only provide us
            with credit or debit card details for which you are the sole account
            holder.
          </Paragraph>

          <Paragraph>
            <StrongSpan>7.1.11</StrongSpan> Provide a safe environment in which
            the treatment can be carried out.
          </Paragraph>

          <Paragraph>
            <StrongSpan>7.2</StrongSpan> Please be aware that you may be
            personally liable to the Mobile Therapist if you fail to provide a
            safe environment in which the treatment can be carried out.
          </Paragraph>

          <Paragraph>
            <StrongSpan>Therapist obligations and restrictions</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.</StrongSpan> The Therapist must:
          </Paragraph>

          <ul>
            <li>
              <Paragraph>
                <StrongSpan>8.1.1</StrongSpan> Provide the Therapist Services in
                accordance with this Agreement and the Ruuby Etiquette and
                Hygiene Guides which shall be available on request throughout
                the term of this Agreement;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.2</StrongSpan> Ensure that all treatments or
                other services provided at an Appointment are of the highest
                quality, comply with industry standards and are fit for its
                intended purpose; and agree to adhere as closely as possible to
                health and safety guidelines distributed in accordance with the
                appointment to ensure the safest possible treatment environment
                for you and your client.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.3</StrongSpan> Represent the Ruuby brand at all
                times whilst providing the Therapist Services and only use Ruuby
                branded equipment where such equipment is provided;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.4</StrongSpan> Ensure that it has disclosed
                relevant health and safety information to Users including
                relating to common allergies associated with treatments;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.5</StrongSpan> Provide the Therapist Services
                with reasonable skill and care;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.6</StrongSpan> Ensure their availability (as
                displayed on the Website) is kept up to date at all times during
                the term of this Agreement;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.7</StrongSpan> Honour all bookings with Users
                subject to the cancellation policy in the User Terms;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.8</StrongSpan> Arrive at the Appointment
                location promptly and in any event by the time at which the
                Appointment is due to start;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.9</StrongSpan> Remain at the Appointment
                location for a period of at least 20 minutes after the time at
                which the Appointment is due to start before treating the
                Appointment as having been cancelled by the User;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.10</StrongSpan> Provide the Therapist Services
                at the Appointment in accordance with the details and
                information set out in the relevant Website listing;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.11</StrongSpan> Ensure that all information
                about the Therapist and the Therapist Services provided to the
                Company for inclusion on the Website is true and accurate in all
                respects and could not in way be construed as misleading to a
                User;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.12</StrongSpan> Deal with all Users in a
                professional and courteous manner and in such a way as to not
                cause any harm or damage to Ruuby’s reputation;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.13</StrongSpan> Deal with all queries from Users
                relating to an Appointment or booking in a prompt and
                satisfactory manner;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.14</StrongSpan> Never solicit appointments with
                Ruuby Users directly;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <StrongSpan>8.1.15</StrongSpan> Subject to clause 9, use its
                best endeavours to settle any disputes that may arise in
                relation to an Appointment including taking such action as is
                necessary to bring the dispute to a satisfactory conclusion as
                soon as practicable so that the User may still fulfil their
                booking.
              </Paragraph>
            </li>
          </ul>

          <Paragraph>
            <StrongSpan>8.2</StrongSpan> The Therapist shall at all times during
            the provision of the Therapist Services present itself as an
            ambassador of Ruuby and shall not promote its own contact details,
            social media handles or provide any other marketing material to the
            User at any time in the run up to, during or after the Appointment.
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.3</StrongSpan> The Therapist undertakes to the Company
            that during the term of this Agreement and for a period of 24 months
            after the termination of the Agreement it shall not directly or
            indirectly, either alone or jointly with or on behalf of any other
            person and whether on his own account or in any other capacity
            whatsoever:
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.3.1</StrongSpan> Canvass or solicit for employment any
            person who is or was either at the date on which the Agreement is
            terminated or at any time during the 12 months prior to its
            termination a director or senior employee of the Company;
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.3.2</StrongSpan> Employ or engage any person who is or
            was either at the date on which the Agreement is terminated or at
            any time during the 12 months prior to its termination a director or
            senior employee of the Company;
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.3.3</StrongSpan> Solicit or interfere with any person,
            firm or company who is or was, either at the date on which the
            Agreement is terminated or at any time during the 12 months prior to
            its termination a supplier of goods or services to the Company if
            the solicitation or interference would cause the supplier to cease
            supplying or materially reduce its supply of goods or services to
            the Company;
          </Paragraph>

          <Paragraph>
            <StrongSpan>8.3.4</StrongSpan> Solicit or interfere with any person,
            firm or company who is or was, either at the date on which the
            Agreement is terminated or at any time during the 12 months prior to
            its termination a client or customer of the Company;
          </Paragraph>

          <Paragraph>
            <StrongSpan>9 Termination and suspension</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.1</StrongSpan> We may suspend your Account at any time
            should you be in breach of this Agreement. Furthermore if you have
            not registered with us we may suspend your access to the Website or
            the Services if we believe you to be in breach of this Agreement.
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.2</StrongSpan> If we suspend your Account or access to
            the Website for any reason we may refuse to provide you with any
            Services including the right to make any further bookings. If you
            attempt to circumvent this clause by attempting to create a new
            account we reserve the right to terminate this Agreement and any
            existing Account you may have.
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.3. </StrongSpan> We may terminate this Agreement and
            your Account at any time if:
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.3.1 </StrongSpan> You are in breach of any term of
            this Agreement;
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.3.2 </StrongSpan> We suspect that you are about to
            commit a breach of this Agreement;
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.3.3 </StrongSpan> You become or we suspect that you
            are about to become insolvent.
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.4 </StrongSpan> Upon termination you will no longer be
            able to use our Services or make bookings through us.
          </Paragraph>

          <Paragraph>
            <StrongSpan>9.5 </StrongSpan> If when we terminate this agreement
            you have any outstanding bookings for which you have made
            pre-payment we may refund you accordingly. Any such refund is at our
            absolute discretion.
          </Paragraph>

          <Paragraph>
            <StrongSpan>10. Your liability and indemnity</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>10.1 </StrongSpan> You agree to indemnify us for any
            claims or legal proceedings that may be brought against us and for
            any loss or damage we may suffer or incur as a result of:
          </Paragraph>

          <Paragraph>
            <StrongSpan>10.1.1 </StrongSpan> Your breach of the terms of this
            Agreement; or
          </Paragraph>

          <Paragraph>
            <StrongSpan>10.1.2 </StrongSpan> Your breach of the Appointment
            Conditions; or
          </Paragraph>

          <Paragraph>
            <StrongSpan>10.1.3 </StrongSpan> Your actions in relation to the
            Services, the Website, the Appointment or any booking.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11. Our liability</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.1. </StrongSpan> We will not be liable to you for any
            business, financial or economic loss or for any consequential or
            indirect loss such as loss to reputation, lost bargain, lost profit,
            loss of anticipated savings or lost opportunity arising as a result
            of any services we provide to you under, or in any other way
            connected with, this Agreement (whether suffered or incurred as a
            result of our negligence or otherwise) except in the case of fraud,
            wilful concealment or theft.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.2. </StrongSpan> For the avoidance of doubt, the
            liability excluded under clause 10.1 includes any loss arising from
            your dealings with any Provider or arising from an Appointment and
            we shall have no liability to you whatsoever for any act or omission
            of the Provider in connection with the Appointment or your booking.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.3. </StrongSpan> Our liability to you for all losses
            under this Agreement (subject to any liability in accordance with
            clause 10.5 below) is limited to the management fee we take in
            relation to any booking made by you.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.4. </StrongSpan> No claim may be brought against us
            in relation to this Agreement more than 12 months following the
            Appointment to which the claim relates.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.5. </StrongSpan> Nothing in this agreement limits or
            excludes our liability for death or personal injury arising as a
            result of our negligence or the negligence of our employees, agents
            or self-employed contractors or for fraud or fraudulent
            misrepresentation.
          </Paragraph>

          <Paragraph>
            <StrongSpan>11.6. </StrongSpan> You agree that the above exclusions
            of liability are reasonable in all the circumstances, especially in
            light of the fact that our Services include only the provision of
            the Website and Services and responsibility for the Appointment and
            fulfilment of a booking lies solely with the Provider for whom we
            act only as an agent in a limited capacity.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12. General</StrongSpan>
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.1. </StrongSpan> Each of the parties warrants its
            power to enter into the Agreement and has obtained all necessary
            approvals to do so.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.2. </StrongSpan> By entering into this Agreement you
            also agree to our Privacy Policy, which is available on our Website.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.3. </StrongSpan> Any notice to be served on either of
            the parties by the other shall be sent by prepaid recorded delivery,
            registered post, fax or email to the address of the relevant party
            shown on at the start of this Agreement or such other physical or
            electronic address as may be notified by one party to the other.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.4. </StrongSpan> No term of the Agreement will be
            enforceable by virtue of the Contract (Rights of Third Parties) Act
            1999 by any person that is not a party to it.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.5. </StrongSpan> We will be entitled to assign or
            sub-contract our obligations under this Agreement.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.6. </StrongSpan> Both parties shall be released from
            their respective obligations in the event of national emergency,
            war, prohibitive governmental regulation or if any other cause
            beyond the reasonable control of the parties or either of them
            renders the performance of the Agreement impossible, whereupon all
            money accrued due under the Agreement shall be paid.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.7. </StrongSpan> Each party acknowledges that the
            Agreement, including the Privacy Policy, contains the whole
            agreement between the parties and that it has not relied upon any
            oral or written representations made to it by the other or its
            employees or agents and has made its own independent investigations
            into all matters relevant to it. In particular it is agreed that any
            terms and conditions or other contractual documentation maintained
            by you or your affiliates or which you purport to apply to the
            subject matter of the Agreement will not apply.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.8. </StrongSpan> You agree that these terms are fair
            and reasonable in all the circumstances. However, if any provision
            of this Agreement is prohibited by law or judged by a court to be
            unlawful, void or unenforceable, the provision shall, to the extent
            required, be severed from this Agreement and rendered ineffective as
            far as possible without modifying the remaining provisions of this
            Agreement, and shall not in any way affect any other circumstances
            of or the validity or enforcement of this Agreement.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.9. </StrongSpan> No delay, neglect or forbearance on
            the part of either party in enforcing against the other party any
            term or condition of this Agreement shall either be or be deemed to
            be a waiver or in any way prejudice any right of that party under
            this Agreement. No right, power or remedy in this Agreement
            conferred upon or reserved for either party is exclusive of any
            other right, power or remedy available to that party.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.10. </StrongSpan> Headings contained in this
            Agreement are for reference purposes only and should not be
            incorporated into this Agreement and shall not be deemed to be any
            indication of the meaning of the clauses to which they relate.
          </Paragraph>

          <Paragraph>
            <StrongSpan>12.11. </StrongSpan> These conditions are governed by
            and construed in accordance with the laws of England and Wales. You
            agree to submit to the exclusive jurisdiction of the English courts.
          </Paragraph>

          <br />
          <br />
        </Column>
      </Row>
    </TermsAndConditionsContainerStyled>
  );
};

export { TermsAndConditions };
