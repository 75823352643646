import * as React from "react";
import styled from "styled-components";

import { Card, Image, Text } from "../../../../components/atoms";
import { BookingStatus } from "../../../../components/molecules";
import { IApiBooking } from "../../../../services/api/booking";
import { theme } from "../../../../theme";
import { dateFromIso } from "../../../../utils/index";

interface IProps {
  booking: IApiBooking;
  onMapOpen(w3wAddress: string): void;
}

const BookingHistoryCardTherapistImage = styled(Image)`
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  object-fit: cover;
`;

const BookingHistoryCardTherapistName = styled(Text)`
  display: inline-block;
`;

const BookingHistoryCardMapLink = styled(Text)`
  display: inline-block;
  color: #1b202b;
  font-weight: bold;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const BookingHistoryCardSection = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${theme.color.inputBorderColor};
`;

const BookingHistoryCardBetween = styled.div`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 6px;
`;

const BookingHistoryGroup = styled.div``;

const BookingHistoryCardStyled = styled(Card)`
  width: auto;
  height: auto;
  padding: 17px 26px;

  ${BookingHistoryCardSection} {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  ${BookingHistoryCardBetween} {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const BookingHistoryCard: React.FC<IProps> = (props) => {
  const treatments = props.booking.bookingTreatments.map((treatment) => {
    return (
      <BookingHistoryCardBetween key={treatment.urn}>
        <BookingHistoryGroup>
          <Text size="16" weight="semibold">
            {treatment.treatment.name}
          </Text>
          <Text weight="semibold">{treatment.treatment.duration} minutes</Text>
        </BookingHistoryGroup>
        <Text size="16" weight="semibold">
          £{treatment.price}
        </Text>
      </BookingHistoryCardBetween>
    );
  });

  const { address } = props.booking;

  return (
    <BookingHistoryCardStyled>
      <BookingHistoryCardSection>
        <BookingHistoryCardBetween>
          <BookingHistoryGroup>
            <Text size="16" weight="semibold">
              {dateFromIso(props.booking.timeStarts).toFormat("cccc LLLL dd")}
            </Text>
            <Text weight="semibold">
              {dateFromIso(props.booking.timeStarts).toFormat("h:mm a - ")}
              {dateFromIso(props.booking.timeEnds).toFormat("h:mm a")}
            </Text>
          </BookingHistoryGroup>
          {props.booking.status !== "UNCONFIRMED" && (
            <BookingStatus status={props.booking.status} />
          )}
        </BookingHistoryCardBetween>
      </BookingHistoryCardSection>
      <BookingHistoryCardSection>
        <BookingHistoryCardTherapistImage
          src={props.booking.therapist.logoImage}
          rounded
        />
        <BookingHistoryCardTherapistName size="18" weight="semibold">
          {props.booking.therapist.name}
        </BookingHistoryCardTherapistName>
      </BookingHistoryCardSection>
      <BookingHistoryCardSection>
        {address &&
          (address.w3w ? (
            <BookingHistoryCardMapLink
              weight="semibold"
              onClick={() => props.onMapOpen(address.w3w)}
            >
              {address.address1}, {address.postcode}
            </BookingHistoryCardMapLink>
          ) : (
            <Text weight="semibold">
              {address.address1}, {address.postcode}
            </Text>
          ))}
      </BookingHistoryCardSection>
      <BookingHistoryCardSection>{treatments}</BookingHistoryCardSection>
      <BookingHistoryCardSection>
        {props.booking.total !== props.booking.subTotal && (
          <BookingHistoryCardBetween>
            <BookingHistoryGroup>
              <Text size="16" weight="semibold">
                Subtotal
              </Text>
            </BookingHistoryGroup>
            <Text size="16" weight="semibold">
              £{props.booking.subTotal}
            </Text>
          </BookingHistoryCardBetween>
        )}
        {props.booking.promoCode && props.booking.promoDiscount && (
          <BookingHistoryCardBetween>
            <BookingHistoryGroup>
              <Text size="16" weight="semibold">
                {`Promotion ${props.booking.promoCode}`}
              </Text>
            </BookingHistoryGroup>
            <Text size="16" weight="semibold">
              - £{props.booking.promoDiscount.toFixed(2)}
            </Text>
          </BookingHistoryCardBetween>
        )}
        {props.booking.credits && (
          <BookingHistoryCardBetween>
            <BookingHistoryGroup>
              <Text size="16" weight="semibold">
                Account credit
              </Text>
            </BookingHistoryGroup>
            <Text size="16" weight="semibold">
              - £{props.booking.credits}
            </Text>
          </BookingHistoryCardBetween>
        )}
        {props.booking.bookingFee > 0 && (
          <BookingHistoryCardBetween>
            <BookingHistoryGroup>
              <Text>Booking Fee</Text>
            </BookingHistoryGroup>
            <Text>£{props.booking.bookingFee}</Text>
          </BookingHistoryCardBetween>
        )}
        <BookingHistoryCardBetween>
          <BookingHistoryGroup>
            <Text size="16" weight="bold">
              Total
            </Text>
          </BookingHistoryGroup>
          <Text size="16" weight="bold">
            £{props.booking.total}
          </Text>
        </BookingHistoryCardBetween>
      </BookingHistoryCardSection>
    </BookingHistoryCardStyled>
  );
};

export { BookingHistoryCard };
