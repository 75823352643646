import * as React from "react";
import { Helmet } from "react-helmet";

import { metaData } from "../../../../static/meta";
import { MainTemplate } from "../../../templates/main";
import { Events } from "../../organisms/events";

export const EventsPage: React.FC = () => {
  return (
    <MainTemplate backgroundColor="white">
      <Helmet>
        <title>{metaData.events.title}</title>
        <meta name="description" content={metaData.events.description} />
      </Helmet>
      <Events />
    </MainTemplate>
  );
};
