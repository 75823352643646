export const formatPrice = (
  amount: number,
  options?: Intl.NumberFormatOptions,
): string => {
  const gbSterling = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    ...options,
  });

  return gbSterling.format(amount);
};
