import styled from "styled-components";
import { device } from "../../../../utils/media-queries";

export const AnchorElement = styled.span`
  position: relative;
  visibility: hidden;

  @media ${device.tablet} {
    top: -90px;
  }
`;