import { BookingLocationPresenter } from "./booking-location-presenter";
/**
 * Represents an address that we can check out with. Abstract.
 */
export abstract class CheckoutAddressPresenter extends BookingLocationPresenter {
  public line_1!: string;
  public line_2!: string;
  public postcode!: string;

  public canCheckoutWithThisAddress() {
    return true;
  }
  public getTitle() {
    return this.line_1;
  }
  public getShortAddress() {
    return `${this.line_1}, ${this.postcode}`;
  }
  public getCheckoutAddress() {
    return {
      address1: this.line_1,
      address2: this.line_2,
      postcode: this.postcode,
    };
  }
  public getPostCodeOutwardCode() {
    const outward_code = this.postcode.replace(RegExp(" [0-9A-Z]{3}$"), "");

    return outward_code;
  }
}
