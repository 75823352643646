import menu from "./menu.json";

export interface IMenuItems {
  id: string;
  link: string;
  title: string;
}

const menuData: IMenuItems[] = menu as IMenuItems[];

export { menuData };
