import * as React from "react";
import styled from "styled-components";

import { theme } from "../../../../theme";

interface IProps {
  onToggleBurger(): void;
}

const Burger = styled.div`
  display: block;
  width: 25px;
  height: 18px;
  margin-right: 20px;
  cursor: pointer;
  z-index: 500;
`;

const BurgerWrapper = styled.div`
  position: absolute;

  > div {
    background: ${theme.color.ruubyBlack};
    height: 3px;
    margin: 0;
    position: absolute;
  }
`;

const BurgerLineTop = styled.div`
  top: 0px;
  width: 25px;
`;

const BurgerLineMid = styled.div`
  top: 8px;
  width: 25px;
`;

const BurgerLineBot = styled.div`
  top: 16px;
  width: 14px;
`;

const NavBurger: React.FC<IProps> = (props) => {
  return (
    <Burger onClick={props.onToggleBurger}>
      <BurgerWrapper>
        <BurgerLineTop />
        <BurgerLineMid />
        <BurgerLineBot />
      </BurgerWrapper>
    </Burger>
  );
};

export { NavBurger };
