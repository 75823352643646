import { rem } from "polished";
import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import {
  Button,
  Heading1,
  Paragraph,
} from "../../../../components/themed-v2/atoms";
import { KaleidoscopeNumberLabel } from "../../../../components/themed-v2/molecules";

const KaleidoscopeRow = styled(Row)`
  margin-top: ${rem("23px")};
  margin-bottom: ${rem("23px")};

  > div:not(:first-child) {
    margin-top: ${rem("27px")};

    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  @media ${device.tablet} {
    margin-top: ${rem("31px")};
    margin-bottom: ${rem("31px")};
  }
`;

const ButtonRow = styled(Row)`
  margin-top: ${rem("24px")};

  > div {
    display: flex;
    justify-content: center;
  }
`;

const ParagraphStyled = styled(Paragraph)`
  margin-bottom: ${rem("23px")};

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: ${rem("31px")};
  }
`;

interface IProps {
  handleClickButton: () => void;
}

export const ProcessWidget = ({ handleClickButton }: IProps): JSX.Element => (
  <Container>
    <Row justify="center">
      <Col md={9}>
        <Heading1>How Ruuby Works</Heading1>
        <ParagraphStyled>
          Our therapists are qualified professionals who deliver high quality
          mobile treatments and are carefully vetted before joining the Ruuby
          community.
        </ParagraphStyled>
      </Col>
    </Row>
    <KaleidoscopeRow>
      <Col md={3}>
        <KaleidoscopeNumberLabel
          number={1}
          label="Browse or search for a treatment"
        />
      </Col>
      <Col md={3}>
        <KaleidoscopeNumberLabel
          number={2}
          label="Select date, time and professional therapists"
        />
      </Col>
      <Col md={3}>
        <KaleidoscopeNumberLabel
          number={3}
          label="Enter your booking address and make payment "
        />
      </Col>
      <Col md={3}>
        <KaleidoscopeNumberLabel
          number={4}
          label="Sit back, relax and enjoy your beauty time. "
        />
      </Col>
    </KaleidoscopeRow>
    <ButtonRow>
      <Col>
        <Button onClick={handleClickButton} size="small">
          Book now
        </Button>
      </Col>
    </ButtonRow>
  </Container>
);
