import { rem } from "polished";

import { theme as newTheme } from "./new";

interface ITransitions {
  ease05: "0.5s ease";
  easeInOut02: "0.2s ease-in-out";
  easeInOut03: "0.3s ease-in-out";
  easeInOut04: "0.4s ease-in-out";
  easeInOut05: "0.5s ease-in-out";
  linear02: "0.2s linear";
  linear03: "0.3s linear";
}

interface IFontNames {
  didot: "'Didot 16 A'";
  heading: "bigcaslon_romregular";
  main: "'Nunito Sans', sans-serif";
  quote: "adobe_caslon_proregular";
  title: "handelsontwo";
}

interface IAdditionalColors {
  facebookBlue: "#3b5998";
}

interface IFontSizes {
  /**
   * Font size rem(20px)
   */
  big: string;
  /**
   * Font size rem(16px)
   */
  bigger: string;
  /**
   * Font size rem(40px)
   */
  header: string;
  /**
   * Font size rem(60px)
   */
  headerLarge: string;
  /**
   * Font size rem(24px)
   */
  heading: string;
  /**
   * Font size rem(28px)
   */
  headingBigger: string;
  /**
   * Font size rem(22px)
   */
  headingNew: string;
  /**
   * Font size rem(70px)
   */
  huge: string;
  /**
   * Font size rem(14px)
   */
  main: string;
  /**
   * Font size rem(18px)
   */
  paragraph: string;
  /**
   * Font size rem(10px)
   */
  small: string;
  /**
   * Font size rem(12px)
   */
  smaller: string;
  /**
   * Font size rem(8px)
   */
  tiny: string;
  /**
   * Font size rem(36px)
   */
  title: string;
}

interface ITheme {
  color: {
    additional: IAdditionalColors;
    borderColor: "#bbbfbd";
    borderColorGreen: "#4dcc9a";
    borderColorGrey: "#e5e9e6";
    borderColorNew: "#9c9fac";
    borderColorNewGrey: "#d7d8db";
    error: "#de8989";
    iconColor: "#c1c5c7";
    inputBorderColor: "#d6dbd7";
    ruubyBlack: "#151614";
    ruubyBlue: "#1B202B";
    ruubyBlueDark: "#586b72";
    ruubyDarkerSlateGrey: "#2e323b";
    ruubyDarkGrey: "#505767";
    ruubyDarkSlateGrey: "#3f4552";
    ruubyGrey: "#757b89";
    ruubyGreyBg: "#f4f4f4";
    ruubyGreyBlue: "#bdc1c9";
    ruubyGreyDark: "#686d79";
    ruubyGreyDarker: "#5d626e";
    ruubyGreyLight: "#e7eaeb";
    ruubyGreyMid: "#c0c0c0";
    ruubyGreyText: "#707378";
    ruubyLightRose: "#edd6c8";
    ruubyNewBlue: "#3A405A";
    ruubyNewBlueGrey: "#505766";
    ruubyNewGrey: "#F1F5F6";
    ruubyNewLightGreyBar: "#E7ECED";
    ruubyNewGreyLight: "#C3C6CF";
    ruubyNewPinkLight: "#FFFCF9";
    ruubyPink: "#f5ebe7";
    ruubyPinkDark: "#f6eae2";
    ruubyRose: "#e3c1ac";
    ruubyRoseDark: "#c3a18c";
    tooltip: "rgba(21, 22, 20, 0.9)";
  };
  fontName: IFontNames;
  fontSize: IFontSizes;
  transition: ITransitions;
}

const theme: ITheme = {
  color: {
    additional: {
      facebookBlue: "#3b5998",
    },
    borderColor: "#bbbfbd",
    borderColorGreen: "#4dcc9a",
    borderColorGrey: "#e5e9e6",
    borderColorNew: "#9c9fac",
    borderColorNewGrey: "#d7d8db",
    error: "#de8989",
    iconColor: "#c1c5c7",
    inputBorderColor: "#d6dbd7",
    ruubyBlack: "#151614",
    ruubyBlue: "#1B202B",
    ruubyBlueDark: "#586b72",
    ruubyDarkerSlateGrey: "#2e323b",
    ruubyDarkGrey: "#505767",
    ruubyDarkSlateGrey: "#3f4552",
    ruubyGrey: "#757b89",
    ruubyGreyBg: "#f4f4f4",
    ruubyGreyBlue: "#bdc1c9",
    ruubyGreyDark: "#686d79",
    ruubyGreyDarker: "#5d626e",
    ruubyGreyLight: "#e7eaeb",
    ruubyGreyMid: "#c0c0c0",
    ruubyGreyText: "#707378",
    ruubyLightRose: "#edd6c8",
    ruubyNewBlue: "#3A405A",
    ruubyNewBlueGrey: "#505766",
    ruubyNewGrey: "#F1F5F6",
    ruubyNewLightGreyBar: "#E7ECED",
    ruubyNewGreyLight: "#C3C6CF",
    ruubyNewPinkLight: "#FFFCF9",
    ruubyPink: "#f5ebe7",
    ruubyPinkDark: "#f6eae2",
    ruubyRose: "#e3c1ac",
    ruubyRoseDark: "#c3a18c",
    tooltip: "rgba(21, 22, 20, 0.9)",
  },
  fontName: {
    didot: "'Didot 16 A'",
    heading: "bigcaslon_romregular",
    main: "'Nunito Sans', sans-serif",
    quote: "adobe_caslon_proregular",
    title: "handelsontwo",
  },
  fontSize: {
    big: rem("20px"),
    bigger: rem("16px"),
    header: rem("40px"),
    headerLarge: rem("60px"),
    heading: rem("24px"),
    headingBigger: rem("28px"),
    headingNew: rem("22px"),
    huge: rem("70px"),
    main: rem("14px"),
    paragraph: rem("18px"),
    small: rem("10px"),
    smaller: rem("12px"),
    tiny: rem("8px"),
    title: rem("36px"),
  },
  transition: {
    ease05: "0.5s ease",
    easeInOut02: "0.2s ease-in-out",
    easeInOut03: "0.3s ease-in-out",
    easeInOut04: "0.4s ease-in-out",
    easeInOut05: "0.5s ease-in-out",
    linear02: "0.2s linear",
    linear03: "0.3s linear",
  },
};

export { theme, newTheme };
