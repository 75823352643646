import React from "react";
import styled from "styled-components";
import { rem } from "polished";

export type IconProps = {
  width?: string;
  height?: string;
}

const IconWrapper = styled.svg<IconProps>`
  & svg {
    width: ${(props) => props.width ? props.width : rem("20px")};
    height: ${(props) => props.height ? props.height : rem("20px")};
    fill: ${({ theme }) => theme.colours.icon};
  }
`;

export const Icon = (props: IconProps & { src: React.FC }) => {
  const { src, ...rest } = props;
  const Svg = src;

  return (
    <IconWrapper {...rest}>
      <Svg/>
    </IconWrapper>
  );
};
