import React, { useEffect, useMemo, useRef, useState } from "react";
import { rem } from "polished";
import styled from "styled-components";

import { ErrorText } from "../../../../atoms/errors/error-text";
import { TherapistReview } from "../therapist-review";
import { device } from "../../../../../../utils";
import { Review } from "../../types";
import { ApiError } from "../../../../../../services/api/types/graphql";

const TherapistReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem("18px")};
  padding: ${({ theme }) =>
    `${theme.spacing.size22} ${theme.spacing.size18} ${theme.spacing.size18}`};
`;

const BottomOfReviews = styled.div`
  scroll-margin-top: ${rem("-70px")};
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

const SeeMoreText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  color: ${({ theme }) => theme.colours.textMain};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const LoadingText = styled(SeeMoreText)`
  color: ${({ theme }) => theme.colours.textGrey};
  text-align: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing.size18}; ;
`;

interface Props {
  reviews: Review[];
  error?: ApiError;
  isLoading: boolean;
  isLoadingMore?: boolean;
  onClickLoadMore?(): Promise<void>;
}

export const TherapistReviews = ({
  reviews,
  error,
  isLoading,
  isLoadingMore = false,
  onClickLoadMore,
}: Props): JSX.Element => {
  const [hasLoadedMoreReviews, setHasLoadedMoreReviews] = useState(false);
  const reviewsEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (reviewsEndRef.current && hasLoadedMoreReviews) {
        reviewsEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [reviews, isLoading]);

  const seeMoreButton = useMemo(
    () =>
    isLoadingMore && !error ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        <SeeMoreButton
          onClick={async () => {
            if (typeof onClickLoadMore !== "undefined") {
              await onClickLoadMore();
              setHasLoadedMoreReviews(true);
            }
          }}
        >
          <SeeMoreText>See More Reviews</SeeMoreText>
        </SeeMoreButton>
      ),
    [isLoadingMore, onClickLoadMore, setHasLoadedMoreReviews],
  );

  return (
    <>
      <TherapistReviewsContainer>
        {!isLoading ? reviews.map((review, index) => (
          <TherapistReview key={`${review.customerName}-${index}`} {...review} />
        )) : Array.apply(null, Array(3)).map((_, i) => <TherapistReview key={i} />)}
        {!isLoading && onClickLoadMore && seeMoreButton}
      </TherapistReviewsContainer>
      {error && (
        <ErrorContainer>
          <ErrorText data-testid="therapist-reviews-error">
            Oops, something went wrong!
          </ErrorText>
        </ErrorContainer>
      )}
      <BottomOfReviews ref={reviewsEndRef} />
    </>
  );
};
