import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import {
  Heading1,
  Panel,
  Paragraph,
} from "../../../components/themed-v2/atoms";
import { rem } from "polished";

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  padding-top: ${rem("15px")};
`;

const HeadingStyled = styled(Heading1)`
  text-align: center;
`;

const ParagraphStyled = styled(Paragraph)`
  text-align: center;
`;

type Props = {
  heading: string;
  paragraphs: (string | JSX.Element)[];
} & PropsWithChildren;

export const HeroPanel = ({
  heading,
  paragraphs,
  children,
}: Props): JSX.Element => (
  <PanelStyled>
    <div>
      <HeadingStyled>{heading}</HeadingStyled>
      {/* Using index as key here is not ideal, but the list is rather static */}
      {/* Paragraphs are string or JSX.Element, which makes identifying unique key more difficult */}
      {paragraphs.map((p, i) => (
        <ParagraphStyled large key={i}>
          {p}
        </ParagraphStyled>
      ))}
    </div>
    {children}
  </PanelStyled>
);
