import { DateTime, Settings } from "luxon";

import { config } from "../config";

// set global time zone to London time during module import on app bootstrap
Settings.defaultZoneName = config.dateTime.timezone;

/**
 * Helper function for luxon's date
 * Using global timezone
 */
export function dateFromIso(date: string | "now" | "min"): DateTime {
  const dateTime = DateTime.local();

  switch (date) {
    case "now":
      return dateTime;

    case "min":
      const maxHour = 22; // if current time is more than 10pm, start from next day

      return dateTime.hour >= maxHour ? dateTime.plus({ days: 1 }) : dateTime;

    default:
      return DateTime.fromISO(date);
  }
}
