import * as React from "react";
import styled from "styled-components";

import {
  Button,
  IconHeart,
  Text,
  Textarea,
} from "../../../../components/atoms";
import { Modal } from '../../../themed-v2/molecules/modal';
import { IApiBooking } from "../../../../services/api/booking";
import { theme } from "../../../../theme";
import { dateFromIso } from "../../../../utils/dateIso";

interface IProps {
  booking: IApiBooking;
  isShow: boolean;
  onCancel(): void;
  onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>): void;
  onRatingSet(index: number): void;
  onSubmit(): void;
  rating?: number;
}

const BookingRateModalText = styled(Text)`
  margin-bottom: 10px;
`;

const BookingRateModalButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const BookingRateModalSection = styled.div`
  min-width: 300px;
`;

const BookingRateModalIcon = styled.div`
  svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    fill: ${theme.color.ruubyRose};
    cursor: pointer;

    &:hover {
      fill: ${theme.color.ruubyLightRose};
    }
  }
`;

const BookingRateModalIcons = styled.div`
  display: inline-flex;
  margin-bottom: 10px;
`;

const BookingRateModal: React.FC<IProps> = (props) => {
  // tslint:disable: no-magic-numbers
  const mapIconRating = (rating = 0) =>
    Array(5)
      .fill(undefined)
      .map((_, index) => {
        let type: "empty" | "full" = "empty";
        if (rating - 1 >= index) {
          type = "full";
        }

        return (
          <BookingRateModalIcon
            key={index}
            onClick={() => props.onRatingSet(index)}
          >
            <IconHeart type={type} />
          </BookingRateModalIcon>
        );
      });

  return (
    <Modal
      title={
        props.booking &&
        dateFromIso(props.booking.timeStarts).toFormat("cccc LLLL dd")
      }
      isShow={props.isShow}
      onClose={props.onCancel}
    >
      {props.isShow && (
        <BookingRateModalSection>
          <BookingRateModalText size="16" weight="semibold">
            How was your treatment?
          </BookingRateModalText>
          <BookingRateModalIcons>
            {mapIconRating(props.rating)}
          </BookingRateModalIcons>
          <Textarea
            rows={3}
            placeholder="Leave some comments..."
            onChange={props.onCommentChange}
          ></Textarea>
          <BookingRateModalButton
            size="small"
            background="blue"
            onClick={props.onSubmit}
            disabled={!props.rating}
          >
            Submit
          </BookingRateModalButton>
        </BookingRateModalSection>
      )}
    </Modal>
  );
};

export { BookingRateModal };
