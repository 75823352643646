import styled from "styled-components";

import { Button } from "../button";
import { device } from "../../../../utils";

export const ButtonStyled = styled(Button)`
  width: 100%;

  @media ${device.tablet} {
    max-width: 335px;
  }

  &:disabled > div > span {
    color: ${({ theme }) => theme.colours.buttonDisabledText};
  }

  &:disabled > div > div > svg {
    fill: ${({ theme }) => theme.colours.buttonDisabledText};
  }
`;
