import React, { useMemo } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { Section, Heading1, Panel } from "../../../../atoms";
import { device } from "../../../../../../utils";

import appImage from "./app.webp";

const InfoPanel = styled(Panel)<{ hideTopMarginTablet?: boolean }>`
  background-color: ${({ theme }) => theme.colours.ruubyBlueGrey};
  margin-top: ${({ theme }) => theme.spacing.size20};
  box-shadow: none;

  @media ${device.tablet} {
    margin-top: ${({ theme, hideTopMarginTablet = false }) =>
      !hideTopMarginTablet ? theme.spacing.size32 : 0};
  }

  h1 {
    text-align: center;

    @media ${device.tablet} {
      text-align: left;
    }
  }
`;

const AppImageCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const AppImage = styled.img`
  display: block;
  width: 269px;
  height: auto;

  @media ${device.tablet} {
    width: auto;
    max-width: 100%;
  }
`;

const NumberedList = styled.ul`
  color: ${({ theme }) => theme.colours.textMain};
  list-style: none;
  counter-reset: item;
  padding-inline-start: 0;
  margin: 0;

  & > li {
    counter-increment: item;
    font-size: ${({ theme }) => theme.type.sizes.size16};
    font-family: ${({ theme }) => theme.type.fonts.main};
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.size18};
    }
    
    @media ${device.tablet} {
      font-size: ${({ theme }) => theme.type.sizes.size20};
    }
  }

  & > li:before {
    display: block;
    font-family: ${({ theme }) => theme.type.fonts.heading};
    font-size: ${({ theme }) => theme.type.sizes.size30};
    text-align: center;
    margin-right: ${({ theme }) => theme.spacing.size18};
    content: counter(item);
    min-width: 20px;
    margin-top: -5px;
  }
`;

const BulletList = styled.ul`
  color: ${({ theme }) => theme.colours.textMain};
  padding-inline-start: 23px;
  margin: 0;

  & > li {
    padding-left: 15px;
    font-size: ${({ theme }) => theme.type.sizes.size16};
    font-family: ${({ theme }) => theme.type.fonts.main};

    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.size18};
    }

    @media ${device.tablet} {
      font-size: ${({ theme }) => theme.type.sizes.size20};
    }
  }
`;

export const AffiliatesInfo = (): JSX.Element => {
  const gainContent = useMemo(
    (): JSX.Element => (
      <>
        <Heading1>What You Gain as an Affiliate</Heading1>
        <BulletList>
          <li>
            Dedicated Support: Experience fast, reliable support for all your
            affiliate-related queries and assistance needs.
          </li>
          <li>
            Complimentary treatments for suitable affiliates if it supports the
            promotional activity.
          </li>
        </BulletList>
      </>
    ),
    [],
  );

  return (
    <Section colour="white">
      <Container>
        <Row align="end">
          <AppImageCol md={5} xl={4}>
            <AppImage src={appImage} />
          </AppImageCol>
          <Col md={7} xl={8}>
            <InfoPanel hideTopMarginTablet>
              <Heading1>Why Join Our Affiliate Program?</Heading1>
              <NumberedList>
                <li>
                  Generous Commission Structure: Earn competitive commissions
                  starting at 8% for each successful referral to Ruuby's
                  services.
                </li>
                <li>
                  Long-Term Tracking: Benefit from a 30 day cookie, ensuring you
                  receive credit for referrals, even if bookings occur later.
                </li>
                <li>
                  Marketing Support: Access a suite of promotional materials and
                  resources to effectively market Ruuby's services.
                </li>
              </NumberedList>
            </InfoPanel>
            <Visible lg xl>
              <InfoPanel>
                {gainContent}
              </InfoPanel>
            </Visible>
          </Col>
        </Row>
        <Hidden lg xl>
          <Row>
            <Col>
              <InfoPanel>
                {gainContent}
              </InfoPanel>
            </Col>
          </Row>
        </Hidden>
      </Container>
    </Section>
  );
};
