import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../../utils/media-queries";

interface Props {
  large?: boolean;
}

export const Paragraph = styled.p<Props>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme: { type: { sizes: { size14, size16} } }, large = false }) => large ? size16 : size14 };
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ theme }) => theme.colours.textMain};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: ${rem("12px")};
  margin-bottom: ${rem("12px")};

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    font-size: ${({ theme: { type: { sizes: { size16, size20} } }, large = false }) => large ? size20 : size16 };
    line-height: 140%;
    margin-top: ${rem("16px")};
    margin-bottom: ${rem("16px")};
  }
`;
