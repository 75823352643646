/**
 * Denormalize array of objects or strings to objects
 */
export function deNormalizeArray<T extends { toString: () => string }>(
  array: T[],
  id?: string,
): { [key: string]: T } {
  return array.reduce((accumulator, currentValue) => {
    if (id) {
      accumulator[currentValue[id]] = currentValue;
    } else {
      accumulator[currentValue.toString()] = currentValue;
    }

    return accumulator;
  }, {});
}
