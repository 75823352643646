import * as React from "react";
import Helmet from "react-helmet";
import { Route, Switch } from "react-router-dom";

import {
  CategorySelectionPage,
} from "../../../components/pages";
import { CommonTemplate } from "../../../components/templates/common";
import { metaData } from "../../../static/meta";

interface IProps {
  path: string;
}

const CategoryPage: React.FC<IProps> = ({ path }) => {
  return (
    <CommonTemplate>
      <Helmet>
        <title>{metaData.category.title}</title>
        <meta name="description" content={metaData.category.description} />
      </Helmet>
      <Switch>
        <Route exact path={`${path}`} component={CategorySelectionPage} />
      </Switch>
    </CommonTemplate>
  );
};

export { CategoryPage };
