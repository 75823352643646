import { useHistory } from 'react-router-dom';

type UseRedirectLogin = () => {
  (redirectUrl: string | undefined): void;
}

export const useLoginRedirect: UseRedirectLogin = () => {
  const history = useHistory();

  const loginRedirect = (redirectUrl: string | undefined) => {
    if (typeof redirectUrl !=='undefined') {
      sessionStorage.setItem('redirectUrl', redirectUrl);
    }
    history.push('/login');
  }

  return loginRedirect;
};

