import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as SearchImage } from "./search.svg";

export const IconSearch = styled(SearchImage)`
  width: ${rem("14px")};
  height: ${rem("14px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
