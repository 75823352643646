import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { ApiError } from "../../../../../../services/api/types/graphql";
import { Button } from "../../../../atoms";
import { Card, SimpleLoader } from "../../../../../atoms";
import { TherapistCard } from "..//therapist-card";
import { TherapistPortfolio } from "..//therapist-portfolio";
import { device } from "../../../../../../utils";
import { ApiErrorMessage } from "../../../api-error-message";
import { getApiErrorText } from "../../../../../../errors";
import { TherapistReviews } from "../therapist-reviews";
import { Review, Therapist } from "../../types";
import { AddedTreatment } from "../../hooks/use-handle-treatments";

const TherapistDetailContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing.size18};
`;

const InnerContainer = styled.div`
  margin: ${({ theme }) => `0 ${theme.spacing.size18}`};

  @media ${device.laptop} {
    margin: 0;
  }
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TherapistPortfolioContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.size18};
`;

const TherapistReviewsContainer = styled(Card)`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

interface Props {
  therapistId: string;
  therapistData?: Therapist;
  therapistError?: ApiError;
  isTherapistLoading: boolean;
  isCheckoutLoading: boolean;
  reviewItems: Review[];
  reviewsError?: ApiError;
  areReviewsLoading?: boolean;
  selectedTreatmentCategoryUrn?: string;
  addedTreatments: AddedTreatment;
  loadMoreReviews?(): Promise<void>;
  onClickReload: () => Promise<void>;
  onAddTreatment: (treatmentUrn: string) => void;
  onRemoveTreatment: (treatmentUrn: string) => void;
  onCheckoutPress: () => void;
}

export const TherapistDetail = ({
  therapistId,
  therapistData,
  therapistError,
  isTherapistLoading,
  isCheckoutLoading,
  reviewItems,
  reviewsError,
  areReviewsLoading,
  selectedTreatmentCategoryUrn,
  addedTreatments,
  loadMoreReviews,
  onClickReload,
  onAddTreatment,
  onRemoveTreatment,
  onCheckoutPress,
}: Props): JSX.Element => (
  <TherapistDetailContainer fluid>
    <InnerContainer>
      {therapistError ? (
        <ErrorMessageContainer>
          <ApiErrorMessage
            title="Oops! There was a problem fetching this therapist."
            text={getApiErrorText(ApiError.GENERAL_ERROR)}
          />
          <Button size="small" onClick={onClickReload} disabled={isTherapistLoading}>
            {isTherapistLoading ? <SimpleLoader /> : "Try again"}
          </Button>
        </ErrorMessageContainer>
      ) : (
        <>
          <Row>
            <Col lg={8}>
              <Row>
                <TherapistCard
                  data={therapistData}
                  isLoading={isTherapistLoading}
                />
              </Row>

              <Row>
                <TherapistPortfolioContainer>
                  <TherapistPortfolio
                    therapistId={therapistId}
                    treatments={therapistData?.treatments ?? []}
                    reviewItems={reviewItems}
                    isLoading={isTherapistLoading}
                    isCheckoutLoading={isCheckoutLoading}
                    areReviewsLoading={areReviewsLoading}
                    selectedCategoryUrn={selectedTreatmentCategoryUrn}
                    addedTreatments={addedTreatments}
                    onAddTreatment={onAddTreatment}
                    onRemoveTreatment={onRemoveTreatment}
                    onCheckout={onCheckoutPress}
                    loadMoreReviews={loadMoreReviews}
                    images={therapistData?.galleryImageUrls ?? []}
                    products={therapistData?.products}
                  />
                </TherapistPortfolioContainer>
              </Row>
            </Col>

            <Col lg={4}>
              {(isTherapistLoading || reviewItems.length > 0) && (
                <TherapistReviewsContainer>
                  <TherapistReviews
                    reviews={reviewItems}
                    error={reviewsError}
                    isLoading={isTherapistLoading}
                    isLoadingMore={areReviewsLoading}
                    onClickLoadMore={loadMoreReviews}
                  />
                </TherapistReviewsContainer>
              )}
            </Col>
          </Row>
        </>
      )}
    </InnerContainer>
  </TherapistDetailContainer>
);
