import React, { useCallback, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { Section } from "../section";
import { StripePaymentForm } from "../../../../organisms/stripe-payment-form";
import { PaymentButton } from "../../../../molecules/payment-button";
import { formatPrice } from "../../../../../../utils/format-price-for-display";
import { ErrorText } from "../../../../atoms/errors/error-text";
import styled from "styled-components";
import {
  ApiError,
  BookingPaymentIntent,
  ReserveBookingMutationVariables,
  useReserveBookingMutation,
} from "../../../../../../services/api/types/graphql";
import { useConfirmationParams } from "../../../../../../hooks/use-confirmation-params";
import { useBookingCheckoutContext } from "../../provider";
import {
  AnalyticsEvents,
  trackBookingFailure,
} from "../../../../../../analytics";
import { parseRakutenCookie } from "../../../../../../utils/cookies";

const Error = styled(ErrorText)`
  margin-bottom: ${({ theme }) => theme.type.sizes.size24};
`;

const GENERIC_CHECKOUT_ERROR = "Something went wrong. Please try again.";
const CheckoutError = {
  BOOKING_TREATMENTS_NOT_SUPPORTED:
    "The selected treatments are not supported by the therapist",
  BOOKING_ADDRESS_REQUIRED: "Please select an address.",
  BOOKING_POSTCODE_NOT_SERVED:
    "The selected address is not served by the therapist.",
  BOOKING_SLOT_TAKEN: "The selected slot is taken. Please chose another time.",
} as const;

const getCheckoutError = (error: ApiError): string => {
  if (error in CheckoutError) {
    return CheckoutError[error];
  }

  return GENERIC_CHECKOUT_ERROR;
};

interface StripeCheckoutProps {
  total: number;
  bookingFee: number;
  creditsDeduction: number | undefined;
  promotionDeduction: number | undefined;
}

export const StripeCheckout = ({
  total,
  bookingFee,
  creditsDeduction,
  promotionDeduction,
}: StripeCheckoutProps): JSX.Element => {
  const { bookingParams, setIsBookingBeingPlaced } =
    useBookingCheckoutContext();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentAllowed, setIsPaymentAllowed] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const [reserveBookingMutation] = useReserveBookingMutation();
  const { saveConfirmationParamsToSession } = useConfirmationParams();

  const handleError = useCallback((message?: string) => {
    trackBookingFailure({
      analyticsEventName: AnalyticsEvents.STRIPE_BOOKING_FAIL,
      message: message ?? GENERIC_CHECKOUT_ERROR,
    });

    setError(message ?? GENERIC_CHECKOUT_ERROR);
    setIsLoading(false);
    setIsBookingBeingPlaced(false);
  }, []);

  const reserveBooking = useCallback(
    async (
      variables: ReserveBookingMutationVariables,
    ): Promise<BookingPaymentIntent | undefined> => {
      const response = await reserveBookingMutation({
        variables,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      });

      const data = response.data?.reserveBooking;

      switch (data?.__typename) {
        case "BookingPaymentIntent":
          return data;

        case "RuubyGraphError":
          handleError(getCheckoutError(data.error));
          break;
      }
    },
    [reserveBookingMutation, handleError],
  );

  const handleCheckout = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    setIsBookingBeingPlaced(true);

    if (!stripe || !elements) {
      handleError();
      return;
    }

    if (!selectedPaymentMethodId) {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        handleError(submitError.message);
        return;
      }
    }

    const rakutenCookie = parseRakutenCookie(document.cookie);
    const bookingReservation = await reserveBooking({
      therapistUrn: bookingParams.therapistUrn,
      bookingTreatments: bookingParams.addedTreatments.map(
        ({ urn, quantity }) => ({
          urn,
          quantity,
        }),
      ),
      timeStarts: bookingParams.bookingDate,
      origin: "WEBSITE",
      ...(bookingParams.addressUrn && {
        addressUrn: bookingParams.addressUrn,
      }),
      ...(bookingParams.promo && {
        promoCode: bookingParams.promo.code,
      }),
      ...(bookingParams.notes && {
        notes: bookingParams.notes,
      }),
      saveCard: !selectedPaymentMethodId && savePaymentMethod,
      ...(rakutenCookie && {
        rakutenSiteId: rakutenCookie.atrv,
        rakutenTimeEntered: new Date(rakutenCookie.auld).toISOString(),
      }),
    });

    if (!bookingReservation) {
      return;
    }

    // TODO: Move this to the createBooking hook
    saveConfirmationParamsToSession({
      therapistName: bookingParams.therapistName,
      addedTreatments: bookingParams.addedTreatments.slice(),
      bookingDate: bookingParams.bookingDate,
      total,
      ...(bookingParams.address && {
        addressString: `${bookingParams.address.address1} ${
          bookingParams.address.address2
            ? `, ${bookingParams.address.address2}`
            : ""
        } ${bookingParams.address.postcode.toUpperCase()}`,
      }),
      ...(bookingParams.promo?.code && {
        promoCode: bookingParams.promo.code,
      }),
      category: bookingParams.category,
      bookingFee,
      creditsDeduction,
      promotionDeduction,
      bookingUrn: bookingReservation.bookingUrn,
      runAnalytics: true,
    });

    const { host, protocol } = window.location;
    const { error: stripeError } = await stripe.confirmPayment({
      ...(!selectedPaymentMethodId && {
        elements,
      }),
      clientSecret: bookingReservation.paymentIntent,
      confirmParams: {
        return_url: `${protocol}//${host}/booking/confirmation`,
        ...(selectedPaymentMethodId && {
          payment_method: selectedPaymentMethodId,
        }),
      },
    });

    if (stripeError) {
      handleError(stripeError.message);
      return;
    }

    setIsLoading(false);
    setIsBookingBeingPlaced(false);
  }, [
    selectedPaymentMethodId,
    savePaymentMethod,
    JSON.stringify(bookingParams),
    total,
  ]);

  const onSavedPaymentMethodClick = useCallback(
    (paymentMethodId: string | undefined) => {
      setError(null);
      setSelectedPaymentMethodId(paymentMethodId);
    },
    [setSelectedPaymentMethodId],
  );

  return (
    <>
      <Section>
        {Boolean(error) && <Error>{error}</Error>}
        <StripePaymentForm
          showSavedPaymentMethods={true}
          showSavePaymentMethod={true}
          selectedPaymentMethodId={selectedPaymentMethodId}
          savePaymentMethod={savePaymentMethod}
          onSavedPaymentMethodClick={onSavedPaymentMethodClick}
          onSavePaymentMethodToggle={() =>
            setSavePaymentMethod(!savePaymentMethod)
          }
          onPaymentAllowedChange={(isPaymentAllowed) =>
            setIsPaymentAllowed(isPaymentAllowed)
          }
        />
      </Section>
      <PaymentButton
        price={formatPrice(total)}
        onClick={() => handleCheckout()}
        isLoading={isLoading}
        isDisabled={!isPaymentAllowed}
      />
    </>
  );
};
