import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

import { device } from "../../../../../../utils";

const Container = styled.div`
  margin: auto;
  padding: 18px 0;
`;

const Images = styled.div`
  display: grid;

  width: 298px;
  gap: 18px 30px;
  grid-template-columns: 1fr 1fr;

  @media ${device.tablet} {
    width: 581px;
    gap: 18px 13px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.laptop} {
    width: 639px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Image = styled.img`
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;

  width: 134px;
  height: 134px;

  @media ${device.tablet} {
    width: 185px;
    height: 185px;
  }
  @media ${device.laptop} {
    width: 201px;
    height: 201px;
  }
`;

interface Props {
  images: string[];
}

export const TherapistImages = ({ images }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleClickImage = useCallback(
    (i: number): void => {
      setIndex(i);
      setIsOpen(true);
    },
    [setIsOpen, setIndex],
  );

  const slides = useMemo(
    () => images.map((image) => ({ src: image.replace(/-300/, "-800") })),
    [images],
  );

  return (
    <Container>
      <Images>
        {images.map((image, index) => (
          <Image src={image} onClick={() => handleClickImage(index)} key={image} />
        ))}

        <Lightbox
          slides={slides}
          open={isOpen}
          index={index}
          controller={{
            closeOnBackdropClick: true,
          }}
          plugins={[Zoom]}
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
          close={() => setIsOpen(false)}
        />
      </Images>
    </Container>
  );
};
