import * as React from "react";
import Helmet from "react-helmet";

import { TermsAndConditions } from "../../../components/organisms/";
import { MainTemplate } from "../../../components/templates/main";
import { metaData } from "../../../static/meta";

const TermsAndConditionsPage: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.termsAndConditions.title}</title>
        <meta
          name="description"
          content={metaData.termsAndConditions.description}
        />
      </Helmet>
      <TermsAndConditions />
    </MainTemplate>
  );
};

export { TermsAndConditionsPage };
