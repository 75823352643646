export type FETCH_THERAPISTS_SEARCH_ATTEMPT =
  "therapists.FETCH_THERAPISTS_SEARCH_ATTEMPT";
export const FETCH_THERAPISTS_SEARCH_ATTEMPT: FETCH_THERAPISTS_SEARCH_ATTEMPT =
  "therapists.FETCH_THERAPISTS_SEARCH_ATTEMPT";
export type FETCH_THERAPISTS_SEARCH_SUCCESS =
  "therapists.FETCH_THERAPISTS_SEARCH_SUCCESS";
export const FETCH_THERAPISTS_SEARCH_SUCCESS: FETCH_THERAPISTS_SEARCH_SUCCESS =
  "therapists.FETCH_THERAPISTS_SEARCH_SUCCESS";
export type FETCH_THERAPISTS_SEARCH_FAILURE =
  "therapists.FETCH_THERAPISTS_SEARCH_FAILURE";
export const FETCH_THERAPISTS_SEARCH_FAILURE: FETCH_THERAPISTS_SEARCH_FAILURE =
  "therapists.FETCH_THERAPISTS_SEARCH_FAILURE";

export type FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT";
export const FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT: FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT";
export type FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS";
export const FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS: FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS";
export type FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE";
export const FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE: FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE =
  "therapists.FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE";

export type FETCH_THERAPISTS_AVAILABILITY_ATTEMPT =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_ATTEMPT";
export const FETCH_THERAPISTS_AVAILABILITY_ATTEMPT: FETCH_THERAPISTS_AVAILABILITY_ATTEMPT =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_ATTEMPT";
export type FETCH_THERAPISTS_AVAILABILITY_SUCCESS =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_SUCCESS";
export const FETCH_THERAPISTS_AVAILABILITY_SUCCESS: FETCH_THERAPISTS_AVAILABILITY_SUCCESS =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_SUCCESS";
export type FETCH_THERAPISTS_AVAILABILITY_FAILURE =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_FAILURE";
export const FETCH_THERAPISTS_AVAILABILITY_FAILURE: FETCH_THERAPISTS_AVAILABILITY_FAILURE =
  "therapists.FETCH_THERAPISTS_AVAILABILITY_FAILURE";

export type FETCH_THERAPISTS_REVIEWS_ATTEMPT =
  "therapists.FETCH_THERAPISTS_REVIEWS_ATTEMPT";
export const FETCH_THERAPISTS_REVIEWS_ATTEMPT: FETCH_THERAPISTS_REVIEWS_ATTEMPT =
  "therapists.FETCH_THERAPISTS_REVIEWS_ATTEMPT";
export type FETCH_THERAPISTS_REVIEWS_SUCCESS =
  "therapists.FETCH_THERAPISTS_REVIEWS_SUCCESS";
export const FETCH_THERAPISTS_REVIEWS_SUCCESS: FETCH_THERAPISTS_REVIEWS_SUCCESS =
  "therapists.FETCH_THERAPISTS_REVIEWS_SUCCESS";
export type FETCH_THERAPISTS_REVIEWS_FAILURE =
  "therapists.FETCH_THERAPISTS_REVIEWS_FAILURE";
export const FETCH_THERAPISTS_REVIEWS_FAILURE: FETCH_THERAPISTS_REVIEWS_FAILURE =
  "therapists.FETCH_THERAPISTS_REVIEWS_FAILURE";

export type SET_THERAPISTS_SUBCATEGORIES =
  "therapists.SET_THERAPISTS_SUBCATEGORIES";
export const SET_THERAPISTS_SUBCATEGORIES: SET_THERAPISTS_SUBCATEGORIES =
  "therapists.SET_THERAPISTS_SUBCATEGORIES";

export type CLEAR_THERAPISTS = "therapists.CLEAR_THERAPISTS";
export const CLEAR_THERAPISTS: CLEAR_THERAPISTS = "therapists.CLEAR_THERAPISTS";
export type CLEAR_ADDITIONAL_THERAPISTS =
  "therapists.CLEAR_ADDITIONAL_THERAPISTS";
export const CLEAR_ADDITIONAL_THERAPISTS: CLEAR_ADDITIONAL_THERAPISTS =
  "therapists.CLEAR_ADDITIONAL_THERAPISTS";

export type SELECT_THERAPIST = "therapists.SELECT_THERAPIST";
export const SELECT_THERAPIST: SELECT_THERAPIST = "therapists.SELECT_THERAPIST";
export type DESELECT_THERAPIST = "therapists.DESELECT_THERAPIST";
export const DESELECT_THERAPIST: DESELECT_THERAPIST =
  "therapists.DESELECT_THERAPIST";

export type CLEAR_DATE = "therapists.CLEAR_DATE";
export const CLEAR_DATE: CLEAR_DATE = "therapists.CLEAR_DATE";
export type SET_DATE = "therapists.SET_DATE";
export const SET_DATE: SET_DATE = "therapists.SET_DATE";
