import * as React from "react";
import styled from "styled-components";

import { Image } from "../../atoms";
import { Column, Row } from "../../grid";
import { theme } from "../../../theme";

import facial from "../../../assets/images/about/facial.png";
import hair from "../../../assets/images/about/hair.png";
import makeup from "../../../assets/images/about/makeup.png";
import nails from "../../../assets/images/about/nails.png";
import tanning from "../../../assets/images/about/tanning.png";
import wellness from "../../../assets/images/about/wellness.png";

const AboutOfferTreatmentImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  margin: 0 auto;
  border-radius: 50%;
  background: ${theme.color.ruubyPink};

  @media screen and (max-width: 992px) {
    height: 80px;
    width: 80px;

    margin: 10px;
  }

  ${Image} {
    max-height: 80px;
    max-width: 80px;

    @media screen and (max-width: 992px) {
      max-height: 40px;
      max-width: 40px;
    }
  }
`;

const OfferTreatmentsRow: React.FC = () => {
  return (
    <Row>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={nails} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={hair} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={facial} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={tanning} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={makeup} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
      <Column md="2" sm="4">
        <AboutOfferTreatmentImageWrapper>
          <Image src={wellness} />
        </AboutOfferTreatmentImageWrapper>
      </Column>
    </Row>
  );
};

export { OfferTreatmentsRow };
