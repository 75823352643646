import React from "react";

import { Button } from "../../../../atoms";
import styled from "styled-components";
import { newTheme } from "../../../../../theme";

interface GetInTouchButtonProps {
  href?: string;
  to?: string;
  variant: 'light' | 'dark';
}

const EventButton = styled(Button)<{ color: string }>`
  color: ${(props) => props.color ? newTheme.colours.text[props.color] : newTheme.colours.text.main};
`;

export const GetInTouchButton: React.FC<GetInTouchButtonProps> = (props) => {
  const backgroundColor = props.variant === 'light'? 'grey' : 'blue';
  const textColor = props.variant === 'light'? 'main' : 'white';

  return (
    <EventButton color={textColor} background={backgroundColor} size="medium" href={props.href} to={props.to} target="_blank">
      Get in touch with us
    </EventButton>
  );
}
