import * as React from "react";
import styled from "styled-components";

import { Button, IconLock, Text, Textarea } from "../../../../components/atoms";
import { TextDoubleHeader } from "../../../../components/molecules";
import { theme } from "../../../../theme";

interface IProps {
  innerRef: React.RefObject<HTMLDivElement>;
  isCheckoutDisabled: boolean;
  originalAmount: number;
  totalAmount: number;
  minimumAmount: number;
  updateNotes(e: React.FocusEvent<HTMLTextAreaElement>): void;
}

const CheckoutTotalTextarea = styled(Textarea)`
  padding-bottom: 14px;
`;

const CheckoutTotalButton = styled(Button)`
  width: 100%;

  svg {
    vertical-align: bottom;
  }
`;

const CheckoutTotalWrapper = styled.div`
  padding-top: 40px;
  border-top: 1px solid ${theme.color.inputBorderColor};
`;

const CheckoutTotal: React.FC<IProps> = (props) => {
  return (
    <CheckoutTotalWrapper ref={props.innerRef}>
      <CheckoutTotalTextarea
        // tslint:disable-next-line: no-magic-numbers
        rows={5}
        placeholder="Parking, address clarification, special requests for your booking etc."
        onBlur={props.updateNotes}
      />
      <TextDoubleHeader>
        <Text size="20" weight="bold" transform="uppercase">
          Total to Pay
        </Text>
        <Text size="20" weight="semibold" transform="uppercase">
          £{props.totalAmount}
        </Text>
      </TextDoubleHeader>
      {props.totalAmount === 0 && props.originalAmount >= props.minimumAmount && (
        <Text size="16" weight="semibold">
          There’s nothing to pay so you are ready to go!
        </Text>
      )}
      {props.originalAmount < props.minimumAmount && (
        <Text weight="semibold">
          Minimum booking amount is £{props.minimumAmount}
        </Text>
      )}
      <CheckoutTotalButton
        background="grey"
        disabled={props.isCheckoutDisabled}
        size="small"
      >
        <IconLock /> Place Booking
      </CheckoutTotalButton>
    </CheckoutTotalWrapper>
  );
};

export { CheckoutTotal };
