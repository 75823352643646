import * as React from "react";
import styled from "styled-components";

import { Card } from "../../../../components/atoms";
import { CarouselImageCard } from "../../../../components/molecules/carousel-image-card";
import { ScrollerContainer } from "../../../../containers/common/scroller";

interface IBackground {
  background?: string;
}

interface IProps extends IBackground {
  images: string[];
}

const FeaturedTreatmentsScrollerItemsWrapper = styled.div`
  display: inline-flex;
  padding: 0 0 30px;
  padding-top: 20px;

  a {
    margin: 0 15px;
  }

  ${Card} {
    width: 360px;

    @media screen and (max-width: 500px) {
      width: 80vw;
    }
  }
`;

const FeaturedTreatmentsScrollerWrapper = styled.div`
  margin-top: 20px;
  background: ${(props: IBackground) =>
    props.background ? props.background : "none"};
`;

const FeaturedTreatmentsScrollerCard = styled(CarouselImageCard)`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const FeaturedTreatmentsHeaderScroller: React.FC<IProps> = ({ images }) => {
  const elements = images.map((s) => {
    return <FeaturedTreatmentsScrollerCard key={s} imageUrl={s} />;
  });

  return (
    <FeaturedTreatmentsScrollerWrapper>
      <ScrollerContainer centerElements>
        <FeaturedTreatmentsScrollerItemsWrapper>
          {elements}
        </FeaturedTreatmentsScrollerItemsWrapper>
      </ScrollerContainer>
    </FeaturedTreatmentsScrollerWrapper>
  );
};

export { FeaturedTreatmentsHeaderScroller };
