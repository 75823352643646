import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Button, Paragraph } from "../../../../components/atoms";
import { Input } from "../../../../components/molecules";
import {
  deleteUserAddress,
  fetchUserAddresses,
  updateUserAddress,
} from "../../../../services/api/addresses";
import { theme } from "../../../../theme";
import { compareAddresses, validateAddress } from "../../../../utils/addresses";
import { IRootState } from "../../../../store/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  BookingLocationPresenter,
  inflateBookingLocationPresenter,
} from "../../../../presenters/booking-location";
import { ICheckoutAddress } from "../../../../presenters/booking-location/booking-location-presenter";
import { AddressDeletionModal } from "../../../../components/molecules/address-group/address-deletion-modal";
import { actionCreators as postcodeActions } from "../../../../store/postcodes/actions";

interface IReduxProps {
  selectedBookingAddress?: BookingLocationPresenter;
  selectedBillingAddress?: BookingLocationPresenter;
}

type Props = IReduxProps & RouteComponentProps & { dispatch: Dispatch<any> };

interface State {
  urn: string;
  address1: string;
  address2: string;
  postcode: string;
  errorMessage: string;
  fullAddress: ICheckoutAddress | null;
  isBookingAddress: boolean;
  isBillingAddress: boolean;
  isDeletable: boolean;
  showDeleteConfirmationModal: boolean;
}

const AddressSelectTitle = styled.h2`
  font-family: ${theme.fontName.heading};
  font-weight: normal;
  color: ${theme.color.ruubyGrey};
`;

const AddressSelectButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

const ErrorParagraph = styled(Paragraph)`
  color: ${theme.color.error};

  a {
    color: ${theme.color.error};
  }
`;

const FormRow = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
  }
`;

class Component extends React.Component<Props, State> {
  public state: State = {
    urn: "",
    address1: "",
    address2: "",
    postcode: "",
    errorMessage: "",
    fullAddress: null,
    isBookingAddress: false,
    isBillingAddress: false,
    isDeletable: false,
    showDeleteConfirmationModal: false,
  };

  public async componentDidMount() {
    const urn = this.props.match.params["urn"];
    const address = (await fetchUserAddresses()).find(
      (address) => address.urn === urn,
    );

    if (typeof address === "undefined") {
      this.handleBack();
    } else {
      const bookingAddress = this.props.selectedBookingAddress
        ? this.props.selectedBookingAddress.getCheckoutAddress()
        : undefined;
      const isBookingAddress = !!(
        bookingAddress && compareAddresses(address, bookingAddress)
      );

      const billingAddress = this.props.selectedBillingAddress
        ? this.props.selectedBillingAddress.getCheckoutAddress()
        : undefined;
      const isBillingAddress = !!(
        billingAddress && compareAddresses(address, billingAddress)
      );

      this.setState({
        urn,
        address1: address.address1,
        address2: address.address2,
        postcode: address.postcode,
        fullAddress: address,
        isBookingAddress,
        isBillingAddress,
        isDeletable: !isBookingAddress && !isBillingAddress,
      });
    }
  }

  public async handleEditAddress() {
    const address = {
      "@id": this.state.urn,
      address1: this.state.address1,
      address2: this.state.address2,
      postcode: this.state.postcode,
    };
    const errorMessage = validateAddress(address);

    if (typeof errorMessage !== "undefined") {
      this.setState({
        errorMessage,
      });
    } else {
      try {
        await updateUserAddress(address);
        const selectedAddress = {
          line_1: address.address1,
          line_2: address.address2,
          postcode: address.postcode,
        };

        if (this.state.isBookingAddress) {
          this.props.dispatch(
            postcodeActions.setAddressManually(selectedAddress),
          );
        }

        if (this.state.isBillingAddress) {
          this.props.dispatch(
            postcodeActions.setBillingAddressManually(selectedAddress),
          );
        }

        this.handleBack();
      } catch (error) {
        if (error instanceof Error) {
          this.setState({
            errorMessage: error.message,
          });
        }
      }
    }
  }

  public async handleDeleteAddress() {
    const addressUrn = this.state.urn;

    try {
      await deleteUserAddress(addressUrn);

      this.handleBack();
    } catch (error) {
      if (error instanceof Error) {
        this.setState({
          errorMessage: error.message,
        });
      }
    }
  }

  private toggleDeleteConfirmationModal(state: boolean) {
    this.setState({ showDeleteConfirmationModal: state });
  }

  public handleBack() {
    this.props.history.goBack();
  }

  public renderError(): React.ReactNode {
    if (this.state.errorMessage !== "") {
      return <ErrorParagraph>{this.state.errorMessage}</ErrorParagraph>;
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <AddressSelectTitle>Edit address</AddressSelectTitle>
        <FormRow>
          <Input
            label="First line of address"
            name="address1"
            value={this.state.address1}
            onChange={(e) =>
              this.setState({ ...this.state, address1: e.currentTarget.value })
            }
          />
        </FormRow>

        <FormRow>
          <Input
            label="Second line of address"
            name="address2"
            value={this.state.address2}
            onChange={(e) =>
              this.setState({ ...this.state, address2: e.currentTarget.value })
            }
          />
        </FormRow>

        <FormRow>
          <Input
            label="Postcode"
            name="postcode"
            value={this.state.postcode}
            onChange={(e) =>
              this.setState({
                ...this.state,
                postcode: e.currentTarget.value.toUpperCase(),
              })
            }
          />
        </FormRow>
        {this.renderError()}
        <AddressSelectButton
          size="small"
          onClick={this.handleEditAddress.bind(this)}
        >
          Continue
        </AddressSelectButton>
        {this.state.isDeletable && (
          <AddressSelectButton
            size="small"
            onClick={() => this.toggleDeleteConfirmationModal(true)}
          >
            Delete
          </AddressSelectButton>
        )}
        <AddressDeletionModal
          address={this.state.fullAddress || undefined}
          isShow={this.state.showDeleteConfirmationModal}
          onApprove={() => this.handleDeleteAddress()}
          onCancel={() => this.toggleDeleteConfirmationModal(false)}
        />

        <AddressSelectButton size="small" onClick={this.handleBack.bind(this)}>
          Back
        </AddressSelectButton>
        {!this.state.isDeletable &&
          "You cannot currently delete this address as it is selected for this booking."}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState): IReduxProps => {
  return {
    ...(state.postcodesState.bookingLocation && {
      selectedBookingAddress: inflateBookingLocationPresenter(
        state.postcodesState.bookingLocation,
      ),
    }),
    ...(state.postcodesState.billingLocation && {
      selectedBillingAddress: inflateBookingLocationPresenter(
        state.postcodesState.billingLocation,
      ),
    }),
  };
};

export const AddressUpdate = withRouter(
  connect<IReduxProps, {}, {}, IRootState>(mapStateToProps)(Component),
);
