import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { config } from "./config";
import { getAuthToken } from "./services/token-storage";

const DeviceInfo = config.appApi.credentials.deviceId;

const httpLink = createHttpLink({
  uri: config.graphql.url,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  return {
    headers: {
      ...headers,
      "Device-Id": DeviceInfo,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
