import * as React from "react";
import styled from "styled-components";

import { Header, Image } from "../../../../components/atoms";
import {
  OfferTreatmentsRow,
  TextSection,
} from "../../../../components/molecules";
import { Column, Container, Row } from "../../../grid";
import { theme } from "../../../../theme";

import offer1 from "../../../../assets/images/about/img1.jpg";
import offer2 from "../../../../assets/images/about/img2.jpg";
import offer3 from "../../../../assets/images/about/img3.jpg";

const AboutOfferHeaderH2 = styled(Header)`
  margin: 0;
`;

const AboutOfferTextSection = styled(TextSection)`
  margin-top: 32px;
  margin-bottom: 40px;

  .title {
    font-family: ${theme.fontName.title};
    font-size: ${theme.fontSize.title};
    color: ${theme.color.ruubyBlue};
  }

  .text {
    font-size: ${theme.fontSize.paragraph};
    font-weight: 600;
    color: ${theme.color.ruubyBlue};
    padding: 0 10%;
  }
`;

const AboutOfferRow = (
  <Row>
    <Column md="4">
      <Image src={offer1} />
    </Column>
    <Column md="4">
      <Image src={offer2} />
    </Column>
    <Column md="4">
      <Image src={offer3} />
    </Column>
  </Row>
);

const AboutOffer: React.FC = () => {
  return (
    <Container background="white" offsetPaddings>
      <Row>
        <Column textAlign="center">
          <AboutOfferHeaderH2 as="h2">The Offer</AboutOfferHeaderH2>
          <AboutOfferTextSection
            title="1"
            text={`Download the Ruuby app or browse our site, and choose from a selection of beauty services,
            including blowdries, manicures, massages and more.`}
          />
          <OfferTreatmentsRow />
          <AboutOfferTextSection
            title="2"
            text={`Choose your time and address, and take a browse through our available beauty professionals.
            You can browse through images of their work, too.`}
          />
          {AboutOfferRow}
          <AboutOfferTextSection
            title="3"
            text={
              "Book your time slot, and wait for your therapist to arrive! You can chat with them on the app."
            }
          />
        </Column>
      </Row>
    </Container>
  );
};

export { AboutOffer };
