import React from "react";
import styled from "styled-components";

import { Card, NavItem } from "../../../../../atoms";
import { device } from "../../../../../../utils";
import { config } from "../../../../../../config";
import { formatPrice } from "../../../../../../utils/format-price-for-display";

const MinimumFeeCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.size24} 0`};
`;

const MinimumFeeText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const CancellationPolicyLink = styled(NavItem)`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  color: ${({ theme }) => theme.colours.textMain};
`;

export const MinimumFee = (): JSX.Element => (
  <MinimumFeeCard>
    <MinimumFeeText>
      Please note our minimum call-out fee is{" "}
      {formatPrice(config.minimumCallOutFee, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </MinimumFeeText>
    <CancellationPolicyLink
      type="link"
      to="/faqs#payments-cancellations-refunds"
    >
      View our Cancellation Policy
    </CancellationPolicyLink>
  </MinimumFeeCard>
);
