import * as React from "react";
import jQuery from "jquery";

import { debounce } from "lodash-es";

import { Scroller } from "../../components/molecules/scroller"; // Load directly to avoid error in tests

interface IProps {
  centerElements?: boolean;
  centerElementsIfMobile?: boolean;
  itemIndexIn?: number;
  setIndexScrolled?: number;
  children?: React.ReactNode;
}

interface IState {
  indexScrolled: number;
  isLeftArrow: boolean;
  isRightArrow: boolean;
  itemsNumber: number;
}

class ScrollerContainer extends React.PureComponent<IProps, IState> {
  private readonly debounceTime = 200;
  private readonly scrollerBeltRef = React.createRef<HTMLDivElement>();
  private readonly scrollerWrapperRef = React.createRef<HTMLDivElement>();
  private windowWidth?: number;
  private scrollerBeltWrapper?: JQuery;
  private scrollerBelt?: JQuery;
  private scrollerItemsWrapper?: JQuery;
  private maxIndex?: number;
  private touchStartX = 0;
  private touchEndX = 0;
  private touchMoveX = 0;

  public state: IState = {
    indexScrolled: 0,
    isLeftArrow: false,
    isRightArrow: false,
    itemsNumber: 0,
  };

  public componentDidMount() {
    this.windowWidth = window.innerWidth;
    this.init();
    jQuery(window).on("resize", this.debounceWindowResize);
  }

  /* tslint:disable: no-any no-unsafe-any */
  public componentDidUpdate(prevProps: any) {
    if (
      (React.Children.only(this.props.children) as any).props.children
        .length !== prevProps.children.props.children.length
    ) {
      this.reset();
    }

    if (
      this.props.itemIndexIn &&
      this.props.itemIndexIn !== prevProps.itemIndexIn
    ) {
      this.reset();
    }
  }
  /* tslint:enable */

  public componentWillUnmount() {
    jQuery(window).off("resize", this.debounceWindowResize);
  }

  /**
   * Initialize scroller (width, items)
   */
  public init(): void {
    if (this.scrollerBeltRef.current && this.scrollerWrapperRef.current) {
      this.scrollerBeltWrapper = jQuery(this.scrollerBeltRef.current);
      this.scrollerBelt = jQuery(this.scrollerBeltWrapper).children();
      this.scrollerItemsWrapper = jQuery(this.scrollerBelt).children();

      const scrollerWrapper = jQuery(this.scrollerWrapperRef.current);
      const scrollerItems = jQuery(this.scrollerItemsWrapper).children();
      const scrollerMapperWidth = scrollerWrapper.width();
      const scrollerItemsWidth = scrollerItems.outerWidth(true);
      if (scrollerMapperWidth && scrollerItemsWidth) {
        const maxItems = Math.floor(scrollerMapperWidth / scrollerItemsWidth);
        const newBeltWidth = maxItems * scrollerItemsWidth;
        const newMaxScrollLength =
          maxItems * scrollerItemsWidth * (scrollerItems.length / maxItems);
        const roundingUpAdded = 0.3;
        const newmMaxIndex = Math.round(
          newMaxScrollLength / newBeltWidth + roundingUpAdded,
        );
        this.maxIndex = newmMaxIndex;
        this.initGestures();

        if (
          this.scrollerItemsWrapper.width() !== this.scrollerBeltWrapper.width()
        ) {
          this.scrollerBeltWrapper.width(newBeltWidth);
          this.setState({ isLeftArrow: false });
        }

        if (scrollerItems.length <= maxItems) {
          this.setState({ isRightArrow: false });
        } else {
          this.setState({ isRightArrow: true });
        }

        if (this.props.setIndexScrolled) {
          this.setState({ indexScrolled: this.props.setIndexScrolled }, () =>
            this.handleTreatmentCarousel(this.state.indexScrolled),
          );
        } else if (this.props.itemIndexIn) {
          this.setState(
            {
              indexScrolled: Math.floor(this.props.itemIndexIn / maxItems),
            },
            () => {
              this.handleTreatmentCarousel(this.state.indexScrolled);
            },
          );
        } else if (this.props.itemIndexIn === 0) {
          this.setState({ indexScrolled: 0 }, () =>
            this.handleTreatmentCarousel(this.state.indexScrolled),
          );
        }
      }
    }
  }

  /**
   * Reset scroller
   */
  public reset() {
    // update width
    this.windowWidth = window.innerWidth;
    // reset values
    if (this.scrollerBeltWrapper) {
      this.scrollerBeltWrapper.width("auto");
    }
    this.setState({ indexScrolled: 0 }, () => {
      // check and reset state
      this.handleTreatmentCarousel();

      // then init scroller
      this.init();
    });
  }

  /**
   * Set to initial scroller state on window width resize
   */
  public resetOnResize = (): void => {
    if (this.windowWidth === window.innerWidth) {
      return;
    }

    this.reset();
  };

  /**
   * debounce function on window resize
   */
  public debounceWindowResize = debounce(this.resetOnResize, this.debounceTime);

  /**
   * Set scroll position of belt
   */
  public handleTreatmentCarousel(index?: number): void {
    if (this.scrollerBelt) {
      const oldWidth = this.scrollerBelt.width();
      if (oldWidth) {
        const width = oldWidth * (index || this.state.indexScrolled);

        jQuery(this.scrollerBelt).css(
          "transform",
          `translate(-${width}px, 0px)`,
        );
      }
    }

    this.checkScrolling();
  }

  /**
   * Scroll right
   */
  public handleTreatmentCarouselNext = (): void => {
    if (this.state.indexScrolled + 1 === this.maxIndex) {
      return;
    }
    this.setState({ indexScrolled: this.state.indexScrolled + 1 }, () => {
      this.handleTreatmentCarousel();
    });
  };

  /**
   * Scroll left
   */
  public handleTreatmentCarouselPrevious = (): void => {
    if (this.state.indexScrolled === 0) {
      return;
    }

    this.setState({ indexScrolled: this.state.indexScrolled - 1 }, () => {
      this.handleTreatmentCarousel();
    });
  };

  /**
   * Update arrow states depending on
   * scroll position (index)
   */
  public checkScrolling(): void {
    if (this.state.indexScrolled > 0) {
      this.setState({ isLeftArrow: true });
    } else {
      this.setState({ isLeftArrow: false });
    }

    if (this.state.indexScrolled + 1 === this.maxIndex) {
      this.setState({ isRightArrow: false });
    } else {
      this.setState({ isRightArrow: true });
    }
  }

  /**
   * Init gesture methods
   */
  public initGestures() {
    if (this.scrollerBelt && this.scrollerBeltWrapper) {
      const moveTreshold = 15;

      jQuery(this.scrollerBelt).addClass("animate");

      jQuery(this.scrollerBeltWrapper).on("touchstart", (e: JQuery.Event) => {
        jQuery(this.scrollerBelt as any).removeClass("animate");
        if (e.changedTouches) {
          this.touchStartX = e.changedTouches[0].screenX;
        }
      });

      jQuery(this.scrollerBeltWrapper).on("touchmove", (e: JQuery.Event) => {
        if (e.changedTouches) {
          this.touchMoveX = e.changedTouches[0].screenX;
        }

        if (
          this.touchMoveX > this.touchStartX + moveTreshold ||
          this.touchMoveX < this.touchStartX - moveTreshold
        ) {
          const width = this.scrollerBeltWrapper?.width();
          if (width && this.scrollerBelt) {
            const moveX =
              this.state.indexScrolled * width +
              (this.touchStartX - this.touchMoveX);

            jQuery(this.scrollerBelt).css(
              "transform",
              `translate(-${moveX}px, 0px)`,
            );
          }
        } else {
          this.handleTreatmentCarousel();
        }
      });

      jQuery(this.scrollerBeltWrapper).on("touchend", (e: JQuery.Event) => {
        e.stopImmediatePropagation();

        if (this.scrollerBelt) {
          jQuery(this.scrollerBelt).addClass("animate");
          if (e.changedTouches) {
            this.touchEndX = e.changedTouches[0].screenX;
          }
        }

        this.handleGesture();
      });
    }
  }

  /**
   * Handle swipte gestures
   * call prev. or next methods
   */
  public handleGesture(): void {
    const horizontalTreshold = 10;

    if (this.touchEndX + horizontalTreshold < this.touchStartX) {
      this.handleTreatmentCarouselNext();
    } else {
      this.handleTreatmentCarousel();
    }

    if (this.touchEndX - horizontalTreshold > this.touchStartX) {
      this.handleTreatmentCarouselPrevious();
    } else {
      this.handleTreatmentCarousel();
    }
  }

  /**
   * Render Scroller component
   */
  public render() {
    return (
      <Scroller
        center={this.props.centerElements}
        centerIfMobile={this.props.centerElementsIfMobile}
        clickNext={this.handleTreatmentCarouselNext}
        clickPrev={this.handleTreatmentCarouselPrevious}
        scrollerBeltRef={this.scrollerBeltRef}
        scrollerWrapperRef={this.scrollerWrapperRef}
        leftArrow={this.state.isLeftArrow}
        rightArrow={this.state.isRightArrow}
      >
        {this.props.children}
      </Scroller>
    );
  }
}

export { ScrollerContainer };