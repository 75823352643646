import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { FilterBar } from "./filter-bar";
import { Heading1 } from "../../../../../atoms";
import { device } from "../../../../../../../utils";
import { FilterFields } from "../../../hooks/use-search-filter";
import { DropdownItem } from "../../../../../molecules/filter/filter-field-dropdown/types";

const HeaderContainer = styled.section<{
  topOffset: number;
}>`
  position: sticky;
  top: ${({ topOffset }) => `-${topOffset + 18}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ theme, topOffset }) =>
    `${topOffset + 22}px ${theme.spacing.size18} 0`};
  background-color: ${({ theme }) => theme.colours.ruubyBlueGrey};
  z-index: 99;

  @media ${device.tablet} {
    padding-top: ${({ topOffset }) => `${topOffset + 18}px`};
    top: ${({ topOffset }) => `-${topOffset + 22}px`};
  }
`;

const BottomOffset = styled.div`
  height: ${rem("32px")};
`;

interface Props {
  categoryOptions: [string, string][];
  timeOptions: DropdownItem[];
  filter: FilterFields;
  isValidPostcode: (selectedPostcode: string) => boolean;
  onChangeCategory: (category: string) => void;
  onChangePostcode: (postcode: string) => void;
  onChangeDate: (date: string) => void;
  onChangeTime: (time: string) => void;
}

export const TherapistListingHeader = ({
  categoryOptions,
  timeOptions,
  filter,
  isValidPostcode,
  onChangeCategory,
  onChangePostcode,
  onChangeDate,
  onChangeTime,
}: Props): JSX.Element => {
  const headerHeight = document.querySelector("header")?.offsetHeight ?? 0;

  return (
    <HeaderContainer topOffset={headerHeight}>
      <Heading1>Select a professional</Heading1>
      <FilterBar
        selectedFilterFields={filter}
        categoryOptions={categoryOptions}
        timeOptions={timeOptions}
        topOffset={headerHeight}
        isValidPostcode={isValidPostcode}
        onChangeCategory={onChangeCategory}
        onChangePostcode={onChangePostcode}
        onChangeDate={onChangeDate}
        onChangeTime={onChangeTime}
      />
      <BottomOffset />
    </HeaderContainer>
  );
};
