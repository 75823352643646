import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  type?: "error" | "success" | "pending" | "completed";
}

const Badge = styled.div`
  display: inline-block;
  padding: 5px;

  ${(props: IProps) => setType(props.type)};
`;

// tslint:disable-next-line: completed-docs
function setType(type?: string) {
  switch (type) {
    case "error":
      return `
        border: 1px solid ${theme.color.error};
        &&&{
        > * {
          color: ${theme.color.error};
          }
        }
      `;

    case "success":
      return `
        border: 1px solid ${theme.color.borderColorGreen};
        &&&{
        > * {
          color: ${theme.color.borderColorGreen};
          }
        }
      `;

    case "pending":
      return `
        border: 1px solid ${theme.color.ruubyBlue};
        &&&{
        > * {
          color: ${theme.color.ruubyBlue};
          }
        }
      `;

    case "completed":
      return `
        border: 1px solid ${theme.color.ruubyLightRose};
        &&&{
        > * {
          color: ${theme.color.ruubyLightRose};
          }
        }
      `;

    default:
      return `
        border: 1px solid ${theme.color.ruubyBlack};
        &&&{
        > * {
          color: ${theme.color.ruubyBlack};
          }
        }
      `;
  }
}

export { Badge };
