import React, { useMemo, useState } from "react";

import { categories } from "../../../../../../utils/search";
import { FilterFieldDropdown } from "../../../../molecules/filter/filter-field-dropdown";
import { FieldWrapper } from "../field-wrapper";

interface Props {
  value: string;
  onSelect: (text: string) => void;
}

export const CategoryField = ({ onSelect, value }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const categoryOptions = useMemo(
    () => categories.map<[string, string]>(({ id, name }) => [id, name]),
    [],
  );
  
  return (
    <FieldWrapper isOpen={isOpen}>
      <FilterFieldDropdown
        placeholder="Choose Treatment"
        items={categoryOptions}
        selectedValue={value}
        onSelect={onSelect}
        onChangeOpen={setIsOpen}
      />
    </FieldWrapper>
  );
};