import { rem } from "polished";
import React, { useCallback } from "react";
import { ScreenClass, useScreenClass } from "react-grid-system";
import { utcToZonedTime } from "date-fns-tz";
import styled from "styled-components";

import { Card } from "../../../../../../../atoms";
import { TherapistAvatar } from "../../../../../../atoms/therapist-avatar";
import { TierLabel } from "../../../../../../atoms/tier-label";
import { TherapistRating } from "../../../../../../atoms/therapist-rating";
import { PastTimeLabel } from "../../../../../therapist-detail-page/components/past-time-label";
import { ListingTherapist } from "../../../../types";
import { device } from "../../../../../../../../utils";
import { config } from "../../../../../../../../config";
import { TherapistTimeWidget } from "../../therapist-time-widget";

const CardStyled = styled(Card)`
  height: 100%;
  transition: all ${({ theme }) => theme.transition.easeInOut03};

  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
  }
`;

const TherapistCardContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ theme }) =>
    `${theme.spacing.size16} ${theme.spacing.size16} ${theme.spacing.size18}`};
`;

const TherapistAvatarContainer = styled.div`
  > img,
  > div {
    width: ${rem("74px")};
    height: ${rem("74px")};

    @media ${device.laptop} {
      width: ${rem("100px")};
      height: ${rem("100px")};
    }
  }
`;

const TherapistCardDetails = styled.div`
  /* max-width: 100%; */
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.size8};

  @media ${device.laptop} {
    gap: ${({ theme }) => theme.spacing.size12};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${rem("33px")};

  @media ${device.tablet} {
    min-height: ${rem("48px")};
  }
`;

const TherapistCardDetailsContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.size16};
  width: 100%;

  @media ${device.laptop} {
    margin-left: ${({ theme }) => theme.spacing.size14};
  }
`;

const TherapistName = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textMain};
  margin: 0;
  text-transform: uppercase;

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.type.sizes.size20};
  }
`;

const TierLabelContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.size12};
`;

const CategoriesContainer = styled.div`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.mobileS} {
    max-width: 220px;
  }

  @media ${device.mobileL} {
    max-width: 280px;
  }

  @media ${device.tablet} {
    max-width: 220px;
  }

  @media ${device.laptop} {
    max-width: 300px;
  }
`;

const CategoryName = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textGrey};
  margin: 0;
  text-transform: capitalize;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.size8};

    &::after {
      content: "";
      width: ${rem("4px")};
      height: ${rem("4px")};
      background-color: ${({ theme }) => theme.colours.textGrey};
      border-radius: 50%;
      display: inline-block;
      margin-left: ${({ theme }) => theme.spacing.size8};
      vertical-align: middle;
    }
  }

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const PastTimeLabelText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textGrey3};
  white-space: nowrap;

  time {
    font-style: normal;
    color: ${({ theme }) => theme.colours.textGrey3};
  }

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  therapist: ListingTherapist;
  onClick(therapistUrnOrSlug: string, slotTime?: string): void;
}

export const TherapistListingCard = ({
  therapist,
  onClick,
}: Props): JSX.Element => {
  const screenClass = useScreenClass();

  const isDesktop = (["lg", "xl", "xxl", "xxxl"] as ScreenClass[]).includes(
    screenClass,
  );
  const tierLabelSize = isDesktop ? 48 : 33;
  const tierLabelFont = isDesktop ? 28 : 18;

  const {
    urn,
    avatarImageUrl,
    bookings: { lastTimeCreated },
    name,
    reviews: { totalAverageRating, totalCount },
    tier,
    categories,
  } = therapist;

  const handleClick = useCallback(() => onClick(urn), [urn]);

  return (
    <CardStyled>
      <TherapistCardContainer onClick={handleClick}>
        <TherapistAvatarContainer>
          <TherapistAvatar avatarImageUrl={avatarImageUrl} name={name} />
        </TherapistAvatarContainer>
        <TherapistCardDetailsContainer>
          <TitleContainer>
            <TherapistName>{name}</TherapistName>
            <TierLabelContainer>
              <TierLabel
                type={tier}
                width={tierLabelSize}
                height={tierLabelSize}
                fontSize={tierLabelFont}
              />
            </TierLabelContainer>
          </TitleContainer>
          <TherapistCardDetails>
            {totalAverageRating && totalCount && (
              <TherapistRating
                averageRating={totalAverageRating}
                numberOfRatings={totalCount}
              />
            )}
            <CategoriesContainer>
              {categories.map((category, index) => (
                <CategoryName key={`${category}-${index}`}>
                  {category}
                </CategoryName>
              ))}
            </CategoriesContainer>
            {lastTimeCreated && (
              <PastTimeLabelText>
                Last booked{" "}
                <PastTimeLabel
                  date={utcToZonedTime(
                    lastTimeCreated,
                    config.dateTime.timezone,
                  )}
                />
              </PastTimeLabelText>
            )}
          </TherapistCardDetails>
        </TherapistCardDetailsContainer>
      </TherapistCardContainer>
      <TherapistTimeWidget 
        therapistUrn={therapist.urn}
        availability={therapist.availability}
        onClick={onClick}
      />  
    </CardStyled>
  );
};
