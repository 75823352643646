import * as React from "react";
import styled from "styled-components";

import { IconMail, NavItem } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  fontSize: "smaller" | "main" | "bigger";
  mobileFontSize: "smaller" | "main" | "bigger";
  className?: string;
}

const MailWrapper = styled.div`
  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    fill: ${theme.color.ruubyBlack};
    transition: all ${theme.transition.linear03};
  }

  a {
    display: flex;
    align-items: center;
    font-size: ${(props: IProps) => theme.fontSize[props.fontSize]};
    font-weight: 400;
    color: ${theme.color.ruubyBlack};
    text-decoration: none;
    transition: all ${theme.transition.linear03};

    @media screen and (max-width: 992px) {
      font-size: ${(props: IProps) => theme.fontSize[props.mobileFontSize]};
    }
  }

  &:hover {
    svg {
      fill: ${theme.color.ruubyRoseDark};
    }
  }
`;

export const Mail: React.FC<IProps> = (props) => {
  return (
    <MailWrapper {...props}>
      <NavItem href="mailto:concierge@ruuby.com">
        <IconMail />
        concierge@ruuby.com
      </NavItem>
    </MailWrapper>
  );
};
