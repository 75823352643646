import styled from "styled-components";

interface IProps {
  background?: string;
  limitedWidth?: boolean;
  offsetPaddings?: boolean;
  offsetVerticalPaddings?: boolean;
  noPadding?: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props: IProps) => (props.noPadding ? 0 : "0 5%")};
  margin-right: auto;
  margin-left: auto;

  ${(props: IProps) => props.background && `background: ${props.background}`};

  ${(props: IProps) => props.offsetPaddings && `padding: 30px 5%;`};

  ${(props: IProps) =>
    props.offsetVerticalPaddings &&
    `
    padding: 30px 5%;
  `};

  /**
   * Limited width for container regarding screen size
   */
  ${(props: IProps) =>
    props.limitedWidth &&
    `
    @media screen and (min-width: 1200px) {
      max-width: 1140px;

      ${props.offsetVerticalPaddings && `padding: 50px 0`}
    };
    @media screen and (min-width: 2100px) {
      max-width: 1180px;

      ${props.offsetVerticalPaddings && `padding: 50px 0`}
    };

    @media screen and (min-width: 2100px) {
      max-width: 1250px;

      ${props.offsetVerticalPaddings && `padding: 50px 0`}
    };
  `}
`;

export { Container };
