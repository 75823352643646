import styled from "styled-components";

import { theme } from "../../../../theme";

const OrderSummarySection = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${theme.color.inputBorderColor};
`;

export { OrderSummarySection };
