import * as React from "react";
import styled from "styled-components";

import { Button, SimpleLoader } from "../../../../components/atoms";

export interface IOrderSummaryButtonProps {
  isCheckLoading?: boolean;
  onCheckout?(): void;
  className?: string;
}

const OrderSummaryButtonContainer = styled(Button)`
  width: 100%;

  &:last-child {
    margin-bottom: 20px;
  }
`;

const OrderSummaryButton: React.FC<IOrderSummaryButtonProps> = (props) => {
  return (
    <OrderSummaryButtonContainer
      background="blue"
      size="small"
      onClick={props.onCheckout}
      disabled={props.isCheckLoading}
      className={props.className}
    >
      {props.isCheckLoading ? <SimpleLoader /> : "Checkout"}
    </OrderSummaryButtonContainer>
  );
};

export { OrderSummaryButton };
