import { IServiceTreatment } from "../../services/api/therapists";
import {
  getFeaturedTreatments,
  getFeaturedTreatmentsLists,
  IApiFeaturedTreatmentsList,
  IFeaturedTreatment,
} from "../../services/api/treatments";
import { ActionDispatch } from "../index";
import { deNormalizeArray } from "../utils";

import * as types from "./action-types";

export interface IFeaturedTreatmentsService {
  [urn: string]: IFeaturedTreatment[];
}

export interface IFeaturedTreatmentsList {
  [urn: string]: IApiFeaturedTreatmentsList;
}

export const actionCreators = {
  addTreatment: (data: IServiceTreatment) => ({
    payload: { data },
    type: types.ADD_TREATMENT,
  }),
  setTreatments: (data: { [urn: string]: IServiceTreatment }) => ({
    payload: { data },
    type: types.SET_TREATMENTS,
  }),
  clearErrors: () => ({
    type: types.CLEAR_ERRORS,
  }),
  clearTreatments: () => ({
    type: types.CLEAR_TREATMENTS,
  }),
  getFeaturedTreatmentsAttempt: () => ({
    type: types.GET_FEATURED_TREATMENTS_ATTEMPT,
  }),
  getFeaturedTreatmentsError: () => ({
    type: types.GET_FEATURED_TREATMENTS_ERROR,
  }),
  getFeaturedTreatmentsListAttempt: () => ({
    type: types.GET_FEATURED_TREATMENTS_LIST_ATTEMPT,
  }),
  getFeaturedTreatmentsListError: () => ({
    type: types.GET_FEATURED_TREATMENTS_LIST_ERROR,
  }),
  getFeaturedTreatmentsListSuccess: (data: IFeaturedTreatmentsList) => ({
    payload: { data },
    type: types.GET_FEATURED_TREATMENTS_LIST_SUCCESS,
  }),
  getFeaturedTreatmentsSuccess: (data: IFeaturedTreatmentsService) => ({
    payload: { data },
    type: types.GET_FEATURED_TREATMENTS_SUCCESS,
  }),
  removeTreatment: (data: string) => ({
    payload: { data },
    type: types.REMOVE_TREATMENT,
  }),
};

/**
 * Dispatch featured treatments list action for reducer
 * Call API to get featured treatments list
 */
export function getFeaturedTreatmentsListData(): ActionDispatch<void> {
  return async (dispatch) => {
    dispatch(actionCreators.getFeaturedTreatmentsListAttempt());

    try {
      const data = await getFeaturedTreatmentsLists();
      const reducedList = deNormalizeArray(data, "urn");

      dispatch(actionCreators.getFeaturedTreatmentsListSuccess(reducedList));
    } catch (error) {
      dispatch(actionCreators.getFeaturedTreatmentsListError());
    }
  };
}

/**
 * Dispatch featured treatments action for reducer
 * Call API to get featured treatments details
 */
export function getFeaturedTreatmentsData(urn: string): ActionDispatch<void> {
  return async (dispatch, getState) => {
    dispatch(actionCreators.getFeaturedTreatmentsAttempt());

    try {
      if (!getState) {
        throw new Error("Could not get state");
      }

      const categories = getState().categoriesState.allCategories;

      const response = await getFeaturedTreatments(urn, categories);
      const data = { [urn]: response };

      dispatch(actionCreators.getFeaturedTreatmentsSuccess(data));
    } catch (error) {
      dispatch(actionCreators.getFeaturedTreatmentsError());
    }
  };
}
