import React, { forwardRef } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  border: 0px;
  border-bottom: solid 2px #eff0f2;
  font-family: ${({ theme }) => theme.type.fonts.main};
  color: ${({ theme }) => theme.colours.textMain};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  width: 100%;
  padding-bottom: 10px;
  height: 26px;
  &::placeholder {
    font-style: italic;
  }
  &:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colours.background.white};
  }
`;

export const Input = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  return <StyledInput {...props} ref={ref} />;
});
