import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { SimpleLoader } from "../../components/atoms";
import { FeaturedTreatmentsScroller } from "../../components/organisms";
import { IRootState } from "../../store/index";
import {
  getFeaturedTreatmentsListData,
  IFeaturedTreatmentsList,
} from "../../store/treatments/actions";
import { normalizeObjects } from "../../store/utils";

interface IProps {
  background?: string;
}

interface IReduxProps {
  featuredTreatmentsList?: IFeaturedTreatmentsList;
  isFeaturedTreatmentsListLoading: boolean;
}

type Props = IProps & IReduxProps & { dispatch: Dispatch<any> };

class FeaturedTreatmentsListsContainer extends React.Component<Props> {
  public componentDidMount() {
    this.props.dispatch(getFeaturedTreatmentsListData());
  }

  /**
   * Render featured treatments scroller component
   */
  public render() {
    if (this.props.featuredTreatmentsList) {
      const featuredTreatmentsList = normalizeObjects(
        this.props.featuredTreatmentsList,
      );

      return this.props.isFeaturedTreatmentsListLoading ? (
        <SimpleLoader />
      ) : (
        <React.Fragment>
          {featuredTreatmentsList.length > 0 ? (
            <FeaturedTreatmentsScroller
              list={featuredTreatmentsList}
              background={this.props.background}
            />
          ) : // tslint:disable-next-line: no-null-keyword
          null}
        </React.Fragment>
      );
    }
  }
}

// store
const mapStateToProps = (state: IRootState): IReduxProps => {
  return {
    featuredTreatmentsList: state.treatmentsState.featuredTreatmentsList,
    isFeaturedTreatmentsListLoading:
      state.treatmentsState.isFeaturedTreatmentsListLoading,
  };
};

const store = connect<IReduxProps, {}, {}, IRootState>(mapStateToProps)(
  FeaturedTreatmentsListsContainer,
);

export { store as FeaturedTreatmentsListsContainer };
