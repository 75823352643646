import * as lottie from "lottie-web";
import * as React from "react";

import { Loader } from "../../components/atoms";
import loaderJson from "../../components/atoms/loader/ruuby_logo_loader.json";
import { uniqueId } from "../../utils/uniqueId";

interface IProps {
  type: "block" | "overlay";
  centerBlock?: boolean;
  fullHeight?: boolean;
}

class LoaderContainer extends React.Component<IProps> {
  private readonly generatedId = uniqueId("loader-");

  public componentDidMount() {
    lottie.loadAnimation({
      animationData: loaderJson,
      autoplay: true,
      container: document.getElementById(this.generatedId),
      loop: true,
      renderer: "svg",
    });
  }

  public componentWillUnmount() {
    lottie.stop();
  }

  // tslint:disable-next-line: completed-docs
  public render() {
    return (
      <Loader
        type={this.props.type}
        centerBlock={this.props.centerBlock}
        fullHeight={this.props.fullHeight}
        id={this.generatedId}
      />
    );
  }
}

export { LoaderContainer };
