import * as React from "react";
import { Helmet } from "react-helmet";

import { Bridal } from "../../../components/organisms";
import { MainTemplate } from "../../../components/templates/main";
import { metaData } from "../../../static/meta";

export const BridalPage: React.FC = () => {
  return (
    <MainTemplate backgroundColor="white">
      <Helmet>
        <title>{metaData.bridal.title}</title>
        <meta name="description" content={metaData.bridal.description} />
      </Helmet>
      <Bridal />
    </MainTemplate>
  );
};
