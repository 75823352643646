import React from "react";
import styled from "styled-components";

import { device } from '../../../../../../utils';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.size32} 0;
  white-space: pre-line;
  border-top: 1px solid ${({ theme }) => theme.colours.footerBorderColor};
`;

const FooterText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.sectionPanel.text};
  text-align: center;
  margin-left: ${({ theme }) => theme.spacing.size18};
  margin-right: ${({ theme }) => theme.spacing.size18};
  padding-bottom: ${({ theme}) => theme.spacing.size12};

  @media ${device.tablet} {
    margin: 0;
    padding-bottom: 0;
  }
`;

export const PaymentFooter = (): JSX.Element => (
  <FooterContainer>
    <FooterText>
      You have been sent to this page because you have recently booked with
      Ruuby. <br />
      If you did not expect to be directed here please call 02074608319
    </FooterText>
  </FooterContainer>
);
