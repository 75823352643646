import React from "react";
import styled from "styled-components";

import { Column, Container, Row } from "../../../grid";
import { Heading1, Section } from "../../atoms";
import { DownloadAppPanel } from "../../molecules";

import { PartnersPanel } from "../../pages/corporate/components/partners-panel";
import { GetInTouchButton } from "../../atoms/events/get-in-touch-button";
import { EventHeader } from "../../molecules/events/header";
import { EventSection } from "../../molecules/events/section";

import photo1 from "../../../../assets/images/corporate/wellness.webp";
import photo2 from "../../../../assets/images/corporate/incentives.webp";
import photo3 from "../../../../assets/images/corporate/gifting.webp";

const PartnerSection = styled(Section)`
  padding: 20px 0;
`;

const PartnersHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colours.background.white};
  text-align: center;
`;

const PartnersContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
`;

const contactHRef="https://ruuby.typeform.com/to/HvHs1GrF";

export const Corporate: React.FC = () => (
  <>
    <EventHeader
      variant="dark"
      title="Employee Wellness"
      content="Elevate your corporate wellness with Ruuby."
      href={contactHRef}
    />

    <EventSection
      variant="light"
      title="Elevate your Corporate Wellness"
      content="Our tailored corporate wellness solutions offer benefits both in and out of the office. Whether it’s celebrating company milestones, honouring events such as International Women’s Day, or simply adding flair to company parties, our dedicated workplace wellness team can create bespoke packages for your business."
      flipImageAndText={true}
      tallerInTabletMode={true}
      backgroundPositionYDesktop="40%"
      photo={photo1}
    />

    <EventSection 
      variant="dark"
      title="In-Office Incentives"
      content="Ruuby makes corporate wellness and office perks more accessible than ever. From express chair massages and ear-piercings, to manis and blow dries on a Friday, give your team something to look forward to on their office working days."
      tallerInTabletMode={true}
      photo={photo2}
    />

    <EventSection 
      variant="light"
      title="Corporate Gifting"
      content="Spoil your team and clients with vouchers from £10 to £1000, redeemable for any treatment for their choice. Ruuby vouchers offer flexibility, allowing recipients to enjoy their preferred services at their convenience. Customise the vouchers to align with your brand for a personalised touch."
      flipImageAndText={true}
      tallerInTabletMode={true}
      photo={photo3}
    />

    <PartnerSection colour="darkGrey">
      <PartnersContainer>
        <Row justifyContent="center">
          <Column>
            <PartnersHeading>A Trusted Partner to leading businesses</PartnersHeading>
          </Column>
        </Row>
        <PartnersPanel />
      </PartnersContainer>
    </PartnerSection>

    <Section colour="white">
      <Container>
        <Row justifyContent="center">
          <GetInTouchButton variant="dark" href={contactHRef} />
        </Row>
      </Container>
    </Section>

    <Section colour="white" noTopPadding>
      <Container noPadding>
        <Row justifyContent="center" noMargin>
          <Column justifyContent="center">
            <DownloadAppPanel colour="blueGrey" />
          </Column>
        </Row>
      </Container>
    </Section>
  </>
);
