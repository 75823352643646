import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { TherapistImages } from "../therapist-images";
import { Card } from "../../../../../atoms";
import { TherapistTabs } from "../therapist-tabs";
import { TherapistServices } from "../therapist-services";
import { MinimumFee } from "../minimum-fee";
import { Tab } from "../therapist-tabs/types";
import { TherapistReviews } from "../therapist-reviews";
import { ApiError } from "../../../../../../services/api/types/graphql";
import { AddedTreatment } from "../../hooks/use-handle-treatments";
import { device } from "../../../../../../utils";
import { Review, TherapistTreatment } from "../../types";
import { TherapistProducts } from "../therapist-products";
import { TreatmentRow } from "../treatment-row";
import { CheckoutButtonContainer } from "../checkout-button-container";

const PortfolioCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopOfContent = styled.div`
  scroll-margin-top: 100px;
`;

const TherapistReviewsContainer = styled.div`
  display: block;

  @media ${device.laptop} {
    display: none;
  }
`;

const MinimumFeeContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.size18};
`;

interface Props {
  therapistId: string;
  images: string[];
  treatments: TherapistTreatment[];
  products?: string;
  reviewItems: Review[];
  reviewsError?: ApiError;
  isLoading: boolean;
  areReviewsLoading?: boolean;
  selectedCategoryUrn?: string;
  addedTreatments: AddedTreatment;
  isCheckoutLoading: boolean;
  onAddTreatment(treatmentUrn: string): void;
  onRemoveTreatment(treatmentUrn: string): void;
  onCheckout(): void;
  loadMoreReviews?(): Promise<void>;
}

export const TherapistPortfolio = ({
  treatments,
  reviewItems,
  reviewsError,
  isLoading,
  isCheckoutLoading,
  areReviewsLoading,
  addedTreatments,
  selectedCategoryUrn,
  images,
  products,
  onAddTreatment,
  onRemoveTreatment,
  onCheckout,
  loadMoreReviews,
}: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState<Tab>(Tab.SERVICES);
  const [hasSelectedActiveTab, setHasSelectedActiveTab] = useState(false);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const contentTopRef = useRef<HTMLDivElement>(null);

  const content = useMemo((): React.ReactNode => {
    if (isLoading) {
      return Array.apply(null, Array(5)).map((_, i) => (
        <TreatmentRow key={i} />
      ));
    }

    switch (activeTab) {
      case Tab.SERVICES:
        return (
          <TherapistServices
            therapistTreatments={treatments}
            selectedCategoryUrn={selectedCategoryUrn}
            addedTreatments={addedTreatments}
            onAddTreatment={onAddTreatment}
            onRemoveTreatment={onRemoveTreatment}
          />
        );
      case Tab.IMAGES:
        return images.length ? <TherapistImages images={images} /> : null;
      case Tab.PRODUCTS:
        return products ? <TherapistProducts products={products} /> : null;
      case Tab.REVIEWS:
        return (
          <TherapistReviewsContainer>
            {reviewItems.length > 0 && (
              <TherapistReviews
                reviews={reviewItems}
                error={reviewsError}
                isLoadingMore={areReviewsLoading}
                isLoading={isLoading}
                onClickLoadMore={loadMoreReviews}
              />
            )}
          </TherapistReviewsContainer>
        );
    }
  }, [
    activeTab,
    treatments,
    reviewItems,
    reviewsError,
    areReviewsLoading,
    selectedCategoryUrn,
    addedTreatments,
    onAddTreatment,
    onRemoveTreatment,
    loadMoreReviews,
    images,
  ]);

  useEffect(() => {
    const tabs = [Tab.SERVICES];

    if (reviewItems.length) {
      tabs.push(Tab.REVIEWS);
    }
    if (images.length) {
      tabs.push(Tab.IMAGES);
    }
    if (products) {
      tabs.push(Tab.PRODUCTS);
    }

    setTabs(tabs);
  }, [images.length, reviewItems.length, products]);

  useEffect(() => {
    if (contentTopRef.current && hasSelectedActiveTab) {
      contentTopRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [hasSelectedActiveTab, activeTab]);

  const handleClickTab = (tab: Tab) => {
    setActiveTab(tab);

    if (!hasSelectedActiveTab) {
      setHasSelectedActiveTab(true);
    }
  };

  return (
    <div>
      <PortfolioCard>
        <TherapistTabs
          isLoading={isLoading}
          tabs={tabs}
          activeTab={activeTab}
          onClickTab={handleClickTab}
        />
        <TopOfContent ref={contentTopRef} />
        {content}
      </PortfolioCard>

      {!isLoading && activeTab === Tab.SERVICES && (
        <>
          <MinimumFeeContainer>
            <MinimumFee />
          </MinimumFeeContainer>

          <CheckoutButtonContainer
            isLoading={isCheckoutLoading}
            onCheckout={onCheckout}
            addedTreatments={addedTreatments}
            treatments={treatments}
          />
        </>
      )}
    </div>
  );
};
