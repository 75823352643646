import React, { useEffect } from "react";
import { rem } from "polished";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { config } from "../../../../config";
import {
  Heading1,
  HeroVideoContainer,
  Section,
} from "../../../../components/themed-v2/atoms";
import {
  DownloadAppPanel,
  HeroPanel,
  RatingAppStore,
} from "../../../../components/themed-v2/molecules";
import {
  CategoryTilesCarousel,
  CustomerReviews,
  FeaturedCarousel,
  FeaturedPanel,
  ProcessWidget,
} from "../../../../components/themed-v2/organisms";
import { useHomePageData } from "./use-home-page-data";
import { HomeSubscribeModalContainer } from "../../../../containers/home/home-subscribe";
import { SearchWidget } from "../../organisms/search-widget";
import { AnchorElement } from "../../atoms/anchor-element";

const RatingAppStoreStyled = styled(RatingAppStore)`
  margin-top: ${rem("24px")};
`;

const SpecialLink = styled.a`
  color: ${({ theme }) => theme.colours.textMain};
  text-decoration: none;
  & > span {
    text-decoration: underline;
  }

  :hover {
    & > span {
      text-decoration: none;
    }
  }
`;

interface IProps {
  useData?: typeof useHomePageData;
}

export const HomePage = ({
  useData = useHomePageData,
}: IProps): JSX.Element => {
  const {
    featureTreatments,
    mostPopularTreatments,
    reviews,
    handleClickButton,
    specialLink,
  } = useData();

  // Scroll to any URL anchor
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const paragraphs: React.ComponentProps<typeof HeroPanel>["paragraphs"] = [
    "Available in London, Manchester, Surrey, and The Cotswolds.",
    <SpecialLink href="/switzerland">
      🇨🇭 Ruuby is now in Switzerland! 🇨🇭 Book{" "}
      <span>here</span>.
    </SpecialLink>,
  ];

  if (specialLink) {
    paragraphs.push(
      <SpecialLink href={specialLink} target="_blank">
        Now also available in Meribel and Courchevel. Click <span>here</span> to
        book.
      </SpecialLink>,
    );
  }

  return (
    <>
      <HeroVideoContainer video={config.heroVideoUrl}>
        <Container>
          <Row justify="center">
            <Col lg={9}>
              <HeroPanel
                heading="Book five star beauty services, direct to your door"
                paragraphs={paragraphs}
              >
                <RatingAppStoreStyled />

                <SearchWidget />
              </HeroPanel>
            </Col>
          </Row>
        </Container>
      </HeroVideoContainer>

      <Section colour="white">
        <Container>
          <Row>
            <Col>
              <CategoryTilesCarousel />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section colour="white" noTopPadding>
        <Container>
          <Row>
            <Col>
              <Heading1>Our Most Popular Treatments</Heading1>
              <FeaturedCarousel
                carouselKey="most-popular-treatments"
                items={mostPopularTreatments}
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section colour="blueGrey">
        <ProcessWidget handleClickButton={handleClickButton} />
      </Section>

      <AnchorElement id="featured-treatments" />
      <Section colour="white">
        <Container>
          <Row>
            <Col>
              <Heading1 style={{ marginBottom: 0 }}>
                Featured Treatments
              </Heading1>
              <FeaturedCarousel
                carouselKey="featured-treatments"
                items={featureTreatments}
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section colour="darkGrey">
        <CustomerReviews
          heading="Experience the at-home beauty app that customers rate five stars"
          reviews={reviews}
        />
        <FeaturedPanel />
      </Section>

      <Section colour="white">
        <Container>
          <Row justify="center">
            <DownloadAppPanel />
          </Row>
        </Container>
      </Section>
      <HomeSubscribeModalContainer />
    </>
  );
};
