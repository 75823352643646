import { getDistrictCode } from "@ruuby/common/lib/utils/postcodes";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { TherapistListingQueryKey } from "../../../../utils/search";
import { Button } from "../../atoms";
import { ErrorText } from "../../atoms/errors/error-text";
import { CategoryField } from "./components/category-field";
import { DateField } from "./components/date-field";
import { PostcodeField } from "./components/postcode-field";
import { device } from "../../../../utils";

const ERROR_MESSAGE_NO_POSTCODE = "Please enter a postcode";
const ERROR_MESSAGE_INVALID_POSTCODE = "Please enter a valid postcode";
const ERROR_MESSAGE_NO_CATEGORY = "Please select a category";
const ERROR_MESSAGE_NO_DATE = "Please select a date";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.size24};
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.size20};

  @media ${device.tablet} {
    min-width: 388px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: ${({ theme }) => theme.spacing.size20};
  padding: ${({ theme }) => theme.spacing.size10} 0;
`;

const StyledButton = styled(Button)`
  width: 100%;

  &:active {
    ${({ theme, disabled }) =>
      !disabled && `background-color: ${theme.colours.ruubyLightGrey}`};
  }
`;

interface Props {
  category?: string;
  featuredTreatmentUrn?: string;
}

export const SearchWidget = ({ category, featuredTreatmentUrn }: Props): JSX.Element => {
  const history = useHistory();
  const [error, setError] = useState<string | undefined>();
  const [postcode, setPostcode] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [searchCategory, setCategory] = useState<string | undefined>(undefined);

  const handleClick = useCallback((): void => {
    const selectedCategory = searchCategory ?? category;

    if (!featuredTreatmentUrn && !selectedCategory) {
      return setError(ERROR_MESSAGE_NO_CATEGORY);
    }

    if (!postcode) {
      return setError(ERROR_MESSAGE_NO_POSTCODE);
    } else if (!Boolean(getDistrictCode(postcode))) {
      return setError(ERROR_MESSAGE_INVALID_POSTCODE);
    }

    if (!date) {
      return setError(ERROR_MESSAGE_NO_DATE);
    }

    setError(undefined);

    const params = new URLSearchParams({
      [TherapistListingQueryKey.POSTCODE]: postcode,
      [TherapistListingQueryKey.DATE]: date,
      [TherapistListingQueryKey.TIME]: "all",
    });

    if (featuredTreatmentUrn) {
      params.set(TherapistListingQueryKey.TREATMENT_URN, featuredTreatmentUrn);
    } else if (selectedCategory) {
      params.set(TherapistListingQueryKey.CATEGORY, selectedCategory);
    }

    history.push(`/book?${params.toString()}`);
  }, [featuredTreatmentUrn, postcode, searchCategory, date, setError]);

  const handlePostcodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setPostcode(event.target.value);
      if (error && error !== ERROR_MESSAGE_NO_CATEGORY) {
        setError(undefined);
      }
    },
    [error],
  );

  const handleCategorySelect = useCallback((text: string) => {
    setCategory(text);
    setError(undefined);
  }, []);

  return (
    <Container>
      {!category && !featuredTreatmentUrn && (
        <CategoryField
          onSelect={handleCategorySelect}
          value={searchCategory ?? ""}
        />
      )}
      <PostcodeField onChange={handlePostcodeChange} value={postcode} />
      <div>
        <DateField onChange={setDate} value={date} />
        <ErrorContainer>
          {error && <ErrorText>{error}</ErrorText>}
        </ErrorContainer>
        <StyledButton onClick={handleClick}>Book now</StyledButton>
      </div>
    </Container>
  );
};
