import React from "react";

import { IconChevron } from "../../atoms";
import { ButtonInnerContainer } from "../../atoms/button-inner-container";
import { ButtonStickyContainer } from "../../atoms/button-sticky-container";
import { ButtonIconContainer } from "../../atoms/button-icon-container";
import { ButtonText } from "../../atoms/button-text";
import { ButtonStyled } from "../../atoms/button-styled";


interface Props {
  isDisabled: boolean;
  onClick(): void;
}

export const FloatingCtaButton = ({
  isDisabled,
  onClick,
}: Props): JSX.Element => (
  <ButtonStickyContainer>
    <ButtonStyled size="small" onClick={onClick} disabled={isDisabled}>
      <ButtonInnerContainer>
        <ButtonText>Continue to Checkout</ButtonText>
        <ButtonIconContainer>
          <IconChevron />
        </ButtonIconContainer>
      </ButtonInnerContainer>
    </ButtonStyled>
  </ButtonStickyContainer>
);
