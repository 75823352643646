import React, { useCallback, useMemo } from "react";
import { parseISO } from "date-fns";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

import { useBookingCheckoutContext } from "../../provider";
import { device } from "../../../../../../utils";
import {
  IconCalendarDate,
  IconHome,
  IconPencil,
  InnerButton,
  Text,
  IconPerson,
} from "../../../../atoms";
import { Row } from "../row";
import { Section } from "../section";
import { useAddress } from "../../hooks/use-address";
import { DateTime } from "luxon";

const Item = styled(Text)`
  padding-left: 35px;
  width: 100%;
`;

const Notes = styled.textarea`
  border: 0px;
  width: 100%;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  padding: 5px 0px;
  outline: none;
  resize: none;
  &::placeholder {
    font-style: italic;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
  @media ${device.laptop} {
    padding-right: 60px;
  }
`;

export const CheckoutSection = (): JSX.Element => {
  const history = useHistory();
  const { bookingParams, updateBookingParams, isBookingBeingPlaced } = useBookingCheckoutContext();
  const { address, isLoading } = useAddress();
  
  const handleClickChangeAddress = useCallback(() => {
    if (!isBookingBeingPlaced) {
      history.push("/address/booking/select");
    }
  }, [isBookingBeingPlaced]);

  const bookingDate = useMemo(() => parseISO(bookingParams.bookingDate), [bookingParams.bookingDate]);

  const handleUpdateNotes = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      updateBookingParams({ notes: e.target.value });
    },
    [updateBookingParams]
  );
  
  return (
    <Section>
      {bookingParams.isTherapistMobile ? (
        <>
          <Row gap>
            <IconHome width="20px" height="20px" />
            <Text weight="bold" flexGrow={1}>
              Booking Address
            </Text>
            <InnerButton onClick={handleClickChangeAddress} disabled={isLoading}>
              {address ? "Change" : "Add"}
            </InnerButton>
          </Row>
          {address && (
            <Row gap>
              <Item>
                {isLoading ? <Skeleton count={1} height={23} /> : address}
              </Item>
            </Row>
          )}
        </>
      ) : null}
      <Row gap marginTop>
        <IconCalendarDate width="20px" height="23px" />
        <Text weight="bold">Appointment Details</Text>
      </Row>
      <Row gap>
        <Item>
          {isLoading ? (
            <Skeleton count={1} height={23} />
          ) : (
            DateTime.fromJSDate(bookingDate).toFormat("cccc LLLL dd - h:mm a")
          )}
        </Item>
      </Row>
      <Row gap>
        {isLoading ? (
          <Skeleton count={1} height={23} />
        ) : (
          <>
            <IconPerson width="20px" height="23px" />
            <Text>{bookingParams.therapistName}</Text>
          </>
        )}
      </Row>
      <Row gap marginTop>
        <IconPencil width="20px" height="14px" />
        <Text weight="bold">Special Instructions</Text>
      </Row>
      <Row gap display="contents">
        <Item>
          <Notes
            placeholder="Parking, address clarification, special requests for your booking etc."
            onChange={handleUpdateNotes}
            value={bookingParams.notes}
          />
        </Item>
      </Row>
    </Section>
  );
};
