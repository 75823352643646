import { convert, UrnResource } from "@ruuby/common/lib/utils/urn";
import { config } from "../config";
import { Category } from "../types";

export const categories = [
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.MASSAGE]),
    name: "Massage",
    id: Category.MASSAGE,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.HAIR]),
    name: "Hair",
    id: Category.HAIR,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.NAILS]),
    name: "Nails",
    id: Category.NAILS,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.TANNING]),
    name: "Tanning",
    id: Category.TANNING,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.MAKEUP]),
    name: "Makeup",
    id: Category.MAKEUP,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.FACIAL]),
    name: "Facial",
    id: Category.FACIAL,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.WAXING]),
    name: "Waxing",
    id: Category.WAXING,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.EYES]),
    name: "Eyes",
    id: Category.EYES,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.WELLNESS]),
    name: "Wellness",
    id: Category.WELLNESS,
  },
  {
    urn: convert(UrnResource.TREATMENT_CATEGORY, [Category.AESTHETICS]),
    name: "Aesthetics",
    id: Category.AESTHETICS,
  },
];

export const defaultTimeOptions: [keyof typeof config.dateTime.intervals, string][] = [
  ["all", "All"],
  ["am", "Morning"],
  ["pm", "Afternoon"],
  ["eve", "Evening"],
];

export enum TherapistListingQueryKey {
  CATEGORY = "category",
  POSTCODE = "postcode",
  DATE = "date",
  TIME = "time",
  TREATMENT_URN = "treatmentUrn",
}