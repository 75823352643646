import React from "react";
import styled from "styled-components";

const SavePaymentMethodContainer = styled.div`
  margin-top: ${({ theme }) => theme.type.sizes.size20};
`;

const SavePaymentMethodInput = styled.input`
  width: ${({ theme }) => theme.type.sizes.size20};
  height: ${({ theme }) => theme.type.sizes.size20};
  vertical-align: middle;
`;

const SavedPaymentMethodLabel = styled.label`
  color: ${({ theme }) => theme.colours.darkGrey};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  margin-left: ${({ theme }) => theme.type.sizes.size8};
  vertical-align: middle;
`;

interface Props {
  checked?: boolean;
  onChange?: () => void;
}

export const SavePaymentMethod = ({ checked, onChange }: Props): JSX.Element => (
  <SavePaymentMethodContainer>
    <SavePaymentMethodInput
      type="checkbox"
      id="save-payment-method"
      checked={checked}
      onChange={onChange}
    /> 
    <SavedPaymentMethodLabel htmlFor="save-payment-method">
      Save this method for future payments
    </SavedPaymentMethodLabel>
  </SavePaymentMethodContainer>
);
