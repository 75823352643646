import React from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as CapricheLogoImage } from "./capriche.svg";
import { ReactComponent as ChanelLogoImage } from "./chanel.svg";
import { ReactComponent as GucciLogoImage } from "./gucci.svg";
import { ReactComponent as MoriartLogoImage } from "./moriart.svg";
import { ReactComponent as SincuraLogoImage } from "./sincura.svg";
import { device } from "../../../../../../utils";

const ChanelLogo = styled(ChanelLogoImage)`
  order: 1;
  width: ${rem("122px")};
  height: ${rem("18px")};
  @media ${device.mobileL} {
    order: 0;
    width: ${rem("130px")};
    height: ${rem("19px")};
  }
  @media ${device.tablet} {
    width: ${rem("159px")};
    height: ${rem("24px")};
  }
`;

const CapricheLogo = styled(CapricheLogoImage)`
  order: 3;
  width: ${rem("127px")};
  height: ${rem("33px")};
  @media ${device.mobileL} {
    order: 4;
  }
  @media ${device.tablet} {
    order: 1;
    width: ${rem("154px")};
    height: ${rem("41px")};
  }
`;

const GucciLogo = styled(GucciLogoImage)`
  order: 0;
  width: ${rem("125px")};
  height: ${rem("25px")};
  @media ${device.mobileL} {
    order: 2;
    width: ${rem("136px")};
    height: ${rem("26px")};
  }
  @media ${device.tablet} {
    order: 2;
    width: ${rem("177px")};
    height: ${rem("36px")};
}
`;

const SincuraLogo = styled(SincuraLogoImage)`
  order: 2;
  width: ${rem("134px")};
  height: ${rem("55px")};
  @media ${device.mobileL} {
    order: 3;
    width: ${rem("137px")};
    height: ${rem("60px")};
  }
  @media ${device.tablet} {
    order: 3;
    width: ${rem("185px")};
    height: ${rem("83px")};
  }
`;

const MoriartLogo = styled(MoriartLogoImage)`
  order: 4;
  width: ${rem("109px")};
  height: ${rem("27px")};
  @media ${device.mobileL} {
    order: 1;
    width: ${rem("123px")};
    height: ${rem("30px")};
  }
  @media ${device.tablet} {
    order: 4;
    width: ${rem("144px")};
    height: ${rem("36px")};
  }
`;

const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > svg {
    @media ${device.mobileS} {
      margin: ${rem("10px")} ${rem("14px")};
    }
    @media ${device.mobileL} {
      margin: ${rem("10px")} ${rem("25px")};
    }
  }
`;

export const PartnersPanel = (): JSX.Element => (
  <Row>
    <ColStyled>
      <ChanelLogo />
      <CapricheLogo />
      <GucciLogo />
      <SincuraLogo />
      <MoriartLogo />
    </ColStyled>
  </Row>
);
