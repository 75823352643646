import { Tier } from "../../services/api/types/graphql";
import { Colours, theme as newTheme } from "../../theme/new";

type TierProperty = {
  colour: Colours;
  label: string;
};

type UseTierType = (params: { type: Tier }) => TierProperty;

// TODO Can we move this to @ruuby/common?
const tierTypes: Record<
  keyof Omit<typeof Tier, "HOSPITALITY" | "ON_DEMAND" | "PRODUCTIONS">,
  TierProperty | undefined
> = {
  [Tier.CLASSIC]: {
    colour: newTheme.colours.tierLabels.tiers.classic,
    label: "C",
  },
  [Tier.ELITE]: {
    colour: newTheme.colours.tierLabels.tiers.elite,
    label: "E",
  },
  [Tier.BLACK_LABEL]: {
    colour: newTheme.colours.tierLabels.tiers.blackLabel,
    label: "C",
  },
};

export const useTierType: UseTierType = ({ type }) => ({
  colour: tierTypes[type].colour,
  label: tierTypes[type].label,
});
