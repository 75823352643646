import { parseISO, intervalToDuration, Duration, isAfter } from "date-fns";
import { useState, useEffect } from "react";

type UseCountdown = (expirationDate: string) => {
  durationLeft: string;
  isCountdownFinished: boolean;
};

/**
 * Custom hook that creates a countdown towards a specified expiration date.
 * @param expirationDate A string representing the exact date and time when the countdown should expire in ISO format
 * @returns An object containing formatted duration left of the countdown, and a boolean indicating if the countdown has finished.
 *   - durationLeft: A string representing the remaining time in minutes and seconds (e.g., "01:42").
 *   - isCountdownFinished: A boolean value indicating whether the countdown has reached zero.
 *
 * Example usage:
 * ```tsx
 * const { durationLeft isCountdownFinished } = useCountdown("2023-10-20T14:00:00.000Z");
 *
 * return (
 *   <Text>
 *     Time left: {durationLeft}
 *   </Text>
 * );
 * ```
 */
export const useCountdown: UseCountdown = (expirationDate) => {
  const [durationLeft, setDurationLeft] = useState<string>('');
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);

  useEffect(() => {
    const formatDurationLeft = (duration: Duration) => {
      const pad = (num: number) => String(num).padStart(2, "0");
      const minutes = duration.minutes ?? 0;
      const seconds = duration.seconds ?? 0;
      return `${pad(minutes)}:${pad(seconds)}`;
    };

    const countdownInterval = setInterval(() => {
      if (expirationDate) {
        const expiration = parseISO(expirationDate);
        const now = new Date();
        if (isAfter(expiration, now)) {
          setIsCountdownFinished(false);
          const duration = intervalToDuration({
            start: now,
            end: expiration,
          });
          const formattedDuration = formatDurationLeft(duration);
          setDurationLeft(formattedDuration);
        } else {
          setIsCountdownFinished(true);
        }
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [expirationDate]);

  return {
    durationLeft,
    isCountdownFinished,
  };
};
