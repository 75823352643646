import { useEffect, useState } from "react";
import { fetchConfiguration } from "../../services/api/configuration";

export enum PaymentServiceType {
  BRAINTREE = "BRAINTREE",
  STRIPE = "STRIPE",
}

export const useGetPaymentServiceType = (): PaymentServiceType => {
  const [isBraintreeActive, setIsBraintreeActive] = useState(false);

  useEffect(() => {
    fetchConfiguration().then(({ IS_BRAINTREE_ACTIVE }) =>
      setIsBraintreeActive(IS_BRAINTREE_ACTIVE),
    );
  }, []);

  if (isBraintreeActive) {
    return PaymentServiceType.BRAINTREE;
  }

  return PaymentServiceType.STRIPE;
};
