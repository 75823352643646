import React from "react";
import styled from "styled-components";

import { Column, Container, Row } from "../../../grid";
import { Heading1, Section } from "../../atoms";
import { DownloadAppPanel } from "../../molecules";

import { GetInTouchButton } from "../../atoms/events/get-in-touch-button";
import { PartnersPanel } from "../../pages/events/components/partners-panel";
import { EventHeader } from "../../molecules/events/header";
import { EventSection } from "../../molecules/events/section";

import photo1 from "../../../../assets/images/events/events_facial.webp";
import photo2 from "../../../../assets/images/events/events_vip.webp";
import photo3 from "../../../../assets/images/events/events_runway.webp";
import photo4 from "../../../../assets/images/events/events_mirror.webp";

const PartnersHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colours.background.white};
  text-align: center;
`;

const PartnersContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 20px;
`;

const contactHRef="https://ruuby.typeform.com/to/HvHs1GrF";

export const Events: React.FC = () => (
  <>
    <EventHeader
      variant="light"
      title="Events"
      content="Elevate your event or special occasion with the unparalleled expertise of Ruuby's beauty and wellness professionals."
      href={contactHRef}
    />

    <EventSection
      variant="dark"
      title="Event Pop-Ups"
      content="Transform your event into an unforgettable experience by incorporating Ruuby's beauty stations, offering tailored treatments, express services, and exclusive party add-ons for your guests."
      photo={photo1}
    />

    <EventSection 
      variant="light"
      title="VIP Client Hospitality"
      content="As the preferred beauty provider for VIP clients and luxury brands, our concierge team excels in pre-event preparations, gifting experiences, and personalised styling sessions, ensuring a seamless and bespoke touch to every interaction."
      flipImageAndText={true}
      photo={photo2}
    />

    <EventSection 
      variant="dark"
      title="Campaigns and Shows"
      content="Partner with our team of globally acclaimed talent who have extensive experience in delivering runway, editorial and campaigns shoots."
      photo={photo3}
    />

    <EventSection 
      variant="light"
      title="Branded Experiences"
      content="Collaborate with Ruuby to create tailored treatment experiences to showcase your brand. From branded treatments to concept nail art, our team will work with you to execute your vision for press days, marketing activations, influencer appointments and large-scale events."
      backgroundPositionY="top"
      flipImageAndText={true}
      photo={photo4}
    />

    <Section colour="darkGrey">
      <PartnersContainer>
        <Row justifyContent="center">
          <Column>
            <PartnersHeading>A Trusted Partner to leading businesses</PartnersHeading>
          </Column>
        </Row>
        <PartnersPanel />
      </PartnersContainer>
    </Section>

    <Section colour="white">
      <Container>
        <Row justifyContent="center">
          <GetInTouchButton variant="dark" href={contactHRef} />
        </Row>
      </Container>
    </Section>

    <Section colour="white" noTopPadding>
      <Container noPadding>
        <Row justifyContent="center" noMargin>
          <Column justifyContent="center">
            <DownloadAppPanel colour="blueGrey" />
          </Column>
        </Row>
      </Container>
    </Section>
  </>
);
