import React from "react";
import styled from "styled-components";

import { device } from "../../../../../utils";
import { Section, EventTitle, EventText } from "../../../atoms";
import { EventHeaderProps } from "../header";

const PopupSection = styled(Section)`
  display: inline-flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px; 
  width: 100%;
`;

const ImageAndText = styled.div<{ mobileInvert?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.mobileInvert ? "column-reverse" : "column"};
  @media ${device.mobileL} {
    flex-direction: unset;
  }
  align-items: center;
  gap: unset;
  @media ${device.mobileL} {
    gap: 24px;
  }
  @media ${device.desktop} {
    gap: 30px;
  }
  margin: auto;
  max-width: 100%;
`;

const EventImage = styled.div<{ image: string, backgroundPositionY?: string, backgroundPositionYDesktop?: string, tall?: boolean }>`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  background: url(${(props) => props.image}) lightgray 50% / cover no-repeat;
  background-position-y: ${(props) => props.backgroundPositionY || "center"};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
  @media ${device.mobileL} {
    width: 540px;
    min-width: 309px;
    height: ${(props) => props.tall? "366px" : "300px"};
  }
  @media ${device.laptop} {
    background-position-y: ${(props) => props.backgroundPositionYDesktop || "center"};
    height: 300px;
  }
`;

const PopupText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 540px;
  margin: auto;
`;

interface EventSectionProps extends EventHeaderProps {
  photo: string,
  flipImageAndText?: boolean,
  backgroundPositionY?: string,
  backgroundPositionYDesktop?: string,
  tallerInTabletMode?: boolean,
}

const Image = (props: EventSectionProps) => {
  return (
    <EventImage 
      image={props.photo}
      backgroundPositionY={props.backgroundPositionY}
      backgroundPositionYDesktop={props.backgroundPositionYDesktop}
      tall={props.tallerInTabletMode}
    />
  );
}

export const EventSection = (props: EventSectionProps) => {
  const backgroundColor = props.variant === 'light'? 'blueGrey' : 'darkGrey';
  const textColor = props.variant === 'light'? 'main' : 'white';

  return (
    <PopupSection colour={backgroundColor}>
      <ImageAndText mobileInvert={!props.flipImageAndText}>
        {props.flipImageAndText && (
          <Image {...props} />
        )}
        <PopupText>
          <EventTitle color={textColor === 'main' ? undefined: textColor}>{props.title}</EventTitle>
          <EventText color={textColor}>{props.content}</EventText>
        </PopupText>
        {!props.flipImageAndText && (
          <Image {...props} />
        )}
      </ImageAndText>
    </PopupSection>
  );
}
