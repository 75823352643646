import * as React from "react";

interface IProps {
  type?: "full" | "empty" | "half";
}

// tslint:disable:max-line-length
const IconHeartFull = (
  <svg
    width="1792"
    height="1792"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26t-55.5-65.5-68-97.5-53.5-121-23.5-138q0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z" />
  </svg>
);

const IconHeartEmpty = (
  <svg
    width="1792"
    height="1792"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1664 596q0-81-21.5-143t-55-98.5-81.5-59.5-94-31-98-8-112 25.5-110.5 64-86.5 72-60 61.5q-18 22-49 22t-49-22q-24-28-60-61.5t-86.5-72-110.5-64-112-25.5-98 8-94 31-81.5 59.5-55 98.5-21.5 143q0 168 187 355l581 560 580-559q188-188 188-356zm128 0q0 221-229 450l-623 600q-18 18-44 18t-44-18l-624-602q-10-8-27.5-26t-55.5-65.5-68-97.5-53.5-121-23.5-138q0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344z" />
  </svg>
);

const IconHeartHalf = (
  <svg
    width="1792"
    height="1536"
    viewBox="0 0 1792 1536"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M852,1518,228,916q-10-8-27.5-26T145,824.5A884.912,884.912,0,0,1,77,727Q47,677,23.5,606T0,468Q0,248,127,124T478,0q62,0,126.5,21.5a511.993,511.993,0,0,1,120,58Q780,116,820,148a921.364,921.364,0,0,1,76,68,921.2,921.2,0,0,1,76-68q40-32,95.5-68.5a512,512,0,0,1,120-58Q1252,0,1314,0q224,0,351,124t127,344q0,221-229,450L940,1518q-18,18-44,18T852,1518Zm53.576-144.229L1476,824q188-188,188-356,0-81-21.5-143t-55-98.5Q1554,190,1506,167t-94-31a571.92,571.92,0,0,0-98-8q-52,0-112,25.5a511.136,511.136,0,0,0-110.5,64q-50.5,38.5-86.5,72T945,351a57.837,57.837,0,0,1-37.34,21.153C914.053,624.279,912.178,1118,905.576,1373.771Z" />
  </svg>
);

// tslint:enable
const IconHeart: React.FC<IProps> = ({ type }) => {
  switch (type) {
    case "empty":
      return IconHeartEmpty;
    case "half":
      return IconHeartHalf;
    default:
      return IconHeartFull;
  }
};

export { IconHeart };
