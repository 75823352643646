import * as React from "react";
import styled from "styled-components";

import { Header, Image, Text, NavItem } from "../../../../components/atoms";
import { Column, Container, Row } from "../../../grid";
import { theme } from "../../../../theme";

import circleEyes from "../../../../assets/images/about/circle_eyes.png";
import circleMakeup from "../../../../assets/images/about/circle_makeup.png";

const AboutEventsTextStyled = styled(Text)`
  margin-top: 20px;
`;

const AboutEventsImageWrapper = styled.div`
  position: relative;
`;

const AboutEventsImageWrapperText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${Header} {
    font-size: ${theme.fontSize.title};
    text-transform: uppercase;
  }
`;

const weddingsLink = "mailto:concierge@ruuby.com?subject=Ruuby Wedding Query";
const eventsLink = "mailto:concierge@ruuby.com?subject=Ruuby Events Query";

const AboutEvents: React.FC = () => {
  return (
    <Container background={theme.color.ruubyPink} offsetPaddings>
      <Row justifyContent="space-around">
        <Column md="5" textAlign="center">
          <NavItem type="link" href={weddingsLink}>
            <AboutEventsImageWrapper>
              <Image src={circleMakeup} />
              <AboutEventsImageWrapperText>
                <Header>Weddings</Header>
              </AboutEventsImageWrapperText>
            </AboutEventsImageWrapper>
          </NavItem>
          <AboutEventsTextStyled size="16" color="grey" lineHeight="1.5">
            Ruuby's team of professional hair and makeup artists are highly
            experienced, and offer the perfect service on a wedding day. Artists
            and hairdressers can be booked for both a trial (before the day),
            and the main event, to ensure happy parties all around! We offer
            packages for the bride, and the wedding party.
          </AboutEventsTextStyled>
        </Column>
        <Column md="5" textAlign="center">
          <NavItem type="link" href={eventsLink}>
            <AboutEventsImageWrapper>
              <Image src={circleEyes} />
              <AboutEventsImageWrapperText>
                <Header>Events</Header>
              </AboutEventsImageWrapperText>
            </AboutEventsImageWrapper>
          </NavItem>
          <AboutEventsTextStyled size="16" color="grey" lineHeight="1.5">
            Book Ruuby's highly skilled professionals to attend events. Whether
            it's a hen party, a fashion show, or other large events, we can
            curate a bespoke package to suit the event.
          </AboutEventsTextStyled>
        </Column>
      </Row>
    </Container>
  );
};

export { AboutEvents };
