import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";

import * as categories from "./categories/reducer";
import * as checkout from "./checkout/reducer";
import * as configuration from "./configuration/reducer";
import * as marketingMessage from "./marketing-message/reducer";
import * as payment from "./payments/reducer";
import * as postcodes from "./postcodes/reducer";
import * as therapists from "./therapists/reducer";
import * as treatments from "./treatments/reducer";
import * as user from "./user/reducer";

export interface IRootState {
  categoriesState: categories.IState;
  checkoutState: checkout.IState;
  configurationState: configuration.IState;
  marketingMessageState: marketingMessage.IState;
  paymentState: payment.IState;
  postcodesState: postcodes.IState;
  router: RouterState;
  therapistsState: therapists.IState;
  treatmentsState: treatments.IState;
  userState: user.IState;
}

export type ActionDispatch<R> = ThunkAction<
  R,
  IRootState,
  undefined,
  Action<unknown>
>;

// TODO: convert the action creators of the any reducers below to use 'createStandardAction' from typesafe-actions
export const createRootReducer = (history: History) =>
  combineReducers<IRootState>({
    categoriesState: categories.reducer,
    checkoutState: checkout.reducer,
    configurationState: configuration.reducer,
    marketingMessageState: marketingMessage.reducer,
    paymentState: payment.reducer,
    postcodesState: postcodes.reducer,
    router: connectRouter(history),
    therapistsState: therapists.reducer,
    treatmentsState: treatments.reducer,
    userState: user.reducer,
  });
