import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rem } from "polished";

import { theme } from "../../../theme";
import { Card } from "../../../components/themed-v2/atoms";
import { Text, Button, IconReferral } from "../../../components/atoms";

const ReferralItemCard = styled(Card)`
  padding: ${rem("12px")} ${rem("20px")} ${rem("12px")} ${rem("16px")};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
`;

const ReferralIconWrapper = styled.div`
  height: ${rem("22px")};
  margin-right: ${rem("15px")};

  svg {
    height: ${rem("22px")};
    width: ${rem("22px")};
    fill: ${theme.color.borderColor};

    path {
      fill: ${theme.color.borderColor};
    }
  }
`;

const ReferralCardContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${rem("12px")};
`;

const ReferralCardTextWrapper = styled.div`
  align-items: center;
`;

const ReferralCardButtonWrapper = styled.div`
  > button {
    border: none;
    border-radius: ${rem("3px")};
    text-transform: none;
    color: ${theme.color.ruubyGreyDark};
    padding: ${rem("2px")} ${rem("8px")} ${rem("4px")} ${rem("8px")};
    font-family: ${({ theme }) => theme.type.fonts.main};
    font-style: italic;
    font-weight: 400;
  }
`;

type Props = {
  creditAmount: number;
  referralCode: string;
};

export const ReferralCard = ({
  creditAmount,
  referralCode,
}: Props): JSX.Element => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isCopiedToClipboard) {
      timeoutId = setTimeout(() => {
        setIsCopiedToClipboard(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopiedToClipboard]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    setIsCopiedToClipboard(true);
  };

  const copyButtonText = isCopiedToClipboard ? "Copied" : "Copy";

  return (
    <ReferralItemCard>
      <ReferralCardContentWrapper>
        <ReferralIconWrapper>
          <IconReferral />
        </ReferralIconWrapper>
        <ReferralCardTextWrapper>
          <Text weight="bold">
            Refer a friend and get £{creditAmount} Treatment Credit
          </Text>
          <Text weight="bold">Your unique referral code: {referralCode}</Text>
        </ReferralCardTextWrapper>
      </ReferralCardContentWrapper>
      <ReferralCardButtonWrapper>
        <Button size="tiny" background="grey" onClick={handleCopyToClipboard}>
          {copyButtonText}
        </Button>
      </ReferralCardButtonWrapper>
    </ReferralItemCard>
  );
};
