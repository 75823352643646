import * as React from "react";
import styled from "styled-components";

import { Header, Text } from "../../../../components/atoms";
import { Column, Container, Row } from "../../../grid";
import { theme } from "../../../../theme";

const AboutTextH1 = styled(Text)`
  margin: 0;
`;

const AboutIntroHeaderStyled = styled(Header)`
  margin: 20px 0 40px;
  padding: 0 15%;
  color: ${theme.color.ruubyBlue};
  font-size: ${theme.fontSize.headingBigger};
  line-height: 1.5;
`;

const AboutIntro: React.FC = () => {
  return (
    <Container offsetPaddings>
      <Row>
        <Column textAlign="center">
          <AboutTextH1 weight="bold" size="18" color="grey" as="h1">
            About Ruuby
          </AboutTextH1>
          <AboutIntroHeaderStyled>
            Ruuby is London's first digital beauty concierge, offering five star
            beauty services to private clients, corporates, hotel groups, and
            luxury concierge services. Calling on our network of over 600
            professionally vetted, experienced beauty providers, we deliver
            consistently high-quality beauty services to our clients. Services
            can be booked at the home, office, or hotel, and we also cater to
            large events, weddings, and VIPs.
          </AboutIntroHeaderStyled>
        </Column>
      </Row>
    </Container>
  );
};

export { AboutIntro };
