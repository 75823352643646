import React from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { TherapistListingHeader } from "./components/organisms/therapist-listing-header";
import { useSearchFilter as useSearchFilterDefault } from "./hooks/use-search-filter";
import { useTherapistListing as useTherapistListingDefault } from "./hooks/use-therapist-listing";
import { TherapistListingCardsList } from "./components/organisms/therapist-listing-cards-list";
import { LoaderContainer } from "../../../../containers/common";
import { Tier } from "../../../../services/api/types/graphql";
import { device } from "../../../../utils";
import { ApiErrorMessage } from "../api-error-message";
import { getApiErrorText } from "../../../../errors";
import { TherapistListingTierList } from "./components/molecules/therapist-listing-tier-list";
import { Section } from "../../atoms";

const SectionStyled = styled(Section)`
  padding: ${({ theme }) => `${theme.spacing.size18} 0`};

  @media ${device.tablet} {
    padding-top: ${({ theme }) => theme.spacing.size32};
    padding-bottom: ${({ theme }) => theme.spacing.size36};
  }
`;

const LoaderOuterContainer = styled.div<{ isLoading: boolean }>`
  opacity: 0;
  max-height: 0;
  width: 0;
  transition: ${({ theme }) => `all ${theme.transition.easeInOut05}`};

  ${({ isLoading }) =>
    isLoading &&
    `
    width: 100%;
    opacity: 1;
    max-height: 120px;
    `};

  > div {
    position: unset;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SearchingMoreContainer = styled(ErrorContainer)`
  margin: 25px 0 15px;
  text-align: center;
`;

interface Props {
  useTherapistListing?: typeof useTherapistListingDefault;
  useSearchFilter?: typeof useSearchFilterDefault;
}

export const TherapistListingPage = ({
  useTherapistListing = useTherapistListingDefault,
  useSearchFilter = useSearchFilterDefault,
}: Props): JSX.Element => {
  const history = useHistory();

  const {
    filter,
    categoryOptions,
    timeOptions,
    handleChangeCategory,
    handleChangePostcode,
    handleChangeDate,
    handleChangeTime,
    isValidPostcode,
  } = useSearchFilter();

  const { therapists, error, isLoading, message } = useTherapistListing({
    ...(isValidPostcode(filter.postcode ?? "") && {
      postcode: filter.postcode,
    }),
    treatmentUrn: filter.treatmentUrn,
    category: filter.category,
    selectedDate: filter.date,
    selectedTime: filter.time,
  });

  const handleClickTherapist = (
    therapistUrnOrSlug: string,
    slotTime?: string,
  ) => {
    const searchParams = new URLSearchParams({
      date: filter.date,
      postcode: filter.postcode?.toUpperCase() ?? "",
      ...(slotTime && {
        time: slotTime,
      }),
    });

    history.push(
      `/book/${therapistUrnOrSlug}${
        !filter.treatmentUrn ? `/${filter.category}` : ""
      }?${searchParams.toString()}`,
    );
  };

  return (
    <>
      <Helmet>
        <title>Ruuby - Book Beauty Treatments Straight To Your Door</title>
      </Helmet>

      {filter && (
        <TherapistListingHeader
          categoryOptions={categoryOptions}
          timeOptions={timeOptions}
          filter={filter}
          isValidPostcode={isValidPostcode}
          onChangeCategory={handleChangeCategory}
          onChangePostcode={handleChangePostcode}
          onChangeDate={handleChangeDate}
          onChangeTime={handleChangeTime}
        />
      )}

      <SectionStyled colour="white">
        <Container style={{ maxWidth: "950px" }}>
          <Row align="end">
            <Col>
              <TherapistListingTierList
                tierList={[
                  [Tier.CLASSIC, "Classic"],
                  [Tier.ELITE, "Elite"],
                  [Tier.BLACK_LABEL, "Celebrity"],
                ]}
              />
            </Col>
          </Row>

          {message && (
            <SearchingMoreContainer>{message}</SearchingMoreContainer>
          )}

          <LoaderOuterContainer isLoading={isLoading && !error}>
            <LoaderContainer type="block" />
          </LoaderOuterContainer>

          <Row>
            {error ? (
              <ErrorContainer>
                <ApiErrorMessage
                  title="Oops! There was a problem fetching the therapists."
                  text={getApiErrorText(error)}
                />
              </ErrorContainer>
            ) : (
              therapists &&
              therapists.length > 0 && (
                <TherapistListingCardsList
                  therapists={therapists}
                  onClick={handleClickTherapist}
                />
              )
            )}
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
