import { WithClassName } from "../../../../components/themed-v2/types";
import React from "react";
import styled from "styled-components";

import { ReactComponent as IosImage } from "./ios.svg";
import { ReactComponent as AndroidImage } from "./android.svg";

const Android = styled(AndroidImage)`
  width: 135px;
  height: 40px;
`;

const Ios = styled(IosImage)`
  width: 120px;
  height: 40px;
`;

type Props = {
  type: "ios" | "android";
} & WithClassName;

export const IconStore = ({ type, className }: Props): JSX.Element =>
  type === "ios" ? <Ios class={className} /> : <Android class={className} />;
