import { IPromotion } from "../services/api/promotion";

interface PromoValue {
  percentage?: number;
  deduction: number;
}

export const getPromoValue = ({deduction, deductionType}: IPromotion, total: number): PromoValue => {
  if (deductionType === "percentage") {
    return {
      percentage: deduction,
      deduction: (total * deduction) / 100,
    };
  } else {
    return {
      deduction,
    };
  }
}