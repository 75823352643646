import * as React from "react";

// tslint:disable:max-line-length
const IconPerson = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="18"
      >
        <circle cx="9.00012" cy="9" r="9" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M9.00019 9.57488C11.1332 9.57488 12.8623 7.87918 12.8623 5.78744C12.8623 3.69569 11.1332 2 9.00019 2C6.86723 2 5.13812 3.69569 5.13812 5.78744C5.13812 7.87918 6.86723 9.57488 9.00019 9.57488Z"
          fill="#707378"
        />
        <path
          d="M9.00012 11.4692C5.13413 11.4692 2.00012 14.5427 2.00012 18.334H16.0001C16.0001 14.5427 12.8661 11.4692 9.00012 11.4692Z"
          fill="#707378"
        />
      </g>
    </svg>
  );
};

export { IconPerson };
