import * as React from "react";
import styled from "styled-components";

import { Button, Text } from "../../../atoms";
import { BookingAction } from "../../../../containers/account/account";
import { Column, Row } from "../../../grid";
import { IApiBooking } from "../../../../services/api/booking";
import { theme } from "../../../../theme";
import { dateFromIso } from "../../../../utils/index";

interface IProps {
  booking: IApiBooking;
  onBookingSelect(booking: IApiBooking, action: BookingAction): void;
  upcoming: boolean;
}

const BookingHistoryDetailsRow = styled(Row)`
  margin: 0;
  padding: 12px 0;
  border-bottom: 1px solid ${theme.color.inputBorderColor};
`;

const BookingHistoryDetailsWrapper = styled.div`
  padding: 17px 8px;

  ${BookingHistoryDetailsRow} {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
`;

const BookingHistoryDetails: React.FC<IProps> = (props) => {
  return (
    <BookingHistoryDetailsWrapper>
      {props.booking.status !== "UNCONFIRMED" && (
        <BookingHistoryDetailsRow>
          <Column textAlign="center">
            <Text size="16" weight="semibold">
              Your booking on{" "}
              {dateFromIso(props.booking.timeStarts).toFormat("cccc LLLL dd")}{" "}
              is {props.booking.status.toLowerCase()}.
            </Text>
          </Column>
        </BookingHistoryDetailsRow>
      )}
      <BookingHistoryDetailsRow justifyContent="space-evenly">
        {props.upcoming && props.booking.status !== "CANCELLED" && (
          <Button
            size="small"
            background="light-grey"
            onClick={() =>
              props.onBookingSelect(
                props.booking,
                "cancel" as BookingAction.cancel,
              )
            }
          >
            Cancel Booking
          </Button>
        )}
        {props.booking.canRate && (
          <Button
            size="small"
            background="blue"
            onClick={() =>
              props.onBookingSelect(props.booking, "rate" as BookingAction.rate)
            }
          >
            Rate this booking
          </Button>
        )}
        <Button
          size="small"
          background="pink"
          onClick={() => (window.location.href = "tel:+4402074608319")}
        >
          Contact Ruuby
        </Button>
      </BookingHistoryDetailsRow>
    </BookingHistoryDetailsWrapper>
  );
};

export { BookingHistoryDetails };
