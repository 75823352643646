import React from "react";
import styled from "styled-components";

import { PaymentMethodTile } from "../../molecules/payment-method-tile";
import { PaymentAddTile } from "../../molecules/payment-add-tile";


interface Props {
  selectedPaymentMethodId?: string;
  isAddMoreSelected?: boolean;
  paymentMethods: Pick<
    React.ComponentProps<typeof PaymentMethodTile>,
    "brand" | "expirationMonth" | "expirationYear" | "lastFour" | "id"
  >[];
  onClickPaymentMethod: (id: string) => void;
  onClickAddNew: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.size12};
  align-content: stretch;
  width: calc(100% - 87px);
`;

const Item = styled.div`
  position: relative;
`;

const PaymentAddTileStyled = styled(PaymentAddTile)`
  position: absolute;
  right: -87px;
  top: 0;
`;

export const PaymentMethods = ({
  paymentMethods,
  selectedPaymentMethodId,
  isAddMoreSelected,
  onClickPaymentMethod,
  onClickAddNew,
}: Props) => (
  <Container>
    {paymentMethods.map((p, index) => (
      <Item>
        <PaymentMethodTile
          key={p.id}
          id={p.id}
          lastFour={p.lastFour}
          expirationMonth={p.expirationMonth}
          expirationYear={p.expirationYear}
          brand={p.brand}
          onClick={onClickPaymentMethod}
          isSelected={p.id === selectedPaymentMethodId && !isAddMoreSelected}
        />
        {index === paymentMethods.length - 1 && (
          <PaymentAddTileStyled
            onClick={onClickAddNew}
            isSelected={Boolean(isAddMoreSelected)}
          />
        )}
      </Item>
    ))}
  </Container>
);