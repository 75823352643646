import * as React from "react";

import { BookingRateModal } from "../../components/molecules";
import { IApiBooking } from "../../services/api/booking";

interface IProps {
  booking: IApiBooking;
  isShow: boolean;
  onSubmit(bookingUrn: string, rate: number, comment?: string): void;
  onCancel(): void;
}

interface IState {
  comment?: string;
  rating?: number;
}

class RateModalContainer extends React.Component<IProps, IState> {
  public state: IState = {
    comment: undefined,
    rating: undefined,
  };

  public setRating = (index: number) => {
    this.setState({ rating: index + 1 });
  };

  public commentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comment: e.currentTarget.value });
  };

  public cancel = () => {
    this.setState({ comment: undefined, rating: undefined });
    this.props.onCancel();
  };

  public submit = () => {
    if (this.state.rating) {
      this.props.onSubmit(
        this.props.booking.urn,
        this.state.rating,
        this.state.comment,
      );
    }
  };

  /**
   * Render rate modal component
   */
  public render(): JSX.Element {
    return (
      <BookingRateModal
        booking={this.props.booking}
        isShow={this.props.isShow}
        onCancel={this.cancel}
        onCommentChange={this.commentChange}
        onRatingSet={this.setRating}
        onSubmit={this.submit}
        rating={this.state.rating}
      />
    );
  }
}

export { RateModalContainer };
