import React from "react";
import styled from "styled-components";

import { device } from "../../../../../../utils";

const Title = styled.h1`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme }) => theme.type.sizes.size30};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.size28};

  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.type.sizes.size36};
  }
`;

interface IProps {
  title: string;
}

export const PaymentHeader = ({
  title,
}: IProps): JSX.Element => (
  <Title>✨ {title} ✨</Title>
);
