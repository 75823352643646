import * as React from "react";
import styled from "styled-components";

import { Button, Text } from "../../../../components/atoms";
import { Modal } from '../../../themed-v2/molecules/modal';
import { ICheckoutAddress } from "../../../../presenters/booking-location/booking-location-presenter";

interface IProps {
  address?: ICheckoutAddress;
  isShow: boolean;
  onApprove(): void;
  onCancel(): void;
  children?: React.ReactNode;
}

const AddressDeletionModalText = styled(Text)`
  margin-bottom: 20px;
`;

const AddressDeletionModalButtons = styled.div`
  display: flex;
  direction: row;
  justify-content: flex-end;

  > * {
    margin-left: 10px;
  }
`;

const AddressDeletionModalSection = styled.div``;

const AddressDeletionModal: React.FC<IProps> = (props) => {
  return (
    <Modal isShow={props.isShow} onClose={props.onCancel}>
      {props.isShow && props.address && (
        <AddressDeletionModalSection>
          <AddressDeletionModalText size="16" weight="semibold">
            Are you sure you want to delete the following address:
            <b>
              {" "}
              {props.address.address1} {props.address.address2}{" "}
              {props.address.postcode}{" "}
            </b>
            ?
          </AddressDeletionModalText>

          <AddressDeletionModalButtons>
            <Button
              size="small"
              background="light-grey"
              onClick={props.onApprove}
            >
              Yes
            </Button>
            <Button size="small" background="rose" onClick={props.onCancel}>
              No
            </Button>
          </AddressDeletionModalButtons>
        </AddressDeletionModalSection>
      )}
    </Modal>
  );
};

export { AddressDeletionModal };
