import * as React from "react";
import styled from "styled-components";

import { Card, NavItem, Text } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  imageUrl: string;
}

interface IHomeImageTextCardImage {
  imageUrl: string;
}

const HomeImageTextCardImage = styled.div`
  height: 170px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  background: url(${(props: IHomeImageTextCardImage) => props.imageUrl})
    no-repeat center;
  background-size: 100%;
  transition: background-size ${theme.transition.easeInOut03};
`;

const HomeTrendingTitle = styled(Text)`
  padding-bottom: 5px;
  transition: color ${theme.transition.easeInOut03};
`;

const HomeImageTextCardLink = styled(NavItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 30px;

  &:hover {
    ${HomeImageTextCardImage} {
      background-size: 140%;
    }

    ${HomeTrendingTitle} {
      color: ${theme.color.borderColorNew};
    }
  }
`;

export const CarouselImageCard: React.FC<IProps> = (props) => {
  return (
    <HomeImageTextCardLink>
      <Card>
        <HomeImageTextCardImage imageUrl={props.imageUrl} />
      </Card>
    </HomeImageTextCardLink>
  );
};
