import React, { RefObject, forwardRef } from "react";
import styled from "styled-components";

import { device } from "../../../../utils";

const StyledInput = styled.input`
  border: 0px;
  width: 100%;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  padding: 5px 0px;
  outline: none;
  border-bottom: 1px solid #C3C6CF;
  &::placeholder {
    font-style: italic;
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

export const Input = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  return <StyledInput ref={ref as RefObject<HTMLInputElement>} {...props} />;
});

