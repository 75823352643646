import styled from "styled-components";

import { theme } from "../../../../theme";

interface IProps {
  isIcon?: boolean;
}

const InputBox = styled.input`
  width: 100%;
  height: 56px;
  padding: 24px 16px 14px 16px;
  border: solid 1px ${theme.color.inputBorderColor};
  background-color: #ffffff;
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.smaller};
  font-weight: 600;
  color: ${theme.color.ruubyBlack};
  outline: none;

  ${(props: IProps) => props.isIcon && `padding-left: 44px;`}

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: ${theme.color.ruubyGreyLight};
  }

  &:focus {
    box-shadow: 0 0 0 1px ${theme.color.ruubyBlue};

    &:required:invalid,
    &.invalid {
      box-shadow: 0 0 0 1px ${theme.color.ruubyBlue};
    }
  }

  &.dirty {
    box-shadow: 0 0 0 1px ${theme.color.ruubyBlue};
  }

  &.invalid {
    box-shadow: 0 0 0 1px ${theme.color.error};
  }

  transition: all ${theme.transition.easeInOut03};

  @media screen and (max-width: 992px) {
    font-size: ${theme.fontSize.bigger};
  }
`;

export { InputBox };
