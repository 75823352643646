import React from "react";
import styled from "styled-components";
import { isMobile } from 'react-device-detect';

import { device } from "../../../../../../utils";
import { Heading1, Text } from "../../../../atoms";

import { ReactComponent as WhatsAppImage } from "./whatsapp.svg";
import { ReactComponent as EmailImage } from "./email.svg";
import SouthOfFranceImage from "./south-of-france.webp";
import SouthOfFranceLogo from "./south-of-france-logo.webp";
import QrCodeImage from "./qrcode.webp";

const SouthOfFrance = styled('div')`
  display: flex;
  height: 589px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 37px;
  margin-top: 37px;
  border-radius: 7px;
  overflow: hidden;
  flex-direction: column;
  width: 344px;
  @media ${device.tablet} {
    flex-direction: row;
    width: 745px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.13);
  }
  @media ${device.laptop} {
    width: 920px;
    margin-bottom: 52px;
  }
  @media ${device.desktop} {
    margin-top: 0;
  }
`;

const LogoSection = styled('div')`
  width: 100%;
  height: 260px;
  border-radius: 8px 8px 0px 0px;
  align-content: center;
  align-items: center;
  background: url(${SouthOfFranceImage}) lightgray -9.446px -171.851px / 110.465% 214.566% no-repeat;
  @media ${device.tablet} {
    border-radius: 0;
    background: url(${SouthOfFranceImage}) lightgray -4.507px -59.269px / 101.573% 112.661% no-repeat;
    max-width: 445px;
    min-width: 50%;
    flex-shrink: 0;
    align-self: stretch;
    width: unset;
    height: unset;
  }
`;

const Logo = styled('div')`
  background-image: url(${SouthOfFranceLogo});
  background-size: cover;
  margin: auto;
  width: 213px;
  height: 213px;
  @media ${device.tablet} {
    width: 240px;
    height: 240px;
  }
  @media ${device.laptop} {
    width: 266px;
    height: 266px;
    }
`;

const Content = styled('div')`
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch; 
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background.white};
  @media ${device.tablet} {
    padding: 80px 16px;
  }
  @media ${device.laptop} {
    padding: 142px 48px;
    max-width: 475px;
  }
`;

const BookSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media ${device.tablet} {
    gap: 30px;
  }
`;

const H1 = styled(Heading1)`
  font-size: 24px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 30px;
  }
`

const CtaSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  @media ${device.laptop} {
    padding: 0px 18px;
  }
`;

const Info = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 13px;
`;

const QrCode = styled('img')`
  width: 63px;
  height: 63px;
  background-size: cover;
  @media ${device.tablet} {
    width: 87px;
    height: 87px;
  }
`

const Link = styled('a')`
  color: ${({ theme }) => theme.colours.text.main};
  text-decoration: none;
  font-weight: normal;
`;

const DecoratedLink = styled(Link)`
  text-underline-offset: 7px;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colours.ruubyDarkGrey};
  font-weight: bold;
`;

export const SouthOfFrancePanel = () : JSX.Element => {
  return (
    <SouthOfFrance>
      <LogoSection>
        <Logo />
      </LogoSection>
      <Content>
        <BookSection>
          <H1>Book five star beauty and wellness services to your villa, hotel or yacht.</H1>
          <CtaSection>
            <Info>
              <WhatsAppImage />
              { isMobile ? (
                <Text>
                  <DecoratedLink href="https://wa.me/447946233036">WhatsApp us</DecoratedLink>
                  <Link href="https://wa.me/447946233036">&nbsp;on +447946233036</Link> 
                </Text>
              ) : (
                <>
                  <Text>WhatsApp us</Text>
                  <QrCode src={QrCodeImage} />
                </>                
              )}
            </Info>
            <Info>
              <EmailImage />
              <Text>
                <DecoratedLink href="mailto:concierge@ruuby.com">Email us</DecoratedLink>
                <Link href="mailto:concierge@ruuby.com">&nbsp;at concierge@ruuby.com</Link>
              </Text>
            </Info>
          </CtaSection>
        </BookSection>
      </Content>
    </SouthOfFrance>
  );
}