import React, { useEffect, useMemo } from "react";
import { Container, ScreenClass, useScreenClass } from "react-grid-system";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useConfirmationParams } from "../../../../hooks/use-confirmation-params";
import { BookingConfirmationBlock } from "./components/booking-confirmation-block";
import { BookingConfirmationImage } from "./components/booking-confirmation-image";
import { Text } from "../../atoms";
import { Heading1 } from "../../atoms";
import { dateFromIso } from "../../../../utils";
import { NavItem } from "../../../atoms";
import { formatPrice } from "../../../../utils/format-price-for-display";

import NailsImage from "../../../../assets/images/about/nails.png";
import HairImage from "../../../../assets/images/about/hair.png";
import TanningImage from "../../../../assets/images/about/tanning.png";
import MakeupImage from "../../../../assets/images/about/makeup.png";
import FacialImage from "../../../../assets/images/about/facial.png";
import EyesImage from "../../../../assets/images/about/eyes.png";
import WellnessImage from "../../../../assets/images/about/wellness.png";
import { clearBookingParams } from "../checkout-page/provider";
import { AnalyticsEvents, trackBookingSuccess } from "../../../../analytics";

const categoryImagesMap = {
  nails: NailsImage,
  hair: HairImage,
  tanning: TanningImage,
  makeup: MakeupImage,
  facial: FacialImage,
  eyes: EyesImage,
  wellness: WellnessImage,
} as const;

const ConfirmationContainer = styled(Container)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.size36} 0;
  background-color: #f4f4f4;
`;

export const BookingConfirmationPage = (): JSX.Element => {
  const history = useHistory();
  const { confirmationParams, saveConfirmationParamsToSession } =
    useConfirmationParams();
  const screenClass = useScreenClass();
  const isDesktop = (["lg", "xl", "xxl", "xxxl"] as ScreenClass[]).includes(
    screenClass,
  );

  useMemo(() => clearBookingParams(), []);

  useEffect(() => {
    if (confirmationParams?.runAnalytics) {
      trackBookingSuccess({
        analyticsEventName: AnalyticsEvents.STRIPE_BOOKING_SUCCESS,
        bookingUrn: confirmationParams.bookingUrn as string,
        ...(confirmationParams.promoCode && {
          promoCode: confirmationParams.promoCode,
        }),
        ...(confirmationParams.category && {
          category: confirmationParams.category,
        }),
        ...(confirmationParams.creditsDeduction && {
          creditsDeduction: confirmationParams.creditsDeduction,
        }),
        ...(confirmationParams.promotionDeduction && {
          promotionDeduction: confirmationParams?.promotionDeduction,
        }),
        bookingFee: confirmationParams.bookingFee as number,
        treatments: confirmationParams.addedTreatments,
      });
      saveConfirmationParamsToSession({
        ...confirmationParams,
        runAnalytics: false,
      });
    }
  }, []);

  if (!confirmationParams) {
    return history.location.pathname !== "/" && history.replace("/");
  }

  const categoryImage = useMemo(() => {
    const { category } = confirmationParams;

    if (category && category in categoryImagesMap) {
      return <BookingConfirmationImage src={categoryImagesMap[category]} />;
    }
  }, [confirmationParams]);

  const therapistBlock = useMemo(
    () => (
      <BookingConfirmationBlock>
        <Text size="size20" color="grey">
          {confirmationParams.therapistName}{" "}
          {dateFromIso(confirmationParams.bookingDate).toFormat(
            "cccc LLLL dd - h:mm a",
          )}
        </Text>
      </BookingConfirmationBlock>
    ),
    [confirmationParams],
  );

  const treatmentsBlock = useMemo(
    () => (
      <BookingConfirmationBlock>
        <Text size="size16" weight="semibold" color="grey">
          Treatments:
        </Text>
        {confirmationParams.addedTreatments.map(({ name, quantity }, index) => (
          <Text size="size16" color="grey" key={index}>
            {quantity > 1 && `${quantity} x `}
            {name}
          </Text>
        ))}
      </BookingConfirmationBlock>
    ),
    [confirmationParams],
  );

  const addressBlock = useMemo(
    () =>
      confirmationParams.addressString && (
        <BookingConfirmationBlock>
          <Text size="size16" weight="semibold" color="grey">
            Address:
          </Text>
          <Text size="size16" color="grey">
            {confirmationParams.addressString}
          </Text>
        </BookingConfirmationBlock>
      ),
    [confirmationParams],
  );

  const paymentBlock = useMemo(
    () => (
      <BookingConfirmationBlock>
        <Text size="size16" weight="semibold" color="grey">
          Payment:
        </Text>
        <Text size="size16" color="grey">
          {formatPrice(confirmationParams.total)}
        </Text>
      </BookingConfirmationBlock>
    ),
    [confirmationParams],
  );

  return (
    <ConfirmationContainer fluid={!isDesktop}>
      <BookingConfirmationBlock>
        {categoryImage}
        <Heading1>Thank You!</Heading1>
      </BookingConfirmationBlock>
      <BookingConfirmationBlock>
        <Text size="size18" color="grey">
          We hope you enjoy your treatment.
          <br />
          Don't forget to check us out or tag us at @ruubyapp!
        </Text>
      </BookingConfirmationBlock>
      {therapistBlock}
      {treatmentsBlock}
      {addressBlock}
      {paymentBlock}
      <BookingConfirmationBlock>
        <Text size="size16" weight="bold" color="grey">
          Questions or issues?
        </Text>
        <Text size="size16" color="grey">
          Contact the Ruuby Team{" "}
          <NavItem href="tel:+4402074608319">02074608319</NavItem>
        </Text>
        <NavItem type="link" href="mailto:concierge@ruuby.com">
          concierge@ruuby.com
        </NavItem>
      </BookingConfirmationBlock>
      <BookingConfirmationBlock>
        <NavItem type="block" to="/account">
          Check status
        </NavItem>
      </BookingConfirmationBlock>
    </ConfirmationContainer>
  );
};
