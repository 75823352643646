import { useCallback } from "react";

import { usePromotion } from "../use-promotion";
import { useBookingCheckoutContext } from "../../provider";

type UsePaymentInfo = () => {
  promoError?: string;
  handlePromo: (code: string) => void;
  isLoadingPromo: boolean;
}

export const usePaymentInfo: UsePaymentInfo = () => {
  const { updateBookingParams, bookingParams } = useBookingCheckoutContext();

  const { error: promoError, isLoading: isLoadingPromo, fetchPromo } = usePromotion({ 
    treatments: bookingParams.addedTreatments.map(({ urn }) => urn), 
    urn: `${bookingParams.therapistUrn}-${bookingParams.therapistWorkstation}`,
  });

  const handlePromo = useCallback(async (code: string) => {
    const promo = await fetchPromo(code);
    updateBookingParams({ promo });
  }, [updateBookingParams]);

  return {
    promoError,
    handlePromo,
    isLoadingPromo,
  }
};
