import * as React from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  topMargin?: string; // "50 px" default
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
  max-width: 1200px;
  margin: 50px auto 30px;
  background-color: ${theme.color.ruubyGreyBg};

  ${(props: IProps) => props.topMargin && `margin-top:${props.topMargin}`}

  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;

const CenteredTemplate: React.FC<IProps> = ({ topMargin, children }) => {
  return <Wrapper topMargin={topMargin}>{children}</Wrapper>;
};

export { CenteredTemplate };
