import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import { actionCreators } from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  braintreeToken?: string;
}

const INITIAL_STATE: IState = {
  braintreeToken: undefined,
};

/**
 * Reducer for payments
 */
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.BRAINTREE_TOKEN_SUCCESS: {
      return iassign(state, (s) => {
        s.braintreeToken = action.payload.data;

        return s;
      });
    }

    default:
      return state;
  }
}
