import React, { forwardRef } from "react";
import { rem } from "polished";
import styled from "styled-components";

import { TooltipPopoverContainer } from "../../../../../containers/common";
import { device } from '../../../../../utils';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.size18}`};

  div[data-lastpass-icon-root] {
    display: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.size12};

  @media ${device.tablet} {
    margin-right: ${({ theme }) => theme.spacing.size22};
  }
`;


const FormInputDom = styled.input<{ isUppercase?: boolean }>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textMain};
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0;
  padding-bottom: ${rem("2px")};
  vertical-align: middle;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colours.textPlaceholder};
    opacity: 1;
    text-transform: none;
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.textPlaceholder};
  }

  ${({ isUppercase }) =>
    isUppercase &&
    `
    text-transform: uppercase;
    
  `}

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const FormInput = forwardRef<HTMLInputElement, React.ComponentProps<typeof FormInputDom>>(
  (props, ref) => {
    return <FormInputDom ref={ref} {...props} />;
  }
);

const TooltipPopoverContainerWrapper = styled.div`
  > div {
    top: 100%;
    white-space: nowrap;
    margin-top: ${({ theme }) => theme.spacing.size12};
  }
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  tooltipText?: string;
  isUppercase?: boolean;
  innerRef?: React.ForwardedRef<HTMLInputElement>;
}

export const FilterFieldInput = ({
  icon, 
  tooltipText,
  isUppercase,
  ...props
}: Props): JSX.Element => {
  console.log({ props });

  return (
  <InputContainer>
    {icon && <IconContainer>{icon}</IconContainer>}
    <FormInput {...props} ref={props.innerRef} isUppercase={isUppercase} />
    <TooltipPopoverContainerWrapper>
      <TooltipPopoverContainer
        isShow={Boolean(tooltipText)}
        text={tooltipText ?? ""}
      />
    </TooltipPopoverContainerWrapper>
  </InputContainer>
);
};