import * as React from "react";
import styled from "styled-components";

import { Card, Text } from "../../../../components/atoms";
import { Column, Container, Row } from "../../../../components/grid";
import { ICategory } from "../../../../services/api/category-list";
import { IService } from "../../../../services/api/therapists";
import { IApiFeaturedTreatmentsList } from "../../../../services/api/treatments";
import { theme } from "../../../../theme";
import { FeaturedTreatmentsHeaderScroller } from "../../../../components/organisms/featured-treatments-group/featured-treatments-header-scroller";

interface ICategories {
  categories: {
    [key: string]: ICategory;
  };
}

interface IProps extends ICategories {
  featuredTreatments: IService[];
  featuredTreatmentsList: IApiFeaturedTreatmentsList;
  onClick(treatment: IService): void;
}

interface ICardBackground extends ICategories {
  categoryName: string;
}

const FeaturedTreatmentsDetailsListColumn = styled(Column)`
  margin-bottom: 20px;
`;

const FeaturedTreatmentsDetailsListCard = styled(Card)<ICardBackground>`
  position: relative;
  padding: 20px;
  min-height: 200px;
  cursor: pointer;

  &:hover {
    ${Text} {
      color: ${theme.color.borderColorNew};
    }
  }

  ${Row}, ${Text} {
    position: relative;
    transition: color ${theme.transition.linear03};
  }

  &::before {
    ${(props) =>
      `
    content: "";
    background: url(${
      props.categories[props.categoryName] &&
      props.categories[props.categoryName].image
        ? props.categories[props.categoryName].image
        : "none"
    }) no-repeat;
    background-size: contain;
    background-position: top right;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    `}
  }
`;

const FeaturedTreatmentsDetailsListRow = styled(Row)`
  margin-bottom: 5px;
`;

const FeaturedTreatmentsDetailsListDescription = styled(Text)`
  margin-top: 10px;
`;

const FeaturedTreatmentsDetailsListTitle = styled(Text)`
  margin-bottom: 20px;
`;

const FeaturedTreatmentsDetailsContainer = styled(Container)`
  padding-top: 25px;

  @media screen and (max-width: 992px) {
    padding-top: 15px;
  }
`;

const FeaturedTreatmentsDetailsList: React.FC<IProps> = (props) => {
  const mapFeaturedTreatments = props.featuredTreatments.map(
    (treatment, index) => (
      <FeaturedTreatmentsDetailsListColumn md="6" key={index}>
        <FeaturedTreatmentsDetailsListCard
          categories={props.categories}
          categoryName={treatment.category}
          onClick={() => props.onClick(treatment)}
        >
          <FeaturedTreatmentsDetailsListRow
            noMargin
            justifyContent="space-between"
          >
            <Text size="18" weight="semibold">
              {treatment.name}
            </Text>
            <Text size="18" weight="semibold">
              £{treatment.price}
            </Text>
          </FeaturedTreatmentsDetailsListRow>
          <Text size="18">
            {treatment.category} &#9679; {treatment.duration} mins
          </Text>
          <FeaturedTreatmentsDetailsListDescription>
            {treatment.description}
          </FeaturedTreatmentsDetailsListDescription>
        </FeaturedTreatmentsDetailsListCard>
      </FeaturedTreatmentsDetailsListColumn>
    ),
  );

  const header = () => (
    <FeaturedTreatmentsHeaderScroller images={props.featuredTreatmentsList.headerImages} />
  );

  return (
    <React.Fragment>
      {header()}

      <FeaturedTreatmentsDetailsContainer offsetPaddings limitedWidth>
        <Row>
          <Column>
            <FeaturedTreatmentsDetailsListTitle size="18" weight="semibold">
              {props.featuredTreatmentsList.description}
            </FeaturedTreatmentsDetailsListTitle>
          </Column>
        </Row>
        <Row>{mapFeaturedTreatments}</Row>
      </FeaturedTreatmentsDetailsContainer>
    </React.Fragment>
  );
};

export { FeaturedTreatmentsDetailsList };
