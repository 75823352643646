import React from "react";
import styled from "styled-components";

import { Tier } from "../../../../services/api/types/graphql";
import { useTierType } from "../../../../hooks/use-tier-type";

const Circle = styled.div<{
  colour: string;
  width?: number;
  height?: number;
  shrink?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: ${({ shrink }) => (shrink ? 1 : 0)};
  width: ${({ width }) => (width ? `${width}px` : "33px")};
  height: ${({ height }) => (height ? `${height}px` : "33px")};
  background-color: ${({ colour }) => colour};
  border-radius: ${({ width }) => (width ? `${width / 2}px` : "16.5px")};
`;

const Label = styled.span<{
  fontSize?: number;
}>`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme, fontSize }) =>
    fontSize ? `${fontSize}px` : theme.type.sizes.size16};
  font-weight: 400;
  line-height: ${({ theme, fontSize }) =>
    fontSize ? `${fontSize}px` : theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.tierLabels.text};
`;

interface Props {
  type: Tier;
  width?: number;
  height?: number;
  fontSize?: number;
  shrink?: boolean;
}

export const TierLabel = ({
  type,
  width,
  height,
  fontSize,
  shrink,
}: Props): JSX.Element | null => {
  if (type === Tier.ON_DEMAND || type === Tier.HOSPITALITY) {
    return null;
  }
  const { colour, label } = useTierType({ type });

  return (
    <Circle
      colour={colour}
      width={width}
      height={height}
      shrink={shrink ?? false}
    >
      <Label fontSize={fontSize}>{label}</Label>
    </Circle>
  );
};
