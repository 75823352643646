import * as React from "react";
import styled from "styled-components";

import { Button, Card } from "../../../../components/atoms";
import { HomeImageTextCard } from "../../../../components/molecules";
import { ScrollerContainer } from "../../../../containers/common/scroller";
import { IApiFeaturedTreatmentsList } from "../../../../services/api/treatments";

interface IBackground {
  background?: string;
}

interface IProps extends IBackground {
  list: IApiFeaturedTreatmentsList[];
}

const MAX_DESCRIPTION_LENGTH = 200;

const FeaturedTreatmentsScrollerItemsWrapper = styled.div`
  display: inline-flex;
  padding: 0 0 30px;

  a {
    margin: 0 15px;
  }

  ${Card} {
    width: 360px;

    @media screen and (max-width: 500px) {
      width: 80vw;
    }
  }
`;

const FeaturedTreatmentsScrollerWrapper = styled.div`
  background: ${(props: IBackground) =>
    props.background ? props.background : "none"};
`;

const FeaturedTreatmentsScrollerButton = styled(Button)`
  margin: 15px;
`;

const FeaturedTreatmentsScrollerCard = styled(HomeImageTextCard)`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const FeaturedTreatmentsScroller: React.FC<IProps> = ({ background, list }) => {
  const mapCategories = list.map((treatment) => {
    const url = `/featured-treatments/${treatment.urn}`;
    const description =
      treatment.description.length > MAX_DESCRIPTION_LENGTH
        ? `${treatment.description.substring(0, MAX_DESCRIPTION_LENGTH)} ...`
        : treatment.description;

    return (
      <FeaturedTreatmentsScrollerCard
        key={treatment.urn}
        link={url}
        imageUrl={treatment.headerImages[0]}
        title={treatment.name}
        text={description}
        externalImage
      >
        <FeaturedTreatmentsScrollerButton size="small" background="blue">
          Book now
        </FeaturedTreatmentsScrollerButton>
      </FeaturedTreatmentsScrollerCard>
    );
  });

  return (
    <FeaturedTreatmentsScrollerWrapper background={background}>
      <ScrollerContainer centerElements>
        <FeaturedTreatmentsScrollerItemsWrapper>
          {mapCategories}
        </FeaturedTreatmentsScrollerItemsWrapper>
      </ScrollerContainer>
    </FeaturedTreatmentsScrollerWrapper>
  );
};

export { FeaturedTreatmentsScroller };
