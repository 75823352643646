import { useCallback, useEffect, useMemo, useState } from "react";
import { TherapistTreatment } from "../../types";

export type AddedTreatment = Record<string, {
  urn: string;
  name: string;
  quantity: number;
  price: number;
  duration: number;
}>;

type UseHandleTreatments = (params: { 
  therapistUrn: string;
  treatments: TherapistTreatment[];
}) => {
  addedTreatments: AddedTreatment;
  treatmentsDuration: string;
  handleAddTreatment(treatmentUrn: string): void;
  handleRemoveTreatment(treatmentUrn: string): void;
};

export const useHandleTreatments: UseHandleTreatments = ({ 
  therapistUrn,
  treatments,
}) => {
  const [addedTreatments, setAddedTreatments] = useState<AddedTreatment>({});
  const [treatmentsDuration, setTreatmentsDuration] = useState("PT0M");
  const treatmentsMap = useMemo(() => 
    Object.fromEntries(
      treatments.map(entry => [entry.treatment.urn, entry]),   
    ), 
    [treatments]
  );

  useEffect(() => {
    const storedTreatments = sessionStorage.getItem(
      `addedTreatments-${therapistUrn}`,
    );

    if (storedTreatments) {
      const savedTreatments = JSON.parse(storedTreatments) as AddedTreatment;

      setAddedTreatments(savedTreatments);
    }
  }, [therapistUrn]);

  useEffect(() => {
    if (Boolean(therapistUrn)) {
      sessionStorage.setItem(
        `addedTreatments-${therapistUrn}`,
        JSON.stringify(addedTreatments),
      );
    }
  }, [addedTreatments, therapistUrn]);

  useEffect(() => {
    const durationInMins = Object.keys(addedTreatments).reduce((duration, key) => 
      duration += addedTreatments[key].quantity * treatmentsMap[key].treatment.duration
    , 0);

    setTreatmentsDuration(`PT${durationInMins}M`);
  }, [treatmentsMap, addedTreatments]);

  const handleAddTreatment = useCallback((treatmentUrn: string) => {
    const treatmentEntry = treatmentsMap[treatmentUrn];

    if (!treatmentEntry) {
      return;
    }
    
    setAddedTreatments((currTreatments) => {
      if (currTreatments) {
        // Treatments have already been added
        const existingTreatment = currTreatments.hasOwnProperty(treatmentUrn);

        if (existingTreatment) {
          // The treatment has already been added
          currTreatments[treatmentUrn].quantity += 1;
          return { ...currTreatments };
        } else {
          // The treatment has not been added yet
          return { 
            ...currTreatments,
            [treatmentUrn]: {
              urn: treatmentUrn,
              name: treatmentEntry.treatment.name,
              quantity: 1,
              duration: treatmentEntry.treatment.duration,
              price: treatmentEntry.merchandisingPrice.price,
            },
          };
        }
      }
      
      return {};
    });
  }, [treatmentsMap]);

  const handleRemoveTreatment = useCallback((treatmentUrn: string) => {
    setAddedTreatments((currTreatments) => {
      if (currTreatments) {
        // Treatments have already been added
        const existingTreatment = currTreatments.hasOwnProperty(treatmentUrn);

        if (!existingTreatment) {
          // The treatment has not been added
          return currTreatments;
        } else if (currTreatments[treatmentUrn].quantity > 1) {
          // The treatment has been added more than once
          currTreatments[treatmentUrn].quantity -= 1;
          return {...currTreatments};
        } else {
          // The treatment has been added once
          delete currTreatments[treatmentUrn];
          return {...currTreatments}
        }
      } else {
        // No treatments have been added yet
        return {};
      }
    });
  }, []);

  return {
    addedTreatments,
    treatmentsDuration,
    handleAddTreatment,
    handleRemoveTreatment,
  };
};
