import React, { useMemo, useState } from "react";

import { AddedTreatment } from "../../hooks/use-handle-treatments";
import { TherapistTreatment } from "../../types";
import { TreatmentHeader } from "../treatment-header";
import { TreatmentRow } from "../treatment-row";

import { groupTherapistTreatments } from "./utils";

interface Props {
  therapistTreatments: TherapistTreatment[];
  selectedCategoryUrn?: string;
  addedTreatments: AddedTreatment;
  onAddTreatment(treatmentUrn: string): void;
  onRemoveTreatment(treatmentUrn: string): void;
}

export const TherapistServices = ({
  therapistTreatments,
  selectedCategoryUrn,
  addedTreatments,
  onAddTreatment,
  onRemoveTreatment,
}: Props): JSX.Element => {
  const [descDisplayMap, setDescDisplayMap] = useState<Record<string, boolean>>(
    {},
  );

  const groupedTreatments = useMemo(() => {
    return groupTherapistTreatments(therapistTreatments, selectedCategoryUrn);
  }, [therapistTreatments, selectedCategoryUrn]);

  const headerHeight = document.querySelector("header")?.offsetHeight ?? 0;
  const therapistTabsHeight =
    document.getElementById("therapist-tabs")?.offsetHeight ?? 0;

  const handleClickTreatmentDetails = (treatmentKey: string): void => {
    const newDescDisplayMap = {
      ...descDisplayMap,
    };

    newDescDisplayMap[treatmentKey] = !newDescDisplayMap[treatmentKey];

    setDescDisplayMap(newDescDisplayMap);
  };

  return (
    <>
      {groupedTreatments.map(({ name, urn, treatments }) => {
        return (
          <div key={urn}>
            <TreatmentHeader
              name={name}
              categoryUrn={urn}
              topPosition={headerHeight + therapistTabsHeight}
            />
            {treatments.map((therapistTreatment, index) => {
              const addedTreatmentCount =
                addedTreatments[therapistTreatment.treatment.urn]?.quantity ?? 0;
              const treatmentKey = `${urn}-${index}`;

              return (
                <TreatmentRow
                  key={treatmentKey}
                  treatment={therapistTreatment}
                  addedQuantity={addedTreatmentCount}
                  isDescriptionShown={Boolean(descDisplayMap[treatmentKey])}
                  onClickDetails={handleClickTreatmentDetails.bind(
                    null,
                    treatmentKey,
                  )}
                  onAdd={onAddTreatment}
                  onRemove={onRemoveTreatment}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
