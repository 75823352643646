import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import jQuery from "jquery";
import { History } from "history";
import { useDispatch } from "react-redux";

import { actionCreators as therapistsActions } from "../../../../store/therapists/actions";

import { Category, categories as allCategories } from "../../../../types";

import { categoryData } from "./data";

type CategoryData = typeof categoryData[Category];

interface CategoryLandingData {
  hero?: CategoryData["hero"];
  mostPopular?: CategoryData["mostPopular"];
  reviews?: CategoryData["reviews"];
  categories?: Category[];
  categoryName?: Category;
  handleClickButton?(): void;
}

export type UseCategoryLandingData = (history: History) => CategoryLandingData;

export const useCategoryLandingData: UseCategoryLandingData = (history) => {
  const { categoryName } = useParams<{ categoryName: Category }>();

  if (!categoryName) {
    return {};
  }

  const dispatch = useDispatch();

  const { hero, mostPopular, reviews } = useMemo(() => {
    const category = categoryData[String(categoryName).toLowerCase()];

    if (!category) {
      history.push("/404");
    }

    return category;
  }, [categoryName]);

  useEffect(() => {
    dispatch(therapistsActions.clearDate());
  }, [categoryName]);

  const categories = useMemo(
    () => allCategories.filter((c) => c !== categoryName),
    [categoryName],
  );

  const handleClickButton = useCallback(() => {
    // the easiest working solution without rewriting a lot of stuff on home page
    jQuery("html, body")
      .stop()
      .animate({ scrollTop: 0 }, 500, "swing", () => {
        jQuery(document).find("h2:contains('Browse availability')").first(); // search button is first on page
      });
  }, []);

  return {
    hero,
    mostPopular,
    reviews,
    categories,
    categoryName,
    handleClickButton,
  };
};
