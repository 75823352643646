import * as React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { Text } from "../../../../components/atoms";
import {
  AccountView,
  TextDoubleHeader,
} from "../../../../components/molecules";
import { ILoginUser } from "../../../../services/api/customer";
import { theme } from "../../../../theme";

interface IProps {
  creditBalance: number;
  onLogout(): void;
  user: ILoginUser;
}

const BookingAccountTextLogout = styled(Text)`
  color: ${theme.color.ruubyGrey};
  cursor: pointer;
  transition: color ${theme.transition.linear03};

  &:hover {
    color: ${theme.color.ruubyGreyBlue};
  }
`;

const BookingAccountWrapper = styled.div`
  max-width: ${rem("460px")};
`;

const BookingAccountTextWrapper = styled.div`
  max-width: ${rem("300px")};
`;

const BookingAccount: React.FC<IProps> = (props) => {
  return (
    <BookingAccountWrapper>
      <BookingAccountTextWrapper>
        <TextDoubleHeader>
          <Text size="20" weight="bold" transform="uppercase">
            Account Info
          </Text>
          <BookingAccountTextLogout
            size="20"
            weight="semibold"
            transform="uppercase"
            onClick={props.onLogout}
          >
            Logout
          </BookingAccountTextLogout>
        </TextDoubleHeader>
      </BookingAccountTextWrapper>
      <AccountView user={props.user} creditBalance={props.creditBalance} />
    </BookingAccountWrapper>
  );
};

export { BookingAccount };
