import React from "react";
import styled, { css, keyframes } from "styled-components";
import Skeleton from "react-loading-skeleton";

import { AvatarImage } from "../avatar-image";
import { device } from "../../../../../../utils";
import { IconStar } from "../../../../../atoms";

const fadeIn = keyframes`
  0% { max-height: 0; opacity: 0 }
  100% { max-height: 1000px; opacity: 1 }
`;

const TherapistReviewContainer = styled.article<{ isLazyLoaded }>`
  display: flex;
  flex-direction: column;
  ${({ isLazyLoaded }) =>
    isLazyLoaded && css`animation: ${fadeIn} 0.75s ease-out;`}

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.colours.ruubyLightGrey}`};
  }
`;

const ReviewerDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ReviewerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewerName = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  color: ${({ theme }) => theme.colours.textMain};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const TherapistReviewComment = styled.p`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: ${({ theme }) => theme.spacing.size18};
`;

const IconStarStyled = styled(IconStar)`
  fill: ${({ theme }) => theme.colours.iconGrey};
`;

interface Props {
  customerName?: string;
  rating?: number;
  text?: string;
  isLazyLoaded?: boolean;
}

export const TherapistReview = ({
  customerName,
  rating,
  text,
  isLazyLoaded,
}: Props): JSX.Element => (
  <TherapistReviewContainer isLazyLoaded={isLazyLoaded}>
    <ReviewerDetails>
      {customerName ? (
        <AvatarImage
          name={customerName}
          dimensionsMobile={60}
          dimensionsDesktop={60}
        />
      ) : (
        <Skeleton width={60} height={60} circle />
      )}
      <ReviewerText>
        <ReviewerName>
          {customerName ? customerName : <Skeleton width={100} />}
        </ReviewerName>
        {/* <PastTimeLabel date={date} /> */}
      </ReviewerText>
    </ReviewerDetails>

    <TherapistReviewComment>
      {typeof text !== "undefined" ? text : <Skeleton count={3} />}
    </TherapistReviewComment>

    <IconsContainer data-testid="icons-container">
      {rating ? (
        [...Array(rating)].map((_, index) => (
          <IconStarStyled key={`${index}`} />
        ))
      ) : (
        <Skeleton width={100} />
      )}
    </IconsContainer>
  </TherapistReviewContainer>
);
