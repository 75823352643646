import { getHandler } from "../request-handler";

export interface IApiJobList {
  id: string;
  title: string;
  description: string;
  salary: string;
  joblocation: string;
  email: string;
}

/**
 * Get list of jobs
 */
export async function fetchJobs(): Promise<IApiJobList[]> {
  const response = await getHandler<IApiJobList[]>("/jobs", false);

  return response.result;
}

/**
 * Get job details
 */
export async function fetchDetails(id: string): Promise<IApiJobList> {
  const response = await getHandler<IApiJobList>("/jobs/" + id, false);

  return response.result;
}
