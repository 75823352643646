import React from "react";
import styled from "styled-components";

import { IconStar } from "..";
import { device } from "../../../../utils";

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconStarStyled = styled(IconStar)`
  fill: ${({ theme }) => theme.colours.iconGrey};
`;

const RatingTextContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.size8};

  @media ${device.tablet} {
    margin-left: ${({ theme }) => theme.spacing.size12};
  }
`;

const RatingText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textGrey};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const Bold = styled(RatingText)`
  font-weight: 700;
`;

interface Props {
  averageRating: number;
  numberOfRatings: number;
  text?: string;
}

export const TherapistRating = ({
  averageRating,
  numberOfRatings,
  text,
}: Props): JSX.Element => (
  <RatingContainer data-testid="therapist-rating">
    <IconStarStyled />
    <RatingTextContainer>
      <RatingText>
        <Bold>{averageRating.toFixed(2)}</Bold> (
        {numberOfRatings === 0 ? 1 : numberOfRatings}) {text}
      </RatingText>
    </RatingTextContainer>
  </RatingContainer>
);
