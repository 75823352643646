import styled from "styled-components";

import { theme } from "../../../../theme";

interface IProps {
  isIcon?: boolean;
}

const InputLine = styled.input`
  width: 100%;
  padding-bottom: 9px;
  border: 0;
  border-bottom: 1px solid ${theme.color.borderColor};
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.main};
  font-weight: 500;
  color: ${theme.color.borderColor};
  background: transparent;
  outline: none;

  ${(props: IProps) => props.isIcon && `padding-left: 44px;`}

  &::placeholder {
    color: ${theme.color.borderColor};
  }
`;

export { InputLine };
