import * as React from "react";
import styled from "styled-components";
import { rem } from "polished";

import {
  IconMail,
  IconMoney,
  IconPerson,
  IconPhone,
  Text,
} from "../../../../components/atoms";
import { ReferralCard } from "../../../../components/themed-v2/molecules/referral-card";
import { ILoginUser } from "../../../../services/api/customer";
import { theme } from "../../../../theme";

interface IProps {
  user: ILoginUser;
  creditBalance: number;
}

const AccountViewIcon = styled.div`
  height: ${rem("22px")};
  padding-right: ${rem("10px")};

  svg {
    height: ${rem("22px")};
    width: ${rem("22px")};
    fill: ${theme.color.borderColor};

    path {
      fill: ${theme.color.borderColor};
    }
  }
`;

const AccountViewReferralCard = styled.div`
  padding-bottom: ${rem("22px")};
`;

const AccountViewSection = styled.div`
  display: flex;
  padding-bottom: ${rem("32px")};
`;

const AccountViewWrapper = styled.div``;

export const AccountView = ({ user, creditBalance }: IProps) => {
  return (
    <AccountViewWrapper>
      {user.referralCode && user.referralReward && (
        <AccountViewReferralCard>
          <ReferralCard
            creditAmount={user.referralReward}
            referralCode={user.referralCode}
          />
        </AccountViewReferralCard>
      )}
      <AccountViewSection>
        <AccountViewIcon>
          <IconPerson />
        </AccountViewIcon>
        <Text weight="bold">{`${user.firstName} ${user.lastName}`}</Text>
      </AccountViewSection>
      <AccountViewSection>
        <AccountViewIcon>
          <IconMail />
        </AccountViewIcon>
        <Text weight="bold">{user.email}</Text>
      </AccountViewSection>
      <AccountViewSection>
        <AccountViewIcon>
          <IconPhone />
        </AccountViewIcon>
        <Text weight="bold">{user.phone}</Text>
      </AccountViewSection>
      {creditBalance > 0 && (
        <AccountViewSection>
          <AccountViewIcon>
            <IconMoney />
          </AccountViewIcon>
          <Text weight="bold">Credit Balance: {creditBalance}</Text>
        </AccountViewSection>
      )}
      <AccountViewSection>
        <AccountViewIcon></AccountViewIcon>
        <Text>
          <a
            href="/terms_and_conditions"
            target="_blank"
            style={{
              color: "#000000",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Terms and Conditions
          </a>
        </Text>
      </AccountViewSection>
    </AccountViewWrapper>
  );
};
