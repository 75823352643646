import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { Modal } from "../../molecules/modal";
import { Image } from "../../../../components/atoms/image";

import QR from "./qr.png";

const QRCodeContainer = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size20};

  max-width: ${rem("400px")};

  img {
    padding: ${rem("10px")} ${rem("10px")};
  }
`;

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export const DownloadQRModal = ({ isVisible, onClose }: Props): JSX.Element => (
  <Modal isShow={isVisible} onClose={onClose} isOverlayClose>
    <QRCodeContainer>
      <a> Scan this QR code to install the Ruuby app. </a>
      <Image src={QR} />
    </QRCodeContainer>
  </Modal>
);
