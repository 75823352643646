import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuid } from "uuid";
import { Navigation } from "swiper";

import { device } from "../../../utils/media-queries";
import { IconChevron } from "../../../components/atoms";

import "swiper/css/bundle";

const Container = styled.div`
  margin-left: -45px;
  margin-right: -45px;
`;

const Mask = styled.div`
  mask-image: linear-gradient(
    to right,
    transparent 0,
    black 52px calc(100% - 52px),
    transparent 100%
  );

  & .swiper {
    padding: 5px 0 5px 0;
  }
`;

const ButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  top: calc(50% - 16px);
  z-index: 10;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: opacity 0.25s;
  opacity: 1;

  &.disabled,
  &.swiper-button-lock {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }

  @media ${device.touch} {
    display: none;
  }
`;

const SwiperButtonNext = styled.div`
  ${ButtonCss}
  right: -30px;
`;

const SwiperButtonPrevious = styled.div`
  ${ButtonCss}
  left: -30px;

  svg {
    transform: rotate(180deg);
  }
`;

const SlideElement = styled(SwiperSlide)`
  &&& {
    width: fit-content;
  }
`;

type SwiperProps = React.ComponentProps<typeof Swiper>;

type Props = Omit<SwiperProps, "spaceBetween"> &
  Required<Pick<SwiperProps, "spaceBetween">> & {
    children: JSX.Element[];
  };

export const SwiperCarousel = (props: Props): JSX.Element => {
  const id = useMemo(uuid, []);

  const swiperProps = {
    modules: [Navigation],
    slidesOffsetBefore: 45,
    slidesOffsetAfter: 45,
    navigation: {
      nextEl: `#next-${id}`,
      prevEl: `#previous-${id}`,
      disabledClass: "disabled",
    },
    ...props,
  };

  return (
    <Container>
      <SwiperButtonPrevious id={`previous-${id}`}>
        <IconChevron />
      </SwiperButtonPrevious>
      <SwiperButtonNext id={`next-${id}`}>
        <IconChevron />
      </SwiperButtonNext>
      <Mask>
        <Swiper {...swiperProps}>
          {props.children.map((element) => (
            <SlideElement>{element}</SlideElement>
          ))}
        </Swiper>
      </Mask>
    </Container>
  );
};
