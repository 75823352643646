import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import {
  ITherapist,
  ITherapistAvailability,
  ITherapistsReduced,
  IApiTherapistReview,
} from "../../services/api/therapists";

import { actionCreators } from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  additionalTherapists: ITherapistsReduced;
  additionalTherapistsFetchError?: Error;
  availableDatesError?: string;
  isAdditionalTherapistsLoading: boolean;
  isAvailableDatesLoading: boolean;
  isTherapistsLoading: boolean;
  selectedDate?: string;
  selectedTherapist?: ITherapist;
  selectedTherapistAvailableDates: ITherapistAvailability;
  selectedTherapistReviews: IApiTherapistReview[];
  therapists: ITherapistsReduced;
  therapistsFetchError?: Error;
  therapistsSubCategories: string[];
}

const INITIAL_STATE: IState = {
  additionalTherapists: {},
  additionalTherapistsFetchError: undefined,
  availableDatesError: undefined,
  isAdditionalTherapistsLoading: false,
  isAvailableDatesLoading: false,
  isTherapistsLoading: false,
  selectedDate: undefined,
  selectedTherapist: undefined,
  selectedTherapistAvailableDates: {},
  selectedTherapistReviews: [],
  therapists: {},
  therapistsFetchError: undefined,
  therapistsSubCategories: [],
};

/**
 * Reducer for therapists list and details
 */
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.FETCH_THERAPISTS_SEARCH_ATTEMPT: {
      return iassign(state, (s) => {
        s.therapistsFetchError = undefined;
        s.isTherapistsLoading = true;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_SEARCH_SUCCESS: {
      return iassign(state, (s) => {
        s.therapists = action.payload.data;
        s.therapistsFetchError = undefined;
        s.isTherapistsLoading = false;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_SEARCH_FAILURE: {
      return iassign(state, (s) => {
        s.therapists = {};
        s.therapistsFetchError = action.payload.error;
        s.isTherapistsLoading = false;
        s.therapistsSubCategories = [];

        return s;
      });
    }

    case types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT: {
      return iassign(state, (s) => {
        s.additionalTherapistsFetchError = undefined;
        s.isAdditionalTherapistsLoading = true;

        return s;
      });
    }

    case types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS: {
      return iassign(state, (s) => {
        s.additionalTherapists = action.payload.data;
        s.additionalTherapistsFetchError = undefined;
        s.isAdditionalTherapistsLoading = false;

        return s;
      });
    }

    case types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE: {
      return iassign(state, (s) => {
        s.additionalTherapists = {};
        s.additionalTherapistsFetchError = action.payload.error;
        s.isAdditionalTherapistsLoading = false;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_AVAILABILITY_ATTEMPT: {
      return iassign(state, (s) => {
        s.isAvailableDatesLoading = true;
        s.selectedTherapistAvailableDates = {};
        s.availableDatesError = undefined;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_AVAILABILITY_SUCCESS: {
      return iassign(state, (s) => {
        s.isAvailableDatesLoading = false;
        s.selectedTherapistAvailableDates = action.payload.data;
        s.availableDatesError = undefined;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_AVAILABILITY_FAILURE: {
      return iassign(state, (s) => {
        s.isAvailableDatesLoading = false;
        s.selectedTherapistAvailableDates = {};
        s.availableDatesError = action.payload.error.message;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_REVIEWS_ATTEMPT: {
      return iassign(state, (s) => {
        s.selectedTherapistReviews = [];

        return s;
      });
    }

    case types.FETCH_THERAPISTS_REVIEWS_SUCCESS: {
      return iassign(state, (s) => {
        s.selectedTherapistReviews = action.payload.data;

        return s;
      });
    }

    case types.FETCH_THERAPISTS_REVIEWS_FAILURE: {
      return iassign(state, (s) => {
        s.selectedTherapistReviews = [];

        return s;
      });
    }

    case types.SELECT_THERAPIST: {
      return iassign(state, (s) => {
        s.selectedTherapist = action.payload.data;

        return s;
      });
    }

    case types.DESELECT_THERAPIST: {
      return iassign(state, (s) => {
        s.selectedTherapist = undefined;

        return s;
      });
    }

    case types.CLEAR_THERAPISTS: {
      return iassign(state, (s) => {
        s.therapists = {};
        s.therapistsSubCategories = [];

        return s;
      });
    }

    case types.CLEAR_ADDITIONAL_THERAPISTS: {
      return iassign(state, (s) => {
        s.additionalTherapists = {};

        return s;
      });
    }

    case types.CLEAR_DATE: {
      return iassign(state, (s) => {
        s.selectedDate = undefined;

        return s;
      });
    }

    case types.SET_DATE: {
      return iassign(state, (s) => {
        s.selectedDate = action.payload.data;

        return s;
      });
    }

    case types.SET_THERAPISTS_SUBCATEGORIES: {
      return iassign(state, (s) => {
        s.therapistsSubCategories = action.payload.data;

        return s;
      });
    }

    default:
      return state;
  }
}
