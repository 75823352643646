import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import {
  Paragraph,
  KaleidoscopeNumber,
} from "../../../components/themed-v2/atoms";
import { device } from "../../../utils/media-queries";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const KaleidoscopeNumberStyled = styled(KaleidoscopeNumber)`
  flex: none;
`;

const Label = styled(Paragraph)`
  margin: 0 0 0 ${rem("23px")};
  line-height: ${rem("20px")};
  flex: auto;
  width: ${rem("136px")};

  @media ${device.tablet} {
    width: ${rem("162px")};
    margin: ${rem("13px")} 0 0 0;
    text-align: center;
  }
`;

interface IProps {
  number: React.ComponentProps<typeof KaleidoscopeNumber>["number"];
  label: string;
}

export const KaleidoscopeNumberLabel = ({ number, label }: IProps) => (
  <Container>
    <KaleidoscopeNumberStyled number={number} />
    <Label>{label}</Label>
  </Container>
);
