import { ActionDispatch } from "../index";

import { fetchCategories, ICategory } from "../../services/api/category-list";
import { IFeaturedTreatment } from "../../services/api/treatments";

import * as types from "./action-types";

const seconds1 = 1000;
const seconds5 = 5000;
const seconds10 = 10000;

export const actionCreators = {
  clearCategory: () => ({
    type: types.CLEAR_CATEGORY,
  }),
  clearCategoryFeaturedTreatment: () => ({
    type: types.CLEAR_CATEGORY_FEATURED_TREATMENT,
  }),
  fetchCategoriesAttempt: () => ({
    type: types.CATEGORIES_FETCH_ATTEMPT,
  }),
  fetchCategoriesFailure: (error: Error) => ({
    payload: { error },
    type: types.CATEGORIES_FETCH_FAILURE,
  }),
  fetchCategoriesSuccess: (data: ICategory[]) => ({
    payload: { data },
    type: types.CATEGORIES_FETCH_SUCCESS,
  }),
  setCategory: (category: ICategory) => ({
    payload: { category },
    type: types.SET_CATEGORY,
  }),
  setCategoryFeaturedTreatment: (data: IFeaturedTreatment) => ({
    payload: { data },
    type: types.SET_CATEGORY_FEATURED_TREATMENT,
  }),
};

/**
 * Dispatch fetch of categories action for reducer
 * Call API to get all categories
 */
export function fetchCategoryList(wait = 1000): ActionDispatch<void> {
  return async (dispatch) => {
    dispatch(actionCreators.fetchCategoriesAttempt());

    try {
      const categories = await fetchCategories();

      dispatch(actionCreators.fetchCategoriesSuccess(categories));
    } catch (error) {
      if (error instanceof Error && !error.message) {
        error = new Error(
          "Something Went Wrong, Please check your internet connection.",
        );
      }

      if (error instanceof Error) {
        if (wait > seconds5) {
          dispatch(actionCreators.fetchCategoriesFailure(error));
        }

        // primitive exponental backoff - try in 1 second, increasing by 1 second
        // to max of 10 seconds
        // https://dzone.com/articles/understanding-retry-pattern-with-exponential-back
        const newWait = wait > seconds10 ? seconds10 : wait + seconds1;
        setTimeout(() => dispatch(fetchCategoryList(newWait)), newWait);
      }
    }
  };
}
