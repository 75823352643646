import { useCallback, useEffect, useState } from "react";
import jQuery from "jquery";

import { homePageData } from "./data";
import { FeaturedCarousel } from "../../../../components/themed-v2/organisms";
import { getFeaturedTreatmentsLists } from "../../../../services/api/treatments";

import { config } from "../../../../config";

type HomePageData = typeof homePageData;
type FeatureTreatments = React.ComponentProps<typeof FeaturedCarousel>["items"];

interface UseHomePageData extends HomePageData {
  featureTreatments: FeatureTreatments;
  specialLink?: string;
  handleClickButton(): void;
}

export const useHomePageData = (): UseHomePageData => {
  const [featureTreatments, setFeatureTreatments] = useState<FeatureTreatments>(
    [],
  );

  useEffect(() => {
    getFeaturedTreatmentsLists().then((treatments) => {
      setFeatureTreatments(
        treatments
          .filter((t) => !t.isHidden)
          .map((treatment) => ({
            link: `/featured-treatments/${treatment.urn}`,
            imageUrl: treatment.imageUrl,
            title: treatment.name,
            text: `${treatment.description.slice(0, 250)}...`,
          })),
      );
    });
  }, []);

  const handleClickButton = useCallback(() => {
    // the easiest working solution without rewriting a lot of stuff on home page
    jQuery("html, body")
      .stop()
      .animate({ scrollTop: 0 }, 500, "swing", () => {
        jQuery(document).find("h2:contains('Browse availability')").first(); // search button is first on page
      });
  }, []);

  return {
    ...homePageData,
    featureTreatments,
    handleClickButton,
    specialLink: config.specialLink,
  };
};
