import * as React from "react";
import { pure } from "recompose";
import styled from "styled-components";

import { OrderSummarySection, Text } from "../../../../components/atoms";
import { TreatmentControl } from "../../../../components/molecules";
import { IServiceTreatment } from "../../../../services/api/therapists";

export interface IOrderSummaryTreatmentsProps {
  treatments: IServiceTreatment[];
  onAdd(service: IServiceTreatment): void;
  onRemove(urn: string): void;
}

const NORMAL_INNER_HEIGHT = 550;

const OrderSummaryTreatmentsContainer = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 17px 0;
  border-bottom: 1px solid #d6dbd7;

  :last-child {
    border-bottom: none;
  }
`;

const OrderSummaryTreatmentsGroup = styled.div`
  width: 70%;
  padding-right: 5px;
`;

const OrderSummaryTreatmentsPrice = styled(Text)`
  padding-right: 10px;
`;

const OrderSummarySectionTreatments = styled(OrderSummarySection)`
  max-height: ${window.innerHeight > NORMAL_INNER_HEIGHT ? "250px" : "80px"};
  padding: 0;
  overflow: auto;
`;

const OrderSummaryTreatments: React.FC<IOrderSummaryTreatmentsProps> = (
  props,
) => {
  const mapTreatments = (treatments: IServiceTreatment[]) => {
    return treatments.map((treatment) => {
      return (
        <OrderSummaryTreatmentsContainer key={treatment.urn}>
          <OrderSummaryTreatmentsGroup>
            <Text size="16" weight="bold">
              {treatment.name}
            </Text>
            <Text mobileSize="12" weight="semibold">
              {treatment.duration} minutes
            </Text>
          </OrderSummaryTreatmentsGroup>
          <OrderSummaryTreatmentsPrice size="16" weight="bold">
            £{treatment.price}
          </OrderSummaryTreatmentsPrice>
          <TreatmentControl
            onAdd={() => props.onAdd(treatment)}
            onRemove={() => props.onRemove(treatment.urn)}
            count={treatment.count}
          />
        </OrderSummaryTreatmentsContainer>
      );
    });
  };

  return (
    <OrderSummarySectionTreatments>
      {mapTreatments(props.treatments)}
    </OrderSummarySectionTreatments>
  );
};

const pureComponent = pure(OrderSummaryTreatments);

export { pureComponent as OrderSummaryTreatments };
