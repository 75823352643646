import React from "react";
import styled from "styled-components";

import { ButtonStickyContainer } from "../../atoms/button-sticky-container";
import { ButtonStyled } from "../../atoms/button-styled";
import { ButtonText } from "../../atoms/button-text";
import { ButtonInnerContainer } from "../../atoms/button-inner-container";
import { ButtonIconContainer } from "../../atoms/button-icon-container";
import { IconChevron } from "../../atoms";
import { IconSpinner } from "../../atoms/icons/spinner";


const PaymentButtonInnerContainer = styled(ButtonInnerContainer)`
  justify-content: start;
  text-transform: none;
  padding: 0 36px 0 16px;
`;

const PaymentButtonText = styled(ButtonText)`
  display: inline-block;
  width: 50%;
  text-align: left;
`;

const PaymentButtonLabel = styled(PaymentButtonText)`
  text-align: right;
`;

interface Props {
  price: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;  
}

export const PaymentButton = ({ price, onClick, isLoading, isDisabled }: Props): JSX.Element => (
  <ButtonStickyContainer>
    <ButtonStyled size="small" onClick={onClick} disabled={isLoading || isDisabled}>
      <PaymentButtonInnerContainer>
        <PaymentButtonText>{price}</PaymentButtonText>
        <PaymentButtonLabel>Order &amp; Pay</PaymentButtonLabel>
        <ButtonIconContainer>
          { isLoading ? <IconSpinner /> : <IconChevron /> }
        </ButtonIconContainer>
      </PaymentButtonInnerContainer>
    </ButtonStyled>
  </ButtonStickyContainer>
);
