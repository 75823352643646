import { ILoginUser } from "../../services/api/customer";
import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

import * as types from "./action-types";
import { actionCreators } from "./actions";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  error: string | null;
  isLogedIn: boolean;
  user?: ILoginUser;
  isProviderLoginError: boolean;
  isLoaded: boolean;
  isLoading: boolean;
}

const INITIAL_STATE: IState = {
  isLogedIn: false,
  user: undefined,
  error: null,
  isProviderLoginError: false,
  isLoaded: false,
  isLoading: false,
};

export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.LOGIN_USER: {
      return iassign(state, (s) => {
        s.isLogedIn = true;
        s.user = action.payload.data;

        return s;
      });
    }

    case types.LOGOUT_USER: {
      return iassign(state, (s) => {
        s.isLogedIn = false;
        s.user = undefined;

        return s;
      });
    }

    case types.SET_USER_FIELDS: {
      return iassign(state, (s) => {
        s.user = action.payload.data;
        return s;
      });
    }

    case types.REGISTRATION_SUCCESS: {
      return iassign(state, (s) => {
        s.isProviderLoginError = false;
        s.isLoading = false;
        s.isLoaded = true;
        if (s.user) {
          s.user.urn = action.payload.urn;
          s.user.authKey = action.payload.token;
        }
        return s;
      });
    }

    case types.REGISTRATION_FAILURE: {
      return iassign(state, (s) => {
        s.isLoading = false;
        s.isLoaded = true;
        s.error = action.payload.error.message;
        return s;
      });
    }

    default:
      return state;
  }
}
