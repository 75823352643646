import * as React from "react";

interface IProps {
  type: "facebook" | "pinterest" | "twitter" | "instagram" | "linkedin";
}

// tslint:disable:max-line-length
const FacebookLogo = (
  <svg
    id="FacebookLogo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="141.7 9.6 113.6 218.8"
  >
    <path
      fill="#BBBFBD"
      d="M215.4 228.4v-99.8h33.5l5-38.9h-38.5V64.9c0-11.3 3.1-18.9 19.3-18.9h20.6V11.2c-3.6-.5-15.8-1.5-30-1.5-29.7 0-50 18.1-50 51.4v28.7h-33.6v38.9h33.6v99.8h40.1z"
    />
  </svg>
);

const PinterestLogo = (
  <svg
    id="PinterestLogo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8.9 11.2"
  >
    <path
      fill="#BBBFBD"
      d="M2.4 11c.3-.5.7-1.2.8-1.8.1-.3.4-1.6.4-1.6.2.4.9.8 1.6.8 2.1 0 3.6-1.9 3.6-4.3 0-2.3-1.9-4-4.3-4C1.6 0 0 2 0 4.2c0 1 .5 2.3 1.4 2.7.1.1.2 0 .2-.1s.1-.6.2-.8v-.2c-.3-.3-.5-1-.5-1.6 0-1.5 1.2-3 3.1-3 1.7 0 2.9 1.2 2.9 2.8 0 1.9-.9 3.2-2.2 3.2-.7 0-1.2-.6-1-1.3.2-.8.6-1.7.6-2.3 0-.5-.3-1-.9-1-.7 0-1.3.7-1.3 1.7 0 .6.2 1 .2 1s-.6 3-.8 3.5c-.1.6-.1 1.4 0 1.9 0 .2.1.3.2.5.1-.1.2-.1.3-.2z"
    />
  </svg>
);

const TwitterLogo = (
  <svg
    id="TwitterLogo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="175.1 -1.6 249.9 203.2"
  >
    <path
      fill="#BBBFBD"
      d="M253.6 201.6c94.3 0 145.9-78.2 145.9-145.9 0-2.2 0-4.4-.1-6.6 10-7.2 18.7-16.3 25.6-26.6-9.2 4.1-19.1 6.8-29.5 8.1 10.6-6.3 18.7-16.4 22.6-28.4-9.9 5.9-20.9 10.1-32.6 12.4-9.4-10-22.7-16.2-37.4-16.2-28.3 0-51.3 23-51.3 51.3 0 4 .5 7.9 1.3 11.7-42.6-2.1-80.4-22.6-105.7-53.6-4.4 7.6-6.9 16.4-6.9 25.8 0 17.8 9.1 33.5 22.8 42.7-8.4-.3-16.3-2.6-23.2-6.4v.7c0 24.8 17.7 45.6 41.1 50.3-4.3 1.2-8.8 1.8-13.5 1.8-3.3 0-6.5-.3-9.6-.9 6.5 20.4 25.5 35.2 47.9 35.6-17.6 13.8-39.7 22-63.7 22-4.1 0-8.2-.2-12.2-.7 22.6 14.4 49.6 22.9 78.5 22.9"
    />
  </svg>
);

const InstagramLogo = (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="156 -156 512 512"
  >
    <path
      fill="#BBBFBD"
      d="M549.8-18.2c-10.9 0-19.7-8.8-19.7-19.7v-39.4c0-10.9 8.8-19.7 19.7-19.7h39.4c10.9 0 19.7 8.8 19.7 19.7v39.4c0 10.9-8.8 19.7-19.7 19.7h-39.4zM412 24.9c-14.3 0-27.5 4.2-38.9 11.1 3.5-1.1 7.2-1.8 11.1-1.8 21 0 38.1 17 38.1 38.1 0 21-17 38.1-38.1 38.1-21 0-38.1-17.1-38.1-38.1 0-3.9.8-7.6 1.9-11.1-6.9 11.4-11.1 24.6-11.1 38.9 0 41.5 33.6 75.1 75.1 75.1 41.5 0 75.1-33.6 75.1-75.1S453.5 24.9 412 24.9z"
    />
    <path
      fill="#BBBFBD"
      d="M572-156H252c-53 0-96 43-96 96v320c0 53 43 96 96 96h320c53 0 96-43 96-96V-60c0-53-43-96-96-96zM293.9 97.1v-1.2c0-.6 0-1.1.1-1.7 0-.5.1-1.1.1-1.6 0-.4 0-.8.1-1.2 0-.7.1-1.3.2-2 0-.3 0-.5.1-.8.1-.8.2-1.5.2-2.3v-.4c3-24.7 13.6-47 29.4-64.7h-.2C345.6-2.9 377-18.2 412-18.2c35 0 66.4 15.2 88.1 39.4h-.2c15.8 17.6 26.5 39.9 29.4 64.7v.4c.1.8.2 1.5.2 2.3 0 .3 0 .5.1.8.1.7.1 1.3.2 2 0 .4.1.8.1 1.2 0 .5.1 1.1.1 1.6 0 .6 0 1.1.1 1.7v4.1c0 65.3-52.9 118.2-118.2 118.2-65.2 0-118.2-52.9-118.2-118.2.1-1 .2-2 .2-2.9zm334.7-75.9h-80.2c-27.3-47-78.2-78.7-136.4-78.7S302.9-25.8 275.6 21.3h-80.2V-60c0-31.2 25.4-56.6 56.6-56.6h320c31.2 0 56.6 25.4 56.6 56.6v81.2z"
    />
  </svg>
);

const LinkedinLogo = (
  <svg
    id="LinkedinLogo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="270.7 76.1 53.4 51"
  >
    <g>
      <g id="g3019">
        <path
          id="path28"
          fill="#BBBFBD"
          d="M282.828 127.198V92.762h-11.41v34.436h11.41zm-5.706-39.225c3.974 0 6.52-2.65 6.52-5.91-.1-3.36-2.445-5.908-6.418-5.908-3.872 0-6.52 2.547-6.52 5.91 0 3.36 2.547 5.908 6.418 5.908z"
        />
        <path
          fill="#BBBFBD"
          d="M289.144 127.198h11.41v-19.256c0-1.02.103-2.038.41-2.75.814-2.038 2.75-4.178 5.908-4.178 4.177 0 5.807 3.158 5.807 7.743v18.44h11.41v-19.764c0-10.596-5.604-15.486-13.143-15.486-6.215 0-8.864 3.464-10.392 5.807h.102v-4.992h-11.41c.1 3.158-.103 34.436-.103 34.436z"
        />
      </g>
    </g>
  </svg>
);

const IconSocial: React.FC<IProps> = ({ type }) => {
  const icon = {
    facebook: FacebookLogo,
    instagram: InstagramLogo,
    linkedin: LinkedinLogo,
    pinterest: PinterestLogo,
    twitter: TwitterLogo,
  };

  return icon[type];
};

export { IconSocial };
