/**
 * Normalize objects to array of objects
 */
interface INormalized {
  [key: string]: object;
}

// tslint:disable:no-any
export function normalizeObjects(objects: INormalized): any[] {
  if (!objects) {
    return [];
  }

  return Object.keys(objects).map((object) => objects[object]);
}
