import * as React from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import { Button, IconX } from "../../../components/atoms";
import { TopLogo } from "../../../components/molecules";
import { menuData } from "../../../static/menu";
// import { theme } from "../../../theme";

import {
  INavUserProps,
  IProps as INavProps,
  setCategoryItem,
} from "../navigation";
import { NavigationItem } from "../navigation/components";

interface IProps extends INavUserProps, INavProps {
  isMobileMenuOpen: boolean;
  onClose(): void;
  onDownload(): void;
}

const duration = {
  enter: 0,
  exit: 0,
};

const mobileMenuStyle = {
  transition: `all 300ms`,
};

const mobileMenuStyles = {
  entered: { opacity: 1, left: 0 },
  entering: { opacity: 0, left: "-500px" },
  exited: { opacity: 0 },
  exiting: { opacity: 1 },
};

const mobileMenuBackdropStyles = {
  entered: { opacity: 1 },
  entering: { opacity: 0 },
  exited: { opacity: 0 },
  exiting: { opacity: 1 },
};

const MobileMenuWrapper = styled.div``;

const MobileMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  height: 100%;
  width: 280px;
  padding: 20px;
  background: white;
  overflow: auto;
  z-index: 9999;
`;

const MobileMenuPart = styled.div``;

const MobileMenuBackdrop = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`;

const MobileMenuLogoBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const MobileMenuCloseIcon = styled.div`
  > svg {
    width: 30px;
    height: 30px;
  }
`;

const MobileMenuButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const MobileMenu: React.FC<IProps> = (props) => {
  const menuItems = menuData.map((category, index) => {
    if (!props.isLoggedIn && category.id === "login") {
      return undefined;
    }

    const navigationItems = (
      <NavigationItem key={index} onClick={props.onClose}>
        {setCategoryItem(category, props)}
      </NavigationItem>
    );

    return navigationItems;
  });

  const onDownloadButtonClick = () => {
    props.onDownload();
    props.onClose();
  };

  return (
    <Transition in={props.isMobileMenuOpen} timeout={duration}>
      {(state) =>
        props.isMobileMenuOpen && (
          <MobileMenuWrapper>
            <MobileMenuContent
              style={{
                ...mobileMenuStyle,
                ...mobileMenuStyles[state],
              }}
            >
              <MobileMenuPart>
                <MobileMenuLogoBar>
                  <TopLogo onClick={props.onClose} />
                  <MobileMenuCloseIcon onClick={props.onClose}>
                    <IconX />
                  </MobileMenuCloseIcon>
                </MobileMenuLogoBar>
                {menuItems}
              </MobileMenuPart>
              <MobileMenuPart>
                {!props.isLoggedIn && (
                  <MobileMenuButton
                    size="small"
                    background="grey"
                    to="/login"
                    onClick={props.onClose}
                  >
                    Log In
                  </MobileMenuButton>
                )}
                <MobileMenuButton
                  size="small"
                  background="blue"
                  onClick={onDownloadButtonClick}
                >
                  Get the app
                </MobileMenuButton>
              </MobileMenuPart>
            </MobileMenuContent>
            <MobileMenuBackdrop
              style={{
                ...mobileMenuStyle,
                ...mobileMenuBackdropStyles[state],
              }}
              onClick={props.onClose}
            />
          </MobileMenuWrapper>
        )
      }
    </Transition>
  );
};

export { MobileMenu };
