import { ApiError } from "../error-handler";
import { getHandler } from "../request-handler";

interface IUser {
  urn: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  referralCode?: string;
  referralReward?: number;
  referralDeduction?: number;
  mailListId: string;
  needsToConfirmGdpr: boolean;
}

export async function fetchUser(): Promise<IUser> {
  const response = await getHandler<IUser>("/customer", true);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

 