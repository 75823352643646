import * as React from "react";
import { Helmet } from "react-helmet";

import {
  AboutEvents,
  AboutIntro,
  AboutOffer,
} from "../../../components/organisms";
import { MainTemplate } from "../../../components/templates/main";
import { metaData } from "../../../static/meta";

const AboutPage: React.FC = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>{metaData.about.title}</title>
        <meta name="description" content={metaData.about.description} />
      </Helmet>
      <AboutIntro />
      <AboutOffer />
      <AboutEvents />
    </MainTemplate>
  );
};

export { AboutPage };
