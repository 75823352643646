import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import {
  Heading3,
  PanelDark,
  Paragraph,
} from "../../../components/themed-v2/atoms";

const StyledPanelDark = styled(PanelDark)`
  cursor: pointer;
`;

const StyledParagraph = styled(Paragraph)`
  margin: ${rem("8px")} 0;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  color: ${({ theme }) => theme.colours.sectionPanel.text};
`;

interface IProps {
  jobTitle: string;
  location: string;
  onClick(): void;
  className?: string;
}

export const JobPanel: React.FC<IProps> = ({
  jobTitle,
  location,
  onClick,
  className,
}) => (
  <StyledPanelDark className={className} onClick={onClick}>
    <div>
      <Heading3>{jobTitle}</Heading3>
      <StyledParagraph>{location}</StyledParagraph>
    </div>
  </StyledPanelDark>
);
