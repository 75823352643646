import * as React from "react";
import { pure } from "recompose";
import styled from "styled-components";

import { IconDate, Text } from "../../../../components/atoms";
import { IServiceTreatment } from "../../../../services/api/therapists";
import { theme } from "../../../../theme";
import { dateFromIso } from "../../../../utils/index";

interface IProps {
  date: string;
  treatments: IServiceTreatment[];
  mobile?: boolean;
}

const OrderSummaryTimeWrapper = styled.div`
  display: inline-flex;

  > svg {
    margin-right: 10px;
    width: 14px;

    path {
      fill: ${theme.color.ruubyGreyText};
    }
  }

  ${(props: Partial<IProps>) => props.mobile && "flex-direction: column"};
`;

const OrderSummaryTime: React.FC<IProps> = (props) => {
  const date = dateFromIso(props.date).toFormat("ccc, dd LLL,");
  const time = dateFromIso(props.date).toFormat("h:mm a");

  const minutesTotal =
    props.treatments.length > 0
      ? props.treatments
          // prevent crash if duration is missing
          // probably not a case for a prod but will leave it here for a while
          // tslint:disable-next-line: strict-boolean-expressions
          .map((treatment) => (treatment.duration || 0) * treatment.count)
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;

  const endTime = dateFromIso(props.date)
    .plus({ minutes: minutesTotal })
    .toFormat("h:mm a");

  return (
    <OrderSummaryTimeWrapper mobile={props.mobile}>
      {props.mobile ? (
        <>
          <Text size="14">{date}</Text>
          <Text>
            {time} {props.treatments.length > 0 && ` - ${endTime}`}
          </Text>
        </>
      ) : (
        <>
          <IconDate />
          <Text size="14">
            {date} {time} {props.treatments.length > 0 && ` - ${endTime}`}
          </Text>
        </>
      )}
    </OrderSummaryTimeWrapper>
  );
};

const pureComponent = pure(OrderSummaryTime);

export { pureComponent as OrderSummaryTime };
