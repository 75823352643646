import * as React from "react";

import { Tooltip } from "../../components/molecules";

interface IProps {
  isShow: boolean;
  onClose?(): void;
  text: string;
}

interface IState {
  isShow: boolean;
}

class TooltipPopoverContainer extends React.Component<IProps, IState> {
  public state: IState = {
    isShow: false,
  };

  public componentDidMount() {
    this.setState({ isShow: this.props.isShow });
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isShow !== prevProps.isShow) {
      this.setState({ isShow: this.props.isShow });
    }
  }

  // tslint:disable-next-line: completed-docs
  public render() {
    return (
      <Tooltip
        isShow={this.state.isShow}
        text={this.props.text}
        onClose={this.props.onClose}
      />
    );
  }
}

export { TooltipPopoverContainer };
