import * as React from "react";
import { Helmet } from "react-helmet";

import { metaData } from "../../../../static/meta";
import { MainTemplate } from "../../../templates/main";
import { Corporate } from "../../organisms/corporate";

export const CorporatePage: React.FC = () => {
  return (
    <MainTemplate backgroundColor="white">
      <Helmet>
        <title>{metaData.corporate.title}</title>
        <meta name="description" content={metaData.corporate.description} />
      </Helmet>
      <Corporate />
    </MainTemplate>
  );
};
