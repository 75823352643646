import * as React from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  backgroundColor?: "grey" | "pink";
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
  margin-top: 50px;

  ${(props: IProps) =>
    props.backgroundColor === "pink"
      ? `background-color: ${theme.color.ruubyPink};`
      : `background-color: ${theme.color.ruubyGreyBg};`};
`;

const CommonTemplate: React.FC<IProps> = (props) => {
  return (
    <Wrapper backgroundColor={props.backgroundColor}>{props.children}</Wrapper>
  );
};

export { CommonTemplate };
