import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as _ from "lodash";

import {
  ApiAddress,
  fetchUserAddresses,
} from "../../../../../../services/api/addresses";
import { useBookingCheckoutContext } from "../../provider";

type UseAddress = () => {
  address?: string;
  isLoading: boolean;
};

// TODO: Move to @ruuby/common
/** Get the district code of an address, or `undefined` if the pattern isn't recognized. */
function getDistrictCode(postcode: string): string | undefined {
  // Strip whitespace
  const nospace = _.filter(postcode, (c) => c !== " ").join("");
  const pattern = _.map(nospace, (c) => (/\d/.test(c) ? "9" : "A")).join("");
  // Source: https://ideal-postcodes.co.uk/guides/uk-postcode-format
  const dcLength = {
    AA9A9AA: 3,
    A9A9AA: 2,
    A99AA: 2,
    A999AA: 3,
    AA99AA: 3,
    AA999AA: 4,
  }[pattern];
  return dcLength ? nospace.slice(0, dcLength) : undefined;
}

/** Check whether the district code of both postcodes is recognized and identical. */
function districtCodesMatch(postcode1: string, postcode2: string): boolean {
  const dc1 = getDistrictCode(postcode1);
  return (dc1 && dc1 === getDistrictCode(postcode2)) || false;
}

const selectDefaultAddress = (
  addresses: ApiAddress[],
  postcodeSearched: string | undefined,
): ApiAddress | undefined => {
  if (addresses?.length === 0 || !postcodeSearched) {
    return;
  }
  const upcaseFilter = postcodeSearched.toUpperCase();

  return (
    _.findLast(addresses, (a) => a.postcode === upcaseFilter) ?? // exact postcode match
    _.findLast(
      addresses,
      (a) => getDistrictCode(a.postcode) === upcaseFilter,
    ) ?? // exact district code match
    _.findLast(addresses, (a) => districtCodesMatch(a.postcode, upcaseFilter)) // postcode in the same district
  );
};
// TODO: End

export const useAddress: UseAddress = () => {
  const [address, setAddress] = useState<string | undefined>();
  const { bookingParams, updateBookingParams } = useBookingCheckoutContext();

  const { data, status } = useQuery(
    "addressBook",
    async () => await fetchUserAddresses(),
  );

  // set address if found in address book
  useEffect(() => {
    if (data) {
      const foundAddress = data.find((a) => a.urn === bookingParams.addressUrn);

      if (foundAddress) {
        const { address1, address2, postcode } = foundAddress;
        setAddress(
          `${address1} ${
            address2 ? `, ${address2}` : ""
          } ${postcode.toUpperCase()}`,
        );
        updateBookingParams({
          address: foundAddress,
        });
      }
    }
  }, [data, bookingParams.addressUrn, setAddress, updateBookingParams]);

  useEffect(() => {
    if (data) {
      const matchedAddress = selectDefaultAddress(data, bookingParams.postcode);

      if (matchedAddress) {
        updateBookingParams({ addressUrn: matchedAddress.urn });
      }
    }
  }, [data, bookingParams.postcode, updateBookingParams]);

  return { address, isLoading: status === "loading" };
};
