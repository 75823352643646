import * as React from "react";

// tslint:disable:max-line-length
const IconMail = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      enableBackground="new 0 0 24 24"
    >
      <g id="Outline">
        <g id="ui_x5F_spec_x5F_header" display="none" />
        <path
          id="XMLID_497_"
          d="M22,6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6z M20,6l-8,4.99L4,6H20z
		 M20,18L4,18V8l8,5l8-5V18z"
        />
      </g>
    </svg>
  );
};

export { IconMail };
