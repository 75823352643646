import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import { useTruncate } from "../../../../../../hooks/use-truncate";
import { device } from "../../../../../../utils";

const MAX_WORDS_LIMIT = 50;

const TherapistBioText = styled.p`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  line-height: ${({ theme }) => theme.type.sizes.size22};
  color: ${({ theme }) => theme.colours.textGrey};
  margin: 0;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const ShowFullBio = styled.span`
  color: ${({ theme }) => theme.colours.textBlue};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  text?: string;
}

const BioContent = ({ text }: Required<Props>): JSX.Element => {
  const {
    displayText,
    isFullTextShown,
    isTextLongerThanLimit,
    toggleShowFullText,
  } = useTruncate({
    text,
    maxWordsLimit: MAX_WORDS_LIMIT,
  });

  return (
    <>
      {displayText}{" "}
      {!isFullTextShown && isTextLongerThanLimit && (
        <ShowFullBio onClick={toggleShowFullText}>See More</ShowFullBio>
      )}
    </>
  );
};

export const TherapistBio = ({ text }: Props): JSX.Element => (
  <TherapistBioText data-testid="therapist-bio">
    {text ? <BioContent text={text} /> : <Skeleton count={4} />}
  </TherapistBioText>
);
