import styled from "styled-components";

import { device } from "../../../../utils";

export const InnerButton = styled.button<{ floating?: boolean }>`
  height: 25px;
  display: inline-flex;
  padding: 0px 6px 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  margin: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colours.background.greyFaint};
  color: ${({ theme }) => theme.colours.textBlue};
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
  ${(props) => props.floating && `
    position: relative;
    float: right;
    top: -30px;
  `}
`;