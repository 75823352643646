import React, { useMemo } from "react";
import styled from "styled-components";

import { formatPrice } from "../../../../../../utils/format-price-for-display";
import { IconInfo, Text } from "../../../../atoms";
import { Row } from "../row";
import { Section } from "../section";
import Skeleton from "react-loading-skeleton";
import { useBookingTotal } from "../../hooks/use-booking-total";
import { useBookingCheckoutContext } from "../../provider";

const FeeInfo = styled.div`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  position: relative;
  display: inline;
  top: 3px;
  cursor: pointer;
`;

const HR = styled.hr`
  width: 100%;
  height: 1px;
  border: 0px;
  background-color: #C3C6CF;
  position: relative;
  top: 5px;
`;

interface SummarySectionProps {
  onClickFeeInfo: () => void;
}

export const SummarySection = ({ onClickFeeInfo }: SummarySectionProps ): JSX.Element => {
  const { bookingParams, isBookingBeingPlaced } = useBookingCheckoutContext();
  const { addedTreatments, promo } = bookingParams;

  const duration = useMemo(() => 
    addedTreatments.reduce((sum, t) => sum + t.duration * t.quantity, 0), 
    [addedTreatments],
  );

  const {
    subTotal,
    total,
    bookingFee,
    creditsDeduction,
    promotionDeduction,
    isLoading: isBookingTotalLoading,
  } = useBookingTotal();

  return (
    <Section small>
      {isBookingTotalLoading ? (
        <Skeleton count={3} />
      ) : (
        <>
          {addedTreatments?.map((treatment, i) => (
            <Row key={`treatment-description-${i}`} baseline>
              <Text weight="bold" flexGrow={1}>
                {treatment.quantity > 1 && `${treatment.quantity}x `}
                {treatment.name}
              </Text>
              <Text>{formatPrice(treatment.price * treatment.quantity)}</Text>
            </Row>
          ))}
          <Row>
            <Text>{duration} minutes</Text>
          </Row>
          <HR/>
          <Row baseline marginTop>
            <Text flexGrow={1}>Subtotal</Text>
            <Text>{formatPrice(subTotal)}</Text>
          </Row>
          <Row baseline>
            <Text flexGrow={1}>
              Booking fee
              &nbsp;
              <FeeInfo onClick={() => !isBookingBeingPlaced && onClickFeeInfo()}>
                <IconInfo width="15px" height="15px" />
              </FeeInfo>
            </Text>
            <Text>{formatPrice(bookingFee)}</Text>
          </Row>
          {promo && promotionDeduction ? (
            <Row baseline>
              <Text flexGrow={1}>{promo.code}</Text>
              <Text>-{formatPrice(promotionDeduction)}</Text>
            </Row>
          ) : null}
          {creditsDeduction ? (
            <Row baseline>
              <Text flexGrow={1}>Credit</Text>
              <Text>-{formatPrice(creditsDeduction)}</Text>
            </Row>
          ) : null}
          <HR/>
          <Row baseline marginTop>
            <Text weight="bold" flexGrow={1}>Total to pay</Text>
            <Text weight="bold">{formatPrice(total)}</Text>
          </Row>
        </>
      )}
    </Section>
  );
};
