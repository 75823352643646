import React from "react";

import { ReviewPanel } from "../../../../../components/themed-v2/molecules";
import { TatlerLogo } from "../../category-landing-page/data";
import { FeaturedCarousel } from "../../../../../components/themed-v2/organisms";

interface HomePageData {
  reviews: React.ComponentProps<typeof ReviewPanel>[];
  mostPopularTreatments: React.ComponentProps<typeof FeaturedCarousel>["items"];
}

export const homePageData: HomePageData = {
  reviews: [
    {
      customer: "Emma",
      review:
        "Ruuby is fantastic! Being able to have treatments at home is so useful. The therapists I have booked have always been extremely professional and the final result brilliant. I couldn’t recommend Ruuby enough!",
    },
    {
      logo: <TatlerLogo />,
      review:
        "Ruuby, the fashion set's go-to digital beauty concierge founded four years ago, has gathered an impeccable reputation for providing salon and medi spa level treatments in the indulgent comfort of one’s home.",
    },
    {
      customer: "Suzy",
      review:
        "I booked a massage with a 5 star rated therapist, and she was amazing. The app is easy to use and there is an immediate response to any questions you have. Effortless. Professional and value for money in your own home.",
    },
  ],
  mostPopularTreatments: [
    {
      title: "Bespoke Blowdry",
      text: "The best blow dries in the business. Choose from bouncy to sleek, our stylists will deliver a bespoke blow-dry to your personal preferences.",
      imageUrl: "images/home/popular/blowdry.jpg",
      internalImage: true,
      link: "/mobile-beauty/hair",
    },
    {
      title: "Ruuby Bespoke Massage",
      text: "Designed to up-lift and re-energise or relax and de-stress, our masseuses will create a personalised massage targeted to your individual needs, pressure preferences and focus areas.",
      imageUrl: "images/home/popular/massage.jpg",
      internalImage: true,
      link: "/mobile-beauty/massage",
    },
    {
      title: "Gel Manicure",
      text: "A gel manicure is chip free and lasts up to two weeks. Nails are filed and buffed, cuticles tidied and hands moisturised during a relaxing hand massage. Choose from a large selection of gel nail polish colours.",
      imageUrl: "images/home/popular/manicure.jpg",
      internalImage: true,
      link: "/mobile-beauty/nails",
    },
  ],
};
