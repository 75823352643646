import * as React from "react";
import { RateBooking } from "../../../components/organisms";
import { MainTemplate } from "../../../components/templates/main";
import * as H from "history";

interface IProps {
  bookingId: string;
  history: H.History;
}
type Props = IProps;

const RatingPage: React.FC<Props> = (props) => {
  return (
    <MainTemplate>
      <RateBooking bookingId={props.bookingId} history={props.history} />
    </MainTemplate>
  );
};

export { RatingPage };
