import styled from "styled-components";

import { theme } from "../../../../theme";

const Header = styled.div`
  font-family: ${theme.fontName.heading};
  font-size: ${theme.fontSize.header};
  color: ${theme.color.ruubyGrey};
`;

export { Header };
