import { ApiError } from "../error-handler";
import { deleteHandler, getHandler, postHandler, putHandler } from "../request-handler";
import { ICheckoutAddress } from "../../presenters/booking-location/booking-location-presenter";

export interface ApiAddress {
  urn: string;
  address1: string;
  address2: string;
  postcode: string;
}

export async function fetchUserAddresses(): Promise<ApiAddress[]> {
  const response = await getHandler<ApiAddress[]>("/customer/address", true);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

export async function saveUserAddress(
  address: ICheckoutAddress,
): Promise<ApiAddress> {
  const body = JSON.stringify(address);
  const response = await postHandler<ApiAddress>(
    "/customer/address",
    body,
    true,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

export async function deleteUserAddress(urn: string): Promise<void> {
  const response = await deleteHandler(`/customer/address/${urn}`, null, true);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

interface UpdateAddressParams extends ICheckoutAddress {
  "@id": string;
}

export async function updateUserAddress(
  address: UpdateAddressParams,
): Promise<ApiAddress> {
  const body = JSON.stringify(address);
  const response = await putHandler(`/customer/address`, body, true);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}
