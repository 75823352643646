import { inflateBookingLocationPresenter } from "../../presenters/booking-location";
import { dateFromIso } from "../../utils/index";

import {
  checkTherapistServicedPostcode,
  fetchTherapistAvailability,
  fetchTherapistsAvailabilityForDay,
  ISearchTherapistsParamsOptions,
  ITherapist,
  ITherapistAvailability,
  ITherapistsReduced,
  searchTherapists,
  IApiTherapistReview,
  fetchTherapistReviews,
} from "../../services/api/therapists";
import { ActionDispatch } from "../index";
import { deNormalizeArray } from "../utils";

import { actionCreators as postcodeActions } from "../postcodes/actions";

import * as types from "./action-types";

export const actionCreators = {
  clearAdditionalTherapists: () => ({
    type: types.CLEAR_ADDITIONAL_THERAPISTS,
  }),
  clearDate: () => ({
    type: types.CLEAR_DATE,
  }),
  clearTherapists: () => ({
    type: types.CLEAR_THERAPISTS,
  }),
  deSelectTherapist: () => ({
    type: types.DESELECT_THERAPIST,
  }),
  fetchAdditionalTherapistsSearchAttempt: () => ({
    type: types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_ATTEMPT,
  }),
  fetchAdditionalTherapistsSearchFailure: (error: Error) => ({
    payload: { error },
    type: types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_FAILURE,
  }),
  fetchAdditionalTherapistsSearchSuccess: (data: ITherapistsReduced) => ({
    payload: { data },
    type: types.FETCH_ADDITIONAL_THERAPISTS_SEARCH_SUCCESS,
  }),
  fetchTherapistsAvailabilityAttempt: () => ({
    type: types.FETCH_THERAPISTS_AVAILABILITY_ATTEMPT,
  }),
  fetchTherapistsAvailabilityFailure: (error: Error) => ({
    payload: { error },
    type: types.FETCH_THERAPISTS_AVAILABILITY_FAILURE,
  }),
  fetchTherapistsAvailabilitySuccess: (data: ITherapistAvailability) => ({
    payload: { data },
    type: types.FETCH_THERAPISTS_AVAILABILITY_SUCCESS,
  }),
  fetchTherapistsReviewsAttempt: () => ({
    type: types.FETCH_THERAPISTS_REVIEWS_ATTEMPT,
  }),
  fetchTherapistsReviewsFailure: (error: Error) => ({
    payload: { error },
    type: types.FETCH_THERAPISTS_REVIEWS_FAILURE,
  }),
  fetchTherapistsReviewsSuccess: (data: IApiTherapistReview[]) => ({
    payload: { data },
    type: types.FETCH_THERAPISTS_REVIEWS_SUCCESS,
  }),
  fetchTherapistsSearchAttempt: () => ({
    type: types.FETCH_THERAPISTS_SEARCH_ATTEMPT,
  }),
  fetchTherapistsSearchFailure: (error: Error) => ({
    payload: { error },
    type: types.FETCH_THERAPISTS_SEARCH_FAILURE,
  }),
  fetchTherapistsSearchSuccess: (data: ITherapistsReduced) => ({
    payload: { data },
    type: types.FETCH_THERAPISTS_SEARCH_SUCCESS,
  }),
  selectTherapist: (data: ITherapist) => ({
    payload: { data },
    type: types.SELECT_THERAPIST,
  }),
  setDate: (data: string) => ({
    payload: { data },
    type: types.SET_DATE,
  }),
  setSubCategories: (data: string[]) => ({
    payload: { data },
    type: types.SET_THERAPISTS_SUBCATEGORIES,
  }),
};

/**
 * Dispatch therapists search action for reducer
 * Call API to get therapists with search params from store
 */
export function fetchTherapistsSearch(
  options?: ISearchTherapistsParamsOptions,
  additional?: boolean,
): ActionDispatch<void> {
  return async (dispatch, getState) => {
    if (additional) {
      dispatch(actionCreators.fetchAdditionalTherapistsSearchAttempt());
    } else {
      dispatch(actionCreators.fetchTherapistsSearchAttempt());
    }

    try {
      if (!getState) {
        throw new Error("Could not get state");
      }

      const categoryState = getState().categoriesState.selectedCategory;
      const categoryFeaturedTreatmentState =
        getState().categoriesState.selectedCategoryFeaturedTreatment;

      const stateBookingLocation = getState().postcodesState.bookingLocation;

      if (!stateBookingLocation) {
        throw new Error();
      }

      const bookingLocation =
        inflateBookingLocationPresenter(stateBookingLocation);

      if (!bookingLocation) {
        throw new Error();
      }

      const outcode = bookingLocation.getPostCodeOutwardCode();
      const treatmentUrn = categoryFeaturedTreatmentState
        ? { treatmentUrn: categoryFeaturedTreatmentState.urn }
        : undefined;

      const params = {
        ...options,
        ...treatmentUrn,
        category: categoryState,
        outcode,
      };

      const therapists = await searchTherapists(params);
      const reducedList = deNormalizeArray(therapists, "urn");
      const subCategories = therapists
        .reduce<string[]>((accumulator, currentValue) => {
          return [...accumulator, ...currentValue.categories];
        }, [])
        .reduce<string[]>((accumulator, currentValue) => {
          if (accumulator.indexOf(currentValue) < 0) {
            accumulator.push(currentValue);
          }

          return accumulator;
        }, []);

      if (additional) {
        dispatch(
          actionCreators.fetchAdditionalTherapistsSearchSuccess(reducedList),
        );
      } else {
        dispatch(actionCreators.fetchTherapistsSearchSuccess(reducedList));
        dispatch(actionCreators.setSubCategories(subCategories));
      }
    } catch (error) {
      if (error instanceof Error) {
        if (additional) {
          dispatch(
            actionCreators.fetchAdditionalTherapistsSearchFailure(error),
          );
        } else {
          dispatch(actionCreators.fetchTherapistsSearchFailure(error));
        }
      }
    }
  };
}

/**
 * Dispatch therapists search action for reducer
 * Call API to get therapists available time for day
 */
export function getTherapistsAvailabilityForDay(
  day: string,
  category?: string,
): ActionDispatch<void> {
  return async (dispatch, getState) => {
    dispatch(actionCreators.fetchTherapistsAvailabilityAttempt());

    try {
      if (!getState) {
        throw new Error("Could not get state");
      }

      const stateBookingLocation = getState().postcodesState.bookingLocation;

      if (!stateBookingLocation) {
        throw new Error();
      }

      const bookingLocation =
        inflateBookingLocationPresenter(stateBookingLocation);

      if (!bookingLocation) {
        throw new Error();
      }

      const outcode = bookingLocation.getPostCodeOutwardCode();
      const passsedCategory = category ? { category } : undefined;
      const params = {
        ...passsedCategory,
        day,
        outcode,
      };

      const availableDates = await fetchTherapistsAvailabilityForDay(params);

      const firstAvailabledDate = availableDates[0];
      const dateGroup = dateFromIso(firstAvailabledDate)
        .startOf("day")
        .setZone("utc")
        .toFormat("yyyy-LL-dd'T'HH:mm:ss'.000Z'");

      const therapistsAvailableDates = {};
      therapistsAvailableDates[dateGroup] = availableDates;

      dispatch(
        actionCreators.fetchTherapistsAvailabilitySuccess(
          therapistsAvailableDates,
        ),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(actionCreators.fetchTherapistsAvailabilityFailure(error));
      }
    }
  };
}

/**
 * Dispatch therapists search action for reducer
 * Call API to get therapists with search params from store
 */
export function getTherapistsAvailability(
  services?: string[],
): ActionDispatch<void> {
  return async (dispatch, getState) => {
    dispatch(actionCreators.fetchTherapistsAvailabilityAttempt());

    try {
      if (!getState) {
        throw new Error("Could not get state");
      }

      const therapistState = getState().therapistsState.selectedTherapist;
      const servicesList = services || [];

      const stateBookingLocation = getState().postcodesState.bookingLocation;

      if (!stateBookingLocation || !therapistState) {
        throw new Error();
      }

      const bookingLocation =
        inflateBookingLocationPresenter(stateBookingLocation);

      if (!bookingLocation) {
        throw new Error();
      }

      const outcode = bookingLocation.getPostCodeOutwardCode();
      // check if therapist is serving this postcode
      const isPostcodeServed = await checkTherapistServicedPostcode(
        therapistState.urn,
        outcode,
      );

      if (!isPostcodeServed) {
        dispatch(
          actionCreators.fetchTherapistsAvailabilityFailure(
            new Error(
              `Sorry, this therapist does not serve postcode ${outcode}.`,
            ),
          ),
        );
        dispatch(postcodeActions.clearAddress());

        return;
      }

      const availableDates = await fetchTherapistAvailability(
        therapistState.urn,
        servicesList,
      );

      dispatch(
        actionCreators.fetchTherapistsAvailabilitySuccess(availableDates),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(actionCreators.fetchTherapistsAvailabilityFailure(error));
      }
    }
  };
}

/**
 * Get reviews
 */
export function getTherapistReviews(): ActionDispatch<void> {
  return async (dispatch, getState) => {
    dispatch(actionCreators.fetchTherapistsReviewsAttempt());

    try {
      if (!getState) {
        throw new Error("Could not get state");
      }

      const selectedTherapist = getState().therapistsState.selectedTherapist;
      if (!selectedTherapist) {
        throw new Error();
      }

      const selectedTherapistId = selectedTherapist.urn;
      const reviews = await fetchTherapistReviews(selectedTherapistId);

      dispatch(actionCreators.fetchTherapistsReviewsSuccess(reviews));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(actionCreators.fetchTherapistsReviewsFailure(error));
      }
    }
  };
}
