import * as React from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  className?: string;
  label?: string;
  name?: string;
  onBlur?(e: React.FocusEvent<HTMLTextAreaElement>): void;
  onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void;
  onFocus?(e: React.FocusEvent<HTMLTextAreaElement>): void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  value?: string;
}

const StyledTextarea = styled.textarea`
  width: 100%;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${theme.color.borderColor};
  border-radius: 5px;
  background-color: #ffffff;
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.main};
  font-weight: 600;
  text-align: left;
  line-height: 17px;
  color: ${theme.color.ruubyGrey};
  outline: none;
  transition: all ${theme.transition.easeInOut03};

  &:focus {
    box-shadow: 0 0 0 1px ${theme.color.ruubyBlue};
  }

  &:disabled {
    background-color: ${theme.color.ruubyGreyLight};
  }

  @media screen and (max-width: 992px) {
    font-size: ${theme.fontSize.bigger};
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 16px;
  top: 8px;
  font-size: ${theme.fontSize.smaller};
  font-weight: 600;
`;

const TextareaWrapper = styled.div`
  position: relative;
`;

const Textarea: React.FC<IProps> = (props) => {
  return (
    <TextareaWrapper className={props.className}>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <StyledTextarea
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        required={props.required}
        rows={props.rows}
        value={props.value}
      />
    </TextareaWrapper>
  );
};

export { Textarea };
