import { useState } from "react";

type UseTruncate = (params: { text: string; maxWordsLimit?: number }) => {
  displayText: string;
  isFullTextShown: boolean;
  isTextLongerThanLimit: boolean;
  toggleShowFullText(): void;
};

export const useTruncate: UseTruncate = ({ text, maxWordsLimit = 50 }) => {
  const [isFullTextShown, setIsFullTextShown] = useState(false);

  const words = text.split(" ");
  const isTextLong = words.length > maxWordsLimit;

  const displayText =
    isTextLong && !isFullTextShown
      ? `${words.slice(0, maxWordsLimit).join(" ")}...`
      : text;

  return {
    displayText,
    isFullTextShown,
    isTextLongerThanLimit: isTextLong,
    toggleShowFullText: () => setIsFullTextShown((prevState) => !prevState),
  };
};
