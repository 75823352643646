import * as React from "react";

import { Modal } from '../../../themed-v2/molecules/modal';
import { SearchWidget } from "../../../themed-v2/organisms/search-widget";

interface IProps {
  treatmentName?: string;
  featuredTreatmentUrn?: string;
  isShow: boolean;
  onBook(): void;
  onCancel(): void;
}

const SearchFormTreatmentsModal: React.FC<IProps> = (props) => {
  return (
    <Modal
      isShow={props.isShow}
      onClose={props.onCancel}
      title={props.treatmentName}
    >
      <SearchWidget featuredTreatmentUrn={props.featuredTreatmentUrn} />
    </Modal>
  );
};

export { SearchFormTreatmentsModal };
