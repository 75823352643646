import * as React from "react";
import styled from "styled-components";

import { Text } from "../../../../components/atoms";
import { TextDoubleHeader } from "../../../../components/molecules";
import { theme } from "../../../../theme";

interface IWrapperProps {
  totalAmount: number;
}

interface IProps extends IWrapperProps {
  innerRef: React.RefObject<HTMLDivElement>;
}

const PaymentDetailsText = styled(Text)`
  color: ${theme.color.borderColor};
`;

const PaymentDetailsBlock = styled.div`
  max-width: 485px;
  margin: 0 auto;

  &&& {
    [data-braintree-id="card"] {
      border-radius: 8px;
      background: ${theme.color.borderColorGrey};
      border-color: ${theme.color.borderColorGrey};
    }

    [data-braintree-id="card-sheet-header"],
    .braintree-heading {
      font-family: ${theme.fontName.main};
      font-weight: 700;
      font-size: ${theme.fontSize.main};
      color: ${theme.color.ruubyBlack};
    }

    [data-braintree-id="methods-edit"],
    [data-braintree-id="toggle"] {
      font-family: ${theme.fontName.main};
      font-weight: 600;
      font-size: ${theme.fontSize.smaller};
      color: ${theme.color.ruubyBlack};
      text-transform: uppercase;
      text-decoration: none;
    }

    .braintree-method__label {
      font-family: ${theme.fontName.main};
      font-size: ${theme.fontSize.main};
      color: ${theme.color.ruubyBlack};
    }

    .braintree-sheet__text {
      font-family: ${theme.fontName.main};
      font-size: ${theme.fontSize.main};
      color: ${theme.color.ruubyBlack};
    }

    .braintree-sheet__content--form {
      padding: 10px 20px 20px 15px;
    }

    .braintree-form__label {
      padding-top: 16px;
      font-family: ${theme.fontName.main};
      font-size: ${theme.fontSize.smaller};
      color: ${theme.color.ruubyBlack};
      text-transform: uppercase;
    }

    .braintree-form__field {
      background: white;
    }

    .braintree-form__hosted-field {
      font-family: ${theme.fontName.main};
      border-color: ${theme.color.inputBorderColor};
    }

    [data-braintree-name="expirationDate"] {
      font-family: ${theme.fontName.main};
      font-size: ${theme.fontSize.main};
      color: ${theme.color.ruubyBlack};
    }

    #braintree__card-view-input__cardholder-name {
      font-size: ${theme.fontSize.main};
      font-weight: normal;
    }
  }
`;

const PaymentDetailsWrapper = styled.div`
  margin-bottom: 32px;
  padding-top: 32px;
  border-top: 1px solid ${theme.color.inputBorderColor};

  ${(props: IWrapperProps) => {
    return props.totalAmount === 0
      ? `
      position: absolute;
      left: -9999px;
    `
      : undefined;
  }}
`;

const PaymentDetails: React.FC<IProps> = (props) => {
  return (
    <PaymentDetailsWrapper totalAmount={props.totalAmount}>
      <TextDoubleHeader>
        <Text size="20" weight="bold" transform="uppercase">
          Payment Details
        </Text>
        <PaymentDetailsText size="12" transform="uppercase">
          SECURE PAYMENT
        </PaymentDetailsText>
      </TextDoubleHeader>
      <PaymentDetailsBlock ref={props.innerRef} />
    </PaymentDetailsWrapper>
  );
};

export { PaymentDetails };
