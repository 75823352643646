import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  addDays,
  addMonths,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  max,
  parseISO,
  startOfDay,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useScreenClass } from "react-grid-system";
import styled from "styled-components";

import { Calendar } from "./calendar";
import { device } from "../../../../../utils";
import { IconCaret } from "../../../../atoms/icons-group";

const FormDatePickerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing.size18}`};

  &:hover {
    cursor: pointer;
  }

  div[data-lastpass-icon-root] {
    display: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.size12};

  @media ${device.tablet} {
    margin-right: ${({ theme }) => theme.spacing.size22};
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  color: ${({ theme }) => theme.colours.textMain};
  border: none;
  padding: 0;
  padding-bottom: 2px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colours.textPlaceholder};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.textPlaceholder};
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
    line-height: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const IconRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  maxDaysRange?: number;
  isWideInput?: boolean;
  selectableDates?: string[];
  selectedDate?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  onSelectDate?: (date: string) => void;
  onChangeOpen?(state: boolean): void;
}

export const FilterFieldDatePicker = ({
  maxDaysRange,
  isWideInput,
  selectableDates,
  selectedDate,
  placeholder,
  icon,
  onSelectDate,
  onChangeOpen,
}: Props): JSX.Element => {
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(new Date()));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const availableDates = useMemo(
    () => selectableDates?.map((d) => parseISO(d)),
    [selectableDates],
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const screenClass = useScreenClass();

  const isDesktop = ["md", "lg", "xl", "xxl", "xxxl"].includes(screenClass);
  const inputDateFormat = (isDesktop || isWideInput) ? "dd MMM yyyy" : "MMM dd";

  const now = new Date();

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      isCalendarOpen &&
      !containerRef.current?.contains(event.target as Node)
    ) {
      if (onChangeOpen) {
        onChangeOpen(false);
      }
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setSelectedMonth(startOfMonth(parseISO(selectedDate)));
    }
  }, [selectedDate]);

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isCalendarOpen]);

  const latestDay = ((): Date | undefined => {
    if (availableDates) {
      return max(availableDates);
    } else if (maxDaysRange) {
      return addDays(now, maxDaysRange);
    }
  })();

  const canSelectPreviousMonth = useMemo(
    (): boolean => isBefore(startOfDay(now), selectedMonth),
    [now, selectedMonth],
  );
  const canSelectNextMonth = useMemo(
    (): boolean =>
      (isSameMonth(now, selectedMonth) ||
        isAfter(selectedMonth, startOfDay(now))) &&
      (latestDay ? isAfter(startOfMonth(latestDay), selectedMonth) : true),
    [now, selectedMonth, latestDay],
  );

  const toggleCalendarOpen = useCallback(
    () =>
      setIsCalendarOpen((v) => {
        if (onChangeOpen) {
          onChangeOpen(!v);
        }

        return !v;
      }),
    [setIsCalendarOpen],
  );

  const handleSelectDate = useCallback(
    (date: Date) => {
      onSelectDate?.(format(date, "yyyy-MM-dd"));
      setIsCalendarOpen(false);
    },
    [onSelectDate, setIsCalendarOpen],
  );

  const handleClickPreviousMonth = useCallback(
    () => setSelectedMonth((currMonth) => subMonths(currMonth, 1)),
    [setSelectedMonth],
  );

  const handleClickNextMonth = useCallback(
    () => setSelectedMonth((currMonth) => addMonths(currMonth, 1)),
    [setSelectedMonth],
  );

  return (
    <FormDatePickerContainer ref={containerRef}>
      <InnerContainer onClick={toggleCalendarOpen}>
        {icon && <IconContainer>{icon}</IconContainer>}
        <Input
          value={
            selectedDate && format(parseISO(selectedDate), inputDateFormat)
          }
          readOnly
          placeholder={placeholder ?? "Date"}
        />
        <IconRightContainer>
          <IconCaret width="9" height="5" />
        </IconRightContainer>
      </InnerContainer>
      {isCalendarOpen && (
        <Calendar
          selectedDate={selectedDate ? parseISO(selectedDate) : undefined}
          selectedMonth={selectedMonth}
          availability={availableDates}
          maxDaysRange={
            !availableDates && maxDaysRange ? maxDaysRange : undefined
          }
          onSelectDate={handleSelectDate}
          onClickPreviousMonth={
            canSelectPreviousMonth ? handleClickPreviousMonth : undefined
          }
          onClickNextMonth={
            canSelectNextMonth ? handleClickNextMonth : undefined
          }
        />
      )}
    </FormDatePickerContainer>
  );
};
