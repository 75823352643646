import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../../../../utils";

const Avatar = styled.img<{
  dimensionsMobile: number;
  dimensionsDesktop: number;
}>`
  width: ${({ dimensionsMobile }) => `${dimensionsMobile}px`};
  height: ${({ dimensionsMobile }) => `${dimensionsMobile}px`};
  border-radius: 50%;

  @media ${device.tablet} {
    width: ${({ dimensionsDesktop }) => `${dimensionsDesktop}px`};
    height: ${({ dimensionsDesktop }) => `${dimensionsDesktop}px`};
  }
`;

const AvatarDefault = styled.div<{
  dimensionsMobile: number;
  dimensionsDesktop: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ dimensionsMobile }) => `${dimensionsMobile}px`};
  height: ${({ dimensionsMobile }) => `${dimensionsMobile}px`};
  border-radius: 50%;
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ dimensionsMobile }) => rem(`${dimensionsMobile / 2}px`)};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textMain};
  background-color: ${({ theme }) => theme.colours.ruubyBlueGrey};

  @media ${device.tablet} {
    width: ${({ dimensionsDesktop }) => `${dimensionsDesktop}px`};
    height: ${({ dimensionsDesktop }) => `${dimensionsDesktop}px`};
    font-size: ${({ dimensionsDesktop }) => rem(`${dimensionsDesktop / 2}px`)};
  }
`;

interface Props {
  dimensionsDesktop: number;
  dimensionsMobile: number;
  imageUrl?: string;
  name?: string;
}

export const AvatarImage = ({
  imageUrl,
  name,
  dimensionsMobile,
  dimensionsDesktop,
}: Props): JSX.Element => {
  const firstLetter = name?.charAt(0).toUpperCase() ?? "";

  return imageUrl ? (
    <Avatar
      src={imageUrl}
      dimensionsMobile={dimensionsMobile}
      dimensionsDesktop={dimensionsDesktop}
      data-testid="avatar-image"
    />
  ) : (
    <AvatarDefault
      dimensionsMobile={dimensionsMobile}
      dimensionsDesktop={dimensionsDesktop}
      data-testid="avatar-image-default"
    >
      {firstLetter}
    </AvatarDefault>
  );
};
