import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import {
  Heading2,
  PanelDark,
  Paragraph,
} from "../../../components/themed-v2/atoms";
import { device } from "../../../utils/media-queries";
import { WithClassName } from "../../../components/themed-v2/types";

const PanelDarkStyled = styled(PanelDark)`
  display: flex;
  flex-direction: column;
`;

const Heading2Styled = styled(Heading2)`
  flex: none;
  margin: 0;
  line-height: 100%;
`;

const ParagraphStyled = styled(Paragraph)`
  margin: ${rem("12px")} 0;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  color: ${({ theme }) => theme.colours.sectionPanel.text};
  line-height: 143%;
  flex: auto;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size14};
  }
`;

type IProps = {
  name: string;
  description: string;
  price: number;
} & WithClassName;

export const TreatmentPanel = ({
  name,
  description,
  price,
  className,
}: IProps): JSX.Element => (
  <PanelDarkStyled className={className}>
    <Heading2Styled>{name}</Heading2Styled>
    <ParagraphStyled>{description}</ParagraphStyled>
    <Heading2Styled>From £{price}</Heading2Styled>
  </PanelDarkStyled>
);
