import {
  ThreeDSecure,
  ThreeDSecureRequest,
} from "@ruuby/common/lib/interfaces/three-d-secure";
import { ApiError } from "../error-handler";
import { getHandler, postHandler } from "../request-handler";

interface IApiGetBraintreeTokenResponse {
  btreeClientToken: string;
}

/**
 * Get braintree token for logged in user
 */
export async function getBraintreeToken(): Promise<string> {
  const response = await getHandler<IApiGetBraintreeTokenResponse>(
    `/payment/token`,
    true,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result.btreeClientToken;
}

export async function get3DSecure(
  body: ThreeDSecureRequest,
): Promise<ThreeDSecure> {
  try {
    const res = await postHandler<ThreeDSecure>(
      "/payment/3dsecure",
      JSON.stringify(body),
      true,
    );

    return res.result;
  } catch (_err) {
    return {
      amount: body.amount,
    };
  }
}
