import { ApiError } from "../error-handler";
import { getHandler, postHandler } from "../request-handler";

export interface IPromotion {
  code: string;
  deduction: number;
  deductionType: "percentage" | "absolute";
  isValid: boolean;
  promotionType: string;
  urn: string;
}

interface ICreditBalance {
  balance: number;
}

export async function fetchUserCreditBalance(): Promise<number> {
  const response = await getHandler<ICreditBalance>("/customer/credit", true);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result.balance;
}

export async function fetchPromotion(
  promoCode: string,
  serviceUrns: string[],
  therapistUrn: string,
): Promise<IPromotion> {
  const body = JSON.stringify({ promoCode, serviceUrns, therapistUrn });

  const response = await postHandler<IPromotion>(
    "/customer/promocode/apply",
    body,
    true,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}
