import jQuery from "jquery";
import React, { useCallback, useEffect } from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import {
  Header,
  Image,
  NavItem,
  Text,
} from "../../../components/atoms";
import { Column, Container, Row } from "../../grid";
import { theme } from "../../../theme";

import flower from "../../../assets/images/about/tanning.png";
import banner from "../../../assets/images/bridal/bridal_banner.jpg";
import bg from "../../../assets/images/bridal/bridal_bg.jpg";
import photo1 from "../../../assets/images/bridal/bridal_photo_1.jpg";
import photo2 from "../../../assets/images/bridal/bridal_photo_2.jpg";
import photo3 from "../../../assets/images/bridal/bridal_photo_3.jpg";

import { bridalPriceListData } from "../../../static/bridal-price-list";
import { Button } from "../../themed-v2/atoms";

const BridalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px 0;
  background: url(${bg}) no-repeat center;
  background-size: cover;
  text-align: center;

  ul {
    padding: 0 5%;
  }

  li {
    text-align: left;
  }
`;

const BridalBanner = styled(Image)`
  max-width: 1140px;
`;

const BridalHeader = styled(Header)`
  color: ${theme.color.ruubyBlack};
`;

const BridalNavItem = styled(NavItem)`
  font-size: ${theme.fontSize.big};
`;

const BridalPriceBlock = styled.div`
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 90%;
  padding: 15%;
  background-color: ${theme.color.ruubyPink};
  z-index: 1;

  img {
    position: absolute;
    max-width: 60px;
    max-height: 60px;
  }

  img:first-of-type {
    right: -20px;
    top: -20px;
  }

  img:last-of-type {
    bottom: -15px;
    left: -20px;
  }

  @media screen and (max-width: 992px) {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
  }
`;

const StyledRow = styled(Row)`
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

const mapTreatments = () =>
  bridalPriceListData.map((g) => (
    <>
      <Text size="16" transform="uppercase" weight="bold">
        <p>{g.name}</p>
      </Text>
      {g.treatments.map((t) => (
        <Text size="16">
          {t.name}: {t.price}
        </Text>
      ))}
    </>
  ));

export const Bridal: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const thankYouTitle = jQuery(document).find(".bridal-title-thank-you");

    if (thankYouTitle.length) {
      setTimeout(scrollToForm, 50);
    }
  }, []);

  const scrollToForm = useCallback((): void => {
    const speed = 500;
    const formTop = jQuery(document)
      .find(".bridal-form-container")
      ?.offset()?.top;

    if (formTop) {
      jQuery("html, body")
        .stop()
        .animate({ scrollTop: formTop }, speed, "swing");
    }
  }, []);

  const onFormSubmitted = useCallback((): void => {
    history.push("/bridal-hair-and-makeup/thank-you");
  }, []);

  const FormComponent = useCallback(
    () => (
      <>
        <Row justifyContent="center">
          <Column md="8" textAlign="center">
            <BridalHeader>
              Enquire now to chat with our concierge team about your big day
            </BridalHeader>
          </Column>
        </Row>
        <Row justifyContent="center">
          <Column>
            <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
              <Button onClick={() => window.open("https://ruuby.typeform.com/to/acD6cw7s")}>Contact us</Button>
            </div>
          </Column>
        </Row>
      </>
    ),
    [onFormSubmitted],
  );

  const ThankYouComponent = useCallback(
    () => (
      <Row className="bridal-title-thank-you" justifyContent="center">
        <Column md="8" textAlign="center">
          <BridalHeader>
            Thank you! Your enquiry has been sent successfully.
          </BridalHeader>
        </Column>
      </Row>
    ),
    [],
  );

  return (
    <React.Fragment>
      <Container limitedWidth>
        <BridalBanner src={banner} />
      </Container>
      <Container limitedWidth offsetPaddings>
        <Row justifyContent="center">
          <Column textAlign="center">
            <BridalHeader>Ultimate bridal beauty from Ruuby</BridalHeader>
          </Column>
        </Row>
        <Row justifyContent="center">
          <Text size="16" lineHeight="1.5" align="center">
            <p>
              With unparalleled expertise and experience in creating bespoke
              five star bridal beauty, Ruuby brides is the ultimate trusted
              partner for all your bridal beauty and wellness needs.
            </p>
          </Text>
        </Row>
        <Row justifyContent="center">
          <Button size="small" onClick={scrollToForm}>
            make an enquiry
          </Button>
        </Row>
      </Container>
      <Container limitedWidth offsetPaddings>
        <Row>
          <Column md="6">
            <Image src={photo1} />
          </Column>
          <Column md="6" alignItems="center">
            <div>
              <BridalHeader>Experts in bridal hair and makeup</BridalHeader>
              <Text size="16" lineHeight="1.5">
                <p>
                  RUUBY's unrivalled bridal offering draws on London's best
                  talent to deliver wedding hair and make-up to the highest
                  possible standard. Our team of experts thrive on working with
                  brides to achieve their dream look, whilst ensuring a five
                  star experience.
                </p>
              </Text>
            </div>
          </Column>
        </Row>
      </Container>
      <Container noPadding>
        <BridalBackdrop>
          <Container limitedWidth offsetPaddings>
            <BridalHeader>Bridal packages</BridalHeader>
            <Text size="16" lineHeight="1.5">
              <p>
                Our bespoke Bridal Packages are designed for those looking for
                360 degree support prior to their big day. Our Concierge team
                will work with each bride to identify and schedule the series of
                services required to ensure every beauty and wellness need has
                been met.
              </p>

              <p>
                Examples of services commonly included in our Bridal Packages
                are:
              </p>
            </Text>
            <Row justifyContent="center">
              <Column md="4" sm="6" justifyContent="center">
                <ul>
                  <li>Advanced Facials (courses can be arranged on request)</li>
                  <li>Lymphatic Drainage</li>
                  <li>Vitamin Drips</li>
                  <li>Manicure & Pedicure</li>
                  <li>Waxing</li>
                </ul>
              </Column>

              <Column md="4" sm="6" justifyContent="center">
                <ul>
                  <li>Lash Extensions</li>
                  <li>Lash and Brow Tinting</li>
                  <li>Eyebrow Shaping</li>
                  <li>Contour Tanning</li>
                  <li>Personal Training</li>
                  <li>Nutritionist</li>
                </ul>
              </Column>
            </Row>
          </Container>
        </BridalBackdrop>
      </Container>
      <Container limitedWidth offsetPaddings>
        <StyledRow>
          <Column md="5">
            <BridalPriceBlock>
              <BridalHeader>Price list</BridalHeader>
              <Text size="16" lineHeight="1.5">
                <p>
                  Listed prices indicate the starting price of each service.
                </p>
              </Text>
              {mapTreatments()}
              <Text size="12">
                <p>
                  Bespoke packages and pricing available with our Black Label
                  experts on request.
                </p>
              </Text>
              <Image src={flower} />
              <Image src={flower} />
            </BridalPriceBlock>
          </Column>
          <Column md="7">
            <Image src={photo2} />
          </Column>
        </StyledRow>
      </Container>
      <Container limitedWidth offsetPaddings>
        <Row>
          <Column md="6">
            <Image src={photo3} />
          </Column>
          <Column md="6" alignItems="center">
            <div>
              <BridalHeader>Amanda Wakeley Lookbook</BridalHeader>
              <Text size="16" lineHeight="1.5">
                <p>
                  We crafted a diverse palette of bridal hair and makeup looks
                  in a lookbook shoot for bridal fashion brand Amanda Wakeley.
                </p>
              </Text>
            </div>
          </Column>
        </Row>
      </Container>
      <Container className="bridal-form-container" limitedWidth offsetPaddings>
        <Switch>
          <Route
            exact
            path="/bridal-hair-and-makeup"
            component={FormComponent}
          />
          <Route
            exact
            path="/bridal-hair-and-makeup/thank-you"
            component={ThankYouComponent}
          />
        </Switch>

        <Row justifyContent="center">
          <Column textAlign="center">
            <Text size="20">
              <BridalNavItem href="mailto:weddings@ruuby.com">
                <p>weddings@ruuby.com</p>
              </BridalNavItem>{" "}
              |{" "}
              <BridalNavItem href="tel:+4402074608319">
                <p>0207 460 8319</p>
              </BridalNavItem>
            </Text>
          </Column>
        </Row>
      </Container>
    </React.Fragment>
  );
};
