import { useCallback, useState } from "react";
import * as analytics from "../../../../../../analytics";
import { ApiError, useOtpEmailRequestMutation } from "../../../../../../services/api/types/graphql";

type UseOtpEmailRequest = (params: { onSuccess(token: string): void }) => {
  isLoading: boolean;
  error?: ApiError;
  initiateOtpRequest(phone: string): void;
};

export const useEmailOtpRequest: UseOtpEmailRequest = ({ onSuccess }) => {
  const [error, setError] = useState<ApiError>();

  const [otpRequest, { loading: isLoading }] = useOtpEmailRequestMutation({
    onCompleted: async ({ otpEmailRequest: data }) => {
      switch (data.__typename) {
        case "OtpRequestSuccess":
          setError(undefined);
          onSuccess(data.token);
          break;
        case "RuubyGraphError":
          if (error) {
            analytics.track({
              name: analytics.AnalyticsEvents.LOGIN_SCREEN_VALIDATION_ERROR,
              payload: {
                error,
              },
            });  
          }
          setError(data.error);
          break;
      }
    },
    onError: () => {
      setError(ApiError.GENERAL_ERROR);
    },
    errorPolicy: "all",
  });

  const initiateOtpRequest = useCallback(
    async (email: string) => {
      analytics.track({
        name: analytics.AnalyticsEvents.EMAIL_OTP_REQUESTED,
        payload: { email },
      });

      await otpRequest({
        variables: {
          email,
          deviceTime: new Date().toISOString(),
        },
      });
    },
    [otpRequest],
  );

  return {
    isLoading,
    error,
    initiateOtpRequest,
  };
};
