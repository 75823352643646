import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazy-load";

import background from "./fallback.jpg";
import { device } from "../../../../utils/media-queries";

const BackdropContainer = styled.div`
  position: relative;
  background-image: url(${background});
  background-size: cover;
  background-position: center center;
  padding: ${rem("78px")} 0;
  z-index: 99;

  @media ${device.tablet} {
    padding: ${rem("91px")} 0;
  }
`;

const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  object-fit: cover;
  display: none;

  @media ${device.tablet} {
    display: inline;
  }

  z-index: -1;
`;

interface IProps {
  video: string;
  children?: React.ReactNode;
}

export const HeroVideoContainer = ({
  video,
  children,
}: IProps): JSX.Element => (
  <BackdropContainer>
    {children}
    <LazyLoad threshold={0.01}>
      <Video autoPlay={true} loop={true} playsInline muted>
        <source src={video}></source>
      </Video>
    </LazyLoad>
  </BackdropContainer>
);
