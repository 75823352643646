import styled from "styled-components";

export const ButtonIconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 6px;

  & > svg {
    fill: ${({ theme }) => theme.colours.buttonText};
  }
`;
