import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import { newTheme } from "../../../../theme";

interface IProps {
  colour: keyof typeof newTheme["colours"]["sectionBackground"];
  noTopPadding?: boolean;
}

export const Section = styled.section<IProps>`
  background-color: ${({ colour, theme }) =>
    theme.colours.sectionBackground[colour]};
  padding: ${({
    theme: {
      spacing: { size20 },
    },
    noTopPadding,
  }) => (noTopPadding ? `0 0 ${size20} 0` : `${size20} 0 ${size20} 0`)};

  @media ${device.tablet} {
    padding: ${({
    theme: {
      spacing: { size32 },
    },
    noTopPadding,
  }) => (noTopPadding ? `0 0 ${size32} 0` : `${size32} 0 ${size32} 0`)};
  }
`;
