import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import {
  Heading2,
  Panel,
  Paragraph,
} from "../../../components/themed-v2/atoms";
import { device } from "../../../utils/media-queries";
import { WithClassName } from "../../../components/themed-v2/types";

const ParagraphStyled = styled(Paragraph)`
  margin: ${rem("12px")} 0;
  font-size: ${({ theme }) => theme.type.sizes.size14};
  color: ${({ theme }) => theme.colours.sectionPanel.text};
  line-height: 143%;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size14};
  }
`;

type IProps = {
  heading: string;
  paragraph: string;
} & WithClassName;

export const SectionPanel = ({
  heading,
  paragraph,
  className,
}: IProps): JSX.Element => (
  <Panel className={className}>
    <Heading2>{heading}</Heading2>
    <ParagraphStyled>{paragraph}</ParagraphStyled>
  </Panel>
);
