import * as React from "react";
import { Header, Text, Image } from "../../../../components/atoms";
import { Column, Container, Row } from "../../../grid";
import { theme } from "../../../../theme";
import { IApiJobList, fetchDetails } from "../../../../services/api/jobs";
import styled from "styled-components";
import background from "../../../../assets/images/join.jpg";

const BlackLabelBackdrop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background: url(${background}) no-repeat center;
  background-size: cover;

  ${Image} {
    max-width: 200px;
    object-fit: contain;
  }
`;

const JobsTextH1 = styled(Text)`
  margin: 0;
`;

const JobsIntroHeaderStyled = styled(Header)`
  margin: 20px 0 40px;
  padding: 0 15%;
  color: ${theme.color.ruubyBlue};
  font-size: ${theme.fontSize.paragraph};
  line-height: 1.5;
  text-align: left;
`;

const EmailPanel = styled.div`
  color: ${theme.color.ruubyBlue};
  font-size: ${theme.fontSize.paragraph};
  padding: 10px;
  text-align: center;
  background-color: #efefef;
`;

interface IState {
  job: IApiJobList;
}

const INITIAL_STATE = {
  job: {
    id: "",
    title: "",
    description: "",
    salary: "",
    joblocation: "",
    email: "",
  },
};

interface IProps {
  id: string;
}
type Props = IProps;

export class JobsDetail extends React.PureComponent<Props> {
  public state: IState = {
    ...INITIAL_STATE,
  };

  public async componentDidMount() {
    this.setState({ job: await fetchDetails(this.props.id) });
  }

  public render(): JSX.Element {
    const sendCvPanel = (
      <EmailPanel>
        To apply for this position please send your cv and cover letter to{" "}
        <a
          style={{ color: "#666666" }}
          href={
            "mailto:" +
            this.state.job.email +
            "?subject=" +
            this.state.job.title
          }
        >
          {this.state.job.email}
        </a>
      </EmailPanel>
    );

    return (
      <React.Fragment>
        <BlackLabelBackdrop></BlackLabelBackdrop>
        <Container offsetPaddings style={{ backgroundColor: "#ffffff" }}>
          <Row>
            <Column textAlign="center">
              <JobsTextH1 weight="bold" size="18" color="grey" as="h1">
                {this.state.job.title}
              </JobsTextH1>
              <JobsIntroHeaderStyled>
                {sendCvPanel}
                <p>{this.state.job.joblocation}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.job.description,
                  }}
                ></p>
                <p>{this.state.job.salary}</p>
                {sendCvPanel}
              </JobsIntroHeaderStyled>
            </Column>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
