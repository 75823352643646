import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { rem } from "polished";

import {
  FeaturedCard,
  SwiperCarousel,
} from "../../../../components/themed-v2/molecules";

const FeaturedCarouselContainer = styled.div`
  margin-top: ${rem("25px")};
`;

const FeaturedLink = styled(NavLink)`
  text-decoration: none;
`;

interface FeaturedCardProps extends React.ComponentProps<typeof FeaturedCard> {
  link: string;
}

interface IProps {
  carouselKey: string;
  items: FeaturedCardProps[];
}

export const FeaturedCarousel = ({ items }: IProps): JSX.Element => (
  <FeaturedCarouselContainer>
    <SwiperCarousel slidesPerView="auto" spaceBetween={17}>
      {items.map((item) => (
        <FeaturedLink to={item.link}>
          <FeaturedCard {...item} />
        </FeaturedLink>
      ))}
    </SwiperCarousel>
  </FeaturedCarouselContainer>
);
