import styled from "styled-components";

import { theme } from "../../../theme";

export const NavigationItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 12px;
    font-size: ${theme.fontSize.main};
    font-weight: 600;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1024px) {
    a {
      padding: 0 8px;
    }
  }

  @media screen and (max-width: 992px) {
    justify-content: flex-start;
    align-items: normal;
    padding-top: 30px;

    a {
      justify-content: flex-start;
      align-items: normal;
      padding: 0;
      padding: 0;
      font-size: ${theme.fontSize.headingNew};
      text-transform: capitalize;
    }
  }
`;

export const NavigationItems = styled.div`
  height: 100%;
  display: flex;

  > :last-child a {
    padding-right: 0;
  }
`;
