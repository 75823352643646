import * as React from "react";
import styled from "styled-components";

import { Header, NavItem, Text } from "../../../components/atoms";
import { TextSection } from "../../../components/molecules";
import { Column, Container, Row } from "../../grid";
import { theme } from "../../../theme";

const PrivacyPolicyContainerStyled = styled(Container)`
  ${Row} {
    margin-bottom: 10px;
  }

  .title {
    font-size: ${theme.fontSize.big};
    font-weight: 600;
    color: ${theme.color.ruubyBlack};
  }

  .text {
    color: ${theme.color.ruubyBlack};
  }

  a {
    font-size: ${theme.fontSize.paragraph};
    text-transform: lowercase;
  }
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <PrivacyPolicyContainerStyled limitedWidth offsetPaddings>
      <Row>
        <Column textAlign="center">
          <Header>OUR PRIVACY POLICY</Header>
        </Column>
      </Row>
      <Row>
        <Text size="18">{`At Ruuby we are committed to maintaining a trusted relationship with our customers and visitors.
        For example, we do not sell or trade lists of our customer or visitor data with third parties for marketing purposes.
        So you are kept in the light on what we do, we have created this Privacy Policy in easy to understand
        English explaining what data of yours we collect, what we do with it, in what limited circumstances we may disclose it
        to others and how we keep it all secure.`}</Text>
      </Row>
      <Row>
        <Text size="24" weight="bold">
          Data we collect
        </Text>
      </Row>
      <Row>
        <TextSection
          title="Website cookies"
          text={`We store a cookie - a small file on your computer - so we can tell who you are if you
             have logged in to our site and then show you the information relevant to you.
             We also store on your computer what search preferences you have selected to save you time having to put them in again.
             We work with Rakuten Advertising who will drop third party cookies.`}
        />
      </Row>
      <Row>
        <TextSection
          title="Google analytics"
          text={`When you visit our site we use a third-party service Google Analytics to collect standard logging information
          such as what pages are visited and in which order. We use this to analyse customer and visitor behaviour as a whole
          and we do not collect, or instruct Google to collect personal information that can identify you.`}
        />
      </Row>
      <Row>
        <TextSection
          title="Mailing lists"
          text={`On our site, you can subscribe to our newsletter. When you do this we collect some information - your email
          address and name so that we can keep you up to date with Ruuby news and special offers, or in case we need to
          contact you to get more or clarify the information we do have.`}
        />
      </Row>
      <Row>
        <Text size="18">{`You can also sign up to our newsletter mailing list when you register with us.
        We will explicitly ask you for permission to do this when do.`}</Text>
      </Row>
      <Row>
        <Text size="18">{`We use a third-party provider called HubSpot to send our newsletters.
        We gather statistics around which newsletters get opened and clicked on using industry-standard technologies to help us
        improve the quality and relevance of our newsletters.`}</Text>
      </Row>
      <Row>
        <Text size="18">
          {`You can unsubscribe to our newsletters, and manage your contact preferences at any time by clicking the
        link in the bottom of every email, or contact our support at `}
          <NavItem href="mailto:support@ruuby.com">support@ruuby.com</NavItem>
        </Text>
      </Row>
      <Row>
        <Text size="24" weight="bold">
          Registering with us and making a booking
        </Text>
      </Row>
      <Row>
        <Text size="18">{`When you register with us you become a customer, and we will then store the information
        you supply such as your name, email address and telephone number. This information is stored in our private
        database which is hosted in a private network with no public access, using security and encryption technologies
        that are compliant with Payment Card Industry regulations.`}</Text>
      </Row>
      <Row>
        <Text size="18">{`When you make a booking with us we also collect information such as your address and any
        other information you may supply at the time. We share this information with our therapists so that they can
        perform the booking for you. We also store this information so we can show you a history of bookings you have
        made on our app and website.`}</Text>
      </Row>
      <Row>
        <Text size="18">{`We never share your personal information with any other company without your consent.`}</Text>
      </Row>
      <Row>
        <Text size="18">
          {`We collect your credit card information to take payment for your booking, but this is never
        stored on our systems, and instead is stored directly with our payment service provider, Braintree.
        As a global leader in card payment services, we trust them to look after our customer’s card data.
        You can read more about their security here - `}
          <NavItem
            href="https://www.braintreepayments.com/en-ie/features/data-security"
            target="_blank"
          >
            https://www.braintreepayments.com/en-ie/features/data-security
          </NavItem>
        </Text>
      </Row>
      <Row>
        <Text size="24" weight="bold">
          Access to your personal information
        </Text>
      </Row>
      <Row>
        <Text size="18">{`You are entitled to view, amend or delete the information about you that we hold.
        To do this please email our support team at support@ruuby.com. By law, we have to action this within one month of your
        request though we will nearly always do this much sooner!  If you wish to have your data deleted please contact support@ruuby.com`}</Text>
      </Row>
      <Row>
        <Text size="24" weight="bold">
          Changes to this policy
        </Text>
      </Row>
      <Row>
        <Text size="18">
          {`This policy was last updated in January 2023. This policy is subject to changes,
        and the most up to date version can always be found at `}
          <NavItem href="https://ruuby.com/privacy-policy" target="_blank">
            https://ruuby.com/privacy-policy
          </NavItem>
        </Text>
      </Row>
    </PrivacyPolicyContainerStyled>
  );
};

export { PrivacyPolicy };
