/**
 * Get token from cookie
 */
export async function getAuthToken() {
  return window.localStorage.getItem("ruuby_token");
}

export function setAuthToken(token: string) {
  return window.localStorage.setItem("ruuby_token", token);
}

export function removeAuthToken() {
  return window.localStorage.removeItem("ruuby_token");
}
