import React from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as LorealLogoImage } from "./loreal.svg";
import { ReactComponent as MishconDeReyaLogoImage } from "./mishcon_de_reya.svg";
import { ReactComponent as UnileverLogoImage } from "./unilever.svg";
import { ReactComponent as MetaLogoImage } from "./meta.svg";

import { device } from "../../../../../../utils";

const LorealLogo = styled(LorealLogoImage)`
  order: 0;
  width: ${rem("133px")};
  height: ${rem("24px")};
  @media ${device.desktop} {
    width: ${rem("153px")};
    height: ${rem("48px")};
  }
`;

const MishconDeReyaLogo = styled(MishconDeReyaLogoImage)`
  order: 3;
  width: ${rem("215px")};
  height: ${rem("30px")};
  @media ${device.mobileL} {
    order: 3;
    width: ${rem("234px")};
    height: ${rem("32px")};
  }
  @media ${device.laptop} {
    order: 1;
  }
  @media ${device.desktop} {
    width: ${rem("238px")};
    height: ${rem("33px")};
  }
`;

const UnileverLogo = styled(UnileverLogoImage)`
  order: 1;
  width: ${rem("116px")};
  height: ${rem("25px")};
  @media ${device.mobileL} {
    order: 2;
    width: ${rem("141px")};
    height: ${rem("29px")};
  }
  @media ${device.desktop} {
    width: ${rem("143px")};
    height: ${rem("31px")};
}
`;

const MetaLogo = styled(MetaLogoImage)`
  order: 2;
  width: ${rem("133px")};
  height: ${rem("25px")};
  @media ${device.mobileL} {
    order: 1;
    width: ${rem("139px")};
    height: ${rem("26px")};
  }
  @media ${device.laptop} {
    order: 3;
  }
  @media ${device.desktop} {
    width: ${rem("149px")};
    height: ${rem("28px")};
  }
`;

const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

> svg {
    margin: ${rem("20px")} ${rem("14px")} ${rem("10px")};
    @media ${device.mobileL} {
      margin: ${rem("25px")} ${rem("26px")} ${rem("10px")};
    }
    @media ${device.desktop} {
      margin: ${rem("20px")} ${rem("42px")} ${rem("10px")};
    }
  }
`;

export const PartnersPanel = (): JSX.Element => (
  <Row>
    <ColStyled>
      <MishconDeReyaLogo />
      <LorealLogo />
      <UnileverLogo />
      <MetaLogo />
    </ColStyled>
  </Row>
);
