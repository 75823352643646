import React from "react";

import {
  PaymentServiceType,
  useGetPaymentServiceType,
} from "../../../../../../hooks/use-get-payment-service-type";
import { StripeCheckout } from "../stripe-checkout";
import { useBookingTotal } from "../../hooks/use-booking-total";
import Skeleton from "react-loading-skeleton";
import { Elements } from "@stripe/react-stripe-js";
import { stripeInstance } from "../../../../../../stripe";
import { theme } from "../../../../../../theme/new";
import { BraintreeCheckout } from "../braintree-checkout";

export const PaymentDetailsSection = (): JSX.Element => {
  const paymentProviderType = useGetPaymentServiceType();
  const {
    total,
    isLoading: isLoadingBookingTotal,
    bookingFee,
    creditsDeduction,
    promotionDeduction,
  } = useBookingTotal();

  if (isLoadingBookingTotal) {
    return <Skeleton count={5} />;
  }

  if (paymentProviderType === PaymentServiceType.STRIPE) {
    return (
      <Elements
        stripe={stripeInstance}
        options={{
          mode: "payment",
          amount: Math.round(total * 100),
          currency: "gbp",
          appearance: {
            variables: {
              colorPrimary: theme.colours.lightBlue,
            },
          },
        }}
      >
        <StripeCheckout
          total={total}
          bookingFee={bookingFee}
          creditsDeduction={creditsDeduction}
          promotionDeduction={promotionDeduction}
        />
      </Elements>
    );
  }

  return (
    <BraintreeCheckout
      total={total}
      bookingFee={bookingFee}
      creditsDeduction={creditsDeduction}
      promotionDeduction={promotionDeduction}
    />
  );
};
