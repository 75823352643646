import meta from "./meta.json";

interface IMetaData {
  [key: string]: {
    title: string;
    description: string;
  };
}

const metaData: IMetaData = meta as unknown as IMetaData;

export { metaData };
