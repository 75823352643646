import React, { useCallback } from "react";
import styled from "styled-components";

import { PaymentTile } from "../payment-tile";

interface Props {
  isSelected: boolean;
  className?: string;
  onClick: () => void;
}

const Container = styled(PaymentTile)`
  width: 75px;
  padding-top: ${({ theme }) => theme.spacing.size12};
  font-size: ${({ theme }) => theme.type.sizes.size36};
  line-height: ${({ theme }) => theme.type.sizes.size36};
`;

export const PaymentAddTile = ({
  isSelected,
  className,
  onClick,
}: Props) => {
  const handleClick = useCallback(() => {
    if (!isSelected) {
      onClick();
    }
  }, [isSelected, onClick]);

  return (
  <Container onClick={handleClick} data-is-selected={isSelected} className={className}>
    +
  </Container>
);
};