import * as React from "react";

interface IProps {
  fill?: string;
  width?: string;
  height?: string;
}

// tslint:disable:max-line-length
const IconDate = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "18"}
      height={props.height ?? "20"}
      viewBox="0 0 18 20"
    >
      <defs>
        <path id="b" d="M0 0h1440v56H0z" />
        <filter
          id="a"
          width="101.4%"
          height="135.7%"
          x="-.7%"
          y="-14.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0985903533 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-532 -90)">
        <circle
          cx="980.5"
          cy="695.5"
          r="599.5"
          fill="#C1C5C7"
          fillOpacity=".4"
        />
        <g transform="translate(0 72)">
          <use fill="#000" filter="url(#a)" />
          <use fill="#FFF" />
        </g>
        <path
          fill={props.fill ? props.fill : "#e3c1ac"}
          fillRule="nonzero"
          d="M538 99h-2v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V94c0-1.1-.9-2-2-2zm0 16h-14V97h14v11z"
        />
      </g>
    </svg>
  );
};

export { IconDate };
