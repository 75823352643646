export interface IApiErrorData {
  code: string;
  message: string;
  data?: { [key: string]: string | number | boolean };
}

export class ApiError extends Error {
  public code: string;
  public data?: { [key: string]: string | number | boolean };

  constructor(error: IApiErrorData) {
    super();
    this.code = error.code;
    this.message = error.message;

    if (typeof error.data !== "undefined") {
      this.data = error.data;
    }

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
