import * as React from "react";

// tslint:disable:max-line-length
const IconRemove = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0C4.25302 0 0 4.25302 0 9.5C0 14.747 4.25302 19 9.5 19C14.747 19 19 14.7464 19 9.5C19 4.25364 14.747 0 9.5 0ZM9.5 17.8125C4.90911 17.8125 1.1875 14.0909 1.1875 9.5C1.1875 4.90911 4.90911 1.1875 9.5 1.1875C14.0909 1.1875 17.8125 4.90911 17.8125 9.5C17.8125 14.0909 14.0909 17.8125 9.5 17.8125ZM13.0625 8.90625H5.9375C5.60975 8.90625 5.34375 9.17225 5.34375 9.5C5.34375 9.82775 5.60975 10.0938 5.9375 10.0938H13.0625C13.3903 10.0938 13.6562 9.82775 13.6562 9.5C13.6562 9.17225 13.3903 8.90625 13.0625 8.90625Z"
        fill="#707378"
      />
    </svg>
  );
};

export { IconRemove };
