import * as React from "react";
import styled from "styled-components";

interface IProps {
  type: "block" | "overlay";
  id: string;
  centerBlock?: boolean;
  fullHeight?: boolean;
  children?: React.ReactNode;
}

const LoaderStyled = styled.div`
  position: absolute;
  height: 50px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const LoaderBlockStyled = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 50px;
  margin: 25px 0;

  ${(props: Partial<IProps>) =>
    props.centerBlock ? `height: 50vh; align-items: center;` : undefined};

  ${(props: Partial<IProps>) =>
    props.fullHeight ? `height: 100vh; align-items: center;` : undefined};
`;

const LoaderOverlayStyled = styled(LoaderBlockStyled)`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background-color: rgba(21, 22, 20, 0.1);
  z-index: 1000;

  ${LoaderStyled} {
    top: 50%;
  }
`;

const LoaderBlock: React.FC<IProps> = (props) => {
  return (
    <LoaderBlockStyled
      centerBlock={props.centerBlock}
      fullHeight={props.fullHeight}
    >
      <LoaderStyled id={props.id} />
      {props.children}
    </LoaderBlockStyled>
  );
};

const LoaderOverlay: React.FC<IProps> = (props) => {
  return (
    <LoaderOverlayStyled>
      <LoaderStyled id={props.id} />
      {props.children}
    </LoaderOverlayStyled>
  );
};

const Loader: React.FC<IProps> = (props) => {
  if (props.type === "block") {
    return <LoaderBlock {...props} />;
  } else if (props.type === "overlay") {
    return <LoaderOverlay {...props} />;
  } else {
    return null;
  }
};

export { Loader };
