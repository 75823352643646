import * as React from "react";
import styled from "styled-components";

import {
  Header,
  IconPublicity,
  NavItem,
  Text,
} from "../../../../components/atoms";
import { Column, Container, Row } from "../../../grid";
import { aboutArticlesData } from "../../../../static/about-articles";
import { theme } from "../../../../theme";

const AboutPressHeaderH2 = styled(Header)`
  margin: 0;
`;

const AboutPressPublicityRowStyled = styled(Row)`
  svg {
    width: 70%;
    max-width: 12em;
    path {
      fill: ${theme.color.ruubyBlue};
      transition: fill ${theme.transition.easeInOut03};
      &.transparent {
        fill: transparent;
      }
    }
  }
`;

const AboutPressPublicityRows = (
  <AboutPressPublicityRowStyled justifyContent="space-around">
    <IconPublicity type="forbes" />
    <IconPublicity type="wired" />
    <IconPublicity type="grazia" />
    <IconPublicity type="nytimes" />
    <IconPublicity type="estandard" />
    <IconPublicity type="style" />
  </AboutPressPublicityRowStyled>
);

const ArticlesRowWrapper = styled.div`
  * {
    color: ${theme.color.ruubyBlue};
  }

  a {
    width: 100%;
    border-top: 1px solid ${theme.color.inputBorderColor};
  }

  ${Header} {
    font-size: ${theme.fontSize.headingBigger};
  }

  ${Row} {
    margin: 20px 0;
  }
`;

const AboutPressArticlesRow = aboutArticlesData.map((article, index) => {
  return (
    <NavItem key={index} type="link" href={article.link} target="_blank">
      <Row alignItems="center">
        <Column md="1" sm="12">
          <Text weight="bold" size="16">
            {article.date}
          </Text>
        </Column>
        <Column md="10" sm="12" textAlign="left">
          <Header>{article.header}</Header>
        </Column>
        <Column md="1" sm="12">
          <Text weight="semibold" size="16">
            READ »
          </Text>
        </Column>
      </Row>
    </NavItem>
  );
});

const AboutPress: React.FC = () => {
  return (
    <Container background="white" offsetPaddings>
      <Row>
        <Column textAlign="center">
          <AboutPressHeaderH2 as="h2">Press</AboutPressHeaderH2>
          {AboutPressPublicityRows}
          <ArticlesRowWrapper>{AboutPressArticlesRow}</ArticlesRowWrapper>
        </Column>
      </Row>
    </Container>
  );
};

export { AboutPress };
