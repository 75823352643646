import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../../utils/media-queries";
import { WithClassName } from "../../../../components/themed-v2/types";

import image from "./sunglasses.webp";

const Container = styled.div`
  width: ${rem("136px")};
  height: ${rem("136px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    width: ${rem("162px")};
    height: ${rem("162px")};
  }
`;

const Image = styled.img`
  width: ${rem("136px")};
  height: ${rem("136px")};
  position: absolute;
  top: 0;
  left: 0;

  @media ${device.tablet} {
    width: ${rem("162px")};
    height: ${rem("162px")};
  }
`;

const Number = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.numbers};
  font-weight: 400;
  font-size: ${rem("70px")};
  line-height: ${rem("70px")};
  color: ${({ theme }) => theme.colours.textMain};
  z-index: 1;
  margin-top: ${rem("20px")};
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  @media ${device.tablet} {
    font-size: ${rem("75px")};
    line-height: ${rem("75px")};
  }
`;

type IProps = {
  number: 1 | 2 | 3 | 4;
} & WithClassName;

export const KaleidoscopeNumber = ({
  number,
  className,
}: IProps): JSX.Element => (
  <Container className={className}>
    <Image src={image} />
    <Number>{number}</Number>
  </Container>
);
