const MOBILE_WINDOW_WITDH = 992;

type Callback = (isMobile: boolean) => void;

/**
 * Compare window width to mobile width
 */
function checkWidth(callback: Callback) {
  const isMobile = window.innerWidth <= MOBILE_WINDOW_WITDH;

  callback(isMobile);
}

export const isMobileWidth = (callback: Callback) => checkWidth(callback);
