import { useCallback, useMemo, useState } from "react";

interface AddedTreatment {
  urn: string;
  name: string;
  quantity: number;
  price: number;
  duration: number;
}

interface ConfirmationParams {
  therapistName: string;
  addedTreatments: AddedTreatment[];
  bookingDate: string;
  total: number;
  addressString?: string;
  category?: string;
  bookingFee?: number;
  creditsDeduction?: number;
  promotionDeduction?: number;
  bookingUrn?: string;
  promoCode?: string;
  runAnalytics?: boolean;
}

interface UseConfirmationParams {
  confirmationParams?: ConfirmationParams;
  saveConfirmationParamsToSession: (params: ConfirmationParams) => void;
}

const CONFIRMATION_PARAMS_KEY = "confirmationParams";

export const useConfirmationParams = (): UseConfirmationParams => {
  const storedParams = sessionStorage.getItem(CONFIRMATION_PARAMS_KEY);
  const parsedParams = useMemo(
    () => storedParams && JSON.parse(storedParams),
    [storedParams],
  );
  const [confirmationParams, setConfirmationParams] = useState(parsedParams);

  const saveConfirmationParamsToSession = useCallback(
    (params: ConfirmationParams) => {
      sessionStorage.setItem(CONFIRMATION_PARAMS_KEY, JSON.stringify(params));
      setConfirmationParams(params);
    },
    [],
  );

  return {
    confirmationParams,
    saveConfirmationParamsToSession,
  };
};
