import { useCallback, useState } from "react";
import { ApiError, useSavedPaymentMethodsLazyQuery } from "../../services/api/types/graphql";

interface PaymentMethod {
  id: string;
  brand: string;
  lastFour: string;
  expirationYear: number;
  expirationMonth: number;
}

type UseSavedPaymentMethods = () => {
  fetchPaymentMethods: () => Promise<void>;
  paymentMethods: PaymentMethod[];
  isLoading: boolean;
  error?: ApiError;
};

export const useSavedPaymentMethods: UseSavedPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [error, setError] = useState<ApiError>();
  
  const [loadPaymentMethods, { loading }] = useSavedPaymentMethodsLazyQuery({
    onCompleted: ({ customer: data }) => {
      switch (data.__typename) {
        case "Customer":
          setPaymentMethods(data.payment.paymentMethods);
          break;
        case "RuubyGraphError":
          setError(data.error);
      }
    },
    onError: () => setError(ApiError.GENERAL_ERROR),
    errorPolicy: "all",
  });

  const fetchPaymentMethods = useCallback(async () => {
    await loadPaymentMethods();

  }, [loadPaymentMethods]); 

  return {
    fetchPaymentMethods,
    paymentMethods,
    isLoading: loading,
    error,
  };
};