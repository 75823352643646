import { useState, useEffect } from "react";

type UseMatchMedia = (deviceMedia: string) => {
  matchesMedia: boolean;
};

export const useMatchMedia: UseMatchMedia = (screenMediaQuery: string) => {
  const matchMedia = window.matchMedia(screenMediaQuery);
  const [matchesMedia, setMatchesMedia] = useState(matchMedia.matches);

  useEffect(() => {
    const matchMediaHandler = (event: MediaQueryListEvent) => {
      setMatchesMedia(event.matches);
    };
    matchMedia.addEventListener("change", matchMediaHandler);

    return () => matchMedia.removeEventListener("change", matchMediaHandler);
  }, []);

  return {
    matchesMedia,
  };
};
