import codes from "./country-telephone-codes.json";

interface ICountryPhoneCodes {
  name: string;
  dial_code: string;
  code: string;
}

const codesData: ICountryPhoneCodes[] = codes as ICountryPhoneCodes[];

export { codesData };
