import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Col, Container, Row } from "react-grid-system";
import { History } from "history";

import { device } from "../../../../utils/media-queries";
import {
  CollapsibleSectionPanel,
  HeroPanelWithButton,
  JobPanel,
  SectionPanel,
} from "../../../../components/themed-v2/molecules";
import {
  HeroContainer,
  Section,
  Heading1,
  Button,
  Loader,
} from "../../../../components/themed-v2/atoms";

import { UseJobData, useJobData } from "./use-job-data";

const StyledLoader = styled(Loader)`
  margin-top: ${rem("100px")};
  margin-bottom: ${rem("100px")};
`;

const CardsRow = styled(Row)`
  margin-top: ${rem("24px")};
  margin-bottom: ${rem("25px")};

  @media ${device.tablet} {
    margin-top: ${rem("45px")};
  }
`;

const ButtonCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSectionPanel = styled(SectionPanel)`
  margin-bottom: ${rem("20px")};
  min-height: calc(
    100% - ${rem("20px")}
  ); // to make the cards same height as siblings
  box-sizing: border-box;
`;

const StyledJobPanel = styled(JobPanel)`
  margin-bottom: ${rem("20px")};
  min-height: calc(
    100% - ${rem("20px")}
  ); // to make the cards same height as siblings
  box-sizing: border-box;
`;

const StyledCollapsiblePanel = styled(CollapsibleSectionPanel)`
  margin-bottom: ${rem("15px")};
`;

interface IProps {
  useData?: UseJobData;
  history: History;
}

export const JobListingPage: React.FC<IProps> = ({
  useData = useJobData,
  history,
}) => {
  const {
    handleClickButton,
    hero: { image, ...heroProps },
    sections: { why, positions, faqs },
  } = useData(history);

  return (
    <>
      <HeroContainer image={image}>
        <Container>
          <Row justify="center">
            <Col lg={7}>
              <HeroPanelWithButton
                {...heroProps}
                onClickButton={handleClickButton}
              />
            </Col>
          </Row>
        </Container>
      </HeroContainer>

      <Section colour="pink">
        <Container>
          <Row>
            <Col>
              <Heading1>{why.heading}</Heading1>
            </Col>
          </Row>
          <CardsRow>
            {why.cards.map((props) => (
              <Col md={6} key={props.heading}>
                <StyledSectionPanel {...props} />
              </Col>
            ))}
          </CardsRow>
          <Row>
            <ButtonCol>
              <Button size="small" onClick={handleClickButton}>
                {why.button.label}
              </Button>
            </ButtonCol>
          </Row>
        </Container>
      </Section>

      <Section colour="white">
        <Container>
          <Row>
            <Col>
              <Heading1>{positions.heading}</Heading1>
            </Col>
          </Row>
          {positions.isLoading && !positions.cards && <StyledLoader />}
          {positions.cards && (
            <CardsRow>
              {positions.cards.map(({ id, ...props }) => (
                <Col md={4} key={id}>
                  <StyledJobPanel {...props} />
                </Col>
              ))}
            </CardsRow>
          )}
        </Container>
      </Section>

      <Section colour="pink">
        <Container>
          <Row>
            <Col>
              <Heading1>{faqs.heading}</Heading1>
            </Col>
          </Row>
          <CardsRow>
            {faqs.cards.map((props) => (
              <Col xs={12} key={props.heading}>
                <StyledCollapsiblePanel {...props} />
              </Col>
            ))}
          </CardsRow>
          <Row>
            <ButtonCol>
              <Button size="small" onClick={handleClickButton}>
                {faqs.button.label}
              </Button>
            </ButtonCol>
          </Row>
        </Container>
      </Section>
    </>
  );
};
