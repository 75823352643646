import React from "react";
import { NavLink } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { rem } from "polished";

import { device } from "../../../../utils/media-queries";
import { theme } from "../../../../theme";
import { theme as newTheme } from "../../../../theme/new";
import { config } from "../../../../config";

import { useMatchMedia } from "../../../../hooks/use-match-media";

const StyledLink = styled(NavLink)`
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.bigger};
  color: ${theme.color.ruubyNewBlue};
`;

const styles = {
  container: (isTablet: boolean): React.CSSProperties => ({
    flexDirection: isTablet ? "row" : "column",
    alignItems: "center",
    left: "auto",
    fontFamily: theme.fontName.main,
    fontSize: theme.fontSize.bigger,
    backgroundColor: theme.color.ruubyPink,
    color: theme.color.ruubyNewBlue,
    maxWidth: isTablet ? "80%" : "85%",
    marginLeft: newTheme.type.sizes.size36,
    marginRight: newTheme.type.sizes.size36,
    marginBottom: isTablet
      ? newTheme.type.sizes.size36
      : newTheme.type.sizes.size24,
    borderRadius: newTheme.type.sizes.size12,
    boxShadow: newTheme.shadows.panel,
    textAlign: isTablet ? "left" : "center",
    padding: newTheme.type.sizes.size8,
    zIndex: 10000000000,
  }),
  content: (): React.CSSProperties => ({
    flex: 1,
  }),
  button: (): React.CSSProperties => ({
    fontFamily: newTheme.type.fonts.main,
    fontSize: theme.fontSize.main,
    fontWeight: "bold",
    backgroundColor: theme.color.ruubyNewBlue,
    color: newTheme.colours.buttonText,
    height: newTheme.type.sizes.size32,
    textTransform: "uppercase",
    borderRadius: newTheme.type.sizes.size16,
    width: rem("160px"),
  }),
};

export const CookieBanner = (): JSX.Element => {
  const { matchesMedia: isTablet } = useMatchMedia(device.mobileL);

  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="CookieConsent"
      style={styles.container(isTablet)}
      contentStyle={styles.content()}
      buttonStyle={styles.button()}
      expires={config.cookieConsentExpirationDuration}
    >
      We use 1st, 3rd Party cookies and similar technologies to provide the best
      experience on our website. <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
    </CookieConsent>
  );
};
