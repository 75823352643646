export type ADD_TREATMENT = "treatments.ADD_TREATMENT";
export const ADD_TREATMENT: ADD_TREATMENT = "treatments.ADD_TREATMENT";

export type SET_TREATMENTS = "treatments.SET_TREATMENTS";
export const SET_TREATMENTS: SET_TREATMENTS = "treatments.SET_TREATMENTS";

export type REMOVE_TREATMENT = "treatments.REMOVE_TREATMENT";
export const REMOVE_TREATMENT: REMOVE_TREATMENT = "treatments.REMOVE_TREATMENT";

export type CLEAR_TREATMENTS = "treatments.CLEAR_TREATMENTS";
export const CLEAR_TREATMENTS: CLEAR_TREATMENTS = "treatments.CLEAR_TREATMENTS";

export type GET_FEATURED_TREATMENTS_LIST_ATTEMPT =
  "treatments.GET_FEATURED_TREATMENTS_LIST_ATTEMPT";
export const GET_FEATURED_TREATMENTS_LIST_ATTEMPT: GET_FEATURED_TREATMENTS_LIST_ATTEMPT =
  "treatments.GET_FEATURED_TREATMENTS_LIST_ATTEMPT";
export type GET_FEATURED_TREATMENTS_LIST_SUCCESS =
  "treatments.GET_FEATURED_TREATMENTS_LIST_SUCCESS";
export const GET_FEATURED_TREATMENTS_LIST_SUCCESS: GET_FEATURED_TREATMENTS_LIST_SUCCESS =
  "treatments.GET_FEATURED_TREATMENTS_LIST_SUCCESS";
export type GET_FEATURED_TREATMENTS_LIST_ERROR =
  "treatments.GET_FEATURED_TREATMENTS_LIST_ERROR";
export const GET_FEATURED_TREATMENTS_LIST_ERROR: GET_FEATURED_TREATMENTS_LIST_ERROR =
  "treatments.GET_FEATURED_TREATMENTS_LIST_ERROR";

export type GET_FEATURED_TREATMENTS_ATTEMPT =
  "treatments.GET_FEATURED_TREATMENTS_ATTEMPT";
export const GET_FEATURED_TREATMENTS_ATTEMPT: GET_FEATURED_TREATMENTS_ATTEMPT =
  "treatments.GET_FEATURED_TREATMENTS_ATTEMPT";
export type GET_FEATURED_TREATMENTS_SUCCESS =
  "treatments.GET_FEATURED_TREATMENTS_SUCCESS";
export const GET_FEATURED_TREATMENTS_SUCCESS: GET_FEATURED_TREATMENTS_SUCCESS =
  "treatments.GET_FEATURED_TREATMENTS_SUCCESS";
export type GET_FEATURED_TREATMENTS_ERROR =
  "treatments.GET_FEATURED_TREATMENTS_ERROR";
export const GET_FEATURED_TREATMENTS_ERROR: GET_FEATURED_TREATMENTS_ERROR =
  "treatments.GET_FEATURED_TREATMENTS_ERROR";

export type CLEAR_ERRORS = "treatments.CLEAR_ERRORS";
export const CLEAR_ERRORS: CLEAR_ERRORS = "treatments.CLEAR_ERRORS";
