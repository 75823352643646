import { DownloadQRModal } from "../../components/themed-v2/organisms/download-qr-modal";
import React, { useCallback, useState } from "react";

type UseDownloadAppLink = () => {
  QrModal: React.FC;
  handleClickDownloadApp: () => void;
};

export const useDownloadAppLink: UseDownloadAppLink = () => {
  const [visible, setVisible] = useState(false);

  const handleClickDownloadApp = useCallback(() => {
    const isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
    const isIos = /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase());

    if (isAndroid) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.ruubycustomerapp",
        "_blank",
      );
    } else if (isIos) {
      window.open(
        "https://itunes.apple.com/gb/app/ruuby/id951092698?mt=8",
        "_blank",
      );
    } else {
      setVisible(!visible);
    }
  }, []);

  const handleClose = useCallback(() => setVisible(false), []);

  return {
    handleClickDownloadApp,
    QrModal: () => (
      <DownloadQRModal isVisible={visible} onClose={handleClose} />
    ),
  };
};
