import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as PinImage } from "./pin.svg";

export const IconPin = styled(PinImage)`
  width: ${rem("13px")};
  height: ${rem("18px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
