import React from "react";
import styled from "styled-components";

import { Text, IconCaret } from "../../../../../../atoms";

interface IInputProps {
  isFocused: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  padding?: boolean;
}

interface IProps extends IInputProps {
  onCodeSelect(value: string): void;
  onPhoneInput(value: string): void;
  onToggleFocus(): void;
  onWrapperClick(e: React.MouseEvent<HTMLDivElement>): void;
  phoneNumber: string;
  selectedCodeIndex: number;
  selectedDialCode: string;
  inputRef: React.RefObject<HTMLInputElement>;
  selectRef: React.RefObject<HTMLSelectElement>;
  children?: React.ReactNode;
}

const CodeSelector = styled.select`
  position: absolute;
  left: 0;
  width: 100%;
  border: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: 16px;
  color: ${({ theme }) => theme.colours.textMain};
  &:disabled {
    cursor: default;
  }
`;

const InputCode = styled(Text)`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 2px;
  white-space: nowrap;
  cursor: pointer;

  svg {
    width: 6px;
    margin: 0px 6px;
    margin-top: 6px;
  }
`;

const InputPhoneSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #ffffff;
  border-bottom: solid 2px #eff0f2;
  cursor: text;

  ${(props: IInputProps) =>
    props.disabled ? `background-color: ${({ theme }) => theme.colours.ruubyBlueGrey}; cursor: default;` : undefined
  }

  padding: ${(props: IInputProps) => props.padding ? `0 16px 8px 0px` : `0 0px 8px 5px`};
`;

const SelectedCodeSpan = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: 16px;
  color: ${({ theme }) => theme.colours.textMain};
`;

const Input = styled.input`
  width: 100%;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: 16px;
  color: ${({ theme }) => theme.colours.textMain};
  background-color: #ffffff;
  border: 0;
  border-left: solid 2px #eff0f2;
  box-shadow: none;
  outline: none;
  padding-left: 6px;
  padding-bottom: 0;
  &::placeholder {
    font-style: italic;
  }
  &:-internal-autofill-selected {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1);
  }
`;

export const InputPhoneCode: React.FC<IProps> = (props) => {
  return (
    <InputPhoneSection
      isFocused={props.isFocused}
      disabled={props.disabled}
      onClick={props.onWrapperClick}
      padding={props.padding}
    >
      <InputCode>
        <>
          <SelectedCodeSpan>{props.selectedDialCode}</SelectedCodeSpan>
          <CodeSelector
            ref={props.selectRef}
            value={props.selectedCodeIndex}
            onChange={(event) => props.onCodeSelect(event.currentTarget.value)}
            disabled={props.disabled}
          >
            {props.children}
          </CodeSelector>
          <IconCaret />
        </>
      </InputCode>
      <Input
        name="phoneNumber"
        ref={props.inputRef}
        onBlur={props.onToggleFocus}
        onChange={(event) => props.onPhoneInput(event.currentTarget.value)}
        onFocus={props.onToggleFocus}
        value={props.phoneNumber}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        placeholder="Mobile number"
      />
    </InputPhoneSection>
  );
};
