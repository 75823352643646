import { ApiAddress } from "../services/api/addresses";
import { ICheckoutAddress } from "../presenters/booking-location/booking-location-presenter";

/**
 * Find address urn in users addresses from ICheckoutAddress object.
 * @param addressRecord
 * @param addresses
 * @returns
 */
const findAddressUrn = (
  addressRecord: ICheckoutAddress,
  addresses: ApiAddress[],
): string | undefined => {
  const address = addresses.find((currAddress) =>
    compareAddresses(addressRecord, currAddress),
  );

  if (typeof address !== "undefined") {
    return address.urn;
  }
};

export const hasAddress = (
  addressRecord: ICheckoutAddress,
  addresses: ApiAddress[],
): boolean => {
  return Boolean(findAddressUrn(addressRecord, addresses));
};

export const validateAddress = (
  address: ICheckoutAddress,
): string | undefined => {
  if (address.address1.trim().length < 2) {
    return "Please complete the first line of your address";
  } else if (address.postcode.trim().length < 2) {
    return "Please complete the postcode of your address";
  }
};

export const compareAddresses = (
  addressRecord1: ICheckoutAddress,
  addressRecord2: ICheckoutAddress,
): boolean => {
  return (
    addressRecord1.address1 === addressRecord2.address1 &&
    addressRecord1.address2 === addressRecord2.address2 &&
    addressRecord1.postcode === addressRecord2.postcode
  );
};
