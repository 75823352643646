import * as React from "react";
import styled from "styled-components";

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const TextDoubleHeaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
`;

const TextDoubleHeader: React.FC<IProps> = (props) => {
  return (
    <TextDoubleHeaderWrapper className={props.className}>
      {props.children}
    </TextDoubleHeaderWrapper>
  );
};

export { TextDoubleHeader };
