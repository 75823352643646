import * as React from "react";
import styled from "styled-components";

import { Button } from "../../../components/atoms";
import { Input } from "../../../components/molecules";
import { theme } from "../../../theme";

const EmailSubscribeWrapper = styled.div`
  form {
    display: inline-flex;
    align-items: flex-end;

    @media screen and (max-width: 992px) {
      width: 100%;

      > div {
        width: 100%;
      }
    }

    input {
      font-size: ${theme.fontSize.smaller};
      font-weight: 600;

      @media screen and (max-width: 992px) {
        font-size: ${theme.fontSize.bigger};
        width: 100%;
      }
    }

    button {
      margin-left: 12px;
      font-size: ${theme.fontSize.smaller};
      font-weight: 600;
    }
  }
`;

const EmailSubscribe: React.FC = () => {
  return (
    <EmailSubscribeWrapper>
      <form
        method="get"
        action="//share.hsforms.com/1F--nBZnYSBCHejAE1KhwNQ3scj5"
        target="_blank"
      >
        <Input
          type="email"
          name="email"
          inputStyle="line"
          placeholder="Enter your email"
          autoComplete="false"
        />
        <Button type="submit" name="subscribe" background="grey" size="tiny">
          Subscribe
        </Button>
      </form>
    </EmailSubscribeWrapper>
  );
};

export { EmailSubscribe };
