import React from "react";
import { Col, Container, Row } from "react-grid-system";

import { HeroContainer } from "../../../../atoms";
import { HeroPanelWithButton } from "../../../../molecules";

import image from "./hero.webp";

interface Props {
  onClickButton(): void;
}

export const AffiliatesHero = ({ onClickButton }: Props): JSX.Element => (
  <HeroContainer image={image}>
    <Container>
      <Row justify="center">
        <Col lg={7}>
          <HeroPanelWithButton
            heading="Become a Ruuby Affiliate"
            paragraphs={["Be Part of Revolutionising At-Home Beauty Treatments!", "Welcome to Ruuby's Affiliate Program, where beauty enthusiasts and content creators come together to elevate the at-home beauty experience."]}
            buttonLabel="Join now"
            onClickButton={onClickButton}
          />
        </Col>
      </Row>
    </Container>
  </HeroContainer>
);