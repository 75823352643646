import React from "react";
import styled from "styled-components";

import { TreatmentText } from "../treatment-text";
import { device } from "../../../../../../utils";

const TreatmentHeaderContainer = styled.h3<{
  topPosition: number;
}>`
  position: sticky;
  top: ${({ topPosition }) => `${topPosition}px`};
  z-index: 99;
  background-color: ${({ theme }) => theme.colours.background.greyFaint};
  padding: ${({ theme }) => `${theme.spacing.size18} ${theme.spacing.size20}`};
  margin: 0;

  @media ${device.tablet} {
    padding: ${({ theme }) =>
      `${theme.spacing.size16} ${theme.spacing.size28}`};
  }
`;

const TreatmentHeaderText = styled(TreatmentText)`
  font-weight: 600;
`;

interface Props {
  name: string;
  categoryUrn: string;
  topPosition: number;
}

export const TreatmentHeader = ({
  name,
  categoryUrn,
  topPosition,
}: Props): JSX.Element => (
  <TreatmentHeaderContainer
    topPosition={topPosition}
    id={`treatment-header-${categoryUrn}`}
  >
    <TreatmentHeaderText>{name}</TreatmentHeaderText>
  </TreatmentHeaderContainer>
);
