import articles from "./about-articles.json";

interface IAboutArticles {
  link: string;
  date: string;
  header: string;
}

const aboutArticlesData: IAboutArticles[] = articles as IAboutArticles[];

export { aboutArticlesData };
