import * as React from "react";
import styled from "styled-components";

import { Button, TopBar } from "../../../components/atoms";
import { Button as RoundedButton } from "../../themed-v2/atoms";
import { Container } from "../../../components/grid";
import { NavBurger, Mail, TopLogo } from "../../../components/molecules";
import { theme } from "../../../theme";
import { theme as newTheme } from "../../../theme/new";
import { isPaymentPage, noNavPages } from "../../../utils/nav-layout";
import { rem } from "polished";

interface IProps {
  isMobile: boolean;
  location: string;
  onDownload(): void;
  onShowMobileMenu(): void;
}

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderButton = styled(Button)`
  margin-left: 20px;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: ${theme.fontSize.main};
  font-weight: 500;

  @media screen and (max-width: 992px) {
    margin-left: 8px;
    padding: 8px 12px;
  }
`;

// TODO Use the new "theme" approach for the styles once the old "theme" is removed
const HeaderButtonRounded = styled(RoundedButton)`
  width: auto;
  height: ${rem("34px")};
  font-size: ${newTheme.type.sizes.size14};
  padding-left: ${newTheme.spacing.size14};
  padding-right: ${newTheme.spacing.size14};
  margin-left: ${newTheme.spacing.size18};
  margin-right: ${newTheme.spacing.size18};
`;

const TopBarHeader = TopBar.withComponent("header");

const HeaderBar: React.FC<IProps> = ({
  isMobile,
  location,
  onDownload,
  onShowMobileMenu,
}) => {
  return (
    <TopBarHeader>
      <Container limitedWidth>
        <HeaderLeftContainer>
          {(isMobile || noNavPages(location)) && (
            <React.Fragment>
              {isMobile && <NavBurger onToggleBurger={onShowMobileMenu} />}
              <TopLogo />
            </React.Fragment>
          )}
        </HeaderLeftContainer>
        <HeaderRightContainer>
          <Mail fontSize="main" mobileFontSize="smaller" />
          {!noNavPages(location) && !isMobile && (
            <HeaderButton size="tiny" background="blue" onClick={onDownload}>
              Get the app
            </HeaderButton>
          )}
          {isPaymentPage(location) && !isMobile && (
            <HeaderButtonRounded size="small" onClick={onDownload}>
              Download The Ruuby App
            </HeaderButtonRounded>
          )}
        </HeaderRightContainer>
      </Container>
    </TopBarHeader>
  );
};

export { HeaderBar };
