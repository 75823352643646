import styled from "styled-components";

export const Row = styled.div<{ marginTop?: boolean, gap?: boolean, display?: string, baseline?: boolean }>`
  display: inline-flex;
  flex-wrap: initial;
  align-items: ${(props) => props.baseline ? 'baseline' : 'center' };
  width: 100%;
  min-height: 30px;
  ${(props) => props.marginTop && `margin-top: 15px;` }
  ${(props) => props.gap && `
    gap: 20px 15px;
    align-content: center;
  ` }}
  ${(props) => props.display && `display: ${props.display};` }
`;
