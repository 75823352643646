import React, { useState, useEffect, useRef, useCallback } from 'react';

import { InputPhoneCode } from '../input-phone-code';
import { codesData } from '../../../../../../../static/country-telephone-codes'; 

export interface IPhoneNumberObject {
  codeIndex: number;
  dialCode: string;
  fullPhoneNumber: string;
  phoneNumber: string;
}

interface Props {
  preselectedPhoneNumber?: string;
  preselectedCodeIndex?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  padding?: boolean;
  onNumberChange(object: IPhoneNumberObject): void;
}

const GB_INDEX = 187;

export const InputPhone: React.FC<Props> = ({
  preselectedPhoneNumber,
  preselectedCodeIndex,
  disabled,
  autoFocus,
  padding,
  onNumberChange
}) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(preselectedPhoneNumber || '');
  const [selectedCodeIndex, setSelectedCodeIndex] = useState(preselectedCodeIndex || GB_INDEX);
  const [selectedDialCode, setSelectedDialCode] = useState(codesData[selectedCodeIndex].dial_code);

  useEffect(() => {
    if (
      preselectedPhoneNumber !== phoneNumber &&
      preselectedCodeIndex !== selectedCodeIndex
    ) {
      setPhoneNumber(preselectedPhoneNumber || "");
      setSelectedCodeIndex(preselectedCodeIndex || GB_INDEX);
      setSelectedDialCode(
        codesData[preselectedCodeIndex || GB_INDEX].dial_code,
      );
    }
  }, [preselectedPhoneNumber, preselectedCodeIndex]);

  useEffect(() => {
    const codeIndex = selectedCodeIndex;
    const dialCode = selectedDialCode;
    const fullPhoneNumber = `${dialCode}${phoneNumber}`;
    onNumberChange({
      codeIndex,
      dialCode,
      fullPhoneNumber,
      phoneNumber
    });
  }, [selectedDialCode]);

  const handleSelectCode = useCallback((value: string) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }    
    const codeIndex = Number.parseInt(value);
    setSelectedCodeIndex(codeIndex);
    setSelectedDialCode(codesData[codeIndex].dial_code);
    setPhoneNumber(phoneNumber);
  }, [phoneNumber, codesData]);

  const handleWrapperClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== selectRef.current && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectRef, inputRef]);

  const handlePhoneNumberChange = useCallback((value: string) => {
    const phoneNumber = value.replace(/\D|^[0]{2}/gi, '');
    
    setPhoneNumber(phoneNumber);
    
    const dialCode = selectedDialCode;
    const codeIndex = selectedCodeIndex;
    const fullPhoneNumber = `${dialCode}${phoneNumber}`;

    onNumberChange({
      codeIndex,
      dialCode,
      fullPhoneNumber,
      phoneNumber
    });
  }, [selectedCodeIndex, selectedDialCode, onNumberChange]);

  const toggleFocus = useCallback(() => setIsFocused(!isFocused), [isFocused]);

  return (
    <InputPhoneCode
      isFocused={isFocused}
      onCodeSelect={handleSelectCode}
      onPhoneInput={handlePhoneNumberChange}
      onToggleFocus={toggleFocus}
      onWrapperClick={handleWrapperClick}
      phoneNumber={phoneNumber}
      selectedCodeIndex={selectedCodeIndex}
      selectedDialCode={selectedDialCode}
      inputRef={inputRef}
      selectRef={selectRef}
      disabled={disabled}
      autoFocus={autoFocus}
      padding={padding}
    >
      {codesData.map((value, index) => (
        <option key={index} value={index}>
          {value.name} ({value.dial_code})
        </option>
      ))}
    </InputPhoneCode>
  );
}
