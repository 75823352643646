import { useCallback, useEffect, useMemo, useState } from "react";
import { addDays, parseISO, setHours, format, formatISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import {
  ApiError,
  BookingStatus,
  useTherapistsLazyQuery,
} from "../../../../../../services/api/types/graphql";
import { ListingTherapist } from "../../types";
import { config } from "../../../../../../config";
import { categories } from "../../../../../../utils/search";

export type UseTherapistListing = (params: {
  postcode?: string;
  treatmentUrn?: string;
  category: string;
  selectedDate: string;
  selectedTime: string;
}) => {
  therapists?: ListingTherapist[];
  error?: ApiError;
  isLoading: boolean;
  message?: string;
};

enum TherapistSearchState {
  INITIAL_SEARCH = "INITIAL_SEARCH",
  FULL_DAY_SEARCH = "FULL_DAY_SEARCH",
  NEXT_DAY_SEARCH = "NEXT_DAY_SEARCH",
  NO_RESULTS_FOUND = "NO_RESULTS_FOUND",
};

export const useTherapistListing: UseTherapistListing = ({
  postcode,
  category,
  treatmentUrn,
  selectedDate,
  selectedTime,
}) => {
  const [therapists, setTherapists] = useState<ListingTherapist[]>();
  const [error, setError] = useState<ApiError>();
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [selectedPostcode, setSelectedPostcode] = useState(postcode);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [searchState, setSearchState] = useState(TherapistSearchState.INITIAL_SEARCH);

  const getCategoriesForTherapist = useCallback(<T extends { name: string; }>(categories: T[]): string[] => {
    const uniqueCategories = Object.fromEntries(
      categories.map(({ name }) => [name.toLowerCase(), name]),
    );
    const selectedCategory = uniqueCategories[category.toLowerCase()];
    if (selectedCategory) {
      delete uniqueCategories[category.toLowerCase()];
      return [selectedCategory, ...[...Object.values(uniqueCategories)].sort()];
    }
    return [...Object.values(uniqueCategories)].sort();
  }, [category]);

  const setTherapistsResults = (therapists: ListingTherapist[]): void => {
    if (!therapists.length) {
      if (searchState === TherapistSearchState.INITIAL_SEARCH && selectedTime !== 'all') {
        setSearchState(TherapistSearchState.FULL_DAY_SEARCH);
        reload(selectedDate);
      } else if ([
        TherapistSearchState.FULL_DAY_SEARCH,
        TherapistSearchState.INITIAL_SEARCH,
      ].includes(searchState)) {
        setSearchState(TherapistSearchState.NEXT_DAY_SEARCH);
        reload(formatISO(tomorrow));
      } else {
        setSearchState(TherapistSearchState.NO_RESULTS_FOUND);
      }
    }

    setTherapists(therapists);
  }; 

  const [fetchTherapists, { loading: isLoading, refetch }] = useTherapistsLazyQuery({
    onCompleted: ({ therapists: data }) => {
      switch (data.__typename) {
        case "TherapistsResultSet":
          setError(undefined);
          setTherapistsResults(
            data.items
              .filter(( { availability }) => availability.length)
              .map<ListingTherapist>((t) => {
                const categories = getCategoriesForTherapist(
                  t.treatments.flatMap(({ treatment }) => treatment.categories[0] ?? [])
                );

                return {
                  urn: t.urn,
                  availability: t.availability.map(({ start, end }) => ({
                    start: utcToZonedTime(start, config.dateTime.timezone),
                    end: utcToZonedTime(end, config.dateTime.timezone),
                  })),
                  avatarImageUrl: t.avatarImageUrl || undefined,
                  bookings: {
                    lastTimeCreated: t.bookings.lastTimeCreated,
                  },
                  name: t.displayName,
                  reviews: {
                    totalAverageRating: t.reviews.totalAverageRating || undefined,
                    totalCount: t.reviews.totalCount,
                  },
                  tier: t.tier.tier,
                  categories,
                };
            }),
          );
          break;
        case "RuubyGraphError":
          setError(data.error);
      }
    },
    onError: () => setError(ApiError.GENERAL_ERROR),
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  // Debounce setting the selected postcode
  useEffect(() => {
    let timeout: NodeJS.Timer;

    if (isFirstLoad) {
      setSelectedPostcode(postcode ?? "");
      setIsFirstLoad(false);
    } else if (postcode) {
      timeout = setTimeout(() => {
        setSelectedPostcode(postcode);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [postcode]);

  const getAvailabilityRangeFilter = useCallback(
    (
      date: string,
      selectedTimePeriod: string,
    ): {
      fromDate: Date;
      toDate: Date;
    } => {
      const currSelectedDate = parseISO(date);
      const timeIntervals = config.dateTime.intervals;

      switch (selectedTimePeriod) {
        case "am":
          return {
            fromDate: setHours(currSelectedDate, timeIntervals.am.start),
            toDate: setHours(currSelectedDate, timeIntervals.am.end),
          };
        case "pm":
          return {
            fromDate: setHours(currSelectedDate, timeIntervals.pm.start),
            toDate: setHours(currSelectedDate, timeIntervals.pm.end),
          };
        case "eve":
          return {
            fromDate: setHours(currSelectedDate, timeIntervals.eve.start),
            toDate: setHours(currSelectedDate, timeIntervals.eve.end),
          };
        default:
          return {
            fromDate: setHours(currSelectedDate, timeIntervals.all.start),
            toDate: setHours(currSelectedDate, timeIntervals.all.end),
          };
      }
    },
    [],
  );

  const tomorrow = useMemo(() => addDays(parseISO(selectedDate), 1), [selectedDate]);

  useEffect(() => {
    setSearchState(TherapistSearchState.INITIAL_SEARCH);
    const { fromDate, toDate } = getAvailabilityRangeFilter(
      String(selectedDate),
      String(selectedTime),
    );

    const categoryUrn = categories.find(c => c.id === category?.toLowerCase())?.urn;

    if (!treatmentUrn && !categoryUrn) {
      throw new Error("Category not found");
    }

    fetchTherapists({
      variables: {
        postcode: selectedPostcode,
        ...(!treatmentUrn && { categoryUrn }),
        ...(treatmentUrn && { treatmentUrn }),
        availabilityFromTime: zonedTimeToUtc(
          fromDate,
          config.dateTime.timezone,
        ),
        availabilityToTime: zonedTimeToUtc(toDate, config.dateTime.timezone),
        limit: 100,
        status: BookingStatus.COMPLETED,
      },
    });
  }, [category, selectedDate, selectedTime, selectedPostcode]);

  const reload = useCallback(async (date: string): Promise<void> => {
    setError(undefined);
    try {
      setRefetchLoading(true);
      const { fromDate, toDate } = getAvailabilityRangeFilter(String(date), "");
      await refetch({
        availabilityFromTime: zonedTimeToUtc(
          fromDate,
          config.dateTime.timezone,
        ),
        availabilityToTime: zonedTimeToUtc(toDate, config.dateTime.timezone),
      });
    } catch {
      setError(ApiError.GENERAL_ERROR);
    } finally {
      setRefetchLoading(false)
    }
  }, [category, selectedDate, selectedTime, selectedPostcode]);

  const message = useMemo(() => {
    if (!isLoading && !refetchLoading) {
      if (therapists?.length) {
        if (searchState === TherapistSearchState.FULL_DAY_SEARCH) {
          return `No results could be found for the ${selectedTime === 'eve' ? 'evening' : selectedTime === 'am' ? 'morning' : 'afternoon'}, but these therapists are available at other times of the day:`;
        } else if (searchState === TherapistSearchState.NEXT_DAY_SEARCH) {
          return `No results could be found for the day you selected, but these therapists are available on the following day (${format(tomorrow, "dd MMM yyyy")}):`;
        }
      } else if (searchState === TherapistSearchState.NO_RESULTS_FOUND) {
        return 'No results could be found for the day you selected. Please try a different date.';
      }
    }
    
    return undefined;
  }, [searchState, therapists?.length, isLoading, refetchLoading, selectedTime, tomorrow]);

  return {
    therapists,
    error,
    isLoading: isLoading || refetchLoading,
    message,
  };
};
