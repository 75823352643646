import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import { device } from "../../../../../../utils";

import { Tab } from "./types";

const TabsContainer = styled.div<{ topOffset: number }>`
  position: sticky;
  width: 100%;
  height: 52px;
  top: ${({ topOffset }) => `${topOffset}px`};
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colours.background.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colours.ruubyLightGrey}`};
`;

const TabsList = styled.ul<{ tabsCountMobile: number; tabsCountDesktop: number; maxTabs: number; }>`
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  > span {
    flex: ${({ tabsCountMobile, maxTabs }) => maxTabs - tabsCountMobile};
  }

  @media ${device.laptop} {
    > span {
      flex: ${({ tabsCountDesktop, maxTabs }) => 
        maxTabs - tabsCountDesktop - 1};
    }
  }
`;

const TabElement = styled.li<{
  isShownOnDoubleColumnLayout: boolean;
  isSelected: boolean;
}>`
  flex: 1;
  border-bottom: 4px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colours.ruubyDarkGrey : "transparent"};
  transition: ${({ theme }) => theme.transition.easeInOut02};

  @media ${device.laptop} {
    ${({ isShownOnDoubleColumnLayout }) =>
      !isShownOnDoubleColumnLayout && "display: none;"}
  }
`;

const TabButton = styled.button<{ isSelected: boolean }>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: ${({ isSelected }) => (isSelected ? "600" : "400")};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colours.tab.textActive : theme.colours.tab.text};
  width: 100%;
  height: 100%;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

interface Props {
  isLoading: boolean;
  tabs: Tab[];
  activeTab: Tab;
  onClickTab(tab: Tab): void;
}

export const TherapistTabs = ({
  isLoading,
  tabs,
  activeTab,
  onClickTab,
}: Props): JSX.Element => {
  const headerHeight = document.querySelector("header")?.offsetHeight ?? 0;

  const tabsCountDesktop = useMemo(() => tabs.filter(tab => tab !== Tab.REVIEWS).length, [tabs]);

  const handleClickTab = useCallback((tab: Tab) => {
    if (!isLoading) {
      onClickTab(tab);
    }
  }, [isLoading, onClickTab]);

  return (
    <TabsContainer topOffset={headerHeight} id="therapist-tabs">
      <TabsList tabsCountMobile={tabs.length} tabsCountDesktop={tabsCountDesktop} maxTabs={4}>
        {tabs.map((tab, index) => (
          <TabElement
            key={`${tab}-${index}`}
            isShownOnDoubleColumnLayout={tab !== Tab.REVIEWS}
            isSelected={!isLoading && activeTab === tab}
          >
            <TabButton
              isSelected={!isLoading && activeTab === tab}
              onClick={() => handleClickTab(tab)}
            >
              {!isLoading ? tab : <Skeleton width={50} />}
            </TabButton>
          </TabElement>
        ))}
        <span></span>
      </TabsList>
    </TabsContainer>
  );
};
