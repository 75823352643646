import styled from "styled-components";

interface IProps {
  src: string;
  rounded?: boolean;
}

const Image = styled.img`
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;

  ${(props: IProps) => (props.rounded ? `border-radius: 50%;` : undefined)};
`;

export { Image };