import React from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";

import { Section, Heading1, Paragraph } from "../../../../atoms";
import { device } from "../../../../../../utils";

import massageImage from "./massage.webp";

const WhoWeAreImage = styled.img`
  border-radius: ${({ theme }) => theme.spacing.size8};
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  
  @media ${device.tablet} {
    height: 400px;
    max-width: 100%;
    object-position: 30% 0;
  }

  @media ${device.laptop} {
    height: auto;
    object-position: center;
  }
`;

const ContentCol = styled(Col)`
  margin-top: ${({ theme }) => theme.spacing.size20};
  text-align: center;

  h1,
  p {
    color: ${({ theme }) => theme.colours.white};
  }

  @media ${device.tablet} {
    margin-top: 0;
    text-align: left;

    h1,
    p {
      text-align: left;
    }
  }
`;

export const AffiliatesWho = (): JSX.Element => (
  <Section colour="darkGrey">
    <Container>
      <Row align="center">
        <Visible xs sm>
          <Col>
            <WhoWeAreImage src={massageImage} />
          </Col>
        </Visible>
        <ContentCol md={7}>
          <Heading1>Who we are</Heading1>
          <Paragraph large>
            Since our establishment, Ruuby has redefined beauty treatments by
            bringing salon-quality services directly to our customers'
            doorsteps. Our mission is to provide impeccable beauty experiences
            through a range of services from massages to manicures. We connect
            clients with top-tier beauty professionals available 24/7, ensuring
            convenience without compromising quality.
          </Paragraph>
        </ContentCol>
        <Hidden xs sm>
          <Col md={5}>
            <WhoWeAreImage src={massageImage} alt="Woman receiving massage by a Ruuby therapist" />
          </Col>
        </Hidden>
      </Row>
    </Container>
  </Section>
);
