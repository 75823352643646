import { IApiMarketingMessage } from "../../services/api/notifications";
import iassign from "immutable-assign";
import { ActionType, getType } from "typesafe-actions";

import { actionCreators } from "./actions";

// eslint-disable-next-line no-undef
export type Action = ActionType<typeof actionCreators>;

export interface IState {
  marketingMessage?: IApiMarketingMessage;
  marketingMessageError?: Error;
  marketingMessageLoading: boolean;
}

const INITIAL_STATE: IState = {
  marketingMessage: undefined,
  marketingMessageError: undefined,
  marketingMessageLoading: false,
};

// tslint:disable-next-line:completed-docs
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case getType(actionCreators.fetchMarketingMessageInit): {
      return iassign(state, (s) => {
        s.marketingMessageLoading = true;
        s.marketingMessageError = undefined;

        return s;
      });
    }
    case getType(actionCreators.fetchMarketingMessageSuccess): {
      return iassign(state, (s) => {
        s.marketingMessageLoading = false;
        s.marketingMessageError = undefined;
        s.marketingMessage = action.payload.message;

        return s;
      });
    }
    case getType(actionCreators.fetchMarketingMessageError): {
      return iassign(state, (s) => {
        s.marketingMessageLoading = false;
        s.marketingMessageError = action.payload.error;

        return s;
      });
    }
    default: {
      return state;
    }
  }
}
