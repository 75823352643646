import { config } from "../config";

import { ApiError, IApiErrorData } from "./error-handler";
import { getAuthToken } from "./token-storage";

interface IApiResponse<T> {
  result: T;
  error: IApiErrorData;
  headers: Headers;
}

const DeviceInfo = config.appApi.credentials.deviceId;
const baseUrl = config.appApi.host;
const error401 = 401;

class UnauthorisedError extends ApiError {
  constructor(message: IApiErrorData) {
    super(message);

    Object.setPrototypeOf(this, UnauthorisedError.prototype);
  }
}

/**
 * Handler for get requests
 */
export async function getHandler<T = {}>(
  path: string,
  authenticate = false,
): Promise<IApiResponse<T>> {
  const url = `${baseUrl}${path}`;

  const headers = new Headers({
    "Device-Id": DeviceInfo,
    // "X-Custom-User-Agent": "ruuby-customer-app",
  });

  if (authenticate) {
    const token = await getAuthToken();
    if (token) {
      headers.append("Authorization", token);
    }
  }

  const request: RequestInit = {
    headers,
    method: "GET",
  };

  const response = await fetch(url, request);

  if (response.status === error401) {
    const errorPayload = await response.json();

    throw new UnauthorisedError(errorPayload.error);
  }

  const data = await response.json();

  data.headers = response.headers;

  return data;
}

/**
 * Handler for post requests
 */
export async function postHandler<T = {}>(
  path: string,
  body: string,
  authenticate = false,
): Promise<IApiResponse<T>> {
  const url = `${baseUrl}${path}`;

  const headers = new Headers({
    "Content-Type": "application/json",
    "Device-Id": DeviceInfo,
    // "X-Custom-User-Agent": "ruuby-customer-app",
  });

  if (authenticate) {
    const token = await getAuthToken();
    if (token) {
      headers.append("Authorization", token);
    }
  }

  const request: RequestInit = {
    body,
    headers,
    method: "POST",
  };

  const response = await fetch(url, request);

  if (response.status === error401) {
    const errorPayload = await response.json();

    throw new UnauthorisedError(errorPayload.error);
  }

  const data = await response.json();

  data.headers = response.headers;

  return data;
}

/**
 * Handler for put requests
 */
export async function putHandler(
  path: string,
  body: string,
  authenticate: boolean,
) {
  const url = `${baseUrl}${path}`;

  const headers = new Headers({
    "Content-Type": "application/json",
    "Device-Id": DeviceInfo,
    // "X-Custom-User-Agent": "ruuby-customer-app",
  });

  if (authenticate) {
    const token = await getAuthToken();
    if (token) {
      headers.append("Authorization", token);
    }
  }

  const request: RequestInit = {
    body,
    headers,
    method: "PUT",
  };

  const response = await fetch(url, request);

  if (response.status === error401) {
    const errorPayload = await response.json();

    throw new UnauthorisedError(errorPayload.error);
  }

  const data = await response.json();

  data.headers = response.headers;

  return data;
}

/**
 * Handler for delete requests
 */
export async function deleteHandler(
  path: string,
  body: string | null,
  authenticate: boolean,
) {
  const url = `${baseUrl}${path}`;

  const headers = new Headers({
    "Content-Type": "application/json",
    "Device-Id": DeviceInfo,
    // "X-Custom-User-Agent": "ruuby-customer-app",
  });

  if (authenticate) {
    const token = await getAuthToken();
    if (token) {
      headers.append("Authorization", token);
    }
  }

  const request: RequestInit = {
    body,
    headers,
    method: "DELETE",
  };

  const response = await fetch(url, request);

  if (response.status === error401) {
    const errorPayload = await response.json();

    throw new UnauthorisedError(errorPayload.error);
  }

  const data = await response.json();

  data.headers = response.headers;

  return data;
}
