import * as React from "react";
import styled from "styled-components";

import { TextSection } from "../../../components/molecules";
import { Column, Container, Row } from "../../grid";
import { theme } from "../../../theme";
import { CategoryTile } from "../../../components/themed-v2/atoms";

import { categories } from "../../../types";

const CategorySelectTextSection = styled(TextSection)`
  display: inline-block;
  margin-top: 32px;
  margin-bottom: 40px;
  max-width: 362px;

  .title {
    font-family: ${theme.fontName.heading};
    font-size: ${theme.fontSize.headingBigger};
  }

  .text {
    font-size: ${theme.fontSize.main};
    font-weight: 700;
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 0;
  }
`;

const CategoryTileStyled = styled(CategoryTile)`
  margin: 18px;
  width: 120px;
  height: 120px;

  > span {
    font-size: ${({ theme }) => theme.type.sizes.size14};
  }
`;

export const CategorySelect: React.FC = () => (
  <Container limitedWidth>
    <Row>
      <Column textAlign="center">
        <CategorySelectTextSection
          title="Select a Treatment"
          text="Book London's best beauty therapists to your door, for five star manicures, blowdries, massages and more..."
        />
      </Column>
    </Row>
    <Row justifyContent="center">
      {categories.map((c) => (
        <CategoryTileStyled category={c} />
      ))}
    </Row>
  </Container>
);
