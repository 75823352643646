import { Fields } from "../../utils/class-types";
import { BookingLocationPresenter } from "./booking-location-presenter";

/**
 * Used to display "vague" areas. Currently defined by outward code of the postcode.
 */
export class AreaPresenter extends BookingLocationPresenter {
  public description!: string;
  public postcodeOutwardCode!: string;
  constructor(props: Fields<AreaPresenter>) {
    super();
    Object.assign(this, props);
  }
  public canCheckoutWithThisAddress() {
    return false;
  }
  public getTitle() {
    return this.description;
  }
  public getLabel(isMobile?: boolean) {
    return isMobile ? this.postcodeOutwardCode : "Your area";
  }
  public getIdentifierKey() {
    return "outcode";
  }
  public getIdentifierValue() {
    return this.postcodeOutwardCode;
  }
  public getPostCodeOutwardCode() {
    return this.postcodeOutwardCode;
  }
  public getShortAddress() {
    return this.getPostCodeOutwardCode();
  }
  public getCheckoutAddress() {
    // This doesn't seem right
    return {
      address1: "unknown",
      address2: "unknown",
      postcode: this.postcodeOutwardCode,
    };
  }
  public getSummaryAddress() {
    // Not intended to be used, but best effort
    return this.getTitle();
  }
}
