import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import LazyLoad from "react-lazy-load";

import { Card, Heading2, Paragraph } from "../../../components/themed-v2/atoms";
import { device } from "../../../utils/media-queries";

const FeaturedImage = styled.div<Pick<IProps, "imageUrl">>`
  width: 100%;
  background: url(${({ imageUrl }) => imageUrl}) no-repeat center;
  background-size: 100%;
  transition: background-size ${({ theme }) => theme.transition.easeInOut03};
  border-radius: 11px 11px 0 0;
  text-decoration: none;

  height: ${rem("153px")};

  @media ${device.tablet} {
    height: ${rem("153px")};
  }

  @media ${device.laptop} {
    height: ${rem("180px")};
  }
`;

const FeaturedTextContent = styled.div`
  padding: 0 ${rem("15px")} ${rem("15px")};
`;

const FeaturedHeading = styled(Heading2)`
  &&& {
    margin-top: ${rem("20px")};
    margin-bottom: ${rem("20px")};
  }
`;

const FeaturedItemCard = styled(Card)`
  width: ${rem("296px")};
  height: ${rem("473px")};

  @media ${device.tablet} {
    width: ${rem("296px")};
  }

  @media ${device.laptop} {
    width: ${rem("358px")};
  }

  &:hover {
    ${FeaturedImage} {
      background-size: 140%;
    }
  }
`;

const ParagraphStyled = styled(Paragraph)`
  line-height: ${rem("24px")};
`;

interface IProps {
  imageUrl: string;
  title: string;
  text: string;
  internalImage?: boolean;
}

export const FeaturedCard = ({
  imageUrl,
  title,
  text,
  internalImage,
}: IProps): JSX.Element => (
  <FeaturedItemCard>
    <LazyLoad offset={200}>
      <FeaturedImage
        imageUrl={
          internalImage
            ? (require(`../../../assets/${imageUrl}`) as string)
            : imageUrl
        }
      />
    </LazyLoad>
    <FeaturedTextContent>
      <FeaturedHeading>{title}</FeaturedHeading>
      <ParagraphStyled>{text}</ParagraphStyled>
    </FeaturedTextContent>
  </FeaturedItemCard>
);
