import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
`;

interface Props {
  icon: React.ReactNode;
  children: React.ReactNode;
}

export const TherapistDetailsWrapper = ({
  icon,
  children,
}: Props): JSX.Element => (
  <Container>
    <IconContainer data-testid="icon">{icon}</IconContainer>
    {children}
  </Container>
);
