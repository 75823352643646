import * as React from "react";

import { Badge, Text } from "../../../../components/atoms";

interface IProps {
  status: string;
}

const BookingStatus: React.FC<IProps> = (props) => {
  return (
    <Badge type={setStatusToType(props.status)}>
      <Text size="16" weight="semibold">
        {props.status}
      </Text>
    </Badge>
  );
};

// tslint:disable-next-line: completed-docs
function setStatusToType(status: string) {
  switch (status) {
    case "CONFIRMED":
      return "success";

    case "COMPLETED":
      return "completed";

    case "CANCELLED":
      return "error";

    default:
      return "completed";
  }
}

export { BookingStatus };
