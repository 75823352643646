import styled from "styled-components";

export interface ButtonProps {
  size?: "small";
  children?: React.ReactNode;
  className?: string;
}

const translateWidth = (size: ButtonProps["size"]): string => {
  switch (size) {
    default:
      return "156px";
  }
};

export const Button = styled.button<Pick<ButtonProps, "size">>`
  border: 0;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.buttonText};
  background-color: ${({ theme }) => theme.colours.buttonBackground};
  width: ${({ size }) => translateWidth(size)};
  height: 40px;
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer;
  padding-bottom: 4px;

  &:disabled {
    color: ${({ theme }) => theme.colours.buttonDisabledText};
    background-color: ${({ theme }) => theme.colours.buttonDisabledBackground};
    cursor: default;
  }
`;
