/**
 * Conditional navigation element
 * Can be label or block
 */
import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { theme } from "../../../theme";

interface IProps {
  activeClassName?: string;
  className?: string;
  href?: string;
  onClick?(e?: React.MouseEvent<HTMLElement>): void;
  target?: string;
  to?: string;
  type?: "label" | "block" | "link";
  children?: React.ReactNode;
}

const linkStyle = `
  display: inline-block;
  font-size: ${theme.fontSize.bigger};
  text-decoration: none;
  color: ${theme.color.ruubyBlack};
  user-select: none;
  transition: all ${theme.transition.linear03};

  &:hover,
  &.active {
    color: ${theme.color.borderColorNew};
  }
`;

const SimpleLink = styled.a`
  display: inline-block;
  text-decoration: none;
  color: ${theme.color.ruubyBlack};
  user-select: none;
  transition: all ${theme.transition.linear03};

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover,
  &.active {
    color: ${theme.color.borderColorNew};
  }
`;

const StyledLink = styled(NavLink)`
  ${linkStyle};
`;

const SimpleStyledLink = styled.a`
  ${linkStyle};
`;

const LabelWrapper = styled.div`
  position: relative;
  display: inline-block;
  font-weight: 600;

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const BlockWrapper = styled.div`
  border-bottom: 1px white;

  ${StyledLink} {
    padding: 5px 20px;
    white-space: nowrap;
    background: white;

    &:hover,
    &.active {
      background: ${theme.color.ruubyNewGrey};
      cursor: pointer;
    }
  }
`;

const NavLabel: React.FC<IProps> = (props) => {
  if (props.to) {
    return <StyledLink to={props.to} {...props} />;
  } else if (props.href) {
    return <SimpleStyledLink {...props} />;
  } else {
    return <LabelWrapper {...props} />;
  }
};

const NavBlock: React.FC<IProps> = (props) => {
  if (props.to) {
    return (
      <BlockWrapper>
        <StyledLink to={props.to} {...props} />
      </BlockWrapper>
    );
  } else if (props.href) {
    return (
      <BlockWrapper>
        <SimpleStyledLink {...props} />
      </BlockWrapper>
    );
  } else {
    return <BlockWrapper {...props} />;
  }
};

const NavStyledLink: React.FC<IProps> = (props) => {
  if (props.to) {
    return <StyledLink to={props.to} {...props} />;
  } else {
    return <SimpleLink {...props} />;
  }
};

const NavItem: React.FC<IProps> = (props) => {
  if (props.type === "block") {
    return <NavBlock {...props} />;
  } else if (props.type === "link") {
    return <NavStyledLink {...props} />;
  } else {
    return <NavLabel {...props} />;
  }
};

export { NavItem };
