import * as React from "react";
import styled from "styled-components";

import { Logo, NavItem } from "../../../components/atoms";
import { theme } from "../../../theme";

interface IProps {
  onClick?(e?: React.MouseEvent<HTMLElement>, close?: boolean): void;
  className?: string;
}

const TopLogoWrapper = styled.div`
  max-width: 100px;
  min-width: auto;

  svg {
    fill: ${theme.color.ruubyBlack};
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    svg {
      width: 90px;
      vertical-align: middle;
    }
  }
`;

const TopLogo: React.FC<IProps> = (props) => {
  return (
    <TopLogoWrapper className={props.className}>
      <NavItem
        type="link"
        to="/"
        onClick={(e) => props.onClick && props.onClick(e, true)}
      >
        <Logo />
      </NavItem>
    </TopLogoWrapper>
  );
};

export { TopLogo };
