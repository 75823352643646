import { config } from "./config";
import { App } from "./containers/app";
import { theme } from "./theme";
import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import { createRoot } from "react-dom/client";
import * as TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ApolloProvider } from "@apollo/client";
import { createGlobalStyle } from "styled-components";
import "react-loading-skeleton/dist/skeleton.css";

import { configureStore, history } from "./configureStore";
import { apolloClient } from "./apollo-client";

if (typeof config.analytics.hotJarId !== "undefined") {
  // tslint:disable-next-line:no-magic-numbers
  hotjar.initialize(config.analytics.hotJarId, 6);
}

if (typeof config.analytics.googleTagManagerId !== "undefined") {
  TagManager.initialize({ gtmId: config.analytics.googleTagManagerId });
}

const { store, persistor } = configureStore({} as any);

const GlobalStyle = createGlobalStyle`
  @-ms-viewport {
    width: device-width;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.fontName.main};
  }

  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    color: #212529;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }
`;

const render = (Component: React.ComponentClass): void => {
  const container = document.getElementById("root");
  if (container) {
    const root = createRoot(container);
    root.render(
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <GlobalStyle />
              <Component />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </ApolloProvider>,
    );
  }
};

render(App);

// tslint:disable-next-line:no-any
(window as any).Intercom("boot", {
  app_id: "wlgsuk0s",
});
