import * as React from "react";
import { Container } from "../../grid";

import { fetch } from "../../../services/api/static";

interface State {
  text: string;
}
export class FaqComponent extends React.Component {
  public state: State = {
    text: "",
  };

  public async componentDidMount() {
    const page = await fetch("faqs");

    this.setState({ text: page.text });
  }

  render() {
    return (
      <Container
        offsetPaddings
        style={{ textAlign: "center", fontSize: "1.1em" }}
      >
        <div
          style={{ lineHeight: "1.25rem" }}
          dangerouslySetInnerHTML={{ __html: this.state.text }}
        ></div>
      </Container>
    );
  }
}
