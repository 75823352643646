import React from "react";
import styled from "styled-components";

import { device } from "../../../../../../utils";
import { IconCalendar } from "../../../../atoms";
import { TherapistDetailsWrapper } from "../therapist-details-wrapper";

const IconCalendarStyled = styled(IconCalendar)`
  fill: ${({ theme }) => theme.colours.iconGrey};
  stroke: ${({ theme }) => theme.colours.iconGrey};
`;

const BookingsCompletedText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  line-height: ${({ theme }) => theme.type.sizes.size22};
  color: ${({ theme }) => theme.colours.textGrey};
  margin-left: ${({ theme }) => theme.spacing.size8};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

interface Props {
  numberOfCompletedBookings: number;
}

export const BookingsCompleted = ({
  numberOfCompletedBookings,
}: Props): JSX.Element => (
  <TherapistDetailsWrapper icon={<IconCalendarStyled />}>
    <BookingsCompletedText data-testid="bookings-completed">
      <Bold>{numberOfCompletedBookings}</Bold>
      {numberOfCompletedBookings > 1
        ? " bookings completed"
        : " booking completed"}
    </BookingsCompletedText>
  </TherapistDetailsWrapper>
);
