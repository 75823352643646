/**
 * Set unique string (id)
 */
export function uniqueId(prefix: string) {
  const n36 = 36;
  const n2 = 2;
  const n6 = 6;

  return `${prefix}${Math.random().toString(n36).substring(n2, n6)}`;
}
