import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { Button, Paragraph } from "../../../../components/atoms";
import { Input } from "../../../../components/molecules";
import { saveUserAddress } from "../../../../services/api/addresses";
import { theme } from "../../../../theme";
import { validateAddress } from "../../../../utils/addresses";

interface State {
  address1: string;
  address2: string;
  postcode: string;
  errorMessage: string;
}

const AddressSelectTitle = styled.h2`
  font-family: ${theme.fontName.heading};
  font-weight: normal;
  color: ${theme.color.ruubyGrey};
`;

const AddressSelectButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

const ErrorParagraph = styled(Paragraph)`
  color: ${theme.color.error};

  a {
    color: ${theme.color.error};
  }
`;

const FormRow = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
  }
`;

class Component extends React.Component<RouteComponentProps, State> {
  public state: State = {
    address1: "",
    address2: "",
    postcode: "",
    errorMessage: "",
  };

  public async handleSaveAddress() {
    const address = {
      address1: this.state.address1,
      address2: this.state.address2,
      postcode: this.state.postcode,
    };
    const errorMessage = validateAddress(address);

    if (typeof errorMessage !== "undefined") {
      this.setState({
        errorMessage,
      });
    } else {
      try {
        await saveUserAddress(address);
        this.handleBack();
      } catch (error) {
        if (error instanceof Error) {
          this.setState({
            errorMessage: error.message,
          });
        }
      }
    }
  }

  public handleBack() {
    this.props.history.goBack();
  }

  public renderError(): React.ReactNode {
    if (this.state.errorMessage !== "") {
      return <ErrorParagraph>{this.state.errorMessage}</ErrorParagraph>;
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <AddressSelectTitle>Add address</AddressSelectTitle>
        <FormRow>
          <Input
            label="First line of address"
            name="address1"
            value={this.state.address1}
            onChange={(e) =>
              this.setState({ ...this.state, address1: e.currentTarget.value })
            }
          />
        </FormRow>

        <FormRow>
          <Input
            label="Second line of address"
            name="address2"
            value={this.state.address2}
            onChange={(e) =>
              this.setState({ ...this.state, address2: e.currentTarget.value })
            }
          />
        </FormRow>

        <FormRow>
          <Input
            label="Postcode"
            name="postcode"
            value={this.state.postcode}
            onChange={(e) =>
              this.setState({
                ...this.state,
                postcode: e.currentTarget.value.toUpperCase(),
              })
            }
          />
        </FormRow>
        {this.renderError()}
        <AddressSelectButton
          size="small"
          onClick={this.handleSaveAddress.bind(this)}
        >
          Continue
        </AddressSelectButton>
        <AddressSelectButton size="small" onClick={this.handleBack.bind(this)}>
          Back
        </AddressSelectButton>
      </>
    );
  }
}

export const AddressCreate = withRouter(Component);
