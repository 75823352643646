import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import { IServiceTreatment } from "../../services/api/therapists";

import {
  actionCreators,
  IFeaturedTreatmentsList,
  IFeaturedTreatmentsService,
} from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

export interface IState {
  featuredTreatments: IFeaturedTreatmentsService;
  featuredTreatmentsList?: IFeaturedTreatmentsList;
  isFeaturedTreatmentsError: boolean;
  isFeaturedTreatmentsListError: boolean;
  isFeaturedTreatmentsListLoading: boolean;
  isFeaturedTreatmentsLoading: boolean;
  treatments: { [urn: string]: IServiceTreatment };
}

const INITIAL_STATE: IState = {
  featuredTreatments: {},
  featuredTreatmentsList: undefined,
  isFeaturedTreatmentsError: false,
  isFeaturedTreatmentsListError: false,
  isFeaturedTreatmentsListLoading: false,
  isFeaturedTreatmentsLoading: false,
  treatments: {},
};

/**
 * Reducer for treatments
 */
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.ADD_TREATMENT: {
      return iassign(
        state,
        (s) => s.treatments,
        (u) => {
          u[action.payload.data.urn] = {
            ...action.payload.data,
            count: u[action.payload.data.urn]
              ? (({ ...u[action.payload.data.urn] } as any).count += 1)
              : 1,
          };

          return u;
        },
      );
    }

    case types.REMOVE_TREATMENT: {
      return iassign(
        state,
        (s) => s.treatments,
        (u) => {
          const treatments = u[action.payload.data];
          if (treatments && treatments.count && treatments.count > 1) {
            u[action.payload.data] = {
              ...u[action.payload.data],
              count: (({ ...treatments } as any).count -= 1),
            };

            return u;
          } else {
            // tslint:disable-next-line: no-inferred-empty-object-type
            return Object.keys(u)
              .filter((treatment) => treatment !== action.payload.data)
              .reduce((accumulator, currentValue) => {
                accumulator[currentValue] = u[currentValue];

                return accumulator;
              }, {});
          }
        },
      );
    }

    case types.SET_TREATMENTS: {
      return iassign(state, (s) => {
        s.treatments = {
          ...action.payload.data,
        };

        return s;
      });
    }

    case types.CLEAR_TREATMENTS: {
      return iassign(state, (s) => {
        s.treatments = {};

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_LIST_ATTEMPT: {
      return iassign(state, (s) => {
        s.isFeaturedTreatmentsListLoading = true;
        s.isFeaturedTreatmentsListError = false;

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_LIST_SUCCESS: {
      return iassign(state, (s) => {
        s.featuredTreatmentsList = action.payload.data;
        s.isFeaturedTreatmentsListLoading = false;
        s.isFeaturedTreatmentsListError = false;

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_LIST_ERROR: {
      return iassign(state, (s) => {
        s.featuredTreatmentsList = undefined;
        s.isFeaturedTreatmentsListLoading = false;
        s.isFeaturedTreatmentsListError = true;

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_ATTEMPT: {
      return iassign(state, (s) => {
        s.isFeaturedTreatmentsLoading = true;
        s.isFeaturedTreatmentsError = false;

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_SUCCESS: {
      return iassign(state, (s) => {
        s.featuredTreatments = {
          ...s.featuredTreatments,
          ...action.payload.data,
        };
        s.isFeaturedTreatmentsLoading = false;
        s.isFeaturedTreatmentsError = false;

        return s;
      });
    }

    case types.GET_FEATURED_TREATMENTS_ERROR: {
      return iassign(state, (s) => {
        s.featuredTreatments = {};
        s.isFeaturedTreatmentsLoading = false;
        s.isFeaturedTreatmentsError = true;

        return s;
      });
    }

    case types.CLEAR_ERRORS: {
      return iassign(state, (s) => {
        s.isFeaturedTreatmentsError = false;
        s.isFeaturedTreatmentsListError = false;

        return s;
      });
    }

    default:
      return state;
  }
}
