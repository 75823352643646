import styled from "styled-components";

import { theme } from "../../../../theme";

interface IProps {
  isIcon?: boolean;
}

const InputRound = styled.input`
  width: 100%;
  padding: 10px 14px;
  border: 1px solid ${theme.color.borderColor};
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${theme.fontName.main};
  font-size: ${theme.fontSize.main};
  font-weight: 500;
  text-align: left;
  line-height: 17px;
  color: ${theme.color.ruubyGrey};
  outline: none;
  transition: all ${theme.transition.easeInOut03};

  ${(props: IProps) => props.isIcon && `text-indent: 26px;`}

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: ${theme.color.ruubyGreyLight};
  }

  @media screen and (max-width: 992px) {
    font-size: ${theme.fontSize.bigger};
  }
`;

export { InputRound };
