/**
 * Categories API
 */
import { ApiError } from "../error-handler";
import { getHandler } from "../request-handler";

export interface ICategory {
  id: string;
  name: string;
  image: string;
  code: string;
  showOnApp?: boolean;
}

interface IApiCategory {
  "@id": string;
  name: string;
  image: string;
  code: string;
  showOnApp?: boolean;
}

/**
 * Fetch ruuby categories with images links
 */
export async function fetchCategories(): Promise<ICategory[]> {
  const endPoint = `/service/category?selectAll=1` as string;

  const response = await getHandler<IApiCategory[]>(endPoint, false);

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result.map((category) => mapToCategory(category));
}

/**
 * Map categories results for store
 */
function mapToCategory(apiCategory: IApiCategory): ICategory {
  return {
    code: apiCategory.code,
    id: apiCategory["@id"],
    image: apiCategory.image,
    name: apiCategory.name,
    showOnApp: Boolean(apiCategory.showOnApp),
  };
}
