import React from "react";
import { rem } from "polished";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Tier } from "../../../../../../services/api/types/graphql";
import { Card } from "../../../../../atoms";
import { TherapistInfo } from "../therapist-info";
import { TherapistBio } from "../therapist-bio";
import { TherapistTierInfo } from "../therapist-tier-info";
import { BookingsCompleted } from "../bookings-completed";
import { LastBooked } from "../last-booked";
import { ShareButton } from "../share-button";
import { Therapist } from "../../types";

const TherapistCardInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing.size18} ${theme.spacing.size18} ${theme.spacing.size24}`};
  gap: ${({ theme }) => theme.spacing.size18};
`;

const TherapistDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.size8};
`;

const TherapistDetails = styled.div`
  min-height: ${rem("43px")};
  display: flex;
  align-items: center;
`;

interface Props {
  data?: Therapist;
  isLoading: boolean;
}

export const TherapistCard = ({ data, isLoading }: Props): JSX.Element => (
  <Card>
    <TherapistCardInnerContainer data-testid="therapist-card-container">
      <TherapistInfo
        name={data?.displayName}
        avatarImageUrl={data?.avatarImageUrl}
        averageRating={data?.reviews.totalAverageRating}
        numberOfRatings={data?.reviews.totalCount || 0}
      />
      <TherapistBio text={data?.bio} />
      <TherapistDetailsContainer>
        {!isLoading ? (
          <>
            <TherapistDetails>
              {data?.tier && data.tier.tier !== Tier.ON_DEMAND && (
                <TherapistTierInfo
                  tierType={data.tier.tier}
                  name={data.tier.name}
                  description={data.tier.description}
                />
              )}
            </TherapistDetails>
            {data && data.bookings.totalCount > 0 && (
              <TherapistDetails>
                <BookingsCompleted
                  numberOfCompletedBookings={data.bookings.totalCount}
                />
              </TherapistDetails>
            )}
            {data && data.bookings.lastTimeCreated && (
              <TherapistDetails>
                <LastBooked lastBookedDate={data.bookings.lastTimeCreated} />
              </TherapistDetails>
            )}
          </>
        ) : (
          <Skeleton width={200} containerTestId="therapist-details-skeleton" />
        )}
      </TherapistDetailsContainer>
      <ShareButton therapistName={data?.displayName} />
    </TherapistCardInnerContainer>
  </Card>
);
