import * as React from "react";
import { pure } from "recompose";
import styled from "styled-components";

import { AddressType } from "../../../../store/postcodes/reducer";
import { Text } from "../../../../components/atoms";

export interface IOrderSummaryAddressProps {
  addressType: AddressType;
  address?: string;
  isLoggedIn: boolean;
  changeAddress?: (addressType: AddressType) => void;
}

const addressTitles = {
  [AddressType.BOOKING_ADDRESS]: "Booking address",
  [AddressType.BILLING_ADDRESS]: "Billing address",
};

const addressSelectMessages = {
  [AddressType.BOOKING_ADDRESS]: "Please select a booking address",
  [AddressType.BILLING_ADDRESS]: "Please select a billing address",
};

const OrderSummaryAddressHeader = styled.div`
  display: flex;
  padding-bottom: 12px;
`;

const OrderSummaryAddressTitle = styled(Text)`
  flex: 1;
`;

const OrderSummaryAddressEditButton = styled.div`
  text-align: right;

  > div {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    vertical-align: top;
  }
`;

const OrderSummaryAddressSelectButton = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

const OrderSummaryAddress = ({
  isLoggedIn,
  changeAddress,
  address,
  addressType,
}: IOrderSummaryAddressProps): JSX.Element => {
  const renderChangeButton = () => {
    if (isLoggedIn && address) {
      return (
        <OrderSummaryAddressEditButton>
          {changeAddress && (
            <Text
              size="12"
              mobileSize="10"
              weight="semibold"
              transform="uppercase"
              onClick={() => changeAddress(addressType)}
            >
              Change
            </Text>
          )}
        </OrderSummaryAddressEditButton>
      );
    }
  };

  const renderSelectButton = () => {
    return (
      <>
        {changeAddress && (
          <OrderSummaryAddressSelectButton
            size="12"
            mobileSize="10"
            weight="semibold"
            onClick={() => changeAddress(addressType)}
          >
            {addressSelectMessages[addressType]}
          </OrderSummaryAddressSelectButton>
        )}
      </>
    );
  };

  const renderAddressString = () => {
    if (address) {
      return <Text weight="semibold">{address}</Text>;
    } else if (isLoggedIn) {
      return renderSelectButton();
    }
  };

  return (
    <React.Fragment>
      <OrderSummaryAddressHeader>
        <OrderSummaryAddressTitle size="14" weight="bold" transform="uppercase">
          {addressTitles[addressType]}
        </OrderSummaryAddressTitle>
        {renderChangeButton()}
      </OrderSummaryAddressHeader>
      {renderAddressString()}
    </React.Fragment>
  );
};

const pureComponent = pure(OrderSummaryAddress);

export { pureComponent as OrderSummaryAddress };
