import iassign from "immutable-assign";
import { ActionType } from "typesafe-actions";

// declare const __DEV__: boolean;
// iassign.freeze = __DEV__;

import { IAddress } from "../../services/api/postcodes";

import { actionCreators } from "./actions";

import * as types from "./action-types";

export type Action = ActionType<typeof actionCreators>;

/**
 * enum locationType
 *
 * Three possible values: address, area and unresolvedAddress.
 *
 * Represents the location we are targeting for the treatment selection process.
 *
 * address means that we have a full postal address and we should inflate the location
 * into an IAddress.
 *
 * area means that we are looking in a more vague area. For example, the first part of a postcode.
 *
 * unresolvedAddress represents when an area has been manually upgraded to an
 * address by adding line_1, line_2 and the rest of the postcode, but we haven't
 * looked up the full details in the address resolution service.
 */
export enum locationType {
  address = "ADDRESS",
  area = "AREA",
  unresolvedAddress = "UNRESOLVED_ADDRESS",
}

export enum AddressType {
  BOOKING_ADDRESS = "booking",
  BILLING_ADDRESS = "billing",
}

export interface IArea {
  postcodeOutwardCode: string;
  description: string;
}

export interface IUnresolvedAddress {
  line_1: string;
  line_2: string;
  postcode: string;
}
export interface IBookingLocation {
  type: locationType;
  value: IAddress | IArea | IUnresolvedAddress;
}

export interface IState {
  selectedSuggestedAddress?: string;
  bookingLocation?: IBookingLocation;
  billingLocation?: IBookingLocation;
}

const INITIAL_STATE: IState = {
  bookingLocation: undefined,
  billingLocation: undefined,
  selectedSuggestedAddress: undefined,
};

/**
 * Reducer for ideal-poscodes actions
 */
export function reducer(state: IState = INITIAL_STATE, action: Action): IState {
  switch (action.type) {
    case types.CLEAR_ADDRESS: {
      return iassign(state, (s) => {
        s.bookingLocation = undefined;
        s.selectedSuggestedAddress = undefined;

        return s;
      });
    }

    case types.SET_ADDRESS_MANUALLY: {
      return iassign(state, (s) => {
        s.bookingLocation = {
          type: action.payload.type || locationType.unresolvedAddress,
          value: action.payload.data,
        };

        return s;
      });
    }

    case types.SET_BILLING_ADDRESS_MANUALLY: {
      return iassign(state, (s) => {
        s.billingLocation = {
          type: action.payload.type || locationType.unresolvedAddress,
          value: action.payload.data,
        };

        return s;
      });
    }

    case types.SET_SELECTED_POSTCODE: {
      return iassign(state, (s) => {
        s.bookingLocation = {
          type: locationType.area,
          value: {
            description: `${action.payload.data} postcode`,
            postcodeOutwardCode: action.payload.data,
          },
        };

        return s;
      });
    }

    default:
      return state;
  }
}
