import * as React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import { Header, Text } from "../../../components/atoms";
import { Column, Container, Row } from "../../../components/grid";
import { CenteredTemplate } from "../../../components/templates/centered";

const NotFoundPageHeader = styled(Header)`
  font-size: 260px;

  @media screen and (max-width: 500px) {
    font-size: 180px;
  }
`;

const NotFoundPageTextStyled = styled(Text)`
  margin-top: 20px;
  padding-left: 34px;
`;

const NotFoundPage = () => {
  return (
    <CenteredTemplate>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <Container offsetPaddings>
        <Row justifyContent="center">
          <Column textAlign="center">
            <NotFoundPageHeader>404</NotFoundPageHeader>
            <NotFoundPageTextStyled size="24" weight="semibold">
              Sorry, page not found
            </NotFoundPageTextStyled>
          </Column>
        </Row>
      </Container>
    </CenteredTemplate>
  );
};

export { NotFoundPage };
