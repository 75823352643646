import React, { useMemo } from "react";
import styled from "styled-components";
import { isFuture, differenceInDays, differenceInMonths } from "date-fns";

import { device } from "../../../../../../utils";
import { IconFlash } from "../../../../atoms";
import { TherapistDetailsWrapper } from "../therapist-details-wrapper";

const IconFlashStyled = styled(IconFlash)`
  fill: ${({ theme }) => theme.colours.iconGrey};
`;

const LastBookedText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 400;
  font-style: italic;
  line-height: ${({ theme }) => theme.type.sizes.size22};
  color: ${({ theme }) => theme.colours.textGrey};
  margin-left: ${({ theme }) => theme.spacing.size8};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size16};
  }
`;

const getLastBookedLabel = (date: Date, locale: string = "en-GB") => {
  if (isFuture(date)) {
    return;
  }

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
  const now = new Date();

  const daysDifference = differenceInDays(date, now);
  const monthsDifference = differenceInMonths(date, now);

  if (daysDifference > -60) {
    return formatter.format(daysDifference, "days");
  } else if (daysDifference > -180) {
    return formatter.format(monthsDifference, "months");
  }
};

interface Props {
  lastBookedDate: Date;
}

export const LastBooked = ({
  lastBookedDate,
}: Props): JSX.Element | null => {
  const lastBookedLabel = useMemo(() => getLastBookedLabel(lastBookedDate), [lastBookedDate]);

  return lastBookedLabel ? (
    <TherapistDetailsWrapper icon={<IconFlashStyled />}>
      <LastBookedText data-testid="last-booked">
        Last booked {lastBookedLabel}
      </LastBookedText>
    </TherapistDetailsWrapper>
  ) : null;
};
