import * as React from "react";
import { JobsDetail } from "../../../components/organisms/jobs-group/jobs-detail";
import { MainTemplate } from "../../../components/templates/main";

interface IProps {
  id: string;
}
type Props = IProps;
const JobDetailPage: React.FC<Props> = (props) => {
  return (
    <MainTemplate>
      <JobsDetail id={props.id} />
    </MainTemplate>
  );
};
export { JobDetailPage };
