import React, { useCallback, useRef } from "react";
import styled from "styled-components";

import { ErrorText } from "../../../../atoms/errors/error-text";

import { InnerButton, Input, Text } from "../../../../atoms";
import { Row } from "../row";
import { Section } from "../section";
import { useBookingCheckoutContext } from "../../provider";

const PromoError = styled(ErrorText)`
  margin-top: 10px;
`;

const StyledInput = styled(Input)`
  padding-right: 60px;
`;

interface PromotionSectionProps {
  error?: string;
  isLoading: boolean;
  onHandlePromo: (promo: string) => void;
}

export const PromotionSection = ({ onHandlePromo, isLoading, error } : PromotionSectionProps ) : JSX.Element => {
  const promoRef = useRef<HTMLInputElement>(null);
  const { isBookingBeingPlaced } = useBookingCheckoutContext();

  const handlePromo = useCallback(async () => {
    if (promoRef.current && !isBookingBeingPlaced) {
      onHandlePromo(promoRef.current.value);
      promoRef.current.value = '';
    }
  }, [promoRef, isLoading, isBookingBeingPlaced]);

  return (
    <Section small>
      <Row>
        <Text flexGrow={1}>
          <StyledInput ref={promoRef} disabled={isLoading} placeholder="Promo or gift code" />
          <InnerButton floating onClick={handlePromo} disabled={isLoading}>Apply</InnerButton>
        </Text>
      </Row>
      {error && <Row gap><PromoError>{error}</PromoError></Row>}
    </Section>
  );
};
