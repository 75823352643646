import {
  addDays,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { rem } from "polished";
import React, { forwardRef, useCallback, useMemo } from "react";
import styled from "styled-components";

import { IconCaret } from "../../../../atoms/icons";

const CalendarContainer = styled.div`
  position: absolute;
  width: 270px;
  top: 45px;
  right: 50%;
  transform: translate(50%, 0);
  box-shadow: 0 1px 17px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: white;
  z-index: 99;
`;

const MonthSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem("10px")};
`;

const PreviousArrow = styled.div<{ isDisabled: boolean }>`
  svg {
    width: ${rem("18px")};
    height: ${rem("18px")};
    fill: ${({
      theme: {
        colours: { ruubyDarkGrey, ruubyLightGrey },
      },
      isDisabled,
    }) => (isDisabled ? ruubyLightGrey : ruubyDarkGrey)};
    transform: rotate(-90deg);
  }
  &:hover {
    cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  }
`;

const NextArrow = styled(PreviousArrow)`
  svg {
    transform: rotate(90deg);
  }
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  color: ${({ theme }) => theme.colours.ruubyDarkGrey};
  user-select: none;
`;

const WeekdaysContainer = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${({ theme }) => theme.colours.ruubyLightGrey};
`;

const WeekdayLabel = styled.span`
  color: ${({ theme }) => theme.colours.ruubyDarkGrey};
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  width: calc(100% / 7);
  text-align: center;
  user-select: none;
`;

const DatePickerDaysGridLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const DatePickerDayContainer = styled.div`
  flex-basis: calc(100% / 7);
  text-align: center;
  vertical-align: middle;
  &:nth-child(n - 8) {
    border-bottom: 0.5px solid ${({ theme }) => theme.colours.ruubyBlueGrey};
  }
`;

const DatePickerDay = styled.div<{
  isSelected: boolean;
  isDisabled: boolean;
  isToday: boolean;
  isInMonth: boolean;
}>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: ${({ isToday }) => (isToday ? 700 : 400)};
  padding: 5px;
  margin: 5px;
  user-select: none;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colours.ruubyPink : "transparent"};
  border-radius: ${({ isSelected }) => (isSelected ? "50%" : "none")};
  color: ${({
    theme: {
      colours: { ruubyLightGrey, ruubyDarkGrey, ruubyBlack },
    },
    isDisabled,
    isToday,
  }) => (isDisabled ? ruubyLightGrey : isToday ? ruubyBlack : ruubyDarkGrey)};
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  ${({ isInMonth }) => !isInMonth && "visibility: hidden"}
`;

interface Props {
  selectedDate?: Date;
  selectedMonth: Date;
  availability?: Date[];
  maxDaysRange?: number;
  onSelectDate(date: Date): void;
  onClickPreviousMonth?(): void;
  onClickNextMonth?(): void;
}

export const Calendar = forwardRef(
  (
    {
      selectedDate,
      selectedMonth,
      availability,
      maxDaysRange,
      onSelectDate,
      onClickPreviousMonth,
      onClickNextMonth,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const now = new Date();
    const daysOfMonth = useMemo(
      (): Date[] =>
        Array(7 * 6) // 6 weeks
          .fill(undefined)
          .map((_, i) =>
            addDays(
              startOfWeek(startOfMonth(selectedMonth), { weekStartsOn: 1 }),
              i,
            ),
          )
          .filter((d) => !isAfter(d, endOfMonth(selectedMonth))),
      [selectedMonth],
    );

    const isDayDisabled = useCallback(
      (day: Date) =>
        isBefore(startOfDay(day), startOfDay(now)) || availability
          ? !availability?.some((slot) => isSameDay(day, slot))
          : false ||
            (maxDaysRange
              ? !isBefore(
                  startOfDay(day),
                  startOfDay(addDays(now, maxDaysRange)),
                )
              : false),
      [availability, maxDaysRange, now],
    );

    const handleClickDay = useCallback((day: Date) => {
      if (!isDayDisabled(day)) {
        onSelectDate(day);
      }
    }, [isDayDisabled, onSelectDate]);

    return (
      <CalendarContainer
        ref={ref as React.RefObject<HTMLDivElement>}
        className="calendar"
      >
        <MonthSelector>
          <PreviousArrow
            onClick={onClickPreviousMonth}
            isDisabled={!Boolean(onClickPreviousMonth)}
          >
            <IconCaret />
          </PreviousArrow>
          <Label>{format(selectedMonth, "MMMM y")}</Label>
          <NextArrow
            onClick={onClickNextMonth}
            isDisabled={!Boolean(onClickNextMonth)}
          >
            <IconCaret />
          </NextArrow>
        </MonthSelector>
        <WeekdaysContainer>
          {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((weekDay) => (
            <WeekdayLabel key={weekDay}>{weekDay}</WeekdayLabel>
          ))}
        </WeekdaysContainer>
        <DatePickerDaysGridLayout>
          {daysOfMonth.map((day, index) => (
            <DatePickerDayContainer key={`${formatISO(day)}-${index}`}>
              <DatePickerDay
                isSelected={Boolean(
                  selectedDate && isSameDay(selectedDate, day),
                )}
                isInMonth={isSameDay(startOfMonth(day), selectedMonth)}
                isToday={isSameDay(now, day)}
                isDisabled={isDayDisabled(day)}
                onClick={() => handleClickDay(day)}
              >
                {format(day, "d")}
              </DatePickerDay>
            </DatePickerDayContainer>
          ))}
        </DatePickerDaysGridLayout>
      </CalendarContainer>
    );
  },
);
