import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import { Dispatch } from "redux";

import { Button, Paragraph } from "../../../../components/atoms";
import {
  BookingLocationPresenter,
  inflateBookingLocationPresenter,
} from "../../../../presenters/booking-location";
import {
  ApiAddress,
  fetchUserAddresses,
} from "../../../../services/api/addresses";
import { IRootState } from "../../../../store/index";
import { actionCreators as postcodeActions } from "../../../../store/postcodes/actions";
import { AddressType } from "../../../../store/postcodes/reducer";
import { theme } from "../../../../theme";
import { hasAddress } from "../../../../utils/addresses";
import { BookingParams, deserializeBookingParams, serializeBookingParams } from "../../../themed-v2/pages/checkout-page/provider";

interface IReduxProps {
  bookingLocation?: BookingLocationPresenter;
  billingLocation?: BookingLocationPresenter;
}

type Props = IReduxProps & RouteComponentProps & { dispatch: Dispatch<any> };

interface State {
  addressType: string;
  addresses: ApiAddress[];
}

const AddressSelectTitle = styled.h2`
  font-family: ${theme.fontName.heading};
  font-weight: normal;
  color: ${theme.color.ruubyGrey};
`;

const AddressSelectListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const AddressSelectAddressItem = styled.div`
  flex: 1;
`;

const AddressSelectAddressButton = styled(Button)`
  border-radius: 5px;
  margin-left: 10px;
`;

const AddressSelectButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

class Component extends React.Component<Props, State> {
  public state: State = {
    addressType: "",
    addresses: [],
  };

  public async componentDidMount() {
    this.setState({
      addressType: this.props.match.params["type"],
      addresses: await fetchUserAddresses(),
    });
  }

  public componentDidUpdate(prevProps: Props): void {
    const oldAddressType = prevProps.match.params["type"];
    const addressType = this.props.match.params["type"];

    if (addressType !== oldAddressType) {
      this.setState({
        addressType,
      });
    }
  }

  public handleSelectAddress(address: ApiAddress) {
    const addressType = this.state.addressType;
    const billingLocation = this.props.billingLocation;
    const selectedAddress = {
      line_1: address.address1,
      line_2: address.address2,
      postcode: address.postcode,
    };

    if (addressType === AddressType.BOOKING_ADDRESS) {
      this.props.dispatch(postcodeActions.setAddressManually(selectedAddress));

      serializeBookingParams({
        ...deserializeBookingParams() as BookingParams,
        addressUrn: address.urn,
        address,
      });
    }

    if (
      addressType === AddressType.BILLING_ADDRESS ||
      !billingLocation ||
      !hasAddress(billingLocation.getCheckoutAddress(), this.state.addresses)
    ) {
      this.props.dispatch(
        postcodeActions.setBillingAddressManually(selectedAddress),
      );
    }

    this.goToBooking();
  }

  public handleEditAddress(address: ApiAddress) {
    this.props.history.push(`/address/${address.urn}/edit`);
  }

  public handleNewAddress() {
    this.props.history.push(`/address/new`);
  }

  public goToBooking() {
    this.props.history.replace(`/booking`);
  }

  public renderAddressItems() {
    if (!this.state.addresses.length) {
      return <Paragraph>No addresses found</Paragraph>;
    }

    return this.state.addresses.map((address) => {
      return (
        <AddressSelectListItem>
          <AddressSelectAddressItem>
            {`${address.address1}, ${address.address2} ${address.postcode}`}
          </AddressSelectAddressItem>
          <AddressSelectAddressButton
            size="small"
            background="grey"
            onClick={this.handleSelectAddress.bind(this, address)}
          >
            Select
          </AddressSelectAddressButton>
          <AddressSelectAddressButton
            size="small"
            background="grey"
            onClick={this.handleEditAddress.bind(this, address)}
          >
            Edit
          </AddressSelectAddressButton>
        </AddressSelectListItem>
      );
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <AddressSelectTitle>
          Choose {this.state.addressType} address
        </AddressSelectTitle>
        {this.renderAddressItems()}
        <AddressSelectButton
          size="small"
          onClick={this.handleNewAddress.bind(this)}
        >
          Add new address
        </AddressSelectButton>
        <AddressSelectButton size="small" onClick={this.goToBooking.bind(this)}>
          Back
        </AddressSelectButton>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState): IReduxProps => {
  return {
    ...(state.postcodesState.bookingLocation && {
      bookingLocation: inflateBookingLocationPresenter(
        state.postcodesState.bookingLocation,
      ),
    }),
    ...(state.postcodesState.billingLocation && {
      billingLocation: inflateBookingLocationPresenter(
        state.postcodesState.billingLocation,
      ),
    }),
  };
};

export const AddressSelect = withRouter(
  connect<IReduxProps, {}, {}, IRootState>(mapStateToProps)(Component),
);
