export type LOGIN_USER = "user.LOGIN_USER";
export const LOGIN_USER: LOGIN_USER = "user.LOGIN_USER";

export type LOGOUT_USER = "user.LOGOUT_USER";
export const LOGOUT_USER: LOGOUT_USER = "user.LOGOUT_USER";

export type SET_USER_FIELDS = "user.SET_USER_FIELDS";
export const SET_USER_FIELDS: SET_USER_FIELDS = "user.SET_USER_FIELDS";

export type REGISTRATION_SUCCESS = "login.REGISTRATION_SUCCESS";
export const REGISTRATION_SUCCESS: REGISTRATION_SUCCESS = "login.REGISTRATION_SUCCESS";

export type REGISTRATION_FAILURE = "login.REGISTRATION_FAILURE";
export const REGISTRATION_FAILURE: REGISTRATION_FAILURE = "login.REGISTRATION_FAILURE";
