import styled from "styled-components";
import { rem } from "polished";

import { ReactComponent as StarImage } from "./star.svg";

export const IconStar = styled(StarImage)`
  width: ${rem("22px")};
  height: ${rem("22px")};
  fill: ${({ theme }) => theme.colours.icon};
`;
