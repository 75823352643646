import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { IconChevron } from "../../../atoms";

const BackButton = styled.button`
  width: ${rem("100px")};
  height: ${rem("36px")};
  background-color: ${({ theme }) =>
    theme.colours.button.whiteButton.background};
  border: none;
  border-radius: ${rem("18px")};
  box-shadow: ${({ theme }) => theme.shadows.panel};

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colours.button.whiteButton.backgroundActive};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  transform: rotate(180deg);

  svg {
    fill: ${({ theme }) => theme.colours.button.whiteButton.text};
  }
`;

const ButtonText = styled.span`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size16};
  font-weight: bold;
  line-height: ${({ theme }) => theme.type.sizes.size16};
  color: ${({ theme }) => theme.colours.button.whiteButton.text};
  margin-right: ${({ theme }) => theme.spacing.size8};
`;

interface Props {
  isBack: boolean;
  onClickBack(): void;
}

export const PageBackButton = ({
  isBack,
  onClickBack,
}: Props): JSX.Element => (
  <BackButton onClick={onClickBack}>
    <InnerContainer>
      <IconContainer>
        <IconChevron />
      </IconContainer>
      <ButtonText>{isBack ? "Back" : "Home"}</ButtonText>
    </InnerContainer>
  </BackButton>
);
