import React from "react";

import { CategoryTile } from "../../../../components/themed-v2/atoms";
import { SwiperCarousel } from "../../../../components/themed-v2/molecules";

import { categories } from "../../../../types";

export const CategoryTilesCarousel = (): JSX.Element => (
  <SwiperCarousel slidesPerView="auto" spaceBetween={21}>
    {categories.map((category) => (
      <CategoryTile size="small" category={category} />
    ))}
  </SwiperCarousel>
);
