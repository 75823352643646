import { ApiError } from "../services/api/types/graphql";


const apiErrorMessages = {
  [ApiError.BOOKING_PAYMENT_REQUEST_NOT_FOUND]: "There is no payment request for the booking or it is expired. Please contact Ruuby to request another link.",
  [ApiError.BOOKING_INCOMPATIBLE_STATUS]: "Booking status is not compatible with the operation.",
  [ApiError.THERAPIST_NOT_FOUND]: "The therapist was not found.", 
  [ApiError.GENERAL_ERROR]: "Something went wrong.",
  [ApiError.INVALID_DATES]: "Please enter valid start and end time.",
  [ApiError.INVALID_POSTCODE]: "Please enter a valid postcode."
};

export const getApiErrorText = (error: ApiError | undefined): string => {
  if (error && {}.hasOwnProperty.call(apiErrorMessages, error)) {
    return apiErrorMessages[error];
  }

  return apiErrorMessages[ApiError.GENERAL_ERROR];
};