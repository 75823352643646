import { useCallback, useState } from "react";
import * as analytics from "../../../../../../analytics";
import { ApiError, useOtpPhoneRequestMutation } from "../../../../../../services/api/types/graphql";

type UseOtpPhoneRequest = (params: { onSuccess(token: string): void }) => {
  isLoading: boolean;
  error?: ApiError;
  initiateOtpRequest(phone: string): void;
};

export const usePhoneOtpRequest: UseOtpPhoneRequest = ({ onSuccess }) => {
  const [error, setError] = useState<ApiError>();

  const [otpRequest, { loading: isLoading }] = useOtpPhoneRequestMutation({
    onCompleted: async ({ otpPhoneRequest: data }) => {
      switch (data.__typename) {
        case "OtpRequestSuccess":
          setError(undefined);
          onSuccess(data.token);
          break;
        case "RuubyGraphError":
          if (error) {
            analytics.track({
              name: analytics.AnalyticsEvents.LOGIN_SCREEN_VALIDATION_ERROR,
              payload: {
                error,
              },
            });  
          }
          setError(data.error);
          break;
      }
    },
    onError: () => {
      setError(ApiError.GENERAL_ERROR);
    },
    errorPolicy: "all",
  });

  const initiateOtpRequest = useCallback(
    async (phone: string) => {
      setError(undefined);

      analytics.track({
        name: analytics.AnalyticsEvents.PHONE_OTP_REQUESTED,
        payload: { phone },
      });

      await otpRequest({
        variables: {
          phone,
          deviceTime: new Date().toISOString(),
        },
      });
    },
    [otpRequest],
  );

  return {
    isLoading,
    error,
    initiateOtpRequest,
  };
};
