import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  position: relative;
  float: right;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colours.textBlue};
  color: white;
  padding: 0 10px 2px;
  margin-top: 3px;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-weight: bold;
`;

export const VerifiedLabel = (): JSX.Element => <Container>Verified</Container>;
