import * as React from "react";
import styled from "styled-components";

import {
  InputBox,
  InputLabel,
  InputLine,
  InputRound,
} from "../../../components/atoms";
import { uniqueId } from "../../../utils/uniqueId";

interface IProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  iconElement?: JSX.Element;
  innerRef?: React.RefObject<HTMLInputElement>;
  inputStyle?: "box" | "line" | "round";
  label?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  name?: string;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onClick?(e: React.MouseEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: "text" | "password" | "number" | "email";
  uniqueName?: boolean;
  value?: string;
  children?: React.ReactNode;
}

const InputIconWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);

  > svg {
    width: 18px;
    height: 18px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Boxed: React.FC<IProps> = (props) => {
  const { children, iconElement, ...noChildren } = props;

  return (
    <InputWrapper className={props.className}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      {props.iconElement && (
        <InputIconWrapper>{props.iconElement}</InputIconWrapper>
      )}
      <InputBox
        {...noChildren}
        isIcon={Boolean(props.iconElement)}
        name={props.uniqueName ? uniqueId("input-") : props.name}
      />
      {props.children}
    </InputWrapper>
  );
};

const Rounded: React.FC<IProps> = (props) => {
  const { innerRef, children, iconElement, ...noChildren } = props;

  return (
    <InputWrapper className={props.className}>
      {props.iconElement && (
        <InputIconWrapper>{props.iconElement}</InputIconWrapper>
      )}
      <InputRound
        {...noChildren}
        ref={innerRef}
        isIcon={Boolean(props.iconElement)}
        name={props.uniqueName ? uniqueId("input-") : props.name}
      />
      {props.children}
    </InputWrapper>
  );
};

const Lined: React.FC<IProps> = (props) => {
  const { children, iconElement, ...noChildren } = props;

  return (
    <InputWrapper className={props.className}>
      {props.iconElement && (
        <InputIconWrapper>{props.iconElement}</InputIconWrapper>
      )}
      <InputLine
        {...noChildren}
        isIcon={Boolean(props.iconElement)}
        name={props.uniqueName ? uniqueId("input-") : props.name}
      />
      {props.children}
    </InputWrapper>
  );
};

const Input: React.FC<IProps> = (props) => {
  switch (props.inputStyle) {
    case "line":
      return <Lined {...props} />;
    case "round":
      return <Rounded {...props} />;

    case "box":
    default:
      return <Boxed {...props} />;
  }
};

export { Input };
