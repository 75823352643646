import styled from "styled-components";

import { device } from "../../../../utils/media-queries";

interface Props {
  image: string;
}

export const HeroContainer = styled.div<Props>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  padding: ${({ theme }) => theme.spacing.size20 } 0;
  z-index: 99;
  position: relative;

  @media ${device.tablet} {
    padding: ${({ theme }) => theme.spacing.size32 } 0;
  }
`;
