import React from "react";
import styled from "styled-components";
import * as router from "react-router-dom";
import { rem } from "polished";

import { WithClassName } from "../../../../components/themed-v2/types";
import { device } from "../../../../utils/media-queries";

import { Panel } from "../panel";
import { Category } from "../../../../types";

import NailsImage from "./images/nails.png";
import HairImage from "./images/hair.png";
import MassageImage from "./images/massage.png";
import TanningImage from "./images/tanning.png";
import MakeupImage from "./images/makeup.png";
import FacialImage from "./images/facial.png";
import WaxingImage from "./images/waxing.png";
import EyesImage from "./images/eyes.png";
import WellnessImage from "./images/wellness.png";
import OsteopathyImage from "./images/osteopathy.png";
import HealthImage from "./images/health.png";
import AestheticsImage from "./images/aesthetics.png";

type Size = "small" | "normal";

const Container = styled(Panel)<{ background: string; size?: Size }>`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ size }) => (size === "small" ? rem("105px") : rem("130px"))};
  height: ${({ size }) => (size === "small" ? rem("105px") : rem("130px"))};
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background-size: 100%;
  background-image: ${({ background }) => `url(${background})`};
  background-position: center center;
  transition: background-size 0.3s ease-in-out;

  @media ${device.tablet} {
    width: ${({ size }) => (size === "small" ? rem("105px") : rem("140px"))};
    height: ${({ size }) => (size === "small" ? rem("105px") : rem("140px"))};
  }

  @media ${device.laptop} {
    width: ${({ size }) => (size === "small" ? rem("105px") : rem("220px"))};
    height: ${({ size }) => (size === "small" ? rem("105px") : rem("220px"))};
  }
`;

const Label = styled.span<{ size?: Size }>`
  font-family: ${({ theme }) => theme.type.fonts.main};
  font-size: ${({ theme }) => theme.type.sizes.size14};
  font-weight: 600;
  color: ${({ theme }) => theme.colours.textMain};
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  text-align: center;

  @media ${device.laptop} {
    font-size: ${({ theme, size }) =>
      size === "small" ? theme.type.sizes.size14 : theme.type.sizes.size20};
  }
`;

const Link = styled(router.Link)`
  display: inline-block;
  text-decoration: none;

  &:hover {
    ${Label} {
      color: ${({ theme }) => theme.colours.link.hover};
    }

    ${Container} {
      background-size: 120%;
    }
  }
`;

const categoryMap: Record<Category, { label: string; image: string }> = {
  [Category.NAILS]: { label: "Nails", image: NailsImage },
  [Category.HAIR]: { label: "Hair", image: HairImage },
  [Category.MASSAGE]: { label: "Massage", image: MassageImage },
  [Category.TANNING]: { label: "Tanning", image: TanningImage },
  [Category.MAKEUP]: { label: "Makeup", image: MakeupImage },
  [Category.FACIAL]: { label: "facial", image: FacialImage },
  [Category.WAXING]: { label: "waxing", image: WaxingImage },
  [Category.EYES]: { label: "Eyes", image: EyesImage },
  [Category.WELLNESS]: { label: "Wellness", image: WellnessImage },
  [Category.OSTEOPATHY]: { label: "Osteo / Physio", image: OsteopathyImage },
  [Category.HEALTH]: { label: "IV Drips", image: HealthImage },
  [Category.AESTHETICS]: { label: "Aesthetics", image: AestheticsImage },
};

type Props = {
  category: Category;
  size?: Size;
} & WithClassName;

export const CategoryTile = ({
  category,
  className,
  size,
}: Props): JSX.Element => {
  const { label, image } = categoryMap[category];

  return (
    <Link to={`/mobile-beauty/${category}`}>
      <Container background={image} className={className} size={size}>
        <Label size={size}>{label}</Label>
      </Container>
    </Link>
  );
};
