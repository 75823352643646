import React from "react";
import { rem } from "polished";
import styled from "styled-components";

import { device } from "../../../utils/media-queries";

import { Heading2, IconStar, Panel, Paragraph } from "../atoms";
import { WithClassName } from "../types";
import { Hidden, Visible } from "react-grid-system";

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled(Heading2)`
  flex: none;
  text-align: center;

  @media ${device.laptop} {
    min-height: ${rem("57px")};
  }
`;

const CustomerName = styled.span`
  font-weight: 600;
`;

const CustomerLabel = styled.span`
  font-style: italic;
  font-weight: 400;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem("12px")};

  @media ${device.laptop} {
    min-height: ${rem("57px")};
  }
`;

const ParagraphStyled = styled(Paragraph)`
  flex: auto;
  margin-top: 0;

  @media ${device.tablet} {
    text-align: center;
  }
`;

const StarContainer = styled.div`
  flex: none;

  > svg {
    @media ${device.tablet} {
      width: ${rem("30px")};
      height: ${rem("30px")};
    }
  }
`;

type Props = {
  review: string;
  customer?: string;
  logo?: JSX.Element;
} & WithClassName;

export const ReviewPanel = ({
  review,
  customer,
  logo,
  className,
}: Props): JSX.Element => (
  <PanelStyled className={className}>
    {Boolean(customer) && (
      <Heading>
        <CustomerName>{customer}</CustomerName>
        <Visible lg xl>
          <br />
        </Visible>
        <CustomerLabel>
          <Hidden lg xl>
            {" "}
            -{" "}
          </Hidden>
          Ruuby Customer
        </CustomerLabel>
      </Heading>
    )}
    {Boolean(logo) && <LogoContainer>{logo}</LogoContainer>}
    <ParagraphStyled>“{review}”</ParagraphStyled>
    <StarContainer>
      <IconStar />
      <IconStar />
      <IconStar />
      <IconStar />
      <IconStar />
    </StarContainer>
  </PanelStyled>
);
