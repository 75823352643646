import * as React from "react";
import styled from "styled-components";

import { NavItem } from "../../../../components/atoms";
import { Column, Container, Row } from "../../../grid";

import { theme } from "../../../../theme";
import { theme as newTheme } from "../../../../theme/new";

const SimpleFooterWrapper = styled.div<{ isWhite?: boolean }>`
  display: flex;
  align-items: center;
  border-top: solid 1px ${theme.color.borderColorGrey};
  background: ${({ isWhite }) =>
    isWhite ? newTheme.colours.background : theme.color.ruubyGreyBg};

  @media screen and (max-width: 992px) {
    ${Column} {
      padding-top: 10px;
    }
  }
`;

const SimpleFooterRow = styled(Row)`
  height: 60px;
  align-items: center;

  @media screen and (max-width: 992px) {
    height: auto;
    padding-bottom: 10px;
  }
`;

const SimpleFooterNavItem = styled(NavItem)`
  font-size: 14px;
`;

interface Props {
  isWhite?: boolean;
}

const SimpleFooter = ({ isWhite }: Props) => (
  <SimpleFooterWrapper id="footer" isWhite={isWhite}>
    <Container>
      <SimpleFooterRow>
        <Column sm="6" md="3" textAlign="center">
          <SimpleFooterNavItem href="tel:+4402074608319}">
            Contact Ruuby
          </SimpleFooterNavItem>
        </Column>
        <Column sm="6" md="3" textAlign="center">
          <SimpleFooterNavItem to="/faqs#payments-cancellations-refunds">
            Cancellation Policy
          </SimpleFooterNavItem>
        </Column>
        <Column sm="6" md="3" textAlign="center">
          <SimpleFooterNavItem to="/faqs">FAQs</SimpleFooterNavItem>
        </Column>
      </SimpleFooterRow>
    </Container>
  </SimpleFooterWrapper>
);

export { SimpleFooter };
