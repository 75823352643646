import * as React from "react";
import styled from "styled-components";

import { Header, Text } from "../../../../components/atoms";
import {
  BookingHistoryCard,
  BookingHistoryDetails,
} from "../../../../components/molecules";
import { BookingAction } from "../../../../containers/account/account";
import { Column, Row } from "../../../grid";
import { IApiBooking, IBooking } from "../../../../services/api/booking";
import { theme } from "../../../../theme";

interface IProps {
  bookings: IBooking;
  onBookingSelect(booking: IApiBooking, action: BookingAction): void;
  onMapOpen(w3wAddress: string): void;
}

const BookingHistoryHeader = styled(Header)`
  font-size: ${theme.fontSize.title};
  padding-bottom: 10px;
  margin-bottom: 17px;
  border-bottom: 1px solid ${theme.color.borderColor};
  text-align: center;
`;

const BookingHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BookingHistoryText = styled(Text)`
  padding: 20px 0;
  text-align: center;
`;

const noBookings = (upcoming: boolean) => {
  const text = `You have no ${upcoming ? "upcoming" : "completed"} bookings`;

  return (
    <BookingHistoryText color="grey" size="18" weight="bold">
      {text}
    </BookingHistoryText>
  );
};

const BookingHistory: React.FC<IProps> = (props) => {
  const mapBookings = (bookings: IApiBooking[], upcoming: boolean) => {
    return bookings.map((booking) => {
      return (
        <React.Fragment key={booking.urn}>
          <BookingHistoryCard booking={booking} onMapOpen={props.onMapOpen} />
          <BookingHistoryDetails
            booking={booking}
            onBookingSelect={props.onBookingSelect}
            upcoming={upcoming}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <BookingHistoryWrapper>
      <Row>
        <Column md="6">
          <BookingHistoryHeader>Upcoming Bookings</BookingHistoryHeader>
          {props.bookings.upcoming.length
            ? mapBookings(props.bookings.upcoming, true)
            : noBookings(true)}
        </Column>
        <Column md="6">
          <BookingHistoryHeader>Completed Bookings</BookingHistoryHeader>
          {props.bookings.completed.length
            ? mapBookings(props.bookings.completed, false)
            : noBookings(false)}
        </Column>
      </Row>
    </BookingHistoryWrapper>
  );
};

export { BookingHistory };
