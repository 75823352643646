import React from "react";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton'

import { device } from "../../../../../../utils";
import { TherapistRating } from "../../../../atoms/therapist-rating";
import { TherapistAvatar } from "../../../../atoms/therapist-avatar";

const TherapistInfoContainer = styled.div`
  display: flex;
`;

const TherapistInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing.size14};

  @media ${device.tablet} {
    margin-left: ${({ theme }) => theme.spacing.size8};
  }
`;

const TherapistName = styled.h2`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  font-size: ${({ theme }) => theme.type.sizes.size22};
  font-weight: 500;
  color: ${({ theme }) => theme.colours.textMain};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.size4};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size36};
    line-height: ${({ theme }) => theme.type.sizes.size36};
    margin-bottom: ${({ theme }) => theme.spacing.size18};
  }
`;

interface Props {
  name?: string;
  avatarImageUrl?: string;
  averageRating?: number;
  numberOfRatings?: number;
}

export const TherapistInfo = ({
  name,
  avatarImageUrl,
  averageRating,
  numberOfRatings,
}: Props): JSX.Element => (
  <TherapistInfoContainer data-testid="therapist-info">
    <TherapistAvatar name={name} avatarImageUrl={avatarImageUrl} />

    <TherapistInfoDetails>
      <TherapistName>{ name ? name : <Skeleton width={200} />}</TherapistName>
      {averageRating && (typeof numberOfRatings !== "undefined") && (
        <TherapistRating
          averageRating={averageRating}
          numberOfRatings={numberOfRatings}
          text="average rating"
        />
      )}
    </TherapistInfoDetails>
  </TherapistInfoContainer>
);
