import * as React from "react";

interface IProps {
  width?: string;
  height?: string;
}

// tslint:disable:max-line-length
const IconCaret = ({ width, height }: IProps) => {
  return (
    <svg
      enableBackground="new 0 0 29 14"
      height={height ?? "14"}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 29 14"
      width={width ?? "29"}
    >
      <polygon fill="#151614" points="0.15,0 14.5,14.35 28.85,0 " />
    </svg>
  );
};

export { IconCaret };
