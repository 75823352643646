import React from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";

import { Section, Heading1, Paragraph, Panel, Button } from "../../../../atoms";

const StyledSection = styled(Section)`
  padding-top: 0;
`;

const ReadyCard = styled(Panel)`
  background-color: ${({ theme }) => theme.colours.ruubyDarkGrey};
  text-align: center;
  box-shadow: none;

  h1,
  p {
    color: ${({ theme }) => theme.colours.white};
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.size8};
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.ruubyDarkGrey};
`;

interface Props {
  onClickButton(): void;
}

export const AffiliatesCta = ({ onClickButton }: Props): JSX.Element => (
  <StyledSection colour="white">
    <Container>
      <Row>
        <Col>
          <ReadyCard>
            <Heading1>Ready to Revolutionise Beauty Together?</Heading1>
            <Paragraph large>
              Join our network of affiliates and be part of reshaping the beauty
              industry! Take the first step toward a rewarding partnership with
              Ruuby by signing up today.
            </Paragraph>
            <StyledButton size="small" onClick={onClickButton}>
              Join now
            </StyledButton>
          </ReadyCard>
        </Col>
      </Row>
    </Container>
  </StyledSection>
);
