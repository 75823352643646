export const width = {
  mobileS: 320,
  mobileL: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};

export const device = {
  mobileS: `(min-width: ${width.mobileS}px)`,
  mobileL: `(min-width: ${width.mobileL}px)`,
  tablet: `(min-width: ${width.tablet}px)`,
  laptop: `(min-width: ${width.laptop}px)`,
  desktop: `(min-width: ${width.desktop}px)`,
  touch: "(hover: none)",
};
